import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportSummaryHeaderRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <Fragment>
            <tr className="dgdg-sales-store-bg-row">
                <td className="text-right dgdg-sales-store-bg-row-alt dgdg-font-blue">REPORT DATE</td>
                <td>
                    {
                        this.props.reportMonth
                            ? CommonService.formatDate(this.props.reportMonth)
                            : null
                    }
                </td>
                <td colSpan="2">AS OF 4AM</td>
                {
                    !this.props.isDetailsHidden
                        ? <Fragment>
                            <td colSpan="11" className="dgdg-sales-store-bg dgdg-no-border-right" />
                            <td colSpan="4" className="dgdg-font-grey dgdg-font-medium">Year-Over-Year Tracking Summary</td>
                        </Fragment>
                        : null
                }
            </tr>
            {
                !this.props.isDetailsHidden
                    ? <tr className="dgdg-table-v3-header">
                        <td className="dgdg-sales-store-bg dgdg-no-border-right" colSpan="15" />
                        <td>VOLUME %&Delta;</td>
                        <td>TOTAL GROSS %&Delta;</td>
                        <td>FRONT PVR $&Delta;</td>
                        <td>BACK PVR $&Delta;</td>
                    </tr>
                    : null
            }
        </Fragment>;
    }
}

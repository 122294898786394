import React, { Fragment } from "react";
import preval from "preval.macro"
import moment from "moment";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../dgdgtable/DGDGTableV3Component";

const buildDate = preval`
module.exports = (new Date()).toUTCString();
`;

export class DGDGFooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onLogClick = this.onLogClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
    }

    onLogClick(event) {
        this.setState({
            tableData: CommonService.sortJsonData(this.props.consoleData, "timestamp", "Desc", ""),
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onCancelClick(event) {
        this.setState({
            tableData: [],
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DGDGFooterComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <Fragment>
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                cssName="dgdg-console-log-dialog"
                showDialog={this.state.tableData && this.state.tableData.length > 0}
                title="DGDGDashWeb - Console Logs"
                onCancelClick={this.onCancelClick}
            >
                <div className="dgdg-report-body">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Timestamp" dataColumn="timestamp" sortColumn="timestamp" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Log Type" dataColumn="logType" sortColumn="logType" />
                            <DGDGTableColumnComponent headerText="Component" dataColumn="componentName" sortColumn="componentName" maxLength={25} />
                            <DGDGTableColumnComponent headerText="Function" dataColumn="functionName" sortColumn="functionName" maxLength={25} />
                            <DGDGTableColumnComponent headerText="Message" dataColumn="message" sortColumn="message" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </DGDGModalDialogComponent>
            <div className="fixed-bottom dgdg-footer-container">
                <div className="float-left">
                    <div className="d-inline-block" style={{ "lineHeight": "24px", "marginLeft": "5px" }}>
                        &copy; {moment().year()} - Del Grande Dealer Group
                    </div>
                </div>
                <div className="float-right">
                    {
                        this.props.canShowDevOptions
                            ? <div className="d-inline-block dgdg-desktop">
                                Instance: {this.props.instanceId}
                            </div>
                            : null
                    }
                    <div className="d-inline-block dgdg-icon">Built: {CommonService.formatDateTimeZone(buildDate)}</div>
                    {
                        this.props.canShowDevOptions
                            ? <div className="d-inline-block dgdg-desktop">
                                <button type="button" className="btn btn-link" style={{ "color": "white", "padding": "0px 8px" }} onClick={(event) => this.onLogClick(event)}>
                                    <i className="fas fa-align-justify" />
                                </button>
                            </div>
                            : null
                    }
                </div>
            </div>
        </Fragment>;
    }
}
import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";

/*import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";*/

import { SellToDGDGOffersQueryComponent } from "./SellToDGDGOffersQueryComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class SellToDGDGViewOffersComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            tableData: [],
            offerDetail: null,
            paintTable: false,
            showRawViewPopup: false,
            rawViewTitle: "",
            legendText: null,
            rawViewData: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onViewOfferDetailCallback = this.onViewOfferDetailCallback.bind(this);
        this.onToggleViewDetails = this.onToggleViewDetails.bind(this);
        this.onRawViewClick = this.onRawViewClick.bind(this);
        this.onCancelRawView = this.onCancelRawView.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCopyColumnClick = this.onCopyColumnClick.bind(this);
        this.onOfferNumberColumnCallback = this.onOfferNumberColumnCallback.bind(this);
        this.onVinColumnCallback = this.onVinColumnCallback.bind(this);
        this.onEmailColumnCallback = this.onEmailColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Sell To DGDG";
    statusData = [
        { key: "Created", value: "Created" },
        { key: "Purchased", value: "Purchased" }
    ];
    legacyCutOffDate = moment("06/15/2024");

    componentDidMount() {
        try {
            console.devLog("SellToDGDGViewOffersComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SellToDGDGCalculateOfferComponent/ViewOffers", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLegendText();
            CommonService.clientAzureStorageLog("SellToDGDGViewOffersComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-sell-trade-offer-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-sell-trade-offer-confirm" />&nbsp;&nbsp;&nbsp;Confirmed </div>
            <div className="d-inline-block dgdg-sell-trade-offer-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-sell-trade-offer-warn" />&nbsp;&nbsp;&nbsp;Warn </div>
            <div className="d-inline-block dgdg-sell-trade-offer-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-sell-trade-offer-error" />&nbsp;&nbsp;&nbsp;Error </div>
            <div className="d-inline-block dgdg-sell-trade-offer-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-sell-trade-offer-expired" />&nbsp;&nbsp;&nbsp;Expired </div>
            <div className="d-inline-block dgdg-sell-trade-offer-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-sell-trade-offer-source" />&nbsp;&nbsp;&nbsp;Internal </div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetReportClick(startOfferDate, endOfferDate, vin, email, showExpired) {
        try {
            if (startOfferDate.diff(this.legacyCutOffDate, "days") < 0) {
                startOfferDate = this.legacyCutOffDate;
            }

            this.setState({ isLoadingData: true });
            let inputData = {
                startOfferDate: startOfferDate ? CommonService.formatServerDateTime(startOfferDate.toString()) : null,
                endOfferDate: endOfferDate ? CommonService.formatServerDateTime(endOfferDate.toString()) : null,
                vin: vin,
                email: email,
                isLegacy: false
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGViewOffersComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetReport", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;

                    let currentDate = moment().format("MM/DD/YYYY");
                    tableData.forEach((responseItem, index) => {
                        if (responseItem.source === "DGDGDash") {
                            responseItem.source_cellCssName = "dgdg-sell-trade-offer-source";
                        }
                        if (responseItem.status === "Confirmed") {
                            responseItem.rowCssName = "dgdg-sell-trade-offer-confirm";
                        }
                        else if (responseItem.status === "Warn") {
                            responseItem.rowCssName = "dgdg-sell-trade-offer-warn";
                        }
                        else if (responseItem.status === "Error") {
                            responseItem.rowCssName = "dgdg-sell-trade-offer-error";
                        }

                        let expirationDate = moment(responseItem.expiration_date).format("MM/DD/YYYY");
                        if (moment(expirationDate).isBefore(currentDate)) {
                            responseItem.rowCssName = "dgdg-sell-trade-offer-expired";
                            responseItem.isExpired = true;
                        }
                        else {
                            responseItem.isExpired = false;
                        }
                    });

                    if (!showExpired) {
                        tableData = tableData.filter(responseItem => !responseItem.isExpired);
                    }

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/SellToDGDG/GetReport", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                id: rowData.id,
                offerNumber: rowData.offer_number,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGViewOffersComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/SaveOfferStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "Offer #" + rowData.offerNumber + " Status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/SaveOfferStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getVehicleConditionScore(rowData, vehicleCondition) {
        let score = vehicleCondition === "Like New" ? rowData.offer_detail.offer.calculationVariable.value.excellentCondition
            : vehicleCondition === "Very Good" ? rowData.offer_detail.offer.calculationVariable.value.veryGoodCondition
                : vehicleCondition === "Acceptable" ? rowData.offer_detail.offer.calculationVariable.value.goodCondition
                    : rowData.offer_detail.offer.calculationVariable.value.fairCondition;

        return vehicleCondition + " (" + score + ")";
    }

    onViewOfferDetailCallback(rowData) {
        let engine, transmission, driveTrain, exteriorColor;
        let nonTypicalOptions = "";
        let kbbMileageAdjustmentValue = 0, accidentDeduction = 0, keyDeduction = 0, generalAdjustment = 0, maxMileageAdjustmentDeduction = 0;
        if (rowData.offer_detail) {
            if (rowData.offer_detail.vehicleDetail && rowData.offer_detail.vehicleDetail.vehicleOptions) {
                engine = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Engine")[0];
                transmission = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Transmission")[0];
                driveTrain = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Drivetrain")[0];
                exteriorColor = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Exterior Color")[0];

                rowData.offer_detail.vehicleDetail.vehicleOptions.forEach((vehicleOption, vehicleIndex) => {
                    if (vehicleOption.isTypical === false) {
                        nonTypicalOptions += vehicleOption.optionName + " (" + vehicleOption.categoryGroup + "), ";
                    }
                });

                if (nonTypicalOptions.length > 0) {
                    nonTypicalOptions = nonTypicalOptions.trim().slice(0, -1);
                }
            }

            if (rowData.offer_detail.offer) {
                kbbMileageAdjustmentValue = rowData.offer_detail.offer.kbbMileageAdjustmentValue;
                accidentDeduction = rowData.offer_detail.offer.accidentDeduction;
                keyDeduction = rowData.offer_detail.offer.keyDeduction;
                generalAdjustment = rowData.offer_detail.offer.generalAdjustment;
                maxMileageAdjustmentDeduction = rowData.offer_detail.offer.maxMileageAdjustment;
            }
        }

        return <Fragment>
            <span className="btn-link" role="button" onClick={(event) => this.onToggleViewDetails(event, rowData)}>Detail
                {
                    rowData.showViewDetails
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </span>
            <a className="btn-link dgdg-icon" target="_blank" rel="noreferrer" href={rowData.offer_url}>
                Offer
            </a>
            <span className="btn-link dgdg-icon" role="button" onClick={(event) => this.onRawViewClick(event, rowData)}>
                Raw View
            </span>
            <a className={"btn-link dgdg-icon" + (rowData.salesforce_id ? "" : " disabled")} target="_blank" rel="noreferrer" href={"https://delgrandedealergroup.lightning.force.com/lightning/r/Lead/" + rowData.salesforce_id + "/view"}>
                SFSC
            </a>
            {
                rowData.showViewDetails
                    ? rowData.offer_detail
                        ? <table className="dgdg-sell-trade-offer-detail">
                            <thead>
                                <tr>
                                    <th style={{ "width": "9%" }}></th>
                                    <th style={{ "width": "15%" }}></th>
                                    <th style={{ "width": "8%" }}></th>
                                    <th style={{ "width": "10%" }}></th>
                                    <th style={{ "width": "10%" }}></th>
                                    <th style={{ "width": "8%" }}></th>
                                    <th style={{ "width": "5%" }}></th>
                                    <th style={{ "width": "12%" }}></th>
                                    <th style={{ "width": "11%" }}></th>
                                    <th style={{ "width": "12%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">Vehicle Details</td>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">AutoCheck Details</td>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">Vehicle Condition</td>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">Customer Information</td>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">Offer</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Offer Number:</td>
                                    <td>
                                        <div className="form-inline">
                                            <span className="dgdg-sell-trade-offer-detail-show-ellipsis" style={{ "maxWidth": "85%" }} title={rowData.offer_detail.header.offerNumber}>{rowData.offer_detail.header.offerNumber}</span>
                                            <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.header.offerNumber)}>
                                                <span className="far fa-copy" />
                                            </button>
                                        </div>
                                    </td>
                                    <td className="font-weight-bold">Owner:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.owners : ""}</td>
                                    <td className="font-weight-bold">Paint and Body:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.paintBody) : ""}</td>
                                    <td className="font-weight-bold">First:</td>
                                    <td>{rowData.offer_detail.customerInfo ? rowData.offer_detail.customerInfo.first : ""}</td>
                                    <td className="font-weight-bold">Typical Listing Price:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.typicalListingPrice) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Year:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.year : ""}</td>
                                    <td className="font-weight-bold">Accidents:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.accidentCount : ""}</td>
                                    <td className="font-weight-bold">Windows and Lights:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.windowsLights) : ""}</td>
                                    <td className="font-weight-bold">Last:</td>
                                    <td>{rowData.offer_detail.customerInfo ? rowData.offer_detail.customerInfo.last : ""}</td>
                                    <td className="font-weight-bold">Low Value:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.lowValue) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Make:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.make : ""}</td>
                                    <td className="font-weight-bold">Accident Response:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.accidentResponse : ""}</td>
                                    <td className="font-weight-bold">Wheels and Tires:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.wheelsTires) : ""}</td>
                                    <td className="font-weight-bold">Email:</td>
                                    <td>
                                        {
                                            rowData.offer_detail.customerInfo?.email
                                                ? <Fragment>
                                                    {rowData.offer_detail.customerInfo.email}
                                                    < button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.customerInfo.email)}>
                                                        <span className="far fa-copy" />
                                                    </button>
                                                </Fragment>
                                                : ""
                                        }
                                    </td>
                                    <td className="font-weight-bold">High Value:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.highValue) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Model:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.model : ""}</td>
                                    <td className="font-weight-bold">Issue Categories:</td>
                                    <td className="dgdg-sell-trade-offer-detail-show-ellipsis" title={rowData.offer_detail.offer ? (rowData.offer_detail.offer.issueCategories ? rowData.offer_detail.offer.issueCategories.join(", ") : "") : ""}>{rowData.offer_detail.offer ? (rowData.offer_detail.offer.issueCategories ? rowData.offer_detail.offer.issueCategories.join(", ") : "") : ""}</td>
                                    <td className="font-weight-bold">Upholstery:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.upholstery) : ""}</td>
                                    <td className="font-weight-bold">Phone:</td>
                                    <td>{rowData.offer_detail.customerInfo ? CommonService.formatPhoneNumber(rowData.offer_detail.customerInfo.phone) : ""}</td>
                                    <td className="font-weight-bold">High to Low Diff:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.highValue - rowData.offer_detail.offer.lowValue) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">License Plate:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.licensePlate : ""}</td>
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Interior Equipments:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.interiorEquipment) : ""}</td>
                                    <td className="font-weight-bold">Zip:</td>
                                    <td>{
                                        rowData.offer_detail.customerInfo?.zip
                                            ? <Fragment>
                                                {rowData.offer_detail.customerInfo.zip}
                                                <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.customerInfo.zip)}>
                                                    <span className="far fa-copy" />
                                                </button>
                                            </Fragment>
                                            : ""
                                    }
                                    </td>
                                    <td className="font-weight-bold">Mileage Adjustment:</td>
                                    <td className={kbbMileageAdjustmentValue < 0 ? "text-danger" : ""}>
                                        {
                                            (kbbMileageAdjustmentValue < 0 ? "-" : "") + CommonService.formatCurrency_2(kbbMileageAdjustmentValue).replace("(", "").replace(")", "")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">License Plate State:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.licensePlateState : ""}</td>
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Mechanical:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.mechanical) : ""}</td>
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Average Condition Factor:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatNumber_2(rowData.offer_detail.offer.conditionFactor) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Trim:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.trim : ""}</td>
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Keys:</td>
                                    <td>{rowData.offer_detail.vehicleCondition ? rowData.offer_detail.vehicleCondition.keys : ""}</td>
                                    <td colSpan="2" className="font-weight-bold dgdg-sell-trade-offer-detail-header">Calculation Configuration</td>
                                    <td className="font-weight-bold">Base Offer:</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.baseOfferRange) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Engine:</td>
                                    <td>{engine ? decodeURIComponent(engine.optionName) : ""}</td>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Calculation Rule:</td>
                                    <td>{(rowData.offer_detail.offer?.calculationVariable?.key["make"] ? rowData.offer_detail.offer.calculationVariable.key["make"] : "{any Make}")
                                        + " " + (rowData.offer_detail.offer?.calculationVariable?.key["year"] ? rowData.offer_detail.offer.calculationVariable.key["year"] : "{any Year}")
                                        + " " + (rowData.offer_detail.offer?.calculationVariable?.key["model"] ? rowData.offer_detail.offer.calculationVariable.key["model"] : "{any Model}")}</td>
                                    <td className="font-weight-bold">Accident Deductions:</td>
                                    <td className={accidentDeduction > 0 ? "text-danger" : ""}>
                                        {
                                            (accidentDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(accidentDeduction).replace("(", "").replace(")", "")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Transmission:</td>
                                    <td>{transmission ? decodeURIComponent(transmission.optionName) : ""}</td>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Accident Deduction:</td>
                                    <td>{(rowData.offer_detail.offer?.calculationVariable?.value["accidentDeductionPct"] ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value["accidentDeductionPct"] * 100) : 0) + "%"}</td>
                                    <td className="font-weight-bold">Key Deduction:</td>
                                    <td className={keyDeduction > 0 ? "text-danger" : ""}>
                                        {
                                            (keyDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(keyDeduction).replace("(", "").replace(")", "")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">DriveTrain:</td>
                                    <td>{driveTrain ? decodeURIComponent(driveTrain.optionName) : ""}</td>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Missing Key Deduction:</td>
                                    <td>{(rowData.offer_detail.offer?.calculationVariable?.value["missingKeyDeduction"] ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value["missingKeyDeduction"] * 100) : 0) + "%"}</td>
                                    <td className="font-weight-bold">General Adjustment:</td>
                                    <td className={generalAdjustment < 0 ? "text-danger" : ""}>
                                        {
                                            (generalAdjustment < 0 ? "-" : "") + CommonService.formatCurrency_2(generalAdjustment).replace("(", "").replace(")", "")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Color:</td>
                                    <td>{rowData.offer_detail.vehicleDetail.colorTitle ? rowData.offer_detail.vehicleDetail.colorTitle : (exteriorColor ? decodeURIComponent(exteriorColor.optionName) : "")}</td>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">General Adjustment:</td>
                                    <td>{(rowData.offer_detail.offer?.calculationVariable?.value["generalAdjustmentPct"] ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value["generalAdjustmentPct"] * 100) : 0) + "%"}</td>
                                    <td className="font-weight-bold">Max Mileage Adj Deduction:</td>
                                    <td className={maxMileageAdjustmentDeduction > 0 ? "text-danger" : ""}>
                                        {
                                            (maxMileageAdjustmentDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(maxMileageAdjustmentDeduction).replace("(", "-").replace(")", "")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Odometer:</td>
                                    <td>{
                                        rowData.offer_detail.vehicleDetail?.odometer
                                            ? <Fragment>
                                                {CommonService.formatNumber(rowData.offer_detail.vehicleDetail.odometer)}
                                                <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.vehicleDetail.odometer)}>
                                                    <span className="far fa-copy" />
                                                </button>
                                            </Fragment>
                                            : ""
                                    }
                                    </td>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">Max Mileage Adj Deduction:</td>
                                    <td>{(rowData.offer_detail.offer?.calculationVariable?.value["maxMileageAdjustmentPct"] ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value["maxMileageAdjustmentPct"] * 100) : 0) + "%"}</td>
                                    <td className="font-weight-bold">Offer (Rounded):</td>
                                    <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.offer) + (rowData.offer_detail.offer.offerTLPRatio ? " (" + CommonService.formatPercentage_1(rowData.offer_detail.offer.offerTLPRatio) + " of TLP)" : "") : ""}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="font-weight-bold">No Offer on Major Issues:</td>
                                    <td>{rowData.offer_detail.offer?.calculationVariable?.value["noOfferOnMajorIssues"] ? rowData.offer_detail.offer.calculationVariable.value["noOfferOnMajorIssues"] === true ? "true" : "false" : ""}</td>
                                    <td className="font-weight-bold">Error Message:</td>
                                    <td className="dgdg-sell-trade-offer-detail-show-ellipsis" title={rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}>{rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold text-nowrap">Vehicle Options:</td>
                                    <td colSpan="10">
                                        {nonTypicalOptions}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        : <DGDGSpinnerComponent showSpinner={!rowData.offer_detail} />
                    : null
            }
        </Fragment>;
    }

    onToggleViewDetails(event, rowData) {
        rowData.showViewDetails = rowData.showViewDetails ? false : true;
        this.setState({
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });

        try {
            if (!rowData.offer_detail) {
                this.setState({ isLoadingData: true });
                let inputData = {
                    offerNumber: rowData.offer_number
                };

                this.props.applicationInsights.trackTrace({ message: "/SellToDGDGViewOffersComponent/onToggleViewDetails", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/SellToDGDG/GetOfferDetails", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.offer_detail = parsedResponse;
                        this.setState({
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetOfferDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRawViewClick(event, rowData) {
        try {
            this.setState({
                rawViewTitle: "Offer: " + rowData.offer_number,
                showRawViewPopup: true
            });

            if (!rowData.offer_detail) {
                let inputData = {
                    offerNumber: rowData.offer_number
                };

                this.props.applicationInsights.trackTrace({ message: "/SellToDGDGViewOffersComponent/onRawViewClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/SellToDGDG/GetOfferDetails", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.offer_detail = parsedResponse;
                        this.setState({
                            rawViewData: JSON.stringify(parsedResponse, null, "\t")
                        });
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            else {
                this.setState({
                    rawViewData: JSON.stringify(rowData.offer_detail, null, "\t")
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelRawView(event) {
        try {
            this.setState({
                showRawViewPopup: false,
                rawViewTitle: "",
                rawViewData: null
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SellToDGDGViewOffersComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyColumnClick(event, columnValue) {
        try {
            navigator.clipboard.writeText(columnValue);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SellToDGDGViewOffersComponent", "onCopyColumnClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOfferNumberColumnCallback(rowData) {
        return <div className="form-inline dgdg-sell-trade-offer-number-column">
            <span className="dgdg-sell-trade-offer-detail-show-ellipsis" style={{ "maxWidth": "85%" }} title={rowData.offer_number}>{rowData.offer_number}</span>
            <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_number)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    onVinColumnCallback(rowData) {
        return <div className="form-inline" >
            <span title={rowData.vin}>{rowData.vin}</span>
            <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.vin)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    onEmailColumnCallback(rowData) {
        return <div className="form-inline dgdg-sell-trade-email-column">
            <span title={rowData.email}>{rowData.email}</span>
            <button type="button" className="btn btn-link dgdg-sell-trade-offer-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.email)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                title={this.state.rawViewTitle}
                showDialog={this.state.showRawViewPopup}
                cssName="dgdg-sell-trade-offer-raw-view-dialog"
                onCancelClick={this.onCancelRawView}
            >
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyColumnClick(event, this.state.rawViewData)}>
                        Copy <span className="far fa-copy dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                {
                    this.state.rawViewData
                        ? <div className="dgdg-report-body">
                            <div className="card">
                                <div className="card-block">
                                    <div className="form">
                                        <DGDGFormTextAreaComponent id="offerRawView" value={this.state.rawViewData} rows="25" readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <DGDGSpinnerComponent showSpinner={!this.state.rawViewData} />
                }
            </DGDGModalDialogComponent>
            <SellToDGDGOffersQueryComponent
                applicationInsights={this.props.applicationInsights}
                minDate={moment().subtract(2, "years").startOf("year").format("MM/DD/YYYY")}
                maxDate={moment().format("MM/DD/YYYY")}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onShowAlert={this.props.onShowAlert}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText} tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Source" dataColumn="source" sortColumn="source" filterColumn="source" />
                        <DGDGTableColumnComponent headerText="Offer Number" dataColumnCallback={this.onOfferNumberColumnCallback} headerCssName="dgdg-sell-trade-offer-number-column" dataColumn="offer_number" sortColumn="offer_number" filterColumn="offer_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumnCallback={this.onVinColumnCallback} dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                        <DGDGTableColumnComponent headerText="License Plate" dataColumn="license_plate" sortColumn="license_plate" filterColumn="license_plate" />
                        <DGDGTableColumnComponent headerText="Offer Date" dataColumn="offer_date" sortColumn="offer_date" filterColumn="offer_date" dataType="dateTime" />
                        <DGDGTableColumnComponent headerText="Effective Date" dataColumn="effective_date" sortColumn="effective_date" filterColumn="effective_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Expiration Date" dataColumn="expiration_date" sortColumn="expiration_date" filterColumn="expiration_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Vehicle" dataColumn="vehicle" sortColumn="vehicle" filterColumn="vehicle" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Email" dataColumnCallback={this.onEmailColumnCallback} dataColumn="email" sortColumn="email" filterColumn="email" />
                        <DGDGTableColumnComponent headerText="Offer" dataColumn="dgdg_offer" sortColumn="dgdg_offer" filterColumn="dgdg_offer" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                        {/*<DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" data={this.statusData} itemKey="value" onItemClick={this.onStatusChange} showSpinner />*/}
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent colSpan="13" dataColumnCallback={this.onViewOfferDetailCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGImportWizardComponent } from "../common/dgdgimportwizard/DGDGImportWizardComponent";
import { DGDGTableV2Component } from "../common/dgdgtable/DGDGTableV2Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomComponent } from "../common/dgdgtable/DGDGTableCustomComponent";

import { FIPayrollQueryComponent } from "./FIPayrollQueryComponent";
import { FIPayrollDetailComponent } from "./FIPayrollDetailComponent";
import { FIPayrollDetailEditComponent } from "./FIPayrollDetailEditComponent";
import { FIEmployeePayEditComponent } from "./FIEmployeePayEditComponent";

export class FIPayrollComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            isProcessingAdjustments: false,
            tableData: null,
            months: CommonService.getMonths(),
            selectedSetupMonth: null,
            selectedSetupStore: null,
            setupAdditionalEmployeeNumber: null,
            payrollDetail: null,
            paintTable: false,
            selectedEmployee: null,
            isPayrollEditEnabled: true,
            onLoadMessage: "Select Store, Month and click Get Payroll",
            canProcess: false,

            showImportPayrollDataDialog: false,
            importPayrollDataTitle: "",
            importPayrollDataTextData: "",
            importPayrollDataIsValid: false,
            importPayrollDataTableColumns: null,
            importPayrollDataTableData: [],
            importPayrollDataShowTableSpinner: false,
            importPayrollDataPaintTable: false,
            importPayrollDataVerifyStatus: "",
            importPayrollDataStatus: "",

            isSaveComplete: false,

            isDeveloper: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onPayPlanEditCellClick = this.onPayPlanEditCellClick.bind(this);
        this.onPayPlanSaveClick = this.onPayPlanSaveClick.bind(this);
        this.onPayPlanCancelClick = this.onPayPlanCancelClick.bind(this);
        this.onPayrollDetailEditCellClick = this.onPayrollDetailEditCellClick.bind(this);
        this.onPayrollDetailSaveClick = this.onPayrollDetailSaveClick.bind(this);
        this.onPayrollDetailCancelClick = this.onPayrollDetailCancelClick.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDownload2Click = this.onDownload2Click.bind(this);
        this.onProcessAdjustments = this.onProcessAdjustments.bind(this);

        this.onImportPayrollDataClick = this.onImportPayrollDataClick.bind(this);
        this.onVerifyImportPayrollDataClick = this.onVerifyImportPayrollDataClick.bind(this);
        this.onConfirmImportPayrollDataClick = this.onConfirmImportPayrollDataClick.bind(this);
        this.onCancelImportPayrollDataClick = this.onCancelImportPayrollDataClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FIPayrollComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIPayroll/Payroll", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLastUpdatedDate();
            this.getUserRole();
            CommonService.clientAzureStorageLog("FIPayrollComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Payroll";

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    getLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getUserRole() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/getUserRole", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/GetUserRole")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isDeveloper: parsedResponse });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetUserRole", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth, selectedStore, additionalEmployeeNumber) {
        if (selectedMonth !== null && selectedStore !== null) {
            this.setState({
                onLoadMessage: null,
                isLoadingData: true,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            try {
                let inputData = {
                    month: CommonService.formatServerDateTime(selectedMonth),
                    storeNumber: selectedStore.store_number,
                    additionalEmployeeNumber: additionalEmployeeNumber
                };

                this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onGetPayrollClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/FIPayroll/GetFIPayroll", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = CommonService.addOrdinalColumn(parsedResponse.data);
                        tableData.forEach((rowData) => {
                            rowData.disable_edit = !rowData.can_edit;
                        });
                        this.setState({
                            isLoadingData: false,
                            selectedSetupMonth: selectedMonth,
                            selectedSetupStore: selectedStore,
                            setupAdditionalEmployeeNumber: additionalEmployeeNumber,
                            tableData: tableData,
                            paintTable: true,
                            isPayrollEditEnabled: moment(selectedMonth) > moment().startOf("month").subtract(2, "month"),
                            canProcess: parsedResponse.canProcess
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetFIPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            catch (error) {
                console.devError(error, null, this.props.applicationInsights);
            }
        }
    }

    onDownloadClick(selectedMonth, selectedStore) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownload2Click(selectedMonth, selectedStore) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onDownload2Click", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/Download2", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/Download2", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    importBulkUpdateTSVHeader = "PayrollMonth\tEmployeeNumber\tEmployeeName\tPositionCode\tStoreNumber\tStoreName\tCSI\tAlly\tPay1CIT\tPay2CIT\tDMV";
    onImportPayrollDataClick(startDate) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onImportPayrollDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/ImportPayrollData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        showImportPayrollDataDialog: true,
                        importPayrollDataTextData: parsedResponse,
                        importPayrollDataTitle: "Import F&I Payroll Data for " + CommonService.formatMonthYear(moment(startDate))
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/ImportPayrollData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVerifyImportPayrollDataClick(event, tsvText) {
        try {
            this.setState({
                importPayrollDataShowTableSpinner: true,
                importPayrollDataPaintTable: true
            }, () => {
                this.setState({ importPayrollDataPaintTable: false });
            });
            let inputData = { tsvText: tsvText };
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onVerifyImportPayrollDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/VerifyImportPayrollData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableColumns = [];
                    tableColumns.push(<DGDGTableColumnComponent key="Ordinal" headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} headerCssName="dgdg-no-border-right" />);
                    this.importBulkUpdateTSVHeader.split("\t").forEach((header, key) => {
                        if (parsedResponse.missingFields && parsedResponse.missingFields.includes(header)) {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-fi-payroll-missing-col" headerCssName="dgdg-no-border-right" />);
                        }
                        else {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} backgroundColor={header + "BgColor"} headerCssName="dgdg-no-border-right" />);
                        }
                    });

                    if (parsedResponse.additionalFields && parsedResponse.additionalFields.length > 0) {
                        parsedResponse.additionalFields.forEach((header, key) => {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-fi-payroll-extra-col" headerCssName="dgdg-no-border-right" />);
                        });
                    }

                    this.setState({
                        importPayrollDataIsValid: parsedResponse.isValid,
                        importPayrollDataTableColumns: tableColumns,
                        importPayrollDataTableData: CommonService.addOrdinalColumn(parsedResponse.tsvDataTable),
                        importPayrollDataShowTableSpinner: false,
                        importPayrollDataPaintTable: true,
                        importPayrollDataVerifyStatus: parsedResponse.message
                    }, () => {
                        this.setState({ importPayrollDataPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/VerifyImportPayrollData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    importPayrollDataVerifyStatus: "Failed to verify data. Reason: " + parsedError.message
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmImportPayrollDataClick(event, tsvText) {
        try {
            let inputData = { tsvText: tsvText };
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onConfirmImportPayrollDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/ConfirmImportPayrollData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        importPayrollDataStatus: parsedResponse.message
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/ConfirmImportPayrollData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    importPayrollDataStatus: "Failed to import data. Reason: " + parsedError.message
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelImportPayrollDataClick(event) {
        try {
            this.setState({
                showImportPayrollDataDialog: false,
                importPayrollDataTitle: "",
                importPayrollDataTextData: "",
                importPayrollDataIsValid: false,
                importPayrollDataTableColumns: null,
                importPayrollDataTableData: [],
                importPayrollDataShowTableSpinner: false,
                importPayrollDataPaintTable: true,
                importPayrollDataVerifyStatus: "",
                importPayrollDataStatus: ""
            }, () => {
                this.setState({ importPayrollDataPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({ selectedEmployee: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanSaveClick(event, inputData) {
        try {
            this.setState({ isSaveComplete: false });
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onPayPlanSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/UpdateFIEmployeePayPlan", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isSaveComplete: true });
                    this.onPayPlanCancelClick(event);
                    this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore, this.state.setupAdditionalEmployeeNumber);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/UpdateFIEmployeePayPlan", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanCancelClick(event) {
        try {
            this.setState({ selectedEmployee: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailSaveClick(event, inputData) {
        try {
            this.setState({ isSaveComplete: false });
            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onPayrollDetailSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/UpdateFIPayroll", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isSaveComplete: true });
                    this.onPayrollDetailCancelClick(event);
                    this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore, this.state.setupAdditionalEmployeeNumber);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/UpdateFIPayroll", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

    }

    onPayrollDetailEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                payrollDetail: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailCancelClick(event) {
        try {
            this.setState({
                payrollDetail: null
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessAdjustments(event, selectedMonth) {
        try {
            this.setState({ isProcessingAdjustments: true });
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/FIPayrollComponent/onProcessAdjustments", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/ProcessAdjustments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isProcessingAdjustments: false });
                    this.props.onShowAlert("success", { message: parsedResponse + " rows updated" });
                })
                .catch(notOKResponse => {
                    this.setState({ isProcessingAdjustments: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/ProcessAdjustments", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FIEmployeePayEditComponent
                applicationInsights={this.props.applicationInsights}
                employee={this.state.selectedEmployee}
                isSaveComplete={this.state.isSaveComplete}
                onSaveClick={this.onPayPlanSaveClick}
                onCancelClick={this.onPayPlanCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <FIPayrollDetailEditComponent
                applicationInsights={this.props.applicationInsights}
                payrollDetail={this.state.payrollDetail}
                payrollMonth={this.state.selectedSetupMonth}
                isSaveComplete={this.state.isSaveComplete}
                onSaveClick={this.onPayrollDetailSaveClick}
                onCancelClick={this.onPayrollDetailCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGImportWizardComponent
                title={this.state.importPayrollDataTitle}
                isMultipleLines="true"
                tsvColumnHeader={this.state.importPayrollDataTextData}
                showDialog={this.state.showImportPayrollDataDialog} importStatus={this.state.importPayrollDataStatus} verifyStatus={this.state.importPayrollDataVerifyStatus} isValid={this.state.importPayrollDataIsValid}
                tableColumns={this.state.importPayrollDataTableColumns} tableData={this.state.importPayrollDataTableData} showTableSpinner={this.state.importPayrollDataShowTableSpinner} paintTable={this.state.importPayrollDataPaintTable}
                onVerifyClick={this.onVerifyImportPayrollDataClick}
                onImportClick={this.onConfirmImportPayrollDataClick}
                onCancelClick={this.onCancelImportPayrollDataClick}
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
            />
            <FIPayrollQueryComponent
                applicationInsights={this.props.applicationInsights}
                canProcess={this.state.canProcess}
                stores={this.props.stores}
                months={this.state.months}
                onGetPayrollClick={this.onGetPayrollClick}
                onExportBulkUpdate={this.onExportBulkUpdate}
                onImportPayrollDataClick={this.onImportPayrollDataClick}
                isProcessingAdjustments={this.state.isProcessingAdjustments}
                onProcessAdjustments={this.onProcessAdjustments}
                isDownLoading={this.state.isDownLoading}
                onDownloadClick={this.onDownloadClick}
                onDownload2Click={this.onDownload2Click}
                isDeveloper={this.state.isDeveloper}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-header">F&I Payroll Setup</div>
                    {
                        (this.state.onLoadMessage)
                            ? <div className="card card-header dgdg-payroll-bg-no-load text-center font-weight-bold">
                                {this.state.onLoadMessage}
                            </div>
                            : <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable} tabletitle="test">
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Employee Number" dataColumn="employee_number" sortColumn="employee_number" filterColumn="employee_number" />
                                    <DGDGTableColumnComponent headerText="Employee Name" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" />
                                    <DGDGTableColumnComponent headerText="Job Title" dataColumn="job_title" sortColumn="job_title" filterColumn="job_title" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Plan Date" dataColumn="plan_date" sortColumn="plan_date" filterColumn="plan_date" dataType="date" />
                                    <DGDGTableColumnComponent headerText="Old Mgr Plan" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Old Dir Plan" dataColumn="director_plan_name" sortColumn="director_plan_name" filterColumn="director_plan_name" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="New Mgr Plan" dataColumn="plan_name_2" sortColumn="plan_name_2" filterColumn="plan_name_2" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="New Dir Plan" dataColumn="director_plan_name_2" sortColumn="director_plan_name_2" filterColumn="director_plan_name_2" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Wage" dataColumn="wage" sortColumn="wage" filterColumn="wage" dataType="rate" />
                                    <DGDGTableColumnComponent headerText="Salary" dataColumn="salary" sortColumn="salary" filterColumn="salary" dataType="money" />
                                    <DGDGTableColumnComponent headerText="Mid Month Advance" dataColumn="mid_month_advance" sortColumn="mid_month_advance" filterColumn="mid_month_advance" dataType="money" />
                                    <DGDGTableColumnComponent headerText="Monthly Guarantee" dataColumn="monthly_guarantee" sortColumn="monthly_guarantee" filterColumn="monthly_guarantee" dataType="money" />
                                    <DGDGTableColumnComponent headerText="Director Monthly Guarantee" dataColumn="director_monthly_guarantee" sortColumn="director_monthly_guarantee" filterColumn="director_monthly_guarantee" dataType="money" />
                                    <DGDGTableColumnComponent headerText="Edit" cssName="text-right" dataText="<span class='fas fa-edit' />" disabledColumn="disable_edit" onCellClick={this.onPayPlanEditCellClick} />
                                </DGDGTableRowComponent>
                                <DGDGTableCustomComponent>
                                    <FIPayrollDetailComponent
                                        payrollMonth={this.state.selectedSetupMonth}
                                        isEditEnabled={this.state.isPayrollEditEnabled}
                                        onEditClick={this.onPayrollDetailEditCellClick}
                                        onShowAlert={this.props.onShowAlert} />
                                </DGDGTableCustomComponent>
                            </DGDGTableV2Component>
                    }
                </div>
            </div>
        </div>;
    }
}

import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { ServiceScheduleJournalEntryDetailsComponent } from "./ServiceScheduleJournalEntryDetailsComponent";
import { ServiceScheduleExtendedWarrantyQueryComponent } from "./ServiceScheduleExtendedWarrantyQueryComponent";
import { ServiceScheduleRODetailComponent } from "./ServiceScheduleRODetailComponent";

export class ServiceScheduleExtendedWarrantyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedStore: null,
            tableTitle: "",
            lastSavedRowData: null,
            tableData: [],
            paintTable: false,
            selectedCustomer: "",
            journalEntryDetailsTitle: "",
            journalEntryDetailsTableData: [],
            journalEntryDetailsPaintTable: false,
            showJournalEntryDetails: false,
            totalPostingAmount: 0,
            legendText: null,
            roTableTitle: "",
            roDetailTitle: "",
            roLineTitle: "",
            roMLSTitle: "",
            parsedRODetailTableData: [],
            parsedROMLSTableData: [],
            roTableData: [],
            roDetailTableData: [],
            roMLSTableData: [],
            showRODetails: false,
            roDetailsPaintTable: false
        };

        this.onGetServiceSchedule121Click = this.onGetServiceSchedule121Click.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onAmountCellClick = this.onAmountCellClick.bind(this);
        this.onAmountCellCancelClick = this.onAmountCellCancelClick.bind(this);
        this.onCustomerNumberCellClick = this.onCustomerNumberCellClick.bind(this);
        this.onCustomerNumberCancelClick = this.onCustomerNumberCancelClick.bind(this);
        this.onRODetailCellClick = this.onRODetailCellClick.bind(this);
        this.onRONumberCellClick = this.onRONumberCellClick.bind(this);
        this.onRONumberCellCancelClick = this.onRONumberCellCancelClick.bind(this);
        this.onReferCellClick = this.onReferCellClick.bind(this);

        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onCommentBlur = this.onCommentBlur.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSaveServiceScheduleCommentClick = this.onSaveServiceScheduleCommentClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Service Schedule";
    statusData = [
        { key: "Needs Additional Documentation", value: "Needs Additional Documentation" },
        { key: "Awaiting Check Payment", value: "Awaiting Check Payment" },
        { key: "Awaiting Credit Card Payment", value: "Awaiting Credit Card Payment" },
        { key: "Paid Short/Over - Adjust", value: "Paid Short/Over - Adjust" },
        { key: "Paid in Full", value: "Paid in Full" }
    ];

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    componentDidMount() {
        try {
            console.devLog("ServiceScheduleExtendedWarrantyComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceSchedule/ExtendedWarranty", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLegendText();
            CommonService.clientAzureStorageLog("ServiceScheduleExtendedWarrantyComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-service-schedule-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-service-schedule-status-needs-additional-documentation" />&nbsp;&nbsp;&nbsp;Needs Additional Documentation</div>
            <div className="d-inline-block dgdg-service-schedule-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-service-schedule-status-awaiting-check-payment" />&nbsp;&nbsp;&nbsp;Awaiting Check Payment</div>
            <div className="d-inline-block dgdg-service-schedule-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-service-schedule-status-awaiting-credit-card-payment" />&nbsp;&nbsp;&nbsp;Awaiting Credit Card Payment</div>
            <div className="d-inline-block dgdg-service-schedule-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-service-schedule-status-paid-short-over-adjust" />&nbsp;&nbsp;&nbsp;Paid Short/Over - Adjust</div>
            <div className="d-inline-block dgdg-service-schedule-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-service-schedule-status-paid-in-full" />&nbsp;&nbsp;&nbsp;Paid in Full</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetServiceSchedule121Click(selectedStore) {
        try {
            this.onGetLastUpdatedDate();
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null
            };
            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onGetServiceSchedule121Click", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetServiceSchedule121", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    tableData.forEach((responseItem, index) => {
                        responseItem.finance_mgr_web_name = responseItem.finance_mgr_number ? responseItem.finance_mgr_name + " (" + responseItem.finance_mgr_number + ")" : "";
                        responseItem.original_comment = responseItem.comment;
                        responseItem.age_sort = responseItem.age;
                        responseItem.is_status_disabled = !responseItem.edit_status;
                        responseItem.age = responseItem.age ? responseItem.age + " Days" : null;
                        responseItem.showViewComments = true;
                        responseItem.display_name = responseItem.service_advisor_name + (responseItem.service_advisor_number ? " (" + responseItem.service_advisor_number + ")" : "");
                        responseItem.customer_display_name = responseItem.customer_name + (responseItem.customer_number ? " (" + responseItem.customer_number + ")" : "");
                        if (responseItem.balance < 0)
                            responseItem.balance_cellCssName += " dgdg-font-red";
                        this.setRowColor(responseItem);
                    });
                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.props.onLastUpdatedDateChanged(lastUpdatedDate);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("warning", parsedError, 4000);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceScheduleExtendedWarrantyComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comments);
        let disableAddComments = !rowData.edit_comment;
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={disableAddComments} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_121_" + rowData.customer_number} value={rowData["comments_121_" + rowData.customer_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={disableAddComments} onClick={(event) => this.onSaveServiceScheduleCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comments_121_" + rowData.customer_number;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.customer_number === id.split('_')[2])[0];
            rowData["comments_121_" + rowData.customer_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCommentBlur(id, value, rowData) {
        if (rowData.original_comment !== value) {
            setTimeout(() => {
                this.saveServiceScheduleComment(rowData);
            }, 500);
        }
    }

    onSaveServiceScheduleCommentClick(event, rowData) {
        this.saveServiceScheduleComment(rowData);
    }

    saveServiceScheduleComment(rowData) {
        if (this.state.lastSavedRowData
            && this.state.lastSavedRowData.store_number === rowData.store_number && this.state.lastSavedRowData.customer_number === rowData.customer_number
            && moment().diff(this.state.lastSavedRowData.last_updated_on) < 500) {

            return;
        }

        let tableData = this.state.tableData;
        try {
            rowData.comments_showSpinner = true;
            rowData.last_updated_on = moment();
            this.setState({
                lastSavedRowData: rowData,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                scheduleNumber: 121,
                keyNumber: rowData.customer_number,
                comments: rowData["comments_121_" + rowData.customer_number] ? rowData["comments_121_" + rowData.customer_number].replace(/\n|\r/g, " ") : rowData["comments_121_" + rowData.customer_number]
            };
            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/saveServiceScheduleComment", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/SaveServiceScheduleComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.error) {
                        rowData.comments_showSpinner = false;
                        this.props.onShowAlert("danger", { message: "Select Status before adding Comment" }, 4000);
                    } else {
                        rowData.comments = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_121_" + rowData.customer_number] + (rowData.comments ? '\r' + rowData.comments : "");
                        rowData["comments_121_" + rowData.customer_number] = "";
                        rowData.last_updated_by = this.props.remoteUpn;
                        rowData.comments_showSpinner = false;
                        this.props.onShowAlert("success", { message: "Service Schedule #" + rowData.customer_number + " Comment saved" }, 4000);
                    }
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/SaveServiceScheduleComments", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                scheduleNumber: 121,
                keyNumber: rowData.customer_number,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/SaveServiceScheduleStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.status_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: " Schedule Status for Customer #" + rowData.customer_name + " saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/SaveServiceScheduleStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Paid in Full":
                rowData.rowCssName = "dgdg-service-schedule-status-paid-in-full";
                break;
            case "Paid Short/Over - Adjust":
                rowData.rowCssName = "dgdg-service-schedule-status-paid-short-over-adjust";
                break;
            case "Awaiting Check Payment":
                rowData.rowCssName = "dgdg-service-schedule-status-awaiting-check-payment";
                break;
            case "Awaiting Credit Card Payment":
                rowData.rowCssName = "dgdg-service-schedule-status-awaiting-credit-card-payment";
                break;
            case "Needs Additional Documentation":
                rowData.rowCssName = "dgdg-service-schedule-status-needs-additional-documentation";
                break;
            default:
                rowData.rowCssName = null;
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onCustomerNumberCellClick(event, rowData) {
        try {
            let roDetail = this.state.tableData.find(schedule => schedule.control_1 === rowData.control_1);
            this.setState({
                isLoadingData: true,
                showRODetails: true,
                roTableTitle: roDetail.customer_number ? "RO Detail for " + roDetail.customer_name + " (" + roDetail.customer_number + ")" : "RO Detail"
            });
            let inputData = {
                storeNumber: rowData.store_number,
                customerNumber: roDetail.customer_number,
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onCustomerNumberCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetRODetailByCustomerNumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let roTableData = parsedResponse.table;
                    roTableData.forEach((responseItem, index) => {
                        responseItem.display_name = responseItem.service_advisor_name + " (" + responseItem.service_advisor_number + ")";
                        responseItem.customer_display_name = responseItem.customer_name + " (" + responseItem.customer_number + ")";
                    });
                    let roNumber = parsedResponse.table1.filter(lineDetail => lineDetail.ro_number === parsedResponse.table[0].ro_number)[0].ro_number;
                    this.setState({
                        isLoadingData: false,
                        roTableData: CommonService.addOrdinalColumn(roTableData),
                        roDetailTableData: CommonService.addOrdinalColumn(parsedResponse.table1.filter(lineDetail => lineDetail.ro_number === roNumber)),
                        parsedRODetailTableData: parsedResponse.table1,
                        roMLSTableData: CommonService.addOrdinalColumn(parsedResponse.table2.filter(mlsDetail => mlsDetail.ro_number === roNumber)),
                        parsedROMLSTableData: parsedResponse.table2,
                        roDetailsPaintTable: true,
                        roDetailTitle: "RO - "
                            + rowData.store_number,
                        roLineTitle: "RO Line - "
                            + rowData.store_number + " - "
                            + roNumber,
                        roMLSTitle: "MLS - "
                            + rowData.store_number + " - "
                            + roNumber
                    }, () => {
                        this.setState({ roDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        showRODetails: false,
                        roTableData: [],
                        roTableTitle: "",
                        parsedRODetailTableData: [],
                        parsedROMLSTableData: [],
                        roDetailTableData: [],
                        roMLSTableData: [],
                        roDetailsPaintTable: true
                    }, () => {
                        this.setState({ roDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetRODetailByCustomerNumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAmountCellClick(event, rowData) {
        try {
            this.setState({
                isLoadingData: true,
                selectedCustomer: rowData.customer_name + " (" + rowData.customer_number + ")",
                showJournalEntryDetails: true,
                journalEntryDetailsTitle: rowData.customer_number ? "Journal Entry Detail for " + rowData.customer_name + " (" + rowData.customer_number + ") - Amount: " + CommonService.formatCurrency_2(rowData.posting_amount) : "Journal Entry Detail - Amount: " + CommonService.formatCurrency_2(rowData.posting_amount),
            });
            let inputData = {
                storeNumber: rowData.store_number,
                keyNumber: rowData.ro_number,
                scheduleNumber: 121
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onAmountCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetServiceScheduleJournalEntryDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalPostingAmount = 0;
                    parsedResponse.forEach((responseItem, index) => {
                        totalPostingAmount += responseItem.posting_amount;
                    });
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTableData: CommonService.addOrdinalColumn(parsedResponse),
                        journalEntryDetailsPaintTable: true,
                        totalPostingAmount: totalPostingAmount
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: rowData.customer_number ? "Journal Entry Detail for " + rowData.customer_name + " (" + rowData.customer_number + ") - Amount: " + CommonService.formatCurrency_2(rowData.posting_amount) : "Journal Entry Detail - Amount: " + CommonService.formatCurrency_2(rowData.posting_amount),
                        journalEntryDetailsTableData: [],
                        showJournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetServiceScheduleJournalEntryDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAmountCellCancelClick(event) {
        try {
            this.setState({
                journalEntryDetailsTitle: "",
                journalEntryDetailsTableData: [],
                journalEntryDetailsPaintTable: true,
                showJournalEntryDetails: false,
                totalPostingAmount: 0
            }, () => {
                this.setState({ journalEntryDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberCancelClick() {
        try {
            let closeDualPopup = this.state.showJournalEntryDetails && this.state.showRODetails ? true : false;
            (closeDualPopup || (this.state.showRODetails && !this.state.showJournalEntryDetails)) ?
                this.setState({
                    roTableTitle: "",
                    roDetailTitle: "",
                    roTableData: [],
                    roLineTitle: "",
                    roDetailTableData: [],
                    parsedRODetailTableData: [],
                    roMLSTitle: "",
                    roMLSTableData: [],
                    parsedROMLSTableData: [],
                    roDetailsPaintTable: true,
                    showRODetails: false
                }, () => {
                    this.setState({ roDetailsPaintTable: false });
                })
                : this.onAmountCellCancelClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRONumberCellCancelClick(event) {
        try {
            let closeDualPopup = this.state.showJournalEntryDetails && this.state.showRODetails ? true : false;
            (closeDualPopup || (this.state.showRODetails && !this.state.showJournalEntryDetails)) ?
                this.setState({
                    roTableTitle: "",
                    roDetailTitle: "",
                    roTableData: [],
                    roLineTitle: "",
                    roDetailTableData: [],
                    parsedRODetailTableData: [],
                    roMLSTitle: "",
                    roMLSTableData: [],
                    parsedROMLSTableData: [],
                    roDetailsPaintTable: true,
                    showRODetails: false
                }, () => {
                    this.setState({ roDetailsPaintTable: false });
                })
                : this.onAmountCellCancelClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRODetailCellClick(event, rowData) {
        try {
            let detailTableData = CommonService.addOrdinalColumn(this.state.parsedRODetailTableData.filter(lineDetail => lineDetail.ro_number === rowData.ro_number));
            let mlsTableData = CommonService.addOrdinalColumn(this.state.parsedROMLSTableData.filter(mlsDetail => mlsDetail.ro_number === rowData.ro_number));

            this.setState({
                roDetailTableData: detailTableData,
                roMLSTableData: mlsTableData,
                roDetailsPaintTable: true,
                roLineTitle: "RO Line - "
                    + rowData.store_number + " - "
                    + rowData.ro_number,
                roMLSTitle: "MLS - "
                    + rowData.store_number + " - "
                    + rowData.ro_number
            }, () => {
                this.setState({ roDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRONumberCellClick(event, rowData) {
        try {
            this.setState({
                isLoadingData: true,
                showRODetails: true,
                roTableTitle: rowData.customer_number ? "RO Detail for " + this.state.selectedCustomer : "RO Detail"
            });
            let inputData = {
                storeNumber: rowData.store_number,
                roNumber: rowData.ro_number ? rowData.ro_number : rowData.refer
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleExtendedWarrantyComponent/onRONumberCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetRODetailByRONumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let roTableData = parsedResponse.table;
                    roTableData.forEach((responseItem, index) => {
                        responseItem.display_name = responseItem.service_advisor_name + (responseItem.service_advisor_number ? " (" + responseItem.service_advisor_number + ")" : "");
                        responseItem.customer_display_name = responseItem.customer_name + (responseItem.customer_number ? " (" + responseItem.customer_number + ")" : "");
                    });

                    this.setState({
                        isLoadingData: false,
                        roTableData: CommonService.addOrdinalColumn(roTableData),
                        roDetailTableData: CommonService.addOrdinalColumn(parsedResponse.table1),
                        roMLSTableData: CommonService.addOrdinalColumn(parsedResponse.table2),
                        roDetailsPaintTable: true,
                        roDetailTitle: "RO - "
                            + rowData.store_number + " - "
                            + (rowData.ro_number ? rowData.ro_number : rowData.refer),
                        roLineTitle: "RO Line - "
                            + rowData.store_number + " - "
                            + (rowData.ro_number ? rowData.ro_number : rowData.refer),
                        roMLSTitle: "MLS - "
                            + rowData.store_number + " - "
                            + (rowData.ro_number ? rowData.ro_number : rowData.refer)
                    }, () => {
                        this.setState({ roDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        showRODetails: false,
                        roTableData: [],
                        roDetailTableData: [],
                        roMLSTableData: [],
                        roDetailsPaintTable: true
                    }, () => {
                        this.setState({ roDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetRODetailByRONumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReferCellClick(event, rowData) {
        this.onRONumberCellClick(event, rowData);
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceScheduleJournalEntryDetailsComponent
                showSpinner={this.state.isLoadingData}
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showJournalEntryDetails}
                tableData={this.state.journalEntryDetailsTableData}
                tableTitle={this.state.journalEntryDetailsTitle}
                paintTable={this.state.journalEntryDetailsPaintTable}
                totalPostingAmount={this.state.totalPostingAmount}
                onRODetailCellClick={this.onReferCellClick}
                onCancelClick={this.onRONumberCellCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <ServiceScheduleExtendedWarrantyQueryComponent {...this.props}
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetServiceSchedule121Click}
            />
            <ServiceScheduleRODetailComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showRODetails}
                tableTitle={this.state.roTableTitle}
                paintTable={this.state.roDetailsPaintTable}
                roDetailTitle={this.state.roDetailTitle}
                roTableData={this.state.roTableData}
                roLineTitle={this.state.roLineTitle}
                roDetailTableData={this.state.roDetailTableData}
                roMLSTitle={this.state.roMLSTitle}
                roMLSTableData={this.state.roMLSTableData}
                showSpinner={this.state.isLoadingData}
                disableDrilldown={this.state.showJournalEntryDetails}
                onRODetailCellClick={this.onRODetailCellClick}
                onCancelClick={this.onRONumberCellCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText} stickyHeader
                    tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" data={this.statusData} itemKey="value" disabledColumn="is_status_disabled" placeholder="Select Status" onItemClick={this.onStatusChange} headerCssName="dgdg-cit-acc-desc-col" showSpinner />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" headerCssName="dgdg-cit-acc-desc-col" />
                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" filterColumn="ro_number" onCellClick={this.onRONumberCellClick} />
                        <DGDGTableColumnComponent headerText="Closed Date" dataType="date" dataColumn="close_date" sortColumn="close_date" filterColumn="close_date" />
                        <DGDGTableColumnComponent headerText="Service Advisor" dataColumn="display_name" sortColumn="service_advisor_name" filterColumn="service_advisor_name" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_display_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Accounting Date" dataColumn="min_accounting_date" sortColumn="min_accounting_date" filterColumn="min_accounting_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="posting_amount" sortColumn="posting_amount" filterColumn="posting_amount" dataType="money_2" onCellClick={this.onAmountCellClick} />
                        <DGDGTableColumnComponent headerText="Count" dataColumn="count" sortColumn="count" filterColumn="count" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Age (Days)" dataColumn="age" sortColumn="age_sort" filterColumn="age" />
                        <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent cssName="dgdg-no-border-top">
                        <DGDGTableColumnComponent colSpan="12" dataColumnCallback={this.onAddCommentColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DemoPageDetailPopupChartComponent } from "./DemoPageDetailPopupChartComponent";

export class DemoPageDetailPopupComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DemoPageDetailPopupComponent - getDerivedStateFromProps");
            if (props.isLoadingData) {
                newState.showSpinner = true;
            }
            else if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false
        };

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DemoPageDetailPopupComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDetailPopup}
            cssName="dgdg-demo-page-edit-dialog"
            title="Detail Popup Title"
            onCancelClick={this.props.onCancelClick}
        >
            <div className="dgdg-flex dgdg-flex-row dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.showSpinner} showFooter
                    headerText="Detail Table Title" tableData={this.props.tableData} paintTable={this.props.paintTable}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="String" dataColumn="string_col" sortColumn="string_col" filterColumn="string_col" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Money" dataColumn="money_col" sortColumn="money_col" filterColumn="money_col" dataType="money" footerFunction="sum" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Money 2" dataColumn="money_2_col" sortColumn="money_2_col" filterColumn="money_2_col" dataType="money_2" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Percent" dataColumn="percent_col" sortColumn="percent_col" filterColumn="percent_col" dataType="percent" footerFunction="average" cssName="text-nowrap" ofDataColumn="percent_col" backgroundColor="percent_col_background_color" backgroundColorPct="percent_col_background_color_pct" />
                        <DGDGTableColumnComponent headerText="Percent 2" dataColumn="percent_2_col" sortColumn="percent_2_col" filterColumn="percent_2_col" dataType="percent_2" footerFunction="average" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>

                <DemoPageDetailPopupChartComponent
                    headerText="Chart Title"
                    chartData={this.props.chartData} paintChart={this.props.paintChart}
                />
            </div>
        </DGDGModalDialogComponent>;
    }
}

import React from "react";
import { Route, Switch } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { OpenRODetailComponent } from "./OpenRODetailComponent";
import { ClosedRODetailComponent } from "./ClosedRODetailComponent";
import { ROComponent } from "./ROComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class RORouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            componentState: null
        }

        this.onROComponentUnmount = this.onROComponentUnmount.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("RORouteComponent - componentDidMount");
            this.getControllerPolicy();
            this.getStores();
            CommonService.clientAzureStorageLog("RORouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/RORouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/RO/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "RORouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/RO/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/RORouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/RO/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/RO/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onROComponentUnmount(componentState) {
        this.setState({
            componentState: componentState
        })
    }

    render() {
        return <Switch>
            <Route exact path="/RO/:StoreNumber?/:RONumber?/:RODate?/:Vin?" render={(routeProps) => <ROComponent {...this.props} {...routeProps} {...this.state} onROComponentUnmount={this.onROComponentUnmount} />} />
            <Route exact path="/RO/OpenRO/Detail/:StoreNumber?/:RONumber?/:VehicleId?" render={(routeProps) => <OpenRODetailComponent {...this.props} {...routeProps} {...this.state} />} />
            <Route exact path="/RO/ClosedRO/Detail/:StoreNumber?/:RONumber?/:VehicleId?" render={(routeProps) => <ClosedRODetailComponent {...this.props} {...routeProps} {...this.state} />} />
            <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
        </Switch>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { FSCommon } from "./FSCommon";
import { FinancialStatementValueCellComponent } from "./FinancialStatementValueCellComponent";

export class ServicePartsGrossBreakdownComponent extends React.Component {
    rowNumber = 1;

    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        this.rowNumber = 1;

        let mServiceOEM1noROWritten = null, yServiceOEM1noROWritten = null;
        let mServiceOEM1Sales = null, yServiceOEM1Sales = null;
        let mServiceOEM1GrossProfit = null, yServiceOEM1GrossProfit = null;

        let mPartsOEM1noROWritten = null, yPartsOEM1noROWritten = null;
        let mPartsOEM1Sales = null, yPartsOEM1Sales = null;
        let mPartsOEM1GrossProfit = null, yPartsOEM1GrossProfit = null;

        if (this.props.fsDict) {
            mServiceOEM1noROWritten = FSCommon.sumByKey(this.props.fsDict, "M", "Service Stat", "Service Sales",
                ["Count Customer Labor Mech.", "Count Warranty Claim Labor", "Count Internal Labor", "Count Express Change"]);
            yServiceOEM1noROWritten = FSCommon.sumByKey(this.props.fsDict, "Y", "Service Stat", "Service Sales",
                ["Count Customer Labor Mech.", "Count Warranty Claim Labor", "Count Internal Labor", "Count Express Change"]);

            mServiceOEM1Sales = FSCommon.sumByKey(this.props.fsDict, "M", "Service Gross", "Service Sales",
                ["Sales Customer Labor Mech.", "Sales Warranty Claim Labor", "Sales Internal Labor", "Sales Express Change"]);
            yServiceOEM1Sales = FSCommon.sumByKey(this.props.fsDict, "Y", "Service Gross", "Service Sales",
                ["Sales Customer Labor Mech.", "Sales Warranty Claim Labor", "Sales Internal Labor", "Sales Express Change"]);

            mServiceOEM1GrossProfit = FSCommon.sumByKey(this.props.fsDict, "M", "Service Gross", "Service Gross",
                ["Gross Customer Labor Mech.", "Gross Warranty Claim Labor", "Gross Internal Labor", "Gross Express Change"]);
            yServiceOEM1GrossProfit = FSCommon.sumByKey(this.props.fsDict, "Y", "Service Gross", "Service Gross",
                ["Gross Customer Labor Mech.", "Gross Warranty Claim Labor", "Gross Internal Labor", "Gross Express Change"]);

            mPartsOEM1noROWritten = FSCommon.sumByKey(this.props.fsDict, "M", "Parts Stat", "Parts Sales",
                ["Count P&A - Counter", "Count P&A - Wholesale"]);
            yPartsOEM1noROWritten = FSCommon.sumByKey(this.props.fsDict, "Y", "Parts Stat", "Parts Sales",
                ["Count P&A - Counter", "Count P&A - Wholesale"]);

            mPartsOEM1Sales = FSCommon.sumByKey(this.props.fsDict, "M", "Parts Gross", "Parts Sales",
                ["Sales P&A - R.O. - Mech.", "Sales P&A - Warranty Claim", "Sales P&A - Internal", "Sales P&A - Counter", "Sales P&A - Wholesale", "Sales P&A - Tires"]);
            yPartsOEM1Sales = FSCommon.sumByKey(this.props.fsDict, "Y", "Parts Gross", "Parts Sales",
                ["Sales P&A - R.O. - Mech.", "Sales P&A - Warranty Claim", "Sales P&A - Internal", "Sales P&A - Counter", "Sales P&A - Wholesale", "Sales P&A - Tires"]);

            mPartsOEM1GrossProfit = FSCommon.sumByKey(this.props.fsDict, "M", "Parts Gross", "Parts Gross",
                ["Gross P&A - R.O. - Mech.", "Gross P&A - Warranty Claim", "Gross P&A - Internal", "Gross P&A - Counter", "Gross P&A - Wholesale", "Gross P&A - Purchase Discount", "Gross P&A - Inventory Adjustments", "Gross P&A - Tires"]);
            yPartsOEM1GrossProfit = FSCommon.sumByKey(this.props.fsDict, "Y", "Parts Gross", "Parts Gross",
                ["Gross P&A - R.O. - Mech.", "Gross P&A - Warranty Claim", "Gross P&A - Internal", "Gross P&A - Counter", "Gross P&A - Wholesale", "Gross P&A - Purchase Discount", "Gross P&A - Inventory Adjustments", "Gross P&A - Tires"]);
        }

        return <table className="dgdg-fs-table">
            <tbody>
                <tr className="text-center font-weight-bold">
                    <td />
                    <td colSpan="6">CURRENT MONTH</td>
                    <td>GROSS PROFIT ANALYSIS</td>
                    <td colSpan="4">YEAR TO DATE</td>
                </tr>
                <tr className="text-center">
                    <td>LINE</td>
                    <td style={{ "width": "70px" }}>NO RO WRITTEN</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td colSpan="2">Gross of Sales</td>
                    <td>SERVICE, PARTS & ACCESS, BODY SHOP</td>
                    <td style={{ "width": "70px" }}>NO RO WRITTEN</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td>Gross of Sales</td>
                </tr>
                {this.serviceGrossBreakdownRowByKey("", "SERVICE DEPT", "Customer Labor Mechanical", "Count Customer Labor Mech.", "Sales Customer Labor Mech.", "Gross Customer Labor Mech.", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Warranty Claim Labor - OEM 1", "Count Warranty Claim Labor", "Sales Warranty Claim Labor", "Gross Warranty Claim Labor", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Internal Labor - OEM 1", "Count Internal Labor", "Sales Internal Labor", "Gross Internal Labor", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Express Change", "Count Express Change", "Sales Express Change", "Gross Express Change", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "OEM Income", "", "", "", true, null, null)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", mServiceOEM1noROWritten, mServiceOEM1Sales, mServiceOEM1GrossProfit, "SUB TOTAL-OEM 1", yServiceOEM1noROWritten, yServiceOEM1Sales, yServiceOEM1GrossProfit)}
                {this.serviceGrossBreakdownRowByKey("", null, "Customer Labor Mechanical", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Warranty Claim Labor - OEM 2", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Internal Labor - OEM 2", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Express Change", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "OEM Income", "", "", "", true, null, null)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", "", "", "", "SUB TOTAL-OEM 2", "", "", "")}
                {this.serviceGrossBreakdownRowByKey("", null, "Customer Labor Mechanical", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Warranty Claim Labor - OEM 3", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Internal Labor - OEM 3", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Express Change", "", "", "", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "OEM Income", "", "", "", true, null, null)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", "", "", "", "SUB TOTAL-OEM 3", "", "", "")}
                {this.serviceGrossBreakdownRowByKey("", null, "Other Shop Labor", "Count Other Shop Labor", "Sales Other Shop Labor", "Gross Other Shop Labor", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Sublet Repairs", "", "Sales Sublet Repairs", "Gross Sublet Repairs", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Gas, Oil & Grease", "", "Sales Gas, Oil & Grease", "Gross Gas, Oil & Grease", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Service/Maintenance Contract Sales", "Count Service/Maintenance Contract Sales", "Sales Service/Maintenance Contract Sales", "Gross Service/Maintenance Contract Sales", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Adjustments Cost of Sales-Labor", "", "", "Gross Adjustments Cost of Sales - Labor", true, null, null)}
                {this.serviceGrossBreakdownRowByKey("", null, "Other Income & Expenses", "", "", "Service Gross", true, null, "Gross Recast~Other Add Ded~")}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold",
                    this.props.fsDict && this.props.fsDict["1~M~Service Stat~All~Count Total Service ROs"] ? this.props.fsDict["1~M~Service Stat~All~Count Total Service ROs"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~M~Service Gross~All~Sales Total Service"] ? this.props.fsDict["1~M~Service Gross~All~Sales Total Service"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"] ? this.props.fsDict["1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"][8] : "",
                    "TOTAL SERVICE DEPT.",
                    this.props.fsDict && this.props.fsDict["1~Y~Service Stat~All~Count Total Service ROs"] ? this.props.fsDict["1~Y~Service Stat~All~Count Total Service ROs"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~Y~Service Gross~All~Sales Total Service"] ? this.props.fsDict["1~Y~Service Gross~All~Sales Total Service"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~Y~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"] ? this.props.fsDict["1~Y~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"][8] : "")}
                {this.partsGrossBreakdownRowByKey("", "PARTS & ACCESSORIES", "P & A-R.O.-Mech.- OEM 1", "", "Sales P&A - R.O. - Mech.", "Gross P&A - R.O. - Mech.", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-R.O.-Body - OEM 1", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Warranty Claim - OEM 1", "", "Sales P&A - Warranty Claim", "Gross P&A - Warranty Claim", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Internal - OEM 1", "", "Sales P&A - Internal", "Gross P&A - Internal", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Counter - OEM 1", "Count P&A - Counter", "Sales P&A - Counter", "Gross P&A - Counter", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Wholesale - OEM 1", "Count P&A - Wholesale", "Sales P&A - Wholesale", "Gross P&A - Wholesale", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A- Purchase Discount - OEM 1", "", "", "Gross P&A - Purchase Discount", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Inventory Adjustments - OEM 1", "", "", "Gross P&A - Inventory Adjustments", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A - Tires - OEM 1", "", "Sales P&A - Tires", "Gross P&A - Tires", true)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", mPartsOEM1noROWritten, mPartsOEM1Sales, mPartsOEM1GrossProfit, "TOTAL PARTS & ACCESS.- OEM 1", yPartsOEM1noROWritten, yPartsOEM1Sales, yPartsOEM1GrossProfit)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-R.O.-Mech.- OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-R.O.-Body - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Warranty Claim - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Internal - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Counter - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Wholesale - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A- Purchase Discount - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Inventory Adjustments - OEM 2", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A - Tires - OEM 2", "", "", "", true)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", "", "", "", "TOTAL PARTS & ACCESS - OEM 2", "", "", "")}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-R.O.-Mech.- OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-R.O.-Body - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Warranty Claim - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Internal - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A -Counter - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Wholesale - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A- Purchase Discount - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A-Inventory Adjustments - OEM 3", "", "", "", true)}
                {this.partsGrossBreakdownRowByKey("", "", "P & A - Tires - OEM 3", "", "", "", true)}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", "", "", "", "TOTAL PARTS & ACCESS - OEM 3", "", "", "")}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold",
                    "",
                    this.props.fsDict && this.props.fsDict["1~M~Parts Gross~All~Sales Total Parts"] ? this.props.fsDict["1~M~Parts Gross~All~Sales Total Parts"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"] ? this.props.fsDict["1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"][8] : "",
                    "TOTAL PARTS & ACCESS-DEPT",
                    "",
                    this.props.fsDict && this.props.fsDict["1~Y~Parts Gross~All~Sales Total Parts"] ? this.props.fsDict["1~Y~Parts Gross~All~Sales Total Parts"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~Y~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"] ? this.props.fsDict["1~Y~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"][8] : "")}
                {this.servicePartsGrossBreakdownRowByValue("dgdg-fs-heading-2-bg-color font-weight-bold",
                    "",
                    this.props.fsDict && this.props.fsDict["1~M~Total Gross~All~Sales Total Store"] ? this.props.fsDict["1~M~Total Gross~All~Sales Total Store"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~M~Total Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Store"] ? this.props.fsDict["1~M~Total Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Store"][8] : "",
                    "TOTAL ALL DEPTS",
                    "",
                    this.props.fsDict && this.props.fsDict["1~Y~Total Gross~All~Sales Total Store"] ? this.props.fsDict["1~Y~Total Gross~All~Sales Total Store"][8] : "",
                    this.props.fsDict && this.props.fsDict["1~Y~Total Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Store"] ? this.props.fsDict["1~Y~Total Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Store"][8] : "", true)}
                <tr>
                    <td colSpan="11">&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan="11" className="dgdg-no-padding">
                        <table className="dgdg-fs-table">
                            <tbody>
                                <tr className="text-center">
                                    <td className="dgdg-no-border-left dgdg-no-border-top dgdg-fs-bg-black" />
                                    <td className="dgdg-no-border-top">KEY PERFORMANCE ITEMS</td>
                                    <td className="dgdg-fs-bg-black dgdg-no-border-top" />
                                    <td className="dgdg-no-border-top">MONTH</td>
                                    <td className="dgdg-no-border-top">YEAR</td>
                                    <td className="dgdg-no-border-top">DGDG</td>
                                    <td rowSpan="17" className="dgdg-no-border-top dgdg-no-border-bottom" />
                                    <td rowSpan="17" className="dgdg-fs-bg-green dgdg-no-border-top dgdg-no-border-bottom" style={{ "width": "25px" }}>
                                        <p className="dgdg-text-vertical">STATISTICAL DATA</p>
                                    </td>
                                    <td rowSpan="17" className="dgdg-no-border-top dgdg-no-border-bottom" />
                                    <td colSpan="2" className="dgdg-no-border-top dgdg-no-border-right">STATEMENT CYCLE</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td>Same Day Selling</td>
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td className="dgdg-fs-bg-green">SERVICE DEPARTMENT</td>
                                    <td className="dgdg-no-border-right" />
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td>Menu Usage</td>
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Available - OEM 1</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td>Check-In Utilization</td>
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Available - OEM 2</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td>Texting Usage</td>
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Available - OEM 3</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td />
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Actual - OEM1</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td />
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Actual - OEM2</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td />
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td colSpan="2" className="dgdg-no-border-right">Technician Hours Actual - OEM3</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center">{this.rowNumber++}</td>
                                    <td />
                                    <td className="dgdg-fs-bg-black" />
                                    <td />
                                    <td />
                                    <td />
                                    <td className="dgdg-fs-bg-green dgdg-no-border-bottom">PARTS DEPARTMENT</td>
                                    <td className="dgdg-no-border-right" />
                                </tr>
                                <tr>
                                    <td colSpan="6" rowSpan="8" className="dgdg-no-border-left dgdg-no-border-bottom" />
                                    <td colSpan="2" className="dgdg-no-border-right">Outside Purchase Dollars</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Lost Sales Dollars</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Dealers Parts System Inv. Value</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Parts Inventory Value/No Sales in 6 Mos.</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Parts Inventory Value/No Sales in 12 Mos.</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Number of Part Numbers in Dealer Stock</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right">Number of Part Numbers in Dealer System</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-right dgdg-no-border-bottom">Date Parts Inventory Date</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>;
    }

    serviceGrossBreakdownRowByKey(rowCssName, verticalLabel, label, noROWrittenLD, salesLD, grossProfitLD, showDetail, fsDictSalesKeySuffix, fsDictGrossProfitKeySuffix) {
        let mGrossOfSales = null;
        let yGrossOfSales = null;

        fsDictSalesKeySuffix = fsDictSalesKeySuffix !== null ? fsDictSalesKeySuffix : "Service Gross~Service Sales~";
        fsDictGrossProfitKeySuffix = fsDictGrossProfitKeySuffix !== null ? fsDictGrossProfitKeySuffix : "Service Gross~Service Gross~";

        if (this.props.fsDict) {
            let mSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~" + fsDictSalesKeySuffix + salesLD);
            let mGrossProfitLD = FSCommon.parseDecimal(this.props.fsDict, "1~M~" + fsDictGrossProfitKeySuffix + grossProfitLD);
            let ySales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~" + fsDictSalesKeySuffix + salesLD);
            let yGrossProfitLD = FSCommon.parseDecimal(this.props.fsDict, "1~Y~" + fsDictGrossProfitKeySuffix + grossProfitLD);

            if (mSales > 0) {
                mGrossOfSales = mGrossProfitLD / mSales;
            }
            if (ySales > 0) {
                yGrossOfSales = yGrossProfitLD / ySales;
            }
        }

        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{this.rowNumber++}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Service Stat~Service Sales~" + noROWrittenLD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + fsDictSalesKeySuffix + salesLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + fsDictGrossProfitKeySuffix + grossProfitLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="percent_2" mode={this.props.mode} value={mGrossOfSales} />
            </td>
            {
                (verticalLabel)
                    ? <td rowSpan="25" className="dgdg-fs-bg-green" style={{ "width": "25px" }}>
                        <p className="dgdg-text-vertical">{verticalLabel}</p>
                    </td>
                    : null
            }
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~Service Stat~Service Sales~" + noROWrittenLD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + fsDictSalesKeySuffix + salesLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + fsDictGrossProfitKeySuffix + grossProfitLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={yGrossOfSales} />
            </td>
        </tr>;
    }

    partsGrossBreakdownRowByKey(rowCssName, verticalLabel, label, noROWrittenLD, salesLD, grossProfitLD, showDetail) {
        let mGrossOfSales = null;
        let yGrossOfSales = null;

        if (this.props.fsDict) {
            let mSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Parts Gross~Parts Sales~" + salesLD);
            let mGrossProfitLD = FSCommon.parseDecimal(this.props.fsDict, "1~M~Parts Gross~Parts Gross~" + grossProfitLD);
            let ySales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Parts Gross~Parts Sales~" + salesLD);
            let yGrossProfitLD = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Parts Gross~Parts Gross~" + grossProfitLD);

            if (mSales > 0) {
                mGrossOfSales = mGrossProfitLD / mSales;
            }
            if (ySales > 0) {
                yGrossOfSales = yGrossProfitLD / ySales;
            }
        }


        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{this.rowNumber++}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Parts Stat~Parts Sales~" + noROWrittenLD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Parts Gross~Parts Sales~" + salesLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Parts Gross~Parts Gross~" + grossProfitLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={mGrossOfSales} />
            </td>
            {
                (verticalLabel)
                    ? <td rowSpan="31">
                        <p className="dgdg-text-vertical">{verticalLabel}</p>
                    </td>
                    : null
            }
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~Parts Stat~Parts Sales~" + noROWrittenLD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~Parts Gross~Parts Sales~" + salesLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~Parts Gross~Parts Gross~" + grossProfitLD} onFinancialStatementDetailClick={showDetail && !this.props.isConsolidated ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={yGrossOfSales} />
            </td>
        </tr>;
    }

    servicePartsGrossBreakdownRowByValue(rowCssName, mnoROWritten, mSales, mGrossProfit, label, ynoROWritten, ySales, yGrossProfit, showSeperatorColumn = false) {
        let mGrossOfSales = null;
        let yGrossOfSales = null;

        if (this.props.fsDict) {
            if (mSales > 0)
                mGrossOfSales = mGrossProfit / mSales;
            if (ySales > 0)
                yGrossOfSales = yGrossProfit / ySales;
        }

        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{this.rowNumber++}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mnoROWritten} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mSales} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mGrossProfit} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={mGrossOfSales} />
            </td>
            {
                showSeperatorColumn
                    ? <td />
                    : null
            }
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={ynoROWritten} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={ySales} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yGrossProfit} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={yGrossOfSales} />
            </td>
        </tr>;
    }
}

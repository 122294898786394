import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

export class DGDGFormCalendarExComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onDateClick = this.onDateClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onPrevClick = this.onPrevClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
    }

    componentDidMount() {
        $(this.dateRefElement).datepicker({
            changeMonth: true,
            changeYear: true,
            numberOfMonths: this.props.numberOfMonths,
            showButtonPanel: true,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate,
            yearRange: "c-100:c-0",
            beforeShowDay: (date) => {
                if (this.props.showDays) {
                    return [this.props.showDays.includes(date.getDay())];
                }
                if (this.props.disabledDates) {
                    let formatteDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
                    return [!this.props.disabledDates.includes(formatteDate)];
                }

                return [true];
            }
        }).on("change", (event) => this.onDateChange(event));
        if (this.props.value) {
            $(this.dateRefElement).datepicker("setDate", moment(this.props.value).toDate());
        }

        if (this.props.disabled) {
            $(this.dateRefElement).datepicker("disable");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.minDate !== prevProps.minDate
                || this.props.maxDate !== prevProps.maxDate
            ) {
                $(this.dateRefElement).datepicker("change",
                    {
                        minDate: this.props.minDate,
                        maxDate: this.props.maxDate
                    }
                );
            }

            if (this.props.value !== prevProps.value) {
                if (CommonService.isDateValid(this.props.value)) {
                    $(this.dateRefElement).datepicker("setDate", moment(this.props.value).toDate());
                }
                else {
                    $(this.dateRefElement).datepicker("setDate", "");
                }
            }

            if (this.props.disabled !== prevProps.disabled) {
                if (this.props.disabled) {
                    $(this.dateRefElement).datepicker("disable");
                }
                else {
                    $(this.dateRefElement).datepicker("enable");
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateClick(event) {
        $(this.dateRefElement).datepicker("show");
    }

    onDateChange(event) {
        let result = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
        if (result && this.props.onDateChange) {
            $(this.dateRefElement).datepicker("setDate", result.toDate());
        } else {
            $(this.dateRefElement).datepicker("setDate", "");
        }

        this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val());
    }

    onKeyDown(event) {
        let currentDate = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
        if (!currentDate) {
            currentDate = moment();
        }

        switch (event.key) {
            case "w":
            case "W":
                if (currentDate.day() === 0) {
                    currentDate = currentDate.subtract(1, "weeks");
                } else {
                    currentDate = currentDate.startOf('week');
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val());
                break;

            case "k":
            case "K":
                if (currentDate.day() === 6) {
                    currentDate = currentDate.add(1, "weeks");
                } else {
                    currentDate = currentDate.endOf('week');
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val());
                break;

            default:
                if (this.props.onKeyDown) {
                    this.props.onKeyDown(event);
                }
        }
    }

    onPrevClick(event) {
        if (this.props.dayCount) {
            let result = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
            if (result) {
                let prevDate = result.subtract(this.props.dayCount, "day")
                $(this.dateRefElement).datepicker("setDate", prevDate.toDate());
            }
        }

        this.props.onDatePrevChange(this.props.id, $(this.dateRefElement).datepicker().val());
    }

    onNextClick(event) {
        if (this.props.dayCount) {
            let result = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
            if (result) {
                let prevDate = result.add(this.props.dayCount, "day")
                $(this.dateRefElement).datepicker("setDate", prevDate.toDate());
            }
        }

        this.props.onDateNextChange(this.props.id, $(this.dateRefElement).datepicker().val());
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : " col-form-label ";
        controlSpan += this.props.hideTextbox ? "" : " dgdg-date ";
        let inputClassName = "form-control";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }
        if (this.props.hideTextbox) {
            inputClassName += " d-none";
        }
        return <div className={"form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan} htmlFor={this.props.id}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }
            <div className="form-inline">
                <div className={"input-group " + controlSpan} style={{ "paddingRight": "0px" }}>
                    <input className={inputClassName} placeholder="mm/dd/yyyy" ref={refElement => this.dateRefElement = refElement} id={this.props.id} onKeyDown={(event) => this.onKeyDown(event)} />
                    <span className="input-group-append">
                        <button className={"btn btn-secondary " + (this.props.buttonCssName ? this.props.buttonCssName : "")} type="button" style={{ cursor: "pointer" }} onClick={(event) => this.onDateClick(event)}>
                            <i className="far fa-calendar-alt" />
                        </button>
                    </span>
                </div>
                <button type="button" className="btn btn-link dgdg-font-blue dgdg-font-size-18" disabled={this.props.value === null} style={{ "paddingLeft": "5px", "paddingRight": "0px" }} onClick={(event) => this.onPrevClick(event)}>
                    <span className="fas fa-chevron-circle-left" />
                </button>
                <button type="button" className="btn btn-link dgdg-font-blue dgdg-font-size-18" disabled={this.props.value === null} style={{ "paddingLeft": "5px", "paddingRight": "0px" }} onClick={(event) => this.onNextClick(event)}>
                    <span className="fas fa-chevron-circle-right" />
                </button>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class BuyCenterManheimQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("BuyCenterManheimQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("BuyCenterManheimQueryComponent - componentDidMount");
            this.onGetReportClick();
            CommonService.clientAzureStorageLog("BuyCenterRouteComponent", "BuyCenterManheimQueryComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Buy Center";

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(event, {
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report <span className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class EVOXImagesKBBEVOXMappingQueryComponent extends React.Component {
    static YearCookie = "EVOXImagesKBBEVOXMappingYearCookie";
    static MakeCookie = "EVOXImagesKBBEVOXMappingMakeCookie";
    static ViewCookie = "EVOXImagesKBBEVOXMappingViewCookie";

    viewData = [
        { key: "All", value: "All" },
        { key: "Mapped", value: "Mapped" },
        { key: "Unmapped", value: "Unmapped" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            years: CommonService.getYears("01/01/1998", true, moment().add(1, "year").startOf("year").format("MM/DD/YYYY")),
            selectedYear: null,
            makes: null,
            selectedMake: null,
            selectedView: null,
            vehicleId: null,
            vifNumber: null,
            isFirstLoad: true,
            isLoadingData: false
        };

        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.isFirstLoad && this.state.selectedYear === null) {

                if (this.props.match.params.vehicleId) {
                    this.setState({
                        selectedYear: null,
                        selectedView: this.viewData[0],
                        vehicleId: this.props.match.params.vehicleId,
                        isFirstLoad: false
                    }, () => {
                        this.onGetReportClick();
                        this.getMakes();
                    });
                }
                else {
                    let selectedYear = null;
                    let cookieYear = CommonService.getCookie(EVOXImagesKBBEVOXMappingQueryComponent.YearCookie);
                    if (this.props.match.params.yearId) {
                        cookieYear = this.props.match.params.yearId;
                    }
                    if (cookieYear) {
                        let filterYear = this.state.years.filter((year) => year && year.isSame(cookieYear, 'date'));
                        if (filterYear.length === 1) {
                            selectedYear = filterYear[0];
                        }
                    }

                    let selectedView = this.viewData[0];
                    let cookieView = null;
                    if (!this.props.match.params.yearId && !this.props.match.params.makeName) {
                        cookieView = CommonService.getCookie(EVOXImagesKBBEVOXMappingQueryComponent.ViewCookie);
                    }

                    if (cookieView) {
                        let filterView = this.viewData.filter((view) => view.key === cookieView);
                        if (filterView.length === 1) {
                            selectedView = filterView[0];
                        }
                    }

                    this.setState({
                        selectedYear: selectedYear,
                        selectedView: selectedView,
                        isFirstLoad: false
                    }, this.getMakes);
                }

            }

            if (!prevState.makes && this.state.makes && !this.props.match.params.vehicleId) {
                let cookieMake = CommonService.getCookie(EVOXImagesKBBEVOXMappingQueryComponent.MakeCookie);
                if (this.props.match.params.makeName) {
                    cookieMake = this.props.match.params.makeName;
                }

                let filterMake = this.state.makes.filter(make => String(make.makeName).toLowerCase() === String(cookieMake).toLowerCase());
                if (filterMake.length === 1) {
                    this.setState({
                        selectedMake: filterMake[0]
                    }, this.onGetReportClick);
                }
                else {
                    this.onGetReportClick();
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getMakes() {
        try {
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : null
            };

            this.setState({
                isLoadingData: true
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingQueryComponent/onShowKBBPhotosClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetVehicleMakes", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.items.unshift({ makeId: null, makeName: "All" })
                    this.setState({
                        makes: parsedResponse.items,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year
            }, this.getMakes);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({ selectedMake: make });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onViewClick(id, view) {
        try {
            this.setState({ selectedView: view });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            let cookieYear = this.state.selectedYear ? CommonService.formatServerDateTime(this.state.selectedYear) : null;
            CommonService.setCookie(EVOXImagesKBBEVOXMappingQueryComponent.YearCookie, cookieYear, 30);
            CommonService.setCookie(EVOXImagesKBBEVOXMappingQueryComponent.MakeCookie, this.state.selectedMake ? this.state.selectedMake.makeName : "", 30);
            CommonService.setCookie(EVOXImagesKBBEVOXMappingQueryComponent.ViewCookie, this.state.selectedView ? this.state.selectedView.key : "", 30);
            this.props.onGetReportClick(this.state.selectedYear, this.state.selectedMake, this.state.selectedView, this.state.vehicleId, this.state.vifNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveAllClick(event) {
        try {
            this.props.onSaveAllClick(this.state.selectedYear);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <div className="form-row dgdg-query">
                        <DGDGFormDropdownComponent label="Select Year" data={this.state.years} displayFormat="Year" value={this.state.selectedYear ? this.state.selectedYear : "All"} onItemClick={this.onYearClick} />
                        <DGDGFormDropdownComponent label="Make" data={this.state.makes} itemKey="makeName" onItemClick={this.onMakeClick} value={this.state.selectedMake !== null ? this.state.selectedMake.makeName : "Select Make"} />
                        <DGDGFormDropdownComponent label="Mapped" data={this.viewData} itemKey="value" onItemClick={this.onViewClick} value={this.state.selectedView !== null ? this.state.selectedView.value : "Select View"} cssName="evox-images-separator" />
                        <DGDGFormInputComponent id="vehicleId" label="Vehicle Id" value={this.state.vehicleId} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                        <DGDGFormInputComponent id="vifNumber" label="VIF" value={this.state.vifNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                        <button disabled={this.props.isLoadingData || this.state.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <span className="fas fa-redo-alt dgdg-icon" />
                        </button>
                        <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={this.onSaveAllClick}>
                            Save All<i className="fas fa-save dgdg-icon" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={this.props.isLoadingData || this.state.isLoadingData} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
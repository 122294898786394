import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class AutoCheckVHRQueryComponent extends React.Component {
    static VinCookie = "AutoCheckVHRVinCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("AutoCheckVHRQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            vin: null,
            showSpinner: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.vin === null) {
                let vin = CommonService.getCookie(AutoCheckVHRQueryComponent.VinCookie);
                this.setState({
                    vin: vin
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(AutoCheckVHRQueryComponent.VinCookie, this.state.vin, 30);
            if (this.state.vin) {
                this.setState({ showSpinner: true });
                this.props.onGetReportClick(this.state.vin);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onLookupClick={(event) => this.onGetReportClick(event)} />
                    <button disabled={this.state.showSpinner || (this.state.vin ? false : true)} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

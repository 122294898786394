import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { FIPayrollPlan2TierRowEditComponent } from "./FIPayrollPlan2TierRowEditComponent";

export class FIPayrollPlan2EditComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("FIPayrollPlan2EditComponent - getDerivedStateFromProps");
            if (state.showProgress) {
                newState.showProgress = !props.isSaveComplete;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            editRows: null
        };

        this.onRowEdited = this.onRowEdited.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (!this.props.planDetail && this.state.editRows) {
                this.setState({ editRows: null });
            }
            else if ((!prevProps.planDetail && this.props.planDetail)
                || (prevProps.planDetail && this.props.planDetail && prevProps.planDetail.plan_name !== this.props.planDetail.plan_name)) {
                this.setState({
                    editRows: JSON.parse(JSON.stringify(this.props.planDetail.plan))
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "FI Payroll";

    onRowEdited(editedRow) {
        try {
            let editRows = this.state.editRows;
            editRows.filter(planRow => planRow.tier === editedRow.tier)[0] = editedRow;
            this.setState({ editRows: editRows });
        }
        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showProgress: true });
            let planDetail = this.props.planDetail;
            planDetail.plan = this.state.editRows;
            this.props.onSaveClick(event);
        }
        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                editRows: null
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let planDetail = null;
        if (this.state.editRows) {
            planDetail = [];
            this.state.editRows.forEach((planRow, rowIndex) => {
                planDetail.push(<FIPayrollPlan2TierRowEditComponent key={"tierRow" + rowIndex} editRow={planRow} onRowEdited={this.onRowEdited} applicationInsights={this.props.applicationInsights} />)
            });
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.state.editRows}
            cssName="dgdg-fi-payroll-plan2-dialog"
            title={"Edit " + this.props.planType + " - " + this.props.planDetail?.plan_name}
            onCancelClick={this.onCancelClick}>
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <DGDGSpinnerComponent showSpinner={this.state.showProgress} />
                <div className="card">
                    <div className="card-block">
                        <table className="dgdg-fi-payroll-plan-table">
                            <tbody>
                                <tr className="dgdg-fi-payroll-plan-font">
                                    <td >
                                        Tier
                                    </td>
                                    <td>
                                        Base Bonus
                                    </td>
                                    <td>
                                        Bonus with DMV
                                    </td>
                                    <td>
                                        PVR
                                    </td>
                                    <td>
                                        PPR
                                    </td>
                                    <td>
                                        VSC
                                    </td>
                                    <td>
                                        Maintenance
                                    </td>
                                    <td>
                                        CSI
                                    </td>
                                    <td>
                                        CIT
                                    </td>
                                    <td>
                                        EV Product Increment
                                    </td>
                                    <td>
                                        EV Gross Increment
                                    </td>
                                    <td >
                                        Hourly
                                    </td>
                                </tr>
                                {planDetail}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";

export class FacebookSalesCSVRouteComponent extends React.Component {
    constructor(props) {
        super(props);

        let today = moment();
        let lastQuarterEndMonth = today.month() - 1 - today.month() % 3;
        lastQuarterEndMonth = lastQuarterEndMonth === 0 ? 12 : lastQuarterEndMonth;
        let startDate = moment().date(1).month(lastQuarterEndMonth - 2).year(today.year);
        let endDate = moment().date(0).month(lastQuarterEndMonth).year(today.year());
        this.setState({

        });

        this.state = {
            startDate: startDate,
            endDate: endDate,
            showSpinner: false
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FacebookSalesCSVRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FacebookSalesCSV", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("FacebookSalesCSVRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Facebook Sales CSV";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FacebookSalesCSVRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FacebookSalesCSV/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "FacebookSalesCSVRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FacebookSalesCSV/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate) && CommonService.isDateValid(this.state.endDate)) {
                this.setState({ showSpinner: true });
                let inputData = {
                    startDate: CommonService.formatServerDateTime(this.state.startDate.toString()),
                    endDate: CommonService.formatServerDateTime(this.state.endDate.toString())
                };

                this.props.applicationInsights.trackTrace({ message: "/FacebookSalesCSVRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/FacebookSalesCSV/Download", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response } else { throw response; } })
                    .then(response => {
                        CommonService.downloadBlob(response);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FacebookSalesCSV/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    })
                    .finally(() => {
                        this.setState({ showSpinner: false });
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onDownloadClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <div className="form-inline">
                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Start Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="endDate" label="End Date" value={this.state.endDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                                Download<i className="far fa-download dgdg-icon" />
                            </button>
                            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class StoreContactMapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            email: null,
            employeeSupervisorList: [],
            selectedEmployee: null
        };

        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.store
                && (!prevProps.store || prevProps.store !== this.props.store)) {
                let selectedEmployee = null;
                if (this.props.store.employee_name) {
                    selectedEmployee = this.props.employeeSupervisorList.filter(supervisor => supervisor.employee_name === this.props.store.employee_name)[0];
                }
                this.setState({
                    employeeSupervisorList: this.props.employeeSupervisorList,
                    employeeName: this.props.store.employee_name ? this.props.store.employee_name : "",
                    selectedEmployee: selectedEmployee,
                    email: this.props.store.email_id ? this.props.store.email_id : null
                });
                $('#ddpContactMap').trigger('focus');
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Store";

    onEmployeeChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.employeeSupervisorList) {
                filteredData = this.props.employeeSupervisorList.filter(empRow => {
                    return empRow.employee_last.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || empRow.employee_first.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || empRow.employee_number.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
                });
            }

            let selectedEmployee = filterValue === "" ? null : this.state.selectedEmployee;
            this.setState({
                selectedEmployee: selectedEmployee,
                employeeName: filterValue,
                employeeSupervisorList: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeClick(id, employee) {
        try {
            if (employee.employee_name) {
                this.setState({
                    selectedEmployee: employee,
                    employeeName: employee.employee_name
                });
            } else {
                this.setState({
                    selectedEmployee: null,
                    employeeName: ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (this.props.store) {
                this.setState({ showSpinner: true });
                let inputData = {
                    storenumber: this.props.store.store_number,
                    gmEmailList: this.props.store.email_type === "gm_email_list" ? this.state.email : this.props.store.gm_email_list,
                    salesEmailList: this.props.store.email_type === "sales_email_list" ? this.state.email : this.props.store.sales_email_list,
                    fiEmailList: this.props.store.email_type === "fi_email_list" ? this.state.email : this.props.store.fi_email_list,
                    serviceEmailList: this.props.store.email_type === "service_email_list" ? this.state.email : this.props.store.service_email_list,
                    partsEmailList: this.props.store.email_type === "parts_email_list" ? this.state.email : this.props.store.parts_email_list
                };
                this.props.onSaveClick(event, inputData);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedEmployee: null,
                employeeName: "",
                email: null
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.store) {
            title = this.props.store.display_name + " - " + (this.props.store.employee_type_title ? this.props.store.employee_type_title : this.props.store.email_type_title);
        }

        return <div>
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.props.store}
                cssName="dgdg-store-user-map-dialog"
                title={title}
                onCancelClick={this.onCancelClick}
            >
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary" onClick={this.onSaveClick}>
                        Save <i className="fas fa-save dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <div className="card">
                        <div className="card-block">
                            {
                                this.props.store && this.props.store.employee_type_title
                                    ? <DGDGFormComboBoxComponent id="ddpContactMap" data={this.state.employeeSupervisorList} itemKey="employee_name" value={this.state.employeeName} onItemClick={this.onEmployeeClick} onItemChange={this.onEmployeeChange} autoDrop />
                                    : <DGDGFormInputComponent id="email" value={this.state.email} onChange={this.onTextChange} />
                            }
                        </div>
                    </div>
                </div>

            </DGDGModalDialogComponent></div>;
    }
}

import React from "react";
import { DGDGFormCheckboxV2Component } from "../dgdgcheckbox/DGDGFormCheckboxV2Component";

export class DGDGWidgetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        if (this.props.isMaximized) {
            switch (event.keyCode) {
                case 27:
                    this.props.onRestoreClick(event);
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return <div className={(this.props.isMaximized ? this.props.maximizedCss : this.props.restoredCss) + (this.props.isVisible ? "" : " d-none")}>
            <div className="card dgdg-box-shadow" style={{ height: "100%" }}>
                {
                    this.props.title
                        ? <div className={`card-header ${this.props.toggleEnabled ? this.props.widgetHeaderCss : ""}`}>
                            <span>{this.props.title}</span>
                            {
                                this.props.toggleEnabled
                                    ? <DGDGFormCheckboxV2Component id={this.props.checkboxId} value={this.props.checkboxValue} label={this.props.checkboxLabel} onChange={this.props.onCheckboxChange} cssName="dgdg-widget-reports-toggle" />
                                    : null
                            }
                            {
                                <div className="float-right" style={{ marginLeft: "auto" }}>
                                    {
                                        this.props.rightTitle
                                            ? <span style={{ "padding": "20px" }}>{this.props.rightTitle}</span>
                                            : null
                                    }
                                    {
                                        (this.props.onRestoreClick || this.props.onMaximizeClick)
                                            ? this.props.isMaximized
                                                ? <button type="button" className="btn btn-link" style={{ "padding": "0px" }} onClick={(event) => this.props.onRestoreClick(event)}>
                                                    <i className="far fa-window-restore" style={{ color: "black" }} />
                                                </button>
                                                : <button type="button" className="btn btn-links" style={{ "padding": "0px" }} onClick={(event) => this.props.onMaximizeClick(event)}>
                                                    <i className="far fa-window-maximize" style={{ color: "black" }} />
                                                </button>
                                            : null
                                    }
                                </div>
                            }
                        </div>
                        : null
                }
                <div className="card-block" style={{ height: "calc(100% - 35px)" }}>
                    {this.props.children}
                </div>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class BadDebtDetailQueryComponent extends React.Component {
    static StoreNumberCookie = "BadDebtDetailStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("BadDebtDetailQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                state.showSpinner = props.isLoadingData || props.isDownLoading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            showHidden: false,
            selectedStore: null
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onShowHiddenChange = this.onShowHiddenChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let storeNumber = Number(this.props.match.params.StoreNumber);
                if (storeNumber && storeNumber > 0) {
                    let filterStore = this.props.stores.filter(store => storeNumber === store.store_number);
                    if (filterStore.length > 0) {
                        selectedStore = filterStore[0];
                    }
                }
                else {
                    let cookieStoreNumber = CommonService.getCookie(BadDebtDetailQueryComponent.StoreNumberCookie);
                    if (cookieStoreNumber) {
                        cookieStoreNumber = parseInt(cookieStoreNumber);
                        let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                        if (filterStore.length === 1) {
                            selectedStore = filterStore[0];
                        }
                    }
                }

                this.setState({
                    selectedStore: selectedStore
                }, () => {
                    if (this.props.match.params.StoreNumber) {
                        this.props.onGetReportClick(this.state.selectedStore, this.state.showHidden)
                    }
                    else {
                        this.onGetReportClick(null);
                    }
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowHiddenChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
            this.props.onShowHiddenChange(isChecked);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            window.history.replaceState(null, this.pageTitle, "/BadDebt/Detail");
            CommonService.setCookie(BadDebtDetailQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.setState({ showSpinner: true }, () => {
                this.props.onGetReportClick(this.state.selectedStore, this.state.showHidden);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onDownloadClick(this.state.selectedStore, this.state.showHidden);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
            {
                this.props.canEditIsHidden
                    ? <DGDGFormCheckboxComponent id="showHidden" label="Show Hidden" value={this.state.showHidden} onChange={this.onShowHiddenChange} />
                    : null
            }
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                Download <i className="far fa-download dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ServiceAdvisorReportQueryComponent } from "./ServiceAdvisorReportQueryComponent";
import { ServiceAdvisorReportMenuNotCountedDetailComponent } from "./ServiceAdvisorReportMenuNotCountedDetailComponent";
import { ServiceAdvisorReportTotalClosedRODetailComponent } from "./ServiceAdvisorReportTotalClosedRODetailComponent";
import { ServiceAdvisorReportTotalCommissionableGrossComponent } from "./ServiceAdvisorReportTotalCommissionableGrossComponent";
import { ServiceAdvisorReportCPCommissionableGrossComponent } from "./ServiceAdvisorReportCPCommissionableGrossComponent";
import { ServiceAdvisorReportCPRODetailComponent } from "./ServiceAdvisorReportCPRODetailComponent";

export class ServiceAdvisorReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: true,
            stores: null,
            months: CommonService.getMonths(),
            tableData: [],
            footerRow: null,
            performanceOverallTableTitle: "",
            performanceByServiceTypeTableTitle: "",
            paintTable: false,
            lastUpdatedDate: null,

            menuNotCountedTableData: [],
            menuNotCountedTableTitle: "",
            menuNotCountedHeaderText: "",
            menuNotCountedPaintTable: false,

            cpROTableData: [],
            cpROTableTitle: "",
            cpROHeaderText: "",
            cpROPaintTable: false,

            totalClosedROTableData: [],
            totalClosedROTableTitle: "",
            totalClosedROHeaderText: "",
            totalClosedROPaintTable: false,

            totalCommGrossTableTitle: "",
            totalGrossROTableData: [],
            totalGrossROTableTitle: "",
            totalPaintTable: false,

            totalExcludedROTableData: [],
            totalExcludedROTableTitle: "",

            totalRentalROTableData: [],
            totalRentalROTableTitle: "",

            cpCommGrossTableTitle: "",
            cpCommGrossTableData: [],
            cpCommGrossTotalGrossROTableTitle: "",
            cpCommGrossPaintTable: false,

            childModelClosedTime: null,
            roDetailTitle: "",
            roDetailResponse: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onMenuNotCountedCellClick = this.onMenuNotCountedCellClick.bind(this);
        this.onMenuNotCountedCancelClick = this.onMenuNotCountedCancelClick.bind(this);
        this.onTotalClosedROCellClick = this.onTotalClosedROCellClick.bind(this);
        this.onTotalClosedROCancelClick = this.onTotalClosedROCancelClick.bind(this);
        this.onCPROCellClick = this.onCPROCellClick.bind(this);
        this.onCPROCancelClick = this.onCPROCancelClick.bind(this);
        this.onRODetailCellClick = this.onRODetailCellClick.bind(this);
        this.onRODetailCancelClick = this.onRODetailCancelClick.bind(this);
        this.onTotalCommGrossCellClick = this.onTotalCommGrossCellClick.bind(this);
        this.onTotalCommGrossCancelClick = this.onTotalCommGrossCancelClick.bind(this);
        this.onCPCommGrossCellClick = this.onCPCommGrossCellClick.bind(this);
        this.onCPCommGrossCancelClick = this.onCPCommGrossCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ServiceAdvisorReportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "ServiceAdvisorReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("ServiceAdvisorReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service Advisor Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "ServiceAdvisorReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceAdvisorReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/ServiceAdvisorReport/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {

                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetLastUpdated", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedMonth, selectedStore) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
                storeNumber: selectedStore.store_number
            };

            this.setState({ isLoadingData: true });
            this.onGetLastUpdatedDate(selectedMonth);

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.filter(tableRow => tableRow.service_advisor_number !== null);
                    let footerRow = parsedResponse.filter(tableRow => tableRow.service_advisor_number === null)[0]
                    tableData.forEach((tableRow, index) => {
                        tableRow.month = selectedMonth;
                        tableRow.store_web_name = tableRow.store_number + " | " + tableRow.store_name;
                        tableRow.display_name = tableRow.service_advisor_name + " (" + tableRow.service_advisor_number + ")";
                    });
                    this.setState({
                        isLoadingData: false,
                        performanceOverallTableTitle: selectedStore.display_name + " - " + CommonService.formatMonthYear(moment(selectedMonth)) + " - Total Tech Hours for Month: " + CommonService.formatNumber(footerRow ? footerRow.goal_hours : 0) + " Hrs",
                        performanceByServiceTypeTableTitle: selectedStore.display_name + " - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        footerRow: parsedResponse.filter(tableRow => tableRow.service_advisor_number === null)[0],
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        performanceOverallTableTitle: "",
                        performanceByServiceTypeTableTitle: "",
                        footerRow: null,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetReport", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMenuNotCountedCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(rowData.month),
                storeNumber: rowData.store_number,
                serviceAdvisorNumber: rowData.service_advisor_number
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onMenuNotCountedCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetMenuNotCounted", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseRow) => {
                        responseRow.store_web_name = rowData.store_web_name;
                        responseRow.month = rowData.month;
                        responseRow.display_name = rowData.display_name;
                    });

                    this.setState({
                        isLoadingData: false,
                        menuNotCountedTableTitle: "Menus That Didn't Pass - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)),
                        menuNotCountedHeaderText: rowData.display_name,
                        menuNotCountedTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        menuNotCountedPaintTable: true
                    }, () => {
                        this.setState({ menuNotCountedPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        menuNotCountedTableTitle: "",
                        menuNotCountedHeaderText: "",
                        menuNotCountedTableData: [],
                        menuNotCountedPaintTable: true
                    }, () => {
                        this.setState({ menuNotCountedPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetMenuNotCounted", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMenuNotCountedCancelClick(event) {
        try {
            this.setState({
                menuNotCountedTableData: [],
                menuNotCountedTableTitle: "",
                menuNotCountedHeaderText: "",
                menuNotCountedPaintTable: true
            }, () => {
                this.setState({ menuNotCountedPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRODetailCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                roNumber: rowData.ro_number,
                vehicleId: rowData.vehicle_id
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onRODetailCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetRODetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        roDetailResponse: parsedResponse,
                        roDetailTitle: "RO Detail - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)) + " - "
                            + rowData.display_name + " - "
                            + rowData.ro_number + " - "
                            + rowData.vehicle_id
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        roDetailResponse: null
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetRODetail", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRODetailCancelClick(event) {
        try {
            this.setState({
                roDetailResponse: null,
                childModelClosedTime: moment()
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalClosedROCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(rowData.month),
                storeNumber: rowData.store_number,
                serviceAdvisorNumber: rowData.service_advisor_number
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onTotalClosedROCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetTotalClosedRO", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseRow) => {
                        responseRow.store_web_name = rowData.store_web_name;
                        responseRow.month = rowData.month;
                        responseRow.display_name = rowData.display_name;
                    });

                    this.setState({
                        isLoadingData: false,
                        totalClosedROTableTitle: "Total Closed ROs - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)),
                        totalClosedROHeaderText: rowData.display_name,
                        totalClosedROTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        totalClosedROPaintTable: true
                    }, () => {
                        this.setState({ totalClosedROPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        totalClosedROTableTitle: "",
                        totalClosedROHeaderText: "",
                        totalClosedROTableData: [],
                        totalClosedROPaintTable: true
                    }, () => {
                        this.setState({ totalClosedROPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetTotalClosedRO", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalClosedROCancelClick(event) {
        try {
            this.setState({
                totalClosedROTableData: [],
                totalClosedROTableTitle: "",
                totalClosedROHeaderText: "",
                totalClosedROPaintTable: true
            }, () => {
                this.setState({ totalClosedROPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPROCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(rowData.month),
                storeNumber: rowData.store_number,
                serviceAdvisorNumber: rowData.service_advisor_number
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onCPROCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetCPRO", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseRow) => {
                        responseRow.store_web_name = rowData.store_web_name;
                        responseRow.month = rowData.month;
                        responseRow.display_name = rowData.display_name;
                    });

                    this.setState({
                        isLoadingData: false,
                        cpROTableTitle: "CP ROs - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)),
                        cpROHeaderText: rowData.display_name,
                        cpROTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        cpROPaintTable: true
                    }, () => {
                        this.setState({ cpROPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        cpROTableTitle: "",
                        cpROHeaderText: "",
                        cpROTableData: [],
                        cpROPaintTable: true
                    }, () => {
                        this.setState({ cpROPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReport/GetCPRO", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPROCancelClick(event) {
        try {
            this.setState({
                cpROTableData: [],
                cpROTableTitle: "",
                cpROHeaderText: "",
                cpROPaintTable: true
            }, () => {
                this.setState({ cpROPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalCommGrossCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(rowData.month),
                storeNumber: rowData.store_number,
                serviceAdvisorNumber: rowData.service_advisor_number
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onTotalCommGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetTotalCommissionableGross", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseRow) => {
                        responseRow.store_web_name = rowData.store_web_name;
                        responseRow.month = rowData.month;
                        responseRow.display_name = rowData.display_name;
                    });

                    this.setState({
                        isLoadingData: false,
                        totalCommGrossTableTitle: "Total Commissionable Gross - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)),
                        totalGrossROTableTitle: "Total Gross ROs - " + rowData.display_name,
                        totalGrossROTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        totalPaintTable: true,

                        totalExcludedROTableData: CommonService.addOrdinalColumn(parsedResponse.table1),
                        totalExcludedROTableTitle: "Excluded ROs - " + rowData.display_name,

                        totalRentalROTableData: CommonService.addOrdinalColumn(parsedResponse.table2),
                        totalRentalROTableTitle: "Rental ROs - " + rowData.display_name,
                    }, () => {
                        this.setState({ totalPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        totalCommGrossTableTitle: "",
                        totalGrossROTableData: [],
                        totalGrossROTableTitle: "",
                        totalPaintTable: true,

                        totalExcludedROTableData: [],
                        totalExcludedROTableTitle: "",

                        totalRentalROTableData: [],
                        totalRentalROTableTitle: "",
                    }, () => {
                        this.setState({ totalPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceAdvisorReport/GetTotalCommissionableGross", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalCommGrossCancelClick(event) {
        try {
            this.setState({
                totalCommGrossTableTitle: "",
                totalGrossROTableData: [],
                totalGrossROTableTitle: "",
                totalPaintTable: true,

                totalExcludedROTableData: [],
                totalExcludedROTableTitle: "",

                totalRentalROTableData: [],
                totalRentalROTableTitle: "",
            }, () => {
                this.setState({ totalPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPCommGrossCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(rowData.month),
                storeNumber: rowData.store_number,
                serviceAdvisorNumber: rowData.service_advisor_number
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onCPCommGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/GetCPCommissionableGross", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseRow) => {
                        responseRow.store_web_name = rowData.store_web_name;
                        responseRow.month = rowData.month;
                        responseRow.display_name = rowData.display_name;
                    });
                    this.setState({
                        isLoadingData: false,
                        cpCommGrossTableTitle: "CP Commissionable Gross - "
                            + rowData.store_web_name + " - "
                            + CommonService.formatMonthYear(moment(rowData.month)),
                        cpCommGrossTotalGrossROTableTitle: "Total Gross ROs - " + rowData.display_name,
                        cpCommGrossTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        cpCommGrossPaintTable: true
                    }, () => {
                        this.setState({ cpCommGrossPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        cpCommGrossTableTitle: "",
                        cpCommGrossTableData: [],
                        cpCommGrossTotalGrossROTableTitle: "",
                        cpCommGrossPaintTable: true
                    }, () => {
                        this.setState({ cpCommGrossPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceAdvisorReport/GetCPCommissionableGross", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPCommGrossCancelClick(event) {
        try {
            this.setState({
                cpCommGrossTableTitle: "",
                cpCommGrossTableData: [],
                cpCommGrossTotalGrossROTableTitle: "",
                cpCommGrossPaintTable: true
            }, () => {
                this.setState({ cpCommGrossPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-service-advisor", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceAdvisorReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceAdvisorReportRouteComponent/onPrintClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceAdvisorReport/PrintClick")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<div class="table-title">Performance Overall - ' + this.state.performanceOverallTableTitle
                        + '</div><table class="table table-bordered dgdg-service-advisor-report-print-table-font-size">' + $("#PerformanceOverall  .dgdg-main-table").html()
                        + '</table><div class="dgdg-page-break" /><div class="table-title">Performance by Service Type - ' + this.state.performanceByServiceTypeTableTitle
                        + '</div><table class="table table-bordered">' + $("#PerformanceByServiceType  .dgdg-main-table").html() + '</table>';
                    CommonService.showPrint("Service Advisor Report", printContent, true, true);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceAdvisorReport/PrintClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <ServiceAdvisorReportMenuNotCountedDetailComponent
                tableData={this.state.menuNotCountedTableData} tableTitle={this.state.menuNotCountedTableTitle} headerText={this.state.menuNotCountedHeaderText} paintTable={this.state.menuNotCountedPaintTable}
                roDetailTitle={this.state.roDetailTitle} roDetailResponse={this.state.roDetailResponse} onRODetailCellClick={this.onRODetailCellClick} onRODetailCancelClick={this.onRODetailCancelClick} childModelClosedTime={this.state.childModelClosedTime}
                onCancelClick={this.onMenuNotCountedCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights}
            />
            <ServiceAdvisorReportCPRODetailComponent
                tableData={this.state.cpROTableData} tableTitle={this.state.cpROTableTitle} headerText={this.state.cpROHeaderText} paintTable={this.state.cpROPaintTable}
                roDetailTitle={this.state.roDetailTitle} roDetailResponse={this.state.roDetailResponse} onRODetailCellClick={this.onRODetailCellClick} onRODetailCancelClick={this.onRODetailCancelClick} childModelClosedTime={this.state.childModelClosedTime}
                onCancelClick={this.onCPROCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights}
            />
            <ServiceAdvisorReportTotalClosedRODetailComponent
                tableData={this.state.totalClosedROTableData} tableTitle={this.state.totalClosedROTableTitle} headerText={this.state.totalClosedROHeaderText} paintTable={this.state.totalClosedROPaintTable}
                roDetailTitle={this.state.roDetailTitle} roDetailResponse={this.state.roDetailResponse} onRODetailCellClick={this.onRODetailCellClick} onRODetailCancelClick={this.onRODetailCancelClick} childModelClosedTime={this.state.childModelClosedTime}
                onCancelClick={this.onTotalClosedROCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights}
            />
            <ServiceAdvisorReportTotalCommissionableGrossComponent
                tableTitle={this.state.totalCommGrossTableTitle}
                totalGrossROTableData={this.state.totalGrossROTableData} totalGrossROTableTitle={this.state.totalGrossROTableTitle} totalGrossROPaintTable={this.state.totalPaintTable}
                totalExcludedROTableData={this.state.totalExcludedROTableData} totalExcludedROTableTitle={this.state.totalExcludedROTableTitle} totalExcludedROPaintTable={this.state.totalPaintTable}
                totalRentalROTableData={this.state.totalRentalROTableData} totalRentalROTableTitle={this.state.totalRentalROTableTitle} totalRentalROPaintTable={this.state.totalPaintTable}
                roDetailTitle={this.state.roDetailTitle} roDetailResponse={this.state.roDetailResponse} onRODetailCellClick={this.onRODetailCellClick} onRODetailCancelClick={this.onRODetailCancelClick} childModelClosedTime={this.state.childModelClosedTime}
                onCancelClick={this.onTotalCommGrossCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights}
            />
            <ServiceAdvisorReportCPCommissionableGrossComponent
                tableTitle={this.state.cpCommGrossTableTitle}
                tableData={this.state.cpCommGrossTableData} headerText={this.state.cpCommGrossTotalGrossROTableTitle} paintTable={this.state.cpCommGrossPaintTable}
                roDetailTitle={this.state.roDetailTitle} roDetailResponse={this.state.roDetailResponse} onRODetailCellClick={this.onRODetailCellClick} onRODetailCancelClick={this.onRODetailCancelClick} childModelClosedTime={this.state.childModelClosedTime}
                onCancelClick={this.onCPCommGrossCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights}
            />
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content dgdg-service-advisor-report-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <ServiceAdvisorReportQueryComponent
                        applicationInsights={this.props.applicationInsights}
                        stores={this.state.stores}
                        months={this.state.months}
                        isLoadingData={this.state.isLoadingData}
                        onGetReportClick={this.onGetReportClick}
                        onPrintClick={this.onPrintClick}
                    />
                    <nav className="nav nav-pills dgdg-nav">
                        <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#PerformanceOverall")}>Performance Overall</button>
                        <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#PerformanceByServiceType")}>Performance by Service Type</button>
                    </nav>
                    <div className="dgdg-service-advisor dgdg-site-scrollable-content">
                        <div id="PerformanceOverall" className="dgdg-card-gutter">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Performance Overall - " + this.state.performanceOverallTableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow}
                                showFooter stickyHeader
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37], onCopyTableCallBack: this.onCopyTableCallBack }}
                            >
                                <DGDGTableHeaderComponent position="PreHeader">
                                    <tr className="dgdg-table-v3-header">
                                        <td />
                                        <td />
                                        <td colSpan="15" className="text-center font-weight-bol dgdg-advisor-bg-head">Commissionable Gross</td>
                                        <td colSpan="4" className="text-center font-weight-bol dgdg-advisor-bg-head">Commissionable Hours</td>
                                        <td colSpan="8" className="text-center font-weight-bol dgdg-advisor-bg-head">RO Counts</td>
                                        <td colSpan="9" className="text-center font-weight-bol dgdg-advisor-bg-head">Advisor Performance</td>
                                    </tr>
                                </DGDGTableHeaderComponent>
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="Advisor Name" dataColumn="display_name" sortColumn="service_advisor_name" filterColumn="service_advisor_name" footerText="Totals" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="CP Labor Gross" dataColumn="cp_labor_gross" sortColumn="cp_labor_gross" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Labor Gross %" dataColumn="cp_labor_gross_pct" sortColumn="cp_labor_gross_pct" dataType="percent" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Parts Gross" dataColumn="cp_parts_gross" sortColumn="cp_parts_gross" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Parts Gross %" dataColumn="cp_parts_gross_pct" sortColumn="cp_parts_gross_pct" dataType="percent" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Total Gross" dataColumn="cp_gross" sortColumn="cp_gross" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Labor Discounts" dataColumn="total_discount" sortColumn="total_discount" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Comm Gross" onCellClick={this.onCPCommGrossCellClick} dataColumn="cp_commissionable_gross" sortColumn="cp_commissionable_gross" dataType="money" cssName="font-weight-bold" footerCssName="text-right" columnHelp="Customer pay gross which excludes internal, warranty, sublet, used recon, etc" />
                                    <DGDGTableColumnComponent headerText="Warr & Int Comm Gross" dataColumn="wp_ip_commissionable_gross" sortColumn="wp_ip_commissionable_gross" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Total Comm Gross" onCellClick={this.onTotalCommGrossCellClick} dataColumn="total_commissionable_gross" sortColumn="total_commissionable_gross" dataType="money" cssName="font-weight-bold" footerCssName="text-right" columnHelp="Total gross which excludes sublet, used recon, deductibles, etc" />
                                    <DGDGTableColumnComponent headerText="Total Comm Gross EOM Track" dataColumn="total_commissionable_gross_tracking" sortColumn="total_commissionable_gross_tracking" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Hours" dataColumn="cp_sold_hours" sortColumn="cp_sold_hours" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Hours Per RO" dataColumn="cp_sold_hours_per_ro" sortColumn="cp_sold_hours_per_ro" dataType="number_2" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP ELR" dataColumn="cp_elr" sortColumn="cp_elr" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="WP ELR" dataColumn="wp_elr" sortColumn="wp_elr" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Int ELR" dataColumn="ip_elr" sortColumn="ip_elr" dataType="money" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Goal Hours" dataColumn="goal_hours" sortColumn="goal_hours" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="commissionable_sold_hours" sortColumn="commissionable_sold_hours" dataType="formatted_number" cssName="font-weight-bold" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Goal %" dataColumn="goal_pct" sortColumn="goal_pct" dataType="percent" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="MTD Tracking %" dataColumn="mtd_tracking_pct" sortColumn="mtd_tracking_pct" dataType="percent" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Total Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="number_1" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Total Closed ROs" onCellClick={this.onTotalClosedROCellClick} dataColumn="total_closed_ro_count" sortColumn="total_closed_ro_count" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Total Open ROs" dataColumn="total_open_ro_count" sortColumn="total_open_ro_count" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP ROs" dataColumn="cp_ro_count" onCellClick={this.onCPROCellClick} sortColumn="cp_ro_count" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="ROs Over 7 Days" dataColumn="ro_over_7_days_count" sortColumn="ro_over_7_days_count" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="ROs Over 30 Days" dataColumn="ro_over_30_days_count" sortColumn="ro_over_30_days_count" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="ROs In Done Status" dataColumn="ro_in_done_status" sortColumn="ro_in_done_status" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Done Still Open %" dataColumn="pct_done_still_open" sortColumn="pct_done_still_open" dataType="percent" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="CP Gross Per RO" dataColumn="cp_gross_per_ro" sortColumn="cp_gross_per_ro" dataType="money" cssName="font-weight-bold" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="SDS %" dataColumn="sds_pct" sortColumn="sds_pct" dataType="percent" cssName="font-weight-bold" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Avail Recs To Sell" dataColumn="avail_recs_to_sell" sortColumn="avail_recs_to_sell" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Factory Menu" dataColumn="factory_menu" sortColumn="factory_menu" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Value Menu" dataColumn="value_menu" sortColumn="value_menu" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Long Life Menu" dataColumn="long_life_menu" sortColumn="long_life_menu" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="LOF" dataColumn="lof" sortColumn="lof" dataType="formatted_number" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Menus That Didn’t Pass" onCellClick={this.onMenuNotCountedCellClick} dataColumn="menu_not_counted" sortColumn="menu_not_counted" dataType="formatted_number" footerCssName="text-right" columnHelp="ROs with a menu Op Code having less than 0.5 sold hours or a Gross (labor + parts) to Sales percentage less than 50%" />
                                    <DGDGTableColumnComponent headerText="Menu %" dataColumn="menu_penetration" sortColumn="menu_penetration" dataType="percent" cssName="font-weight-bold" footerCssName="text-right" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                        <div id="PerformanceByServiceType">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Performance by Service Type - " + this.state.performanceByServiceTypeTableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow}
                                showFooter stickyHeader
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="Advisor Name" dataColumn="display_name" sortColumn="service_advisor_name" filterColumn="service_advisor_name" footerText="Totals" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Alignment Count" dataColumn="alignment_count" sortColumn="alignment_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with alignment" />
                                    <DGDGTableColumnComponent headerText="Tire Count" dataColumn="tire_count" sortColumn="tire_count" dataType="formatted_number" footerCssName="text-right" columnHelp="Tire parts count" />
                                    <DGDGTableColumnComponent headerText="Sanitizer Count" dataColumn="sanitizer_count" sortColumn="sanitizer_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with sanitizer" />
                                    <DGDGTableColumnComponent headerText="Fuel Injection Count" dataColumn="fuel_injection_count" sortColumn="fuel_injection_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with fuel injection" />
                                    <DGDGTableColumnComponent headerText="Coolant System Count" dataColumn="coolant_system_count" sortColumn="coolant_system_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with coolant system" />
                                    <DGDGTableColumnComponent headerText="Differential Service Count" dataColumn="differential_service_count" sortColumn="differential_service_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with differential service" />
                                    <DGDGTableColumnComponent headerText="Brake Fluid Exchange Count" dataColumn="brake_fluid_exchange_count" sortColumn="brake_fluid_exchange_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with brake fluid exchange" />
                                    <DGDGTableColumnComponent headerText="Power Steering Fluid Count" dataColumn="power_steering_fluid_count" sortColumn="power_steering_fluid_count" dataType="formatted_number" footerCssName="text-right" columnHelp="RO count with power steering fluid" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

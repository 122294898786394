import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartAzureMapComponent } from "../common/dgdgchart/DGDGChartAzureMapComponent";
import { DGDGChartAzureMapClusterAggregatesComponent } from "../common/dgdgchart/DGDGChartAzureMapClusterAggregatesComponent";
import { DGDGChartAzureMapLegendComponent } from "../common/dgdgchart/DGDGChartAzureMapLegendComponent";

export class DemoChartAzureMapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        const chartType = this.props.chartType;
        return (
            <div className="dgdg-widget-item">
                {

                    chartType === "basicChart" ? (
                        <DGDGChartAzureMapComponent dashboardItem={this.props.dashboardItem} azureSubscriptionKey={this.props.azureSubscriptionKey} popuUpDisplay={this.props.popuUpDisplay} isMaximized={this.props.isMaximized} azureGetAddressUrl={this.props.azureGetAddressUrl} />
                    ) : (
                        chartType === "clusterAggregatesChart" ? (
                            <DGDGChartAzureMapClusterAggregatesComponent dashboardItem={this.props.dashboardItem} azureSubscriptionKey={this.props.azureSubscriptionKey} popuUpDisplay={this.props.popuUpDisplay} isMaximized={this.props.isMaximized} azureGetAddressUrl={this.props.azureGetAddressUrl}
                                entityTypes={this.props.entityTypes} clusterProperties={this.props.clusterProperties} bubbleLayerProperties={this.props.bubbleLayerProperties} azureMapTextOptions={this.props.azureMapTextOptions} azureMapFilterOptions={this.props.azureMapFilterOptions} />
                        ) :
                            (
                                <DGDGChartAzureMapLegendComponent dashboardItem={this.props.dashboardItem} isMaximized={this.props.isMaximized} azureSubscriptionKey={this.props.azureSubscriptionKey} popuUpDisplay={this.props.popuUpDisplay} azureGetAddressUrl={this.props.azureGetAddressUrl} legendData={this.props.legendData} />
                            )

                    )

                }
            </div>
        );
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";

export class SalesDealReportQueryComponent extends React.Component {
    static StoreNumberCookie = "SalesDealReportStoreNumberCookie";
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("SalesDealReportQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isDownloading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        let startDate = moment().startOf('month');
        let endDate = moment();

        this.state = {
            startDate: startDate,
            endDate: endDate,
            showSpinner: false,
            selectedStore: null,
            isGetReportDisabled: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(SalesDealReportQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                this.setState({
                    selectedStore: selectedStore
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value)
            }, this.validateDate);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(SalesDealReportQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            if (CommonService.isDateValid(this.state.startDate) && CommonService.isDateValid(this.state.endDate)) {
                this.setState({ showSpinner: true });
                this.props.onGetReportClick(this.state.selectedStore, this.state.startDate, this.state.endDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate) && CommonService.isDateValid(this.state.endDate)) {
                this.setState({ showSpinner: true });
                this.props.onDownloadClick(this.state.selectedStore, this.state.startDate, this.state.endDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    validateDate() {
        let isGetReportDisabled = false;
        if (moment(this.state.startDate) <= moment(this.state.endDate).subtract(3, 'months')) {
            isGetReportDisabled = true;
        }
        this.setState({
            isGetReportDisabled: isGetReportDisabled
        });
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Start Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="endDate" label="End Date" value={this.state.endDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner || this.state.isGetReportDisabled} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report <i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.state.isGetReportDisabled} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                        Download <i className="far fa-download dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class ManualCheckQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ManualCheckQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedYear: null,
            showSpinner: true
        };

        this.onYearClick = this.onYearClick.bind(this);
        this.onGetEmployeeChecksClick = this.onGetEmployeeChecksClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetEmployeeChecksClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.years && this.state.selectedYear === null) {
                this.setState({
                    selectedYear: this.props.years[0]
                }, this.onGetEmployeeChecksClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({ selectedYear: year });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetEmployeeChecksClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetClick(this.state.selectedYear);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddEmployeeChecksClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onAddClick(this.state.selectedYear);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Year" displayFormat="Year" data={this.props.years} value={this.state.selectedYear} onItemClick={this.onYearClick} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={(event) => this.onGetEmployeeChecksClick(event)}>
                        Get Employee Checks <span className="far fa-sync-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onAddEmployeeChecksClick(event)}>
                        Add Employee Check <span className="fas fa-plus dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { UsedReconditioningAuditQueryComponent } from "./UsedReconditioningAuditQueryComponent";

export class UsedReconditioningAuditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            tableData: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Used Car Prep";

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });

            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onGetReportClick(vin, stockNumber) {
        try {
            vin = vin === "" || vin === undefined ? null : vin;
            stockNumber = stockNumber === "" || stockNumber === undefined ? null : stockNumber;
            this.setState({
                isLoadingData: true
            });

            let inputData = JSON.stringify({
                vin: vin,
                stockNumber: stockNumber
            });

            fetch("/UsedReconditioning/GetAuditReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.length !== 0) {
                        parsedResponse[0].is_wholesale = parsedResponse[0].is_wholesale !== null ? parsedResponse[0].is_wholesale === true ? "Yes" : "No" : null;
                        parsedResponse[0].is_cpo = parsedResponse[0].is_cpo !== null ? parsedResponse[0].is_cpo === true ? "Yes" : "No" : null;
                        parsedResponse[0].recon_interior_only = parsedResponse[0].recon_interior_only !== null ? parsedResponse[0].recon_interior_only === true ? "True" : "False" : null;
                        parsedResponse[0].comments = this.getComments(parsedResponse[0].comments);
                        parsedResponse[0].entrydate = parsedResponse[0].entrydate !== null ? CommonService.formatDate(parsedResponse[0].entrydate) : null;
                        parsedResponse[0].smog_in_date_time = parsedResponse[0].smog_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].smog_in_date_time) : null;
                        parsedResponse[0].smog_out_date_time = parsedResponse[0].smog_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].smog_out_date_time) : null;
                        parsedResponse[0].vin_in_date_time = parsedResponse[0].vin_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].vin_in_date_time) : null;
                        parsedResponse[0].vin_out_date_time = parsedResponse[0].vin_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].vin_out_date_time) : null;
                        parsedResponse[0].weight_in_date_time = parsedResponse[0].weight_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].weight_in_date_time) : null;
                        parsedResponse[0].weight_out_date_time = parsedResponse[0].weight_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].weight_out_date_time) : null;
                        parsedResponse[0].bo_in_date_time = parsedResponse[0].bo_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].bo_in_date_time) : null;
                        parsedResponse[0].bo_out_date_time = parsedResponse[0].bo_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].bo_out_date_time) : null;
                        parsedResponse[0].dr_in_date_time = parsedResponse[0].dr_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].dr_in_date_time) : null;
                        parsedResponse[0].dr_out_date_time = parsedResponse[0].dr_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].dr_out_date_time) : null;
                        parsedResponse[0].auth_in_date_time = parsedResponse[0].auth_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].auth_in_date_time) : null;
                        parsedResponse[0].auth_out_date_time = parsedResponse[0].auth_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].auth_out_date_time) : null;
                        parsedResponse[0].parts_in_date_time = parsedResponse[0].parts_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].parts_in_date_time) : null;
                        parsedResponse[0].parts_out_date_time = parsedResponse[0].parts_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].parts_out_date_time) : null;
                        parsedResponse[0].vendor_in_date_time = parsedResponse[0].vendor_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].vendor_in_date_time) : null;
                        parsedResponse[0].vendor_out_date_time = parsedResponse[0].vendor_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].vendor_out_date_time) : null;
                        parsedResponse[0].monitor_in_date_time = parsedResponse[0].monitor_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].monitor_in_date_time) : null;
                        parsedResponse[0].monitor_out_date_time = parsedResponse[0].monitor_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].monitor_out_date_time) : null;
                        parsedResponse[0].other_in_date_time = parsedResponse[0].other_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].other_in_date_time) : null;
                        parsedResponse[0].other_out_date_time = parsedResponse[0].other_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].other_out_date_time) : null;
                        parsedResponse[0].service_in_date_time = parsedResponse[0].service_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].service_in_date_time) : null;
                        parsedResponse[0].service_out_date_time = parsedResponse[0].service_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].service_out_date_time) : null;
                        parsedResponse[0].body_in_date_time = parsedResponse[0].body_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].body_in_date_time) : null;
                        parsedResponse[0].body_out_date_time = parsedResponse[0].body_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].body_out_date_time) : null;
                        parsedResponse[0].paint_in_date_time = parsedResponse[0].paint_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].paint_in_date_time) : null;
                        parsedResponse[0].paint_out_date_time = parsedResponse[0].paint_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].paint_out_date_time) : null;
                        parsedResponse[0].detail_in_date_time = parsedResponse[0].detail_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].detail_in_date_time) : null;
                        parsedResponse[0].detail_out_date_time = parsedResponse[0].detail_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].detail_out_date_time) : null;
                        parsedResponse[0].recon_in_date_time = parsedResponse[0].recon_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].recon_in_date_time) : null;
                        parsedResponse[0].recon_out_date_time = parsedResponse[0].recon_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].recon_out_date_time) : null;
                        parsedResponse[0].photo_in_date_time = parsedResponse[0].photo_in_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].photo_in_date_time) : null;
                        parsedResponse[0].photo_out_date_time = parsedResponse[0].photo_out_date_time !== null ? CommonService.formatDateTime(parsedResponse[0].photo_out_date_time) : null;
                    }
                    this.setState({
                        tableTitle: parsedResponse.length !== 0 ? "Stock Number: " + parsedResponse[0].stock_number + " - VIN: " + parsedResponse[0].vin : null,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: []
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningAuditQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card d-inline-block">
                    <div className="card-header ui-draggable-handle">
                        <div className="d-inline-block align-middle"><span>{this.state.tableTitle}</span></div>
                    </div>
                    <div className="card-block">
                        <div className="form-inline">
                            <table className="dgdg-used-reconditioning-audit-table">
                                <tbody>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1"> Store:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].store_web_name}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Stock Number:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].stock_number}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">VIN:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].vin}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4 ">YMMT:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].ymmt}</td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Is WholeSale:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].is_wholesale}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Stock Type:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].stocktype}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">CDK Wholesale:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].cdk_wholesale}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4">Entry Date:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].entrydate}</td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Status:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].status}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">License No:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].licenseno}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">Licence State:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].licensestate}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4">Fuel Type:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].fueltype}</td>
                                    </tr>
                                    <tr className="dgdg-used-reconditioning-audit-table-row-separator">
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Is CPO:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].is_cpo}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Smog In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].smog_in_date_time ? "" : this.state.tableData[0].smog_in_date_time + " (" + this.state.tableData[0].smog_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Smog Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].smog_out_date_time ? "" : this.state.tableData[0].smog_out_date_time + " (" + this.state.tableData[0].smog_out_by + ", " + this.state.tableData[0].smog_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">VIN In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vin_in_date_time ? "" : this.state.tableData[0].vin_in_date_time + " (" + this.state.tableData[0].vin_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Vin Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vin_out_date_time ? "" : this.state.tableData[0].vin_out_date_time + " (" + this.state.tableData[0].vin_out_by + ", " + this.state.tableData[0].vin_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Weight In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].weight_in_date_time ? "" : this.state.tableData[0].weight_in_date_time + " (" + this.state.tableData[0].weight_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Weight Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].weight_out_date_time ? "" : this.state.tableData[0].weight_out_date_time + " (" + this.state.tableData[0].weight_out_by + ", " + this.state.tableData[0].weight_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Back Order In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].bo_in_date_time ? "" : this.state.tableData[0].bo_in_date_time + " (" + this.state.tableData[0].bo_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Back Order Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].bo_out_date_time ? "" : this.state.tableData[0].bo_out_date_time + " (" + this.state.tableData[0].bo_out_by + ", " + this.state.tableData[0].bo_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Diag/Repair Shop In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].dr_in_date_time ? "" : this.state.tableData[0].dr_in_date_time + " (" + this.state.tableData[0].dr_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Diag/Repair Shop Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].dr_out_date_time ? "" : this.state.tableData[0].dr_out_date_time + " (" + this.state.tableData[0].dr_out_by + ", " + this.state.tableData[0].dr_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Authorization In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].auth_in_date_time ? "" : this.state.tableData[0].auth_in_date_time + " (" + this.state.tableData[0].auth_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Authorization Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].auth_out_date_time ? "" : this.state.tableData[0].auth_out_date_time + " (" + this.state.tableData[0].auth_out_by + ", " + this.state.tableData[0].auth_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding  Parts In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].parts_in_date_time ? "" : this.state.tableData[0].parts_in_date_time + " (" + this.state.tableData[0].parts_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding  Parts Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].parts_out_date_time ? "" : this.state.tableData[0].parts_out_date_time + " (" + this.state.tableData[0].parts_out_by + ", " + this.state.tableData[0].parts_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Vendor In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vendor_in_date_time ? "" : this.state.tableData[0].vendor_in_date_time + " (" + this.state.tableData[0].vendor_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Vendor Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vendor_out_date_time ? "" : this.state.tableData[0].vendor_out_date_time + " (" + this.state.tableData[0].vendor_out_by + ", " + this.state.tableData[0].vendor_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Monitors In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].monitor_in_date_time ? "" : this.state.tableData[0].monitor_in_date_time + " (" + this.state.tableData[0].monitor_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Monitors Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].monitor_out_date_time ? "" : this.state.tableData[0].monitor_out_date_time + " (" + this.state.tableData[0].monitor_out_by + ", " + this.state.tableData[0].monitor_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Other In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].other_in_date_time ? "" : this.state.tableData[0].other_in_date_time + " (" + this.state.tableData[0].other_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Other Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].other_out_date_time ? "" : this.state.tableData[0].other_out_date_time + " (" + this.state.tableData[0].other_out_by + ", " + this.state.tableData[0].other_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Service In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].service_in_date_time ? "" : this.state.tableData[0].service_in_date_time + " (" + this.state.tableData[0].service_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Service Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].service_out_date_time ? "" : this.state.tableData[0].service_out_date_time + " (" + this.state.tableData[0].service_out_by + ", " + this.state.tableData[0].service_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Body In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].body_in_date_time ? "" : this.state.tableData[0].body_in_date_time + " (" + this.state.tableData[0].body_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Body Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].body_out_date_time ? "" : this.state.tableData[0].body_out_date_time + " (" + this.state.tableData[0].body_out_by + ", " + this.state.tableData[0].body_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Paint In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].paint_in_date_time ? "" : this.state.tableData[0].paint_in_date_time + " (" + this.state.tableData[0].paint_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Paint Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].paint_out_date_time ? "" : this.state.tableData[0].paint_out_date_time + " (" + this.state.tableData[0].paint_out_by + ", " + this.state.tableData[0].paint_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Detail In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].detail_in_date_time ? "" : this.state.tableData[0].detail_in_date_time + " (" + this.state.tableData[0].detail_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Detail Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].detail_out_date_time ? "" : this.state.tableData[0].detail_out_date_time + " (" + this.state.tableData[0].detail_out_by + ", " + this.state.tableData[0].detail_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>

                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Recon In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].recon_in_date_time ? "" : this.state.tableData[0].recon_in_date_time + " (" + this.state.tableData[0].recon_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Recon Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].recon_out_date_time ? "" : this.state.tableData[0].recon_out_date_time + " (" + this.state.tableData[0].recon_out_by + ", " + this.state.tableData[0].recon_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">Recon Interior Only:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].recon_interior_only}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>

                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Photo In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].photo_in_date_time ? "" : this.state.tableData[0].photo_in_date_time + " (" + this.state.tableData[0].photo_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Photo Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].photo_out_date_time ? "" : this.state.tableData[0].photo_out_date_time + " (" + this.state.tableData[0].photo_out_by + ", " + this.state.tableData[0].photo_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title">Comments:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-wrap" colSpan="7">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].comments}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

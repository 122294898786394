import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

export class FrontEndGLReconciliationAccountDetailComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Front-end Adjustments";

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FrontEndGLReconciliationAccountDetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.tableData.length > 0}
            title={this.props.tableTitle}
            cssName="dgdg-product-reserve-account-detail-dialog"
            onCancelClick={this.props.onCancelClick}>

            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showFooter={true}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Date" dataColumn="dt" sortColumn="dt" filterColumn="dt" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Account Number" dataColumn="an" sortColumn="an" filterColumn="an" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="ad" sortColumn="ad" filterColumn="ad" />
                        <DGDGTableColumnDrCrComponent headerText="Type" dataColumn="amt" sortColumn="amt" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="amt" sortColumn="amt" filterColumn="amt" dataType="money_2" footerFunction="sum" footerCssName="text-right" />
                        <DGDGTableColumnComponent headerText="Detail Description" dataColumn="dd" sortColumn="dd" filterColumn="dd" />
                        <DGDGTableColumnComponent headerText="Control 1" dataColumn="c1" sortColumn="c1" filterColumn="c1" />
                        <DGDGTableColumnComponent headerText="Control 2" dataColumn="c2" sortColumn="c2" filterColumn="c2" />
                        <DGDGTableColumnComponent headerText="Reference" dataColumn="re" sortColumn="re" filterColumn="re" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}

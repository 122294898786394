import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class SalesStoreReportWeekendQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("SalesStoreReportWeekendQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            showSpinner: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.startDate === null) {
                let currentDate = moment();
                let maxDate = currentDate.day() === 0 ? currentDate.day(-1).day(0) : currentDate.day(0);

                this.setState({
                    startDate: maxDate.day(-2)
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
            this.onGetReportClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate)) {
                this.setState({ showSpinner: true });
                this.props.onGetReportClick(this.state.startDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let currentDate = moment();
        let maxDate = currentDate.day() === 0 ? currentDate.day(-1).day(0) : currentDate.day(0);

        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={1} showDays={[5]} maxDate={maxDate.format("MM/DD/YYYY")} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report<span className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.props.onCopyClick(event)}>
                Copy<span className="far fa-copy dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

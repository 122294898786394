import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnSparklineComponent } from "../common/dgdgtable/DGDGTableColumnSparklineComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

export class WholesaleYOYReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paintTable: false,
            reportData: [],
            maximizedChart: null,
            visibleChart: "Y1TotalGross",
            y1TotalGrossChartData: null,
            y1TotalGrossChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            y2TotalGrossChartData: null,
            y2TotalGrossChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            y1CountChartData: null,
            y1CountChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            y2CountChartData: null,
            y2CountChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            y1PVRChartData: null,
            y1PVRChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            y2PVRChartData: null,
            y2PVRChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Gross",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("WholesaleYOYReportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/WholesaleYOYReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getReport();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("WholesaleYOYReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Wholesale YOY Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/WholesaleYOYReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/WholesaleYOYReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "WholesaleYOYReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/WholesaleYOYReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getReport() {
        this.props.applicationInsights.trackTrace({ message: "/WholesaleYOYReportRouteComponent/getReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/WholesaleYOYReport/GetReport")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (parsedResponse && parsedResponse.length) {
                    parsedResponse.forEach((rowData, index) => {
                        rowData["y0_total_gross"] = 0;
                    });
                }
                this.showCharts(CommonService.addOrdinalColumn(parsedResponse));
            })
            .catch(notOKResponse => {
                this.setState({
                    reportData: [],
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/WholesaleYOYReport/GetReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    showCharts(response) {
        let header = [{ label: "contract_date", type: "date" }];
        response.map(item => item.store_number + " | " + item.store_name)
            .filter((value, index, self) => self.indexOf(value) === index).forEach((store, Index) => {
                header.push(store);
            });

        let y1ContractDates = [];
        response.map(item => item.y1_contract_date)
            .filter((value, index, self) => self.indexOf(value) === index).forEach((y1ContractDate, Index) => {
                y1ContractDates.push(moment(y1ContractDate));
            });

        let y1TotalGrossChartDataTable = [];
        let y1CountChartDataTable = [];
        let y1PVRChartDataTable = [];

        y1TotalGrossChartDataTable.push(header);
        y1CountChartDataTable.push(header);
        y1PVRChartDataTable.push(header);

        y1ContractDates.forEach((contractDate, index) => {
            let y1TotalGrossDataRow = new Array(header.length);
            let y1CountDataRow = new Array(header.length);
            let y1PVRDataRow = new Array(header.length);

            y1TotalGrossDataRow[0] = contractDate.toDate();
            y1CountDataRow[0] = contractDate.toDate();
            y1PVRDataRow[0] = contractDate.toDate();

            response.filter((reportRow, index) => {
                if (reportRow.y1_contract_date !== null)
                    return moment(reportRow.y1_contract_date).valueOf() === contractDate.valueOf();
                return false;
            }).forEach((filterRow, index) => {
                y1TotalGrossDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y1_total_gross;
                y1CountDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y1_count;
                y1PVRDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y1_pvr;
            });

            y1TotalGrossChartDataTable.push(y1TotalGrossDataRow);
            y1CountChartDataTable.push(y1CountDataRow);
            y1PVRChartDataTable.push(y1PVRDataRow);
        });

        let y2ContractDates = [];
        response.map(item => item.y2_contract_date)
            .filter((value, index, self) => value && self.indexOf(value) === index).forEach((y2ContractDate, Index) => {
                y2ContractDates.push(moment(y2ContractDate));
            });

        let y2TotalGrossChartDataTable = [];
        let y2CountChartDataTable = [];
        let y2PVRChartDataTable = [];

        y2TotalGrossChartDataTable.push(header);
        y2CountChartDataTable.push(header);
        y2PVRChartDataTable.push(header);

        y2ContractDates.forEach((contractDate, index) => {
            let y2TotalGrossDataRow = new Array(header.length);
            let y2CountDataRow = new Array(header.length);
            let y2PVRDataRow = new Array(header.length);

            y2TotalGrossDataRow[0] = contractDate.toDate();
            y2CountDataRow[0] = contractDate.toDate();
            y2PVRDataRow[0] = contractDate.toDate();

            response.filter((reportRow, index) => {
                if (reportRow.y2_contract_date !== null)
                    return moment(reportRow.y2_contract_date).valueOf() === contractDate.valueOf();
                return false;
            }).forEach((filterRow, index) => {
                y2TotalGrossDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y2_total_gross;
                y2CountDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y2_count;
                y2PVRDataRow[header.indexOf(filterRow.store_number + " | " + filterRow.store_name)] = filterRow.y2_pvr;
            });

            y2TotalGrossChartDataTable.push(y2TotalGrossDataRow);
            y2CountChartDataTable.push(y2CountDataRow);
            y2PVRChartDataTable.push(y2PVRDataRow);
        });

        this.setState({
            reportData: response,
            y1TotalGrossChartData: y1TotalGrossChartDataTable,
            y2TotalGrossChartData: y2TotalGrossChartDataTable,
            y1CountChartData: y1CountChartDataTable,
            y2CountChartData: y2CountChartDataTable,
            y1PVRChartData: y1PVRChartDataTable,
            y2PVRChartData: y2PVRChartDataTable,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    showChart(event, chartName) {
        let currentMaximizedChart = this.state.maximizedChart;
        if (currentMaximizedChart) {
            currentMaximizedChart = chartName;
        }
        this.setState({
            visibleChart: chartName,
            maximizedChart: currentMaximizedChart
        });
    }

    onMaximizeClick(event, chartName) {
        try {
            this.setState({ maximizedChart: chartName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedChart: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("WholesaleYOYReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            {
                this.state.maximizedChart
                    ? <div className="mt-auto">
                        <span className="float-right badge badge-primary dgdg-badge">
                            Last Updated Date: {CommonService.formatDateTimeZone(moment())}
                        </span>
                    </div>
                    : null
            }
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-flex dgdg-flex-row" style={{ height: this.state.maximizedChart !== null ? "100%" : "" }}>
                    <div className={this.state.maximizedChart === null ? "d-inline-block" : "d-none"} style={{ paddingRight: "5px" }}>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="2016 - 2017 YOY Comparison" tableData={this.state.reportData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Month" dataColumn="month" dataType="shortMonth" sortColumn="month" />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name" sortColumn="store_name" filterColumn="store_name" />
                                <DGDGTableColumnComponent headerText="2016 <br /> Contract Date" dataColumn="y1_contract_date" dataType="date" sortColumn="y1_contract_date" filterColumn="y1_contract_date" />
                                <DGDGTableColumnComponent headerText="2016 <br /> Count" dataColumn="y1_count" dataType="number" sortColumn="y1_count" filterColumn="y1_count" footerFunction="sum" />
                                <DGDGTableColumnComponent headerText="2016 <br /> Total Gross" dataColumn="y1_total_gross" dataType="money_2" sortColumn="y1_total_gross" />
                                <DGDGTableColumnComponent headerText="2016 <br /> PVR" dataColumn="y1_pvr" dataType="money_2" sortColumn="y1_pvr" />
                                <DGDGTableColumnComponent headerText="2017 <br /> Contract Date" dataColumn="y2_contract_date" dataType="date" sortColumn="y2_contract_date" />
                                <DGDGTableColumnComponent headerText="2017 <br /> Count" dataColumn="y2_count" dataType="number" sortColumn="y2_count" footerFunction="sum" />
                                <DGDGTableColumnComponent headerText="2017 <br /> Total Gross" dataColumn="y2_total_gross" dataType="money_2" sortColumn="y2_total_gross" />
                                <DGDGTableColumnComponent headerText="2017 <br /> PVR" dataColumn="y2_pvr" dataType="money_2" sortColumn="y2_pvr" />
                                <DGDGTableColumnSparklineComponent headerText="Graph" valueFields={["y1_total_gross", "y0_total_gross", "y2_total_gross"]} options={{ type: "bar" }} />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-flex-item">
                        <div className={"card" + (this.state.maximizedChart !== null ? " dgdg-wholesale-yoy-card-maximized" : "")}>
                            <div className="card-block" style={{ height: "100%" }}>
                                <nav className="nav nav-pills">
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y1TotalGross" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y1TotalGross")}>Total Gross (2016)</button>
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y2TotalGross" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y2TotalGross")}>Total Gross (2017)</button>
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y1Count" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y1Count")}>Count (2016)</button>
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y2Count" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y2Count")}>Count (2017)</button>
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y1PVR" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y1PVR")}>PVR (2016)</button>
                                    <button type="button" className={"nav-item nav-link" + (this.state.visibleChart === "Y2PVR" ? " btn btn-dark active" : " btn btn-link")} onClick={(event) => this.showChart(event, "Y2PVR")}>PVR (2017)</button>
                                </nav>
                                <DGDGWidgetComponent title="Wholesale YOY Report - Total Gross (2016)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y1TotalGross"} isVisible={this.state.visibleChart === "Y1TotalGross"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y1TotalGross")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y1TotalGrossChartData} chartOption={this.state.y1TotalGrossChartOption} />
                                </DGDGWidgetComponent>
                                <DGDGWidgetComponent title="Wholesale YOY Report - Total Gross (2017)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y2TotalGross"} isVisible={this.state.visibleChart === "Y2TotalGross"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y2TotalGross")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y2TotalGrossChartData} chartOption={this.state.y2TotalGrossChartOption} />
                                </DGDGWidgetComponent>
                                <DGDGWidgetComponent title="Wholesale YOY Report - Count (2016)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y1Count"} isVisible={this.state.visibleChart === "Y1Count"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y1Count")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y1CountChartData} chartOption={this.state.y1CountChartOption} />
                                </DGDGWidgetComponent>
                                <DGDGWidgetComponent title="Wholesale YOY Report - Count (2017)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y2Count"} isVisible={this.state.visibleChart === "Y2Count"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y2Count")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y2CountChartData} chartOption={this.state.y2CountChartOption} />
                                </DGDGWidgetComponent>
                                <DGDGWidgetComponent title="Wholesale YOY Report - PVR (2016)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y1PVR"} isVisible={this.state.visibleChart === "Y1PVR"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y1PVR")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y1PVRChartData} chartOption={this.state.y1PVRChartOption} />
                                </DGDGWidgetComponent>
                                <DGDGWidgetComponent title="Wholesale YOY Report - PVR (2017)"
                                    maximizedCss="dgdg-widget-maximized dgdg-wholesale-yoy-widget-maximized" restoredCss="align-top dgdg-widget-restored dgdg-wholesale-yoy-widget-restored"
                                    isMaximized={this.state.maximizedChart === "Y2PVR"} isVisible={this.state.visibleChart === "Y2PVR"}
                                    onMaximizeClick={(event) => this.onMaximizeClick(event, "Y2PVR")} onRestoreClick={(event) => this.onRestoreClick(event)}
                                >
                                    <DGDGChartLineComponent chartData={this.state.y2PVRChartData} chartOption={this.state.y2PVRChartOption} />
                                </DGDGWidgetComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class UsedCarMerchandisingQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("UsedCarMerchandisingQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isPublishing;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            vin: "",
            stockNumber: "",
            showSpinner: false
        };
        this.onTextChange = this.onTextChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("UsedCarMerchandisingQueryComponent - componentDidMount");
            this.onGetReportClick();
            CommonService.clientAzureStorageLog("UsedCarMerchandisingRouteComponent", "UsedCarMerchandisingQueryComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.vin, this.state.stockNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPublishClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onPublishClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="stockNumber" label="Stock #" value={this.state.stockNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner || this.props.isUsedCarSelected} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.props.isUsedCarSelected} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={(event) => this.props.onBulkUpdateDataClick(event)}>
                        Bulk Update Data<i className="far fa-copy dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onPublishClick}>
                        Publish<i className="fas fa-upload dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

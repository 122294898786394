export class DGDGEventSourceComponent {
    eventSourceInstance;

    addEventSource = (eventSourceUrl, eventName, eventCallback) => {
        if (typeof (EventSource) !== "undefined") {
            this.eventSourceInstance = new EventSource(eventSourceUrl);
            this.eventSourceInstance.addEventListener(eventName, (eventArgs) => {
                if (eventCallback) {
                    eventCallback(JSON.parse(eventArgs.data));
                }
            });
            this.eventSourceInstance.addEventListener("error", (eventArgs) => {
                if (this.eventSourceInstance) {
                    this.eventSourceInstance.close();
                }
            });
        } else {
            console.error("EventSource not supported by your browser.")
        }
    };

    closeEventSource = () => {
        if (this.eventSourceInstance) {
            this.eventSourceInstance.close();
        }

        this.eventSourceInstance = null;
    };
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";
import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";

export class DemoPageDetailPopupChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                hAxis: {
                    title: "x Axis Label",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "y Axis Label",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            console.devLog("DemoPageDetailPopupChartComponent - DemoPageDetailPopupChartComponent");
            return this.props.paintChart;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGWidgetComponent title={this.props.headerText} restoredCss="d-inline-block align-top dgdg-widget-restored dgdg-demo-detail-widget-restored" isVisible>
            {
                (this.props.chartData !== null)
                    ? <DGDGChartLineComponent chartData={this.props.chartData} chartOption={this.state.chartOption} />
                    : null
            }
        </DGDGWidgetComponent>;
    }
}

import React from "react";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../dgdgtable/DGDGTableV3Component";

export class DGDGRODetailComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.data) {
                newState.showDialog = true;
                newState.roTableData = CommonService.addOrdinalColumn(props.data.table);
                newState.roDetailTableData = CommonService.addOrdinalColumn(props.data.table1);
            } else {
                newState.showDialog = false;
                newState.roTableData = [];
                newState.roDetailTableData = [];
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            roTableData: [],
            roDetailTableData: []
        };

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
    }

    onCopyTableCallBack(event) {
        try {

            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DGDGModalDialogComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.state.showDialog}
            title={this.props.title}
            cssName="dgdg-ro-detail-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width dgdg-card-gutter" tableData={this.state.roTableData}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" />
                        <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" />
                        <DGDGTableColumnComponent headerText="Open Date" dataColumn="open_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Close Date" dataColumn="close_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" dataType="money_2" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.roDetailTableData} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Line Code" dataColumn="line_code" sortColumn="labor_type" />
                        <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Op Code" dataColumn="op_code" sortColumn="op_code" />
                        <DGDGTableColumnComponent headerText="Op Code Description" dataColumn="op_code_description" sortColumn="op_code_description" />
                        <DGDGTableColumnComponent headerText="Actual Hours" dataColumn="actual_hours" sortColumn="actual_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="number_1" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" sortColumn="labor_sale" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" sortColumn="labor_cost" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" sortColumn="parts_sale" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" sortColumn="parts_cost" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" sortColumn="misc_sale" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" sortColumn="misc_cost" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}

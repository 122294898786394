import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class UsedReconditioningSummaryQueryComponent extends React.Component {
    static StoreNumberCookie = "UsedReconditioningInventoryStoreNumberCookie";
    static MonthCookie = "UsedReconditioningInventoryMonthCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("UsedReconditioningQueryComponent - getDerivedStateFromProps");
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            selectedStore: null,
            selectedMonth: null
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onMonthClick = this.onMonthClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null && this.props.months && this.state.selectedMonth === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(UsedReconditioningSummaryQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                let selectedMonth = this.props.months[0];
                let cookieMonth = CommonService.getCookie(UsedReconditioningSummaryQueryComponent.MonthCookie);
                if (cookieMonth) {
                    let filterMonth = this.props.months.filter((month) => month !== "Last 30 Days" && month.isSame(cookieMonth, 'date'));
                    if (filterMonth.length === 1) {
                        selectedMonth = filterMonth[0];
                    }
                }

                this.setState({
                    selectedStore: selectedStore,
                    selectedMonth: selectedMonth
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState(
                {
                    selectedStore: store
                }, this.onGetReportClick);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(id, month) {
        try {
            this.setState({ selectedMonth: month });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(UsedReconditioningSummaryQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            CommonService.setCookie(UsedReconditioningSummaryQueryComponent.MonthCookie, this.state.selectedMonth === "Last 30 Days" ? this.state.selectedMonth : this.state.selectedMonth.format("MM/DD/YYYY"), 30);
            this.props.onGetReportClick(this.state.selectedStore, this.state.selectedMonth);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
            <DGDGFormDropdownComponent label="Month" displayFormat="monthYear" data={this.props.months} value={this.state.selectedMonth} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                Get Chart<i className="fas fa-chart-line dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGChartColumnComponent } from "../common/dgdgchart/DGDGChartColumnComponent";

export class DashboardSalesByVolumeAndGrossComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1 }
                },
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Volume",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    },
                    1: {
                        title: "Total Gross",
                        format: "$#,###",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    getDashboardData() {
        let filteredData = null;

        if (this.props.dashboardItem && this.props.selectedStore) {
            filteredData = [];
            filteredData.push(this.props.dashboardItem.data[0].slice(1)); // add header row.
            this.props.dashboardItem.data.forEach((dataRow, index) => {
                try {
                    if (this.props.selectedStore.store_number && dataRow[0] === this.props.selectedStore.store_number.toString()) {
                        filteredData.push(dataRow.slice(1));
                    }
                }
                catch {
                    // Ignore error
                }
            });
        }

        return filteredData;
    }

    render() {
        return <div className="dgdg-widget-item">
            <div className="d-flex">
                <DGDGFormDropdownComponent data={this.props.stores} itemKey="display_name" value={this.props.selectedStore ? this.props.selectedStore.display_name : "Select Store"} onItemClick={this.props.onStoreClick} />
            </div>
            <div style={{ "height": "calc(100% - 55px)" }}>
                <DGDGChartColumnComponent chartData={this.getDashboardData()} chartOption={this.state.chartOption} isMaximized={this.props.isMaximized} />
            </div>
        </div>;
    }
}

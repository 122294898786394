import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class CovidSubmitComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Covid Submit";

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-covid-submit">Thank you for submitting your vaccination status on {moment(this.props.location.state.signedDate).format("MM/DD/YYYY hh:mm:ss A")}.</div>
        </DGDGPageComponent>;
    }
}

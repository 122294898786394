import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartColumnComponent } from "../common/dgdgchart/DGDGChartColumnComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

import { NewPDISummaryQueryComponent } from "./NewPDISummaryQueryComponent";

export class NewPDISummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            isLoadingData: false,
            selectedStore: null,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            chartData: null,
            chartOption: {
                height: 370,
                series: {
                    0: { targetAxisIndex: 0 }
                },
                tooltip: { isHtml: true },
                hAxis: {
                    title: "Store",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "PDI Average Days",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "New Car Prep";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/NewPDISummaryComponent/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            CommonService.clientAzureStorageLog("NewPDISummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "NewPDISummaryComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/NewPDI/GetSummaryStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse,
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/GetSummaryStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null
            };

            this.props.applicationInsights.trackTrace({ message: "/NewPDISummaryComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/GetSummaryReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => {
                    if (response.ok) { return response.json(); } else { throw response; }
                })
                .then(parsedResponse => {
                    let chartData = [];
                    chartData.push([
                        { label: "Store", type: "string" },
                        { label: "PDI Days", type: "number" },
                        { type: "string", role: "tooltip", p: { html: true } }
                    ])
                    parsedResponse.forEach((responseItem, index) => {
                        chartData.push([responseItem.store_name_web,
                        responseItem.pdi_done_age_avg,
                        responseItem.pdi_done_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_name_web
                        + "</b><br />PDI Days: " + responseItem.pdi_done_age_avg + "</div>",
                        ])
                    });

                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        chartData: chartData,
                        selectedStore: selectedStore,
                        paintTable: true,
                        paintColumnChart: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        paintColumnChart: true
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/GetSummaryReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("NewPDISummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <NewPDISummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.state.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-card-gutter" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="dgdg-new-pdi-store-width" />
                        <DGDGTableColumnComponent headerText="Start Date" dataColumn="start_date" sortColumn="start_date" filterColumn="start_date" dataType="date" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="End Date" dataColumn="end_date" sortColumn="end_date" filterColumn="end_date" dataType="date" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Count" dataColumn="count" sortColumn="count" filterColumn="count" dataType="number" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Done Age Average (Days)" dataColumn="pdi_done_age_avg" sortColumn="pdi_done_age_avg" filterColumn="pdi_done_age_avg" cssName="text-center" dataType="number_2" />
                    </DGDGTableRowComponent>num
                </DGDGTableV3Component>
                <div className="card">
                    <DGDGWidgetComponent title={this.state.tableTitle + " - PDI Done Age Average (Days) for last 30 days"} restoredCss="dgdg-widget-maximized" isVisible>
                        {
                            this.state.chartData && this.state.chartData.length > 1
                                ? <DGDGChartColumnComponent chartData={this.state.chartData} chartOption={this.state.chartOption} />
                                : null
                        }
                    </DGDGWidgetComponent>
                </div>
            </div>
        </div>;
    }
}

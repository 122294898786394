import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";
import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";

export class FIStoreReportChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pvrChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "PVR",
                        format: "$#,###",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: "none",
                chartArea: { left: 70, width: "80%" }
            },
            pprChartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "PPR",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: "none",
                chartArea: { left: 60, width: "80%" }
            },
            warrantyChartOption: {
                tooltip: { isHtml: true },
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Warranty Penetration",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: "none",
                chartArea: { left: 60, width: "80%" }
            },
            maintenanceChartOption: {
                tooltip: { isHtml: true },
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Maintenance Penetration",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: "none",
                chartArea: { left: 60, width: "80%" }
            },
            maximizedChart: null
        };

        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onMaximizeClick(event, chartName) {
        try {
            this.setState({ maximizedChart: chartName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedChart: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="row" style={{ "margin": "0px" }}>
            {
                this.props.showSpinner
                    ? <DGDGSpinnerComponent showSpinner />
                    : <Fragment>
                        <DGDGWidgetComponent title={"24 Month Trend: Back PVR"}
                            maximizedCss="dgdg-widget-maximized" restoredCss="col-3 dgdg-widget-restored"
                            isMaximized={this.state.maximizedChart === "PVR" ? true : false} isVisible={this.state.maximizedChart === "PVR" || this.state.maximizedChart === null}
                            onMaximizeClick={(event) => this.onMaximizeClick(event, "PVR")} onRestoreClick={(event) => this.onRestoreClick(event)}
                        >
                            {
                                this.props.pvrChartData
                                    ? <DGDGChartLineComponent chartData={this.props.pvrChartData} chartOption={this.state.pvrChartOption} />
                                    : null
                            }
                        </DGDGWidgetComponent>
                        <DGDGWidgetComponent title={"24 Month Trend: Overall PPR"}
                            maximizedCss="dgdg-widget-maximized" restoredCss="col-3 dgdg-widget-restored"
                            isMaximized={this.state.maximizedChart === "PPR" ? true : false} isVisible={this.state.maximizedChart === "PPR" || this.state.maximizedChart === null}
                            onMaximizeClick={(event) => this.onMaximizeClick(event, "PPR")} onRestoreClick={(event) => this.onRestoreClick(event)}
                        >
                            {
                                this.props.pprChartData
                                    ? <DGDGChartLineComponent chartData={this.props.pprChartData} chartOption={this.state.pprChartOption} />
                                    : null
                            }
                        </DGDGWidgetComponent>
                        <DGDGWidgetComponent title={"24 Month Trend: Warranty (VSC) Penetration"}
                            maximizedCss="dgdg-widget-maximized" restoredCss="col-3 dgdg-widget-restored"
                            isMaximized={this.state.maximizedChart === "Warranty" ? true : false} isVisible={this.state.maximizedChart === "Warranty" || this.state.maximizedChart === null}
                            onMaximizeClick={(event) => this.onMaximizeClick(event, "Warranty")} onRestoreClick={(event) => this.onRestoreClick(event)}
                        >
                            {
                                this.props.warrantyChartData
                                    ? <DGDGChartLineComponent chartData={this.props.warrantyChartData} chartOption={this.state.warrantyChartOption} />
                                    : null
                            }
                        </DGDGWidgetComponent>
                        <DGDGWidgetComponent title={"24 Month Trend: Maintenance Penetration"}
                            maximizedCss="dgdg-widget-maximized" restoredCss="col-3 dgdg-widget-restored"
                            isMaximized={this.state.maximizedChart === "Maintenance" ? true : false} isVisible={this.state.maximizedChart === "Maintenance" || this.state.maximizedChart === null}
                            onMaximizeClick={(event) => this.onMaximizeClick(event, "Maintenance")} onRestoreClick={(event) => this.onRestoreClick(event)}
                        >
                            {
                                this.props.maintenanceChartData
                                    ? <DGDGChartLineComponent chartData={this.props.maintenanceChartData} chartOption={this.state.maintenanceChartOption} />
                                    : null
                            }
                        </DGDGWidgetComponent>
                    </Fragment>
            }
        </div>;
    }
}
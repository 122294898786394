import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class PartsEmployeePayEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            planDate: "",
            selectedPlanName: "",
            wageRate: "",
            midMonthAdvance: "",
            monthlyGuarantee: "",
            planNames: ["Entry", "Mid", "Senior", "Senior Plus", "Assistant Manager"]
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onPlanNameChange = this.onPlanNameChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.employee
                && (!prevProps.employee || prevProps.employee.employee_number !== this.props.employee.employee_number)) {
                this.setState({
                    planDate: this.props.employee.emp_plan_date ? moment(this.props.employee.emp_plan_date) : "",
                    selectedPlanName: this.props.employee.emp_plan_name ? this.props.employee.emp_plan_name : "Select Plan",
                    wageRate: this.props.employee.emp_wage_rate ? this.props.employee.emp_wage_rate : "",
                    midMonthAdvance: this.props.employee.emp_mid_month_advance ? this.props.employee.emp_mid_month_advance : "",
                    monthlyGuarantee: this.props.employee.emp_monthly_guarantee ? this.props.employee.emp_monthly_guarantee : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Parts Payroll";

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanNameChange(id, planName) {
        try {
            this.setState({ selectedPlanName: planName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (this.props.employee !== null && CommonService.isDateValid(this.state.planDate)) {
                this.setState({ showProgress: true });

                let inputData = {
                    employeeNumber: this.props.employee.employee_number,
                    planDate: this.state.planDate ? CommonService.formatServerDateTime(this.state.planDate.toString()) : null,
                    planName: this.state.selectedPlanName === "Select Plan" ? null : this.state.selectedPlanName,
                    wageRate: this.state.wageRate,
                    midMonthAdvance: this.state.midMonthAdvance,
                    monthlyGuarantee: this.state.monthlyGuarantee
                };

                this.props.applicationInsights.trackTrace({ message: "PartsEmployeePayEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/PartsPayroll/UpdatePartsEmployeePayPlan", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        this.props.onSaveClick(event);
                        this.setState({ showProgress: false });
                    })
                    .catch(notOKResponse => {
                        this.setState({ showProgress: false });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/UpdatePartsEmployeePayPlan", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                planDate: "",
                selectedPlanName: "",
                wageRate: "",
                midMonthAdvance: "",
                monthlyGuarantee: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.employee) {
            title = "Edit Employee Pay Plan - " + this.props.employee.employee_name + " (" + this.props.employee.employee_number + ") - " + this.props.employee.store_name;
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.employee !== null}
            cssName="dgdg-parts-payroll-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.employee
                    ? <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form-inline">
                                    <div className="form-row">
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} label="Plan Date" numberOfMonths={1} id="planDate" value={this.state.planDate} onDateChange={this.onDateChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormDropdownComponent label="Plan Name" data={this.state.planNames} value={this.state.selectedPlanName} onItemClick={this.onPlanNameChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Wage Rate" controlCssName="dgdg-money" id="wageRate" value={this.state.wageRate} inputType="money" onChange={this.onTextChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Mid Month Advance" controlCssName="dgdg-money" id="midMonthAdvance" inputType="money" value={this.state.midMonthAdvance} onChange={this.onTextChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Monthly Guarantee" controlCssName="dgdg-money" id="monthlyGuarantee" inputType="money" value={this.state.monthlyGuarantee} onChange={this.onTextChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}

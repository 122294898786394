import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class FSCommon {
    static sumByKey(fsDict, ym, lc, dept, lds) {
        let totalValue = null;
        lds.forEach((ld) => {
            let key = "1~" + ym + "~" + lc + "~" + dept + "~" + ld;
            if (fsDict[key]) {
                if (totalValue) {
                    totalValue += CommonService.parseDecimal(fsDict[key][8]);
                }
                else {
                    totalValue = CommonService.parseDecimal(fsDict[key][8]);
                }
            }
        });

        return totalValue;
    }

    static subtractByKey(fsDict, ym, lc, dept, lds) {
        let totalValue = null;
        lds.forEach((ld) => {
            let key = "1~" + ym + "~" + lc + "~" + dept + "~" + ld;
            if (fsDict[key]) {
                if (totalValue) {
                    totalValue -= CommonService.parseDecimal(fsDict[key][8]);
                } else {
                    totalValue = CommonService.parseDecimal(fsDict[key][8]);
                }
            }
        });

        return totalValue;
    }

    static parseDecimal(fsDict, key) {
        // Case-Insensitive key check in the fsDict.
        const lowercaseKey = key.toLowerCase();
        let fsItem = fsDict[Object.keys(fsDict).filter(function (k) { return k.toLowerCase() === lowercaseKey; })[0]];
        return fsItem ? CommonService.parseDecimal(fsItem[8]) : 0;

        // let value = 0;
        // if (fsDict[key]) {
        //     value = CommonService.parseDecimal(fsDict[key][8]);
        // }
        // return value;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class SalesEmployeeTimeTrackQueryComponent extends React.Component {
    static StoreNumberCookie = "SalesEmployeeTimeTrackStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("SalesEmployeeTimeTrackQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isDownLoading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            startDate: null,
            showSpinner: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.navigateWeek && prevProps.navigateWeek !== this.props.navigateWeek) {
                this.setState({
                    startDate: this.props.navigateWeek
                }, this.onGetReportClick);
            }
            else if (this.props.stores && this.props.stores.length > 0 && prevProps.stores !== this.props.stores) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(SalesEmployeeTimeTrackQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                let lastWed = moment();
                let lastWedAdjst = 3 - lastWed.weekday();
                if (lastWedAdjst > 0) {
                    lastWedAdjst -= 7;
                }
                lastWed.date(lastWed.date() + lastWedAdjst);

                this.setState({
                    startDate: lastWed,
                    selectedStore: selectedStore
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        try {
            console.devLog("SalesEmployeeTimeTrackQueryComponent - componentWillUnmount");
            window.onpopstate = () => { }
            CommonService.clientAzureStorageLog("SalesEmployeeTimeTrackQueryComponent", "componentWillUnmount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value)
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({
                selectedStore: store
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate) && this.state.selectedStore) {
                this.setState({ showSpinner: true });
                CommonService.setCookie(SalesEmployeeTimeTrackQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
                this.props.onGetReportClick(this.state.selectedStore, this.state.startDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate)) {
                this.setState({ showSpinner: true });
                this.props.onDownloadClick(this.state.selectedStore, this.state.startDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={2} showDays={[3]} maxDate={moment().format("MM/DD/YYYY")} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner || !this.state.selectedStore} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <span className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner || !this.state.selectedStore} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                Download <span className="far fa-download dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

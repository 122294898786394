import React from "react";
import ReactDOM from "react-dom";
import { GoogleCharts } from 'google-charts';

export class DGDGChartColumnComponent extends React.Component {
    constructor(props) {
        super(props);
        this.drawChart = this.drawChart.bind(this);
    }

    resizeObserver = null;

    componentDidMount() {
        try {
            this.resizeObserver = new ResizeObserver(() => { this.drawChart(); });
            this.resizeObserver.observe(ReactDOM.findDOMNode(this));
            GoogleCharts.load(this.drawChart, { packages: ["corechart"] });
        }
        catch (error) {
            console.devError(error)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props &&
            (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
            this.drawChart();
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    drawChart() {
        if (this.props.chartOption && this.props.chartData
            && GoogleCharts.api && GoogleCharts.api.visualization && GoogleCharts.api.visualization.arrayToDataTable && GoogleCharts.api.visualization.ColumnChart) {

            let visualizationData = GoogleCharts.api.visualization.arrayToDataTable(this.props.chartData);
            this.props.chartOption.hAxis.ticks = visualizationData.getDistinctValues(0);
            if (visualizationData.getNumberOfColumns() > 2) {
                let range1 = visualizationData.getColumnRange(1);
                let maxValue1 = Math.max(range1.max, 1);
                let minValue1 = Math.min(range1.min, 0);
                let maxValue2 = 1;
                let minValue2 = 0;
                for (let index = 2; index < visualizationData.getNumberOfColumns(); index++) {
                    let range2 = visualizationData.getColumnRange(index);
                    if (!isNaN(range2.max))
                        maxValue2 = Math.max(range2.max, maxValue2);
                    if (!isNaN(range2.min))
                        minValue2 = Math.min(range2.min, minValue2);
                }

                if (minValue1 < 0 || minValue2 < 0) {
                    maxValue1 = maxValue2 = Math.max(maxValue1, maxValue2);
                    minValue1 = minValue2 = Math.min(minValue1, minValue2);
                }

                if (!this.props.chartOption.vAxes) {
                    this.props.chartOption.vAxes = {};
                }
                this.props.chartOption.vAxes[0] = {
                    maxValue: maxValue1,
                    minValue: minValue1 > 0 ? 0 : minValue1
                };
                this.props.chartOption.vAxes[1] = {
                    maxValue: maxValue2,
                    minValue: minValue2 > 0 ? 0 : minValue2
                };
            }

            let visualization = new GoogleCharts.api.visualization.ColumnChart(ReactDOM.findDOMNode(this));
            GoogleCharts.api.visualization.events.addListener(visualization, 'select', (event) => {
                let selectedData = visualization.getSelection()[0];
                if (selectedData && selectedData.row != null) {
                    this.props.onColumnClicked(event, (selectedData.row + 1), (selectedData.column + 1));
                    visualization.setSelection();
                }
            });
            visualization.draw(visualizationData, this.props.chartOption);
        }
    }

    render() {
        return <div className="dgdg-widget-item" />;
    }
}
import React from "react";

export class DGDGFormRadioComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange(event, item) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, item);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.id, this.props.additionalData);
        }
    }

    render() {

        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let rdlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                rdlElements.push(
                    <div key={"rdlItem" + index} className={"custom-control custom-radio " + controlSpan}>
                        <input type="radio" id={"rdl_" + this.props.name + "_" + index} name={this.props.name} className="input-group custom-control-input"
                            onChange={(event) => this.onChange(event, item)} checked={this.props.value === item[this.props.itemKey] ? true : false} disabled={item.disabled} />
                        <label className="custom-control-label" htmlFor={"rdl_" + this.props.name + "_" + index} style={{ "paddingTop": "2px" }}>
                            {
                                this.props.itemKey
                                    ? item[this.props.itemKey]
                                    : item
                            }
                        </label>
                    </div>
                )
            });
        }

        return <div className={"form-group " + colSpan}>
            {rdlElements}
        </div>;
    }
}

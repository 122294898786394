import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import * as atlas from 'azure-maps-control';

export class DGDGChartAzureMapLegendComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        if (this.props && this.props.dashboardItem &&
            (this.props.azureSubscriptionKey)) {
            this.drawChart();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props && this.props.azureSubscriptionKey &&
                (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
                this.drawChart();
            }
        }
        catch (error) {
            console.devError(error);
        }
    }

    drawChart() {

        let addressText = [];
        let addressListLat = [];
        let addressListLon = [];

        if (this.props.dashboardItem !== null && this.props.dashboardItem !== undefined) {
            this.props.dashboardItem.data.forEach((responseItem, index) => {
                let url = this.props.azureGetAddressUrl + this.props.azureSubscriptionKey + "&api-version=1.0&lat&limit=1&query=" + responseItem.address;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        addressText.push(responseItem.text);
                        addressListLat.push(data.results[0].position.lat);
                        addressListLon.push(data.results[0].position.lon);
                        if (addressListLat.length === this.props.dashboardItem.data.length - 1) {
                            let map = new atlas.Map(ReactDOM.findDOMNode(this), {
                                center: [addressListLon[0], addressListLat[0]],
                                zoom: 12,
                                language: 'en-US',
                                authOptions: {
                                    authType: 'subscriptionKey',
                                    subscriptionKey: this.props.azureSubscriptionKey,
                                },
                                showFeedbackLink: false,
                                showLogo: false,
                            });

                            map.events.add('ready', function () {
                                for (let i = 0; i < addressListLat.length; i++) {
                                    let marker = new atlas.HtmlMarker({
                                        color: i % 2 === 0 ? '#bd5e75' : '#006400',
                                        text: addressText[i],
                                        position: [addressListLon[i], addressListLat[i]],
                                        popup: new atlas.Popup({
                                            content: '<div style={{"padding":"10px"}}>Hello' + i.toString() + '-' + addressText[i] + ' World</div>',
                                            pixelOffset: [0, -30]
                                        })
                                    });
                                    map.markers.add(marker);
                                    map.events.add('click', marker, () => {
                                        marker.togglePopup();
                                    });
                                }

                            });
                        }
                    });
            });
        }
    }

    render() {
        let legendItems = [];
        if (this.props.legendData) {
            this.props.legendData.forEach((legendData, index) => {
                legendItems.push(<Fragment key={"legend-" + index}>
                    <button className="btn" height="12" style={{ "padding": "6px", "backgroundColor": legendData.color }} onClick={(event) => this.onFilterByLegendClick(event, legendData)} />
                    &nbsp;&nbsp;{legendData.text}
                    <br />
                </Fragment>);
            });
        }

        return <div className="dgdg-widget-item" style={{ "width": "calc(100% - 15px)", "height": "calc(100% - 5px)" }}>
            {
                this.props.legendData
                    ? <div className="dgdg-azure-map-legend">
                        {legendItems}
                    </div>
                    : null
            }
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class FinancialStatementValueCellComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paintTable: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.paintTable) {
                this.setState({ paintTable: false });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        //let pcl = null;
        //{
        //    (this.props.mode === "Dev")
        //        ? <div className="dgdg-fs-dev-text-red">{this.props.valueKey}</div>
        //        : null
        //}
        let value = null;
        if (this.props.value) {
            value = this.props.value;
        } else if (this.props.fsDict && this.props.fsDict[this.props.valueKey]) {
            value = this.props.fsDict[this.props.valueKey][8];
        }

        if (value !== null) {
            switch (this.props.dataType) {
                case "money":
                    value = CommonService.formatNumber(value);
                    break;
                case "percent":
                    value = CommonService.formatPercentage(value);
                    break;
                case "percent_2":
                    value = CommonService.formatPercentage_2(value);
                    break;
                case "number":
                    value = CommonService.formatNumber_paran(value);
                    break;
                default:
                    break;
            }
        }

        return (this.props.onFinancialStatementDetailClick)
            ? <button type="button" className="btn btn-link dgdg-fs-value dgdg-font-underline" onClick={(event) => this.props.onFinancialStatementDetailClick(event, this.props.fsDict[this.props.valueKey], this.props.enableDetailAmountClick)}>
                {value}
            </button>
            : value;
    }
}

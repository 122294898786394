import React, { Fragment } from "react";

import moment from "moment";
import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent"
import { DGDGCarouselComponent } from "../common/dgdgcarousel/DGDGCarouselComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";

export class UsedReconditioningEditInventoryMobileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPublishConfirmation: false,
            publishRow: null,

        }
        this.onServiceOutCarousalCallback = this.onServiceOutCarousalCallback.bind(this);
        this.onBodyOutCarousalCallback = this.onBodyOutCarousalCallback.bind(this);
        this.onDetailOutCarousalCallback = this.onDetailOutCarousalCallback.bind(this);
        this.onReconOutCarousalCallback = this.onReconOutCarousalCallback.bind(this);
        this.onPhotoOutCarousalCallback = this.onPhotoOutCarousalCallback.bind(this);
        this.onServiceOutColumnCallback = this.onServiceOutColumnCallback.bind(this);
        this.onBodyOutColumnCallback = this.onBodyOutColumnCallback.bind(this);
        this.onDetailOutColumnCallback = this.onDetailOutColumnCallback.bind(this);
        this.onReconOutColumnCallback = this.onReconOutColumnCallback.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onPhotoOutColumnCallback = this.onPhotoOutColumnCallback.bind(this);

    }

    onServiceOutCarousalCallback() {
        let text = "Pending";
        let status = "pending";
        let statusOut = "";
        if (this.props.rowData?.service_out_date_time) {
            text = "Completed";
            status = "completed";
            if (this.props.rowData?.body_out_date_time || this.props.rowData?.detail_in_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusOut={statusOut} />;
    }

    onBodyOutCarousalCallback() {
        if (!this.props.rowData?.service_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (this.props.rowData?.body_out_date_time || this.props.rowData?.detail_in_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (this.props.rowData?.detail_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onDetailOutCarousalCallback() {
        if (!this.props.rowData?.service_out_date_time || (!this.props.rowData?.body_out_date_time && !this.props.rowData?.detail_in_date_time)) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (this.props.rowData?.detail_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (this.props.rowData?.recon_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onReconOutCarousalCallback() {
        if (!this.props.rowData?.detail_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (this.props.rowData?.recon_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (this.props.rowData?.photo_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onPhotoOutCarousalCallback() {
        if (!this.props.rowData?.recon_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        if (this.props.rowData?.photo_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} />;
    }

    onServiceOutColumnCallback() {
        let rowId = "serviceOutDate_" + this.props.rowData?.stock_number;
        if (this.props.rowData?.service_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold)
            return <div className="text-center">
                {CommonService.formatDate(moment(this.props.rowData?.service_out_date_time))}
                {
                    this.props.rowData?.can_undo_service_out_date_time
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (this.props.rowData?.is_wholesale) return false; this.props.onUndoInventoryClick(event, this.props.rowData, "Service Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </div>;
        else {
            let disabled = this.props.rowData?.edit_service === 0 || (this.props.rowData?.service_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold);
            let buttonDisabled = this.props.rowData?.is_wholesale || disabled || this.props.rowData?.is_parts_hold || this.props.rowData?.is_smog_hold || this.props.rowData?.is_vin_hold || this.props.rowData?.is_weight_hold;
            let CssName = "btn btn-success";
            if (this.props.rowData?.service_out_due_age >= 4)
                CssName = "btn btn-danger";
            else if (buttonDisabled)
                CssName = "btn btn-primary";

            return <div className="text-center">
                <div className="form-inline">
                    <DGDGFormCheckboxV2Component label="Smog:" disabled={this.props.rowData?.is_wholesale || disabled} id={rowId + "_smogHold"} value={this.props.rowData?.is_smog_hold} onChange={this.props.onSmogHoldChange} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="VIN:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={this.props.rowData?.is_wholesale || disabled} id={rowId + "_vinHold"} value={this.props.rowData?.is_vin_hold} onChange={this.props.onVinHoldChange} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="Weight:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={this.props.rowData?.is_wholesale || disabled} id={rowId + "_weightHold"} value={this.props.rowData?.is_weight_hold} onChange={this.props.onWeightHoldChange} />
                </div>
                <div className="form-inline" style={{ "marginBottom": "5px" }}>
                    <DGDGFormDropdownComponent data={this.props.serviceHoldList} label="Hold" itemKey="value" id={rowId + "_service_hold"} disabled={this.props.rowData?.is_wholesale || disabled} onItemClick={this.props.onServiceHoldChange} value={this.props.rowData?.metric_name} />
                </div>
                <div className="text-center">
                    {
                        !buttonDisabled && (!this.props.rowData?.service_hold_metric_name || this.props.rowData?.service_hold_metric_name === "NoHolds")
                            ? <div className="d-inline dropdown">
                                <button className={CssName + " dgdg-button-width dropdown-toggle"} type="button" id="serviceOutBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Service Out
                                </button>
                                <div className="dropdown-menu" aria-labelledby="serviceOutBtn">
                                    <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.props.onServiceOutChange(this.props.rowData, "Body")}>
                                        Service Out to Body
                                    </button>
                                    <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.props.onServiceOutChange(this.props.rowData, "Detail")}>
                                        Service Out to Detail
                                    </button>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="dgdg-used-reconditioning-label">
                    {this.props.rowData?.service_out_due_age + " days since Entry"}
                    <br />
                </div>
            </div>;
        }
    }
    pageTitle = "Used Car Prep";
    startDate = "01/01/2020";
    endDate = moment().format('MM/DD/YYYY')
    onBodyOutColumnCallback() {
        if (this.props.rowData?.body_out_date_time)
            return <div className="text-center">
                {CommonService.formatDate(moment(this.props.rowData?.body_out_date_time))}
                {
                    this.props.rowData?.can_undo_body_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (this.props.rowData?.is_wholesale) return false; this.props.onUndoInventoryClick(event, this.props.rowData, "Body Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </div>;
        else {
            let CssName = "btn btn-success";
            let disabled = (this.props.rowData?.edit_service === 0 || !(this.props.rowData?.service_out_date_time && this.props.rowData?.body_in_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold) || this.props.rowData?.body_out_date_time) || this.props.rowData?.is_wholesale;
            if (this.props.rowData?.body_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <div className="text-center">
                {
                    !disabled
                        ? this.props.selectedStore && this.props.selectedStore.store_number !== 11
                            ? <Fragment>
                                <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onBodyOutClick(event, this.props.rowData)}>Body Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    {this.props.rowData?.body_out_due_age + " days since Service"}
                                </span>
                            </Fragment>
                            : this.props.selectedStore && this.props.selectedStore.store_number === 11
                                ? <Fragment>
                                    <br />
                                    <span className="dgdg-used-reconditioning-label">
                                        {this.props.rowData?.body_out_due_age + " days since Service"}
                                    </span>
                                    <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.v(event, this.props.rowData)}>Body Out</button>
                                </Fragment>
                                : null
                        : <label>N/A</label>
                }
            </div>;
        }
    }

    onDetailOutColumnCallback() {
        let rowId = "detailOutDate_" + this.props.rowData?.stock_number;
        if (this.props.rowData?.detail_out_date_time)
            return <div className="text-center">
                {CommonService.formatDate(moment(this.props.rowData?.detail_out_date_time))}
                {
                    this.props.rowData?.can_undo_detail_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (this.props.rowData?.is_wholesale) return false; this.props.onUndoInventoryClick(event, this.props.rowData, "Detail Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </div>;
        else {
            let CssName = "btn btn-success";
            let disabled = (this.props.rowData?.edit_detail === 0 || !(this.props.rowData?.service_out_date_time && this.props.rowData?.detail_in_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold) || this.props.rowData?.detail_out_date_time) || this.props.rowData?.is_wholesale;
            if (this.props.rowData?.detail_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <div className="text-center">
                {
                    !disabled && this.props.rowData?.detail_in_date_time
                        ? this.props.selectedStore && this.props.selectedStore.store_number !== 11
                            ? <Fragment>
                                <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onDetailOutClick(event, this.props.rowData)}>Detail Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    {this.props.rowData?.detail_out_due_age + " days since " + (this.props.rowData?.body_out_date_time ? "Body" : "Service")}
                                </span>
                            </Fragment>
                            : this.props.selectedStore && this.props.selectedStore.store_number === 11
                                ? <Fragment>
                                    <DGDGFormCheckboxV2Component label="Paint:" id={rowId + "_PaintHold"} value={this.props.rowData?.is_paint_hold} onChange={this.props.onPaintHoldChange} controlCssName="dgdg-used-reconditioning-toggle-yellow" />
                                    <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onDetailOutClick(event, this.props.rowData)} disabled={this.props.rowData?.is_paint_hold}>Detail Out</button>
                                    <br />
                                    <span className="dgdg-used-reconditioning-label">
                                        {this.props.rowData?.detail_out_due_age + " days since " + (this.props.rowData?.body_out_date_time ? "Body" : "Service")}
                                    </span>
                                </Fragment>
                                : null
                        : <label>N/A</label>
                }
            </div>;
        }
    }

    onReconOutColumnCallback() {
        let rowId = "reconOutDate_" + this.props.rowData?.stock_number;
        if (this.props.rowData?.recon_out_date_time)
            return <div className="text-center">
                {CommonService.formatDate(moment(this.props.rowData?.recon_out_date_time))}
                {
                    this.props.rowData?.can_undo_recon_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (this.props.rowData?.is_wholesale) return false; this.props.onUndoInventoryClick(event, this.props.rowData, "Recon Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </div>;
        else {
            let disabled = (this.props.rowData?.edit_recon === 0 || !(this.props.rowData?.service_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold) || !this.props.rowData?.detail_out_date_time || this.props.rowData?.recon_out_date_time) || this.props.rowData?.is_wholesale;
            let CssName = "btn btn-success";
            if (this.props.rowData?.recon_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <div className="text-center">
                {
                    !disabled
                        ? <Fragment>
                            {
                                this.props.selectedStore && this.props.selectedStore.store_number !== 11
                                    ? <Fragment>
                                        <div className="form-inline">
                                            <DGDGFormCheckboxComponent label="Interior Only" id={rowId + "_ReconInteriorOnly"} value={this.props.rowData?.recon_interior_only} onChange={this.props.onReconInteriorOnlyChange} />
                                        </div>
                                        <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onReconOutClick(event, this.props.rowData)}>Recon Out</button>
                                        <br />
                                        <span className="dgdg-used-reconditioning-label">
                                            {this.props.rowData?.recon_out_due_age + " days since Detail"}
                                        </span>
                                    </Fragment>
                                    : this.props.selectedStore && this.props.selectedStore.store_number === 11
                                        ? <Fragment>
                                            <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onReconOutClick(event, this.props.rowData)}>Recon Out</button>
                                            <br />
                                            <span className="dgdg-used-reconditioning-label">
                                                {this.props.rowData?.recon_out_due_age + " days since Detail"}
                                            </span>
                                        </Fragment>
                                        : null
                            }
                        </Fragment>
                        : <label>N/A</label>
                }
            </div>;
        }
    }

    onPhotoOutColumnCallback() {
        if (this.props.rowData?.photo_out_date_time)
            return <div className="text-center">
                {CommonService.formatDate(moment(this.props.rowData?.photo_out_date_time))}
                {
                    this.props.rowData?.can_undo_photo_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (this.props.rowData?.is_wholesale) return false; this.props.onUndoInventoryClick(event, this.props.rowData, "Photo Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </div>;
        else {
            let disabled = (this.props.rowData?.edit_photo === 0 || !(this.props.rowData?.service_out_date_time && !this.props.rowData?.is_smog_hold && !this.props.rowData?.is_vin_hold && !this.props.rowData?.is_weight_hold) || !this.props.rowData?.detail_out_date_time || !this.props.rowData?.recon_out_date_time || this.props.rowData?.photo_out_date_time) || this.props.rowData?.is_wholesale;
            let CssName = "btn btn-success";
            if (this.props.rowData?.photo_out_due_age >= 1)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <div className="text-center">
                {
                    !disabled
                        ? <Fragment>
                            <button type="button" className={CssName + " dgdg-inventory-button-width"} onClick={(event) => this.props.onPhotoOutClick(event, this.props.rowData)}>Photo Out</button>
                            <br />
                            <span className="dgdg-used-reconditioning-label">
                                {this.props.rowData?.photo_out_due_age + " days since Recon"}
                            </span>
                        </Fragment>
                        : <label>N/A</label>
                }
            </div>;
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null && comments !== undefined) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onAddCommentColumnCallback() {
        let comments = this.getComments(this.props.rowData?.comments);
        let disableAddComments = !this.props.rowData?.edit_comment;
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.props.onToggleViewComments(event, this.props.rowData)}>{comments.length / 2} Comments
                {
                    this.props.rowData?.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={disableAddComments} onClick={(event) => this.props.onToggleAddComments(event, this.props.rowData)}>Add Comments
                {
                    this.props.rowData?.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                this.props.rowData?.showViewComments
                    ? comments
                    : null
            }
            {
                this.props.rowData?.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + this.props.rowData?.stock_number} value={this.props.rowData["comments_" + this.props.rowData?.stock_number]} rows="3" onChange={this.props.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={disableAddComments} onClick={(event) => this.props.onSaveCommentClick(event, this.props.rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={this.props.rowData?.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    render() {
        return <div>
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.props.showDialog} title={"Inventory:&nbsp;" + (this.props.rowData ? this.props.rowData.stock_number + ", " + this.props.rowData.vin + ", " + this.props.rowData.year + ", " + this.props.rowData.make + ", " + this.props.rowData.model : "")}
                cssName="dgdg-used-recon-dialog-edit"
                onCancelClick={this.props.onCancelClick}
            >
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change Service Out date"}
                    bodyText={"Do you want to change the Service Out date to " + (this.props.serviceOutData !== null ? this.props.serviceOutData?.service_out_date_time : "") + "?"}
                    showConfirmation={this.props.serviceOutData !== null}
                    onOkClick={this.props.onOkServiceOut}
                    onCancelClick={this.props.onCancelDateChange}
                />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change Recon Out date"}
                    bodyText={"Do you want to change the Recon Out date to " + (this.props.reconOutData !== null ? this.props.reconOutData?.recon_out_date_time : "") + "?"}
                    showConfirmation={this.props.reconOutData !== null}
                    onOkClick={this.props.onOkReconOut}
                    onCancelClick={this.props.onCancelDateChange}
                />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change Photo Out date"}
                    bodyText={"Do you want to change the Photo Out date to " + (this.props.photoOutData !== null ? this.props.photoOutData?.photo_out_date_time : "") + "?"}
                    showConfirmation={this.props.photoOutData !== null}
                    onOkClick={this.props.onOkPhotoOut}
                    onCancelClick={this.props.onCancelDateChange}
                />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change Detail Out date"}
                    bodyText={"Do you want to change the Detail Out date to " + (this.props.detailOutData !== null ? this.props.detailOutData?.detail_out_date_time : "") + "?"}
                    showConfirmation={this.props.detailOutData !== null}
                    onOkClick={this.props.onOkDetailOut}
                    onCancelClick={this.props.onCancelDateChange}
                />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Undo changes to Stock #: " + (this.props.undoDetail !== null ? this.props.undoDetail?.stock_number : "")}
                    bodyText={"Do you want to undo the " + this.props.actionName + " on this vehicle?"}
                    showConfirmation={this.props.undoDetail !== null}
                    onOkClick={this.props.onUndoOkClick}
                    onCancelClick={this.props.onCancelUndoClick}
                />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change Retail/Wholesale"}
                    bodyText={"Do you want to change vehicle " + this.props.wholesaleRow?.stock_number + " to from " + (this.props.wholesaleRow?.is_wholesale ? "Retail" : "Wholesale") + " to " + (this.props.wholesaleRow?.is_wholesale ? "Wholesale" : "Retail") + "?"}
                    showConfirmation={this.props.showWholesaleConfirmation}
                    onOkClick={this.props.onOkWholesaleChange}
                    onCancelClick={this.props.onCancelWholesaleClick}
                />

                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"OK to Publish/Do Not Publish"}
                    bodyText={((this.props.publishRow !== null && !this.props.publishRow?.ok_publish) ? "OK to publish vehicle " : "Do not publish vehicle ") + (this.props.publishRow !== null ? this.props.publishRow?.stock_number : "") + "?"}
                    showConfirmation={this.props.showPublishConfirmation}
                    cssName={"dgdg-used-reconditioning-ok-to-publish-dialog"}
                    onOkClick={this.props.onOkPublishChange}
                    onCancelClick={this.props.onCancelPublishClick}
                />

                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Change CPO/Not CPO"}
                    bodyText={"Do you want to change vehicle " + (this.props.cpoRow !== null ? this.props.cpoRow.stock_number : "") + " from " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "Not CPO" : "CPO") : "") + " to " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "CPO" : "Not CPO") : "") + "?"}
                    showConfirmation={this.props.showCPOConfirmation}
                    onOkClick={this.props.onOkCPOChange}
                    onCancelClick={this.props.onCancelCPOClick}
                />

                <div className="dgdg-report-body">
                    <div className="card">
                        <div className="card-block">
                            <table cellPadding="3" width="100%">
                                <tbody>
                                    <tr>
                                        <td width="220px">
                                            <DGDGFormCheckboxV2Component id={"publish_" + this.props.rowData?.stock_number} disabled={this.props.rowData?.disable_publish} value={this.props.rowData?.ok_publish} onChange={this.props.onPublishSelectChange} additionalData={this.props.rowData} label="OK to Publish" label2="Do Not Publish" />
                                        </td>
                                        <td width="120px">
                                            <DGDGFormCheckboxV2Component id={"wholesale_" + this.props.rowData?.stock_number} disabled={this.props.rowData?.disable_wholesale} value={this.props.rowData?.is_wholesale} onChange={this.props.onWholesaleSelectChange} additionalData={this.props.rowData} label="Retl" label2="Whsl" />
                                        </td>
                                        <td width="120px">
                                            <DGDGFormCheckboxV2Component id={"cpo_" + this.props.rowData?.stock_number} disabled={this.props.rowData?.disable_cpo} value={this.props.rowData?.is_cpo} onChange={this.props.onCPOSelectChange} additionalData={this.props.rowData} label="Not CPO" label2="CPO" />
                                        </td>
                                        <td colSpan="4" />
                                    </tr>
                                    <tr>
                                        <td colSpan="6">
                                            <table cellPadding="3" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center font-weight-bold btn-link text-nowrap">Service Out</td>
                                                        <td className="text-center font-weight-bold btn-link text-nowrap">Body Out</td>
                                                        <td className="text-center font-weight-bold btn-link text-nowrap">Detail Out</td>
                                                        <td className="text-center font-weight-bold btn-link text-nowrap">Recon Out</td>
                                                        <td className="text-center font-weight-bold btn-link text-nowrap">Photo Out</td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <td>{this.onServiceOutCarousalCallback()}</td>
                                                        <td>{this.onBodyOutCarousalCallback()}</td>
                                                        <td>{this.onDetailOutCarousalCallback()}</td>
                                                        <td>{this.onReconOutCarousalCallback()}</td>
                                                        <td>{this.onPhotoOutCarousalCallback()}</td>
                                                    </tr>
                                                    <tr className="align-top">
                                                        <td className={this.props.rowData?.service_out_date_time ? "" : "dgdg-used-reconditioning-service-out"}>{this.onServiceOutColumnCallback()}</td>
                                                        <td>{this.onBodyOutColumnCallback()}</td>
                                                        <td>{this.onDetailOutColumnCallback()}</td>
                                                        <td>{this.onReconOutColumnCallback()}</td>
                                                        <td>{this.onPhotoOutColumnCallback()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{ "padding": "0px 3px" }}>{this.onAddCommentColumnCallback()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </DGDGModalDialogComponent>
        </div>
    }
}

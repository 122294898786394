import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableV2Component } from "../common/dgdgtable/DGDGTableV2Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomComponent } from "../common/dgdgtable/DGDGTableCustomComponent";

import { FIPayrollPlanEditComponent } from "./FIPayrollPlanEditComponent";
import { FIPayrollPlanDetailComponent } from "./FIPayrollPlanDetailComponent";

export class FIPayrollPlanComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            managerPlanTableData: [],
            directorPlanTableData: [],
            isLoadingData: false,
            paintTable: false,
            plans: null,
            selectedPlan: null,
            planType: "",
            showPlanDialog: false,
            isSaveComplete: false
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onManagerPlanEditCellClick = this.onManagerPlanEditCellClick.bind(this);
        this.onDirectorPlanEditCellClick = this.onDirectorPlanEditCellClick.bind(this);
        this.onPlanCancelClick = this.onPlanCancelClick.bind(this);
        this.onPlanSaveClick = this.onPlanSaveClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FIPayrollPlanComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIPayroll/Plan", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getPlans();
            CommonService.clientAzureStorageLog("FIPayrollPlanComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-fi-payroll-plan", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onManagerPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                planType: "Manager Plan",
                selectedPlan: rowData,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDirectorPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                planType: "Director Plan",
                selectedPlan: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanSaveClick(event) {
        try {
            this.setState({ isSaveComplete: false });
            this.props.applicationInsights.trackTrace({ message: "FIPayrollPlanComponent/onPlanSaveClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/SaveFIPayrollPayPlan", {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: "'" + JSON.stringify(this.state.plans) + "'"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isSaveComplete: true });
                    this.onPlanCancelClick(event);
                    this.getPlans();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/SaveFIPayrollPayPlan", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanCancelClick(event) {
        try {
            this.setState({ selectedPlan: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getPlans() {
        this.setState({
            isLoadingData: true,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
        try {
            this.props.applicationInsights.trackTrace({ message: "FIPayrollPlanComponent/getPlans", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/GetFIPayrollPayPlan", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.manager_plans.forEach((responseItem, index) => {
                        responseItem.tier1PPRRange = responseItem.plan.ppr_income.tier1.range;
                        responseItem.tier1PPRPercentage = responseItem.plan.ppr_income.tier1.value;
                        responseItem.tier2PPRRange = responseItem.plan.ppr_income.tier2.range;
                        responseItem.tier2PPRPercentage = responseItem.plan.ppr_income.tier2.value;
                        responseItem.tier3PPRRange = responseItem.plan.ppr_income.tier3.range;
                        responseItem.tier3PPRPercentage = responseItem.plan.ppr_income.tier3.value;
                        responseItem.tier4PPRRange = responseItem.plan.ppr_income.tier4.range;
                        responseItem.tier4PPRPercentage = responseItem.plan.ppr_income.tier4.value;

                        responseItem.tier1PVRRange = responseItem.plan.pvr_income.tier1.range;
                        responseItem.tier1PVRPercentage = responseItem.plan.pvr_income.tier1.value;
                        responseItem.tier2PVRRange = responseItem.plan.pvr_income.tier2.range;
                        responseItem.tier2PVRPercentage = responseItem.plan.pvr_income.tier2.value;
                        responseItem.tier3PVRRange = responseItem.plan.pvr_income.tier3.range;
                        responseItem.tier3PVRPercentage = responseItem.plan.pvr_income.tier3.value;
                        responseItem.tier4PVRRange = responseItem.plan.pvr_income.tier4.range;
                        responseItem.tier4PVRPercentage = responseItem.plan.pvr_income.tier4.value;

                        responseItem.tier1WarrantyPenPercentage = responseItem.plan.warranty_income.tier1.range;
                        responseItem.tier1WarrantyPercentage = responseItem.plan.warranty_income.tier1.value;
                        responseItem.tier2WarrantyPenPercentage = responseItem.plan.warranty_income.tier2.range;
                        responseItem.tier2WarrantyPercentage = responseItem.plan.warranty_income.tier2.value
                        responseItem.tier3WarrantyPenPercentage = responseItem.plan.warranty_income.tier3.range;
                        responseItem.tier3WarrantyPercentage = responseItem.plan.warranty_income.tier3.value;
                        responseItem.tier4WarrantyPenPercentage = responseItem.plan.warranty_income.tier4.range;
                        responseItem.tier4WarrantyPercentage = responseItem.plan.warranty_income.tier4.value;
                        responseItem.tier5WarrantyPenPercentage = responseItem.plan.warranty_income.tier5.range;
                        responseItem.tier5WarrantyPercentage = responseItem.plan.warranty_income.tier5.value;

                        responseItem.tier1MaintenancePenPercentage = responseItem.plan.maintenance.tier1.range;
                        responseItem.tier1MaintenancePercentage = responseItem.plan.maintenance.tier1.value
                        responseItem.tier2MaintenancePenPercentage = responseItem.plan.maintenance.tier2.range;
                        responseItem.tier2MaintenancePercentage = responseItem.plan.maintenance.tier2.value;
                        responseItem.tier3MaintenancePenPercentage = responseItem.plan.maintenance.tier3.range;
                        responseItem.tier3MaintenancePercentage = responseItem.plan.maintenance.tier3.value;

                        responseItem.allyYes = responseItem.plan.ally.Y;
                        responseItem.allyNo = responseItem.plan.ally.N;
                        responseItem.csiYes = responseItem.plan.csi.Yes;
                        responseItem.csiNo = responseItem.plan.csi.No;

                        responseItem.citYesPenPercentage = responseItem.plan.cit.Yes;
                        responseItem.citNoPenPercentage = responseItem.plan.cit.No;

                        responseItem.productBonus = responseItem.plan.ev.product_bonus_value;
                        responseItem.grossBonus = responseItem.plan.ev.gross_bonus_value;
                        responseItem.hourly = responseItem.plan.ev.hourly_value;
                    });

                    parsedResponse.director_plans.forEach((responseItem, index) => {
                        responseItem.tier1PPRRange = responseItem.plan.ppr_income.tier1.range;
                        responseItem.tier1PPRPercentage = responseItem.plan.ppr_income.tier1.value;
                        responseItem.tier2PPRRange = responseItem.plan.ppr_income.tier2.range;
                        responseItem.tier2PPRPercentage = responseItem.plan.ppr_income.tier2.value;
                        responseItem.tier3PPRRange = responseItem.plan.ppr_income.tier3.range;
                        responseItem.tier3PPRPercentage = responseItem.plan.ppr_income.tier3.value;
                        responseItem.tier4PPRRange = responseItem.plan.ppr_income.tier4.range;
                        responseItem.tier4PPRPercentage = responseItem.plan.ppr_income.tier4.value;

                        responseItem.tier1PVRRange = responseItem.plan.pvr_income.tier1.range;
                        responseItem.tier1PVRPercentage = responseItem.plan.pvr_income.tier1.value;
                        responseItem.tier2PVRRange = responseItem.plan.pvr_income.tier2.range;
                        responseItem.tier2PVRPercentage = responseItem.plan.pvr_income.tier2.value;
                        responseItem.tier3PVRRange = responseItem.plan.pvr_income.tier3.range;
                        responseItem.tier3PVRPercentage = responseItem.plan.pvr_income.tier3.value;
                        responseItem.tier4PVRRange = responseItem.plan.pvr_income.tier4.range;
                        responseItem.tier4PVRPercentage = responseItem.plan.pvr_income.tier4.value;

                        responseItem.tier1WarrantyPenPercentage = responseItem.plan.warranty_income.tier1.range;
                        responseItem.tier1WarrantyPercentage = responseItem.plan.warranty_income.tier1.value;
                        responseItem.tier2WarrantyPenPercentage = responseItem.plan.warranty_income.tier2.range;
                        responseItem.tier2WarrantyPercentage = responseItem.plan.warranty_income.tier2.value
                        responseItem.tier3WarrantyPenPercentage = responseItem.plan.warranty_income.tier3.range;
                        responseItem.tier3WarrantyPercentage = responseItem.plan.warranty_income.tier3.value;
                        responseItem.tier4WarrantyPenPercentage = responseItem.plan.warranty_income.tier4.range;
                        responseItem.tier4WarrantyPercentage = responseItem.plan.warranty_income.tier4.value;
                        responseItem.tier5WarrantyPenPercentage = responseItem.plan.warranty_income.tier5.range;
                        responseItem.tier5WarrantyPercentage = responseItem.plan.warranty_income.tier5.value;

                        responseItem.tier1MaintenancePenPercentage = responseItem.plan.maintenance.tier1.range;
                        responseItem.tier1MaintenancePercentage = responseItem.plan.maintenance.tier1.value
                        responseItem.tier2MaintenancePenPercentage = responseItem.plan.maintenance.tier2.range;
                        responseItem.tier2MaintenancePercentage = responseItem.plan.maintenance.tier2.value;
                        responseItem.tier3MaintenancePenPercentage = responseItem.plan.maintenance.tier3.range;
                        responseItem.tier3MaintenancePercentage = responseItem.plan.maintenance.tier3.value;

                        responseItem.allyYes = responseItem.plan.ally.Y;
                        responseItem.allyNo = responseItem.plan.ally.N;
                        responseItem.csiYes = responseItem.plan.csi.Yes;
                        responseItem.csiNo = responseItem.plan.csi.No;

                        responseItem.citYesPenPercentage = responseItem.plan.cit.Yes;
                        responseItem.citNoPenPercentage = responseItem.plan.cit.No;

                        responseItem.productBonus = responseItem.plan.ev.product_bonus_value;
                        responseItem.grossBonus = responseItem.plan.ev.gross_bonus_value;
                        responseItem.hourly = responseItem.plan.ev.hourly_value;
                    });

                    this.setState({
                        isLoadingData: false,
                        plans: parsedResponse,
                        managerPlanTableData: parsedResponse.manager_plans,
                        directorPlanTableData: parsedResponse.director_plans,
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        managerPlanTableData: [],
                        directorPlanTableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetFIPayrollPayPlan", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Payroll";

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FIPayrollPlanEditComponent
                applicationInsights={this.props.applicationInsights}
                planDetail={this.state.selectedPlan}
                planType={this.state.planType}
                isSaveComplete={this.state.isSaveComplete}
                onSaveClick={this.onPlanSaveClick}
                onCancelClick={this.onPlanCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#manager_plans")}>Manager Plan</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#director_plans")}>Director Plan</button>
            </nav>
            <div className="dgdg-fi-payroll-plan dgdg-site-scrollable-content">
                <div id="manager_plans" className="card dgdg-card-gutter">
                    <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.state.managerPlanTableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable} headerText="Manager Plan">
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Plan Name" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" headerCssName="dgdg-fi-payroll-plan-table-textbox-width" cssName=" dgdg-fi-payroll-plan-font" />
                            <DGDGTableColumnComponent headerText="Plan Detail" cssName="text-right" dataText="<span class='fas fa-edit' />" onCellClick={this.onManagerPlanEditCellClick} />
                        </DGDGTableRowComponent>
                        <DGDGTableCustomComponent>
                            <FIPayrollPlanDetailComponent
                                onShowAlert={this.props.onShowAlert} />
                        </DGDGTableCustomComponent>
                    </DGDGTableV2Component>
                </div>
                <div id="director_plans" className="card">
                    <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.state.directorPlanTableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable} headerText="Director Plan">
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Plan Name" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" headerCssName="dgdg-fi-payroll-plan-table-textbox-width" cssName=" dgdg-fi-payroll-plan-font" />
                            <DGDGTableColumnComponent headerText="Plan Detail" cssName="text-right" dataText="<span class='fas fa-edit' />" onCellClick={this.onManagerPlanEditCellClick} />
                        </DGDGTableRowComponent>
                        <DGDGTableCustomComponent>
                            <FIPayrollPlanDetailComponent
                                onShowAlert={this.props.onShowAlert} />
                        </DGDGTableCustomComponent>
                    </DGDGTableV2Component>
                </div>
            </div>
        </div>;
    }
}

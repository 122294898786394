import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class ROQueryComponent extends React.Component {
    static StoreNumberCookie = "ROStoreNumberCookie";
    static RONumberCookie = "RONumberCookie";
    static DateCookie = "RODateCookie";
    static VinCookie = "ROVinCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ROQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            roNumber: "",
            roDate: null,
            showSpinner: false,
            vin: "",
            filterLoaded: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && !this.state.filterLoaded && !this.state.selectedStore && !this.state.vin) {
                if (this.props.match.params.StoreNumber || this.props.match.params.RONumber || this.props.match.params.RODate || this.props.match.params.Vin) {
                    let storeNumber = parseInt(this.props.match.params.StoreNumber)
                    let selectedStore = this.props.stores.filter(store => store.store_number === storeNumber)[0];
                    let roNumber = (this.props.match.params.RONumber === undefined || this.props.match.params.RONumber === "All") ? "" : this.props.match.params.RONumber;
                    let roDate = (this.props.match.params.RODate === "All" || this.props.match.params.RODate === undefined) ? null : moment(this.props.match.params.RODate, "MM-DD-YYYY");
                    let vin = (this.props.match.params.Vin === undefined || this.props.match.params.Vin === "All") ? "" : this.props.match.params.Vin;
                    if (vin !== "" && roDate === null) {
                        roDate = moment().subtract(60, "day");
                    }
                    this.setState({
                        selectedStore: selectedStore,
                        roNumber: roNumber,
                        roDate: roDate,
                        vin: vin,
                        filterLoaded: true
                    }, this.onGetReportClick);
                }
                else {
                    let selectedStore = null;
                    let storeNumber = this.props.componentState ? this.props.componentState.selectedStore.store_number : null;
                    if (!storeNumber) {
                        storeNumber = CommonService.getCookie(ROQueryComponent.StoreNumberCookie);
                    }
                    if (storeNumber !== null) {
                        storeNumber = parseInt(storeNumber);
                        selectedStore = this.props.stores.filter(store => store.store_number === storeNumber)[0]
                    }
                    else {
                        selectedStore = this.props.stores[0];
                    }

                    let roNumber = this.props.componentState ? this.props.componentState.roNumber : "";
                    if (!roNumber) {
                        roNumber = CommonService.getCookie(ROQueryComponent.RONumberCookie)
                    }

                    let roDate = this.props.componentState ? this.props.componentState.RODate : null;
                    if (!roDate) {
                        roDate = CommonService.getCookie(ROQueryComponent.DateCookie);
                    }
                    if (roDate) {
                        roDate = moment(roDate);
                    }

                    let vin = this.props.componentState ? this.props.componentState.vin : "";
                    if (!vin) {
                        vin = CommonService.getCookie(ROQueryComponent.VinCookie);
                    }

                    this.setState({
                        selectedStore: selectedStore,
                        roNumber: roNumber,
                        roDate: roDate,
                        vin: vin,
                        filterLoaded: true
                    });
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value),
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(ROQueryComponent.StoreNumberCookie, this.state.selectedStore ? this.state.selectedStore.store_number : null, 30);
            CommonService.setCookie(ROQueryComponent.RONumberCookie, this.state.roNumber, 30);
            CommonService.setCookie(ROQueryComponent.DateCookie, moment(this.state.roDate).toDate(), 30);
            CommonService.setCookie(ROQueryComponent.VinCookie, this.state.vin, 30);
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedStore, this.state.roNumber, this.state.roDate, this.state.vin);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let disableGetReport = this.state.showSpinner
            || !((this.state.vin && CommonService.isDateValid(this.state.roDate))
                || (!this.state.vin && this.state.selectedStore && (this.state.roNumber || CommonService.isDateValid(this.state.roDate))));

        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="roDate" label="Date" value={this.state.roDate} onDateChange={this.onDateChange} numberOfMonths={1} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="roNumber" label="RO Number" value={this.state.roNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <button disabled={disableGetReport} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

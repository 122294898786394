import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarRangeComponent } from "../common/dgdgcalendar/DGDGFormCalendarRangeComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class ExcelReportsDataComponent extends React.Component {
    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf('month'), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf('month'), endDate: moment().subtract(1, "months").endOf('month') },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    constructor(props) {
        super(props);

        let startDate = moment().startOf('month');
        let endDate = moment();
        this.state = {
            selectedReport: null,
            showSpinner: false,
            startDate: startDate,
            endDate: endDate,
            reportNames: [
                { key: "Lead View", value: "Lead View" }
            ],
            selectedDateRange: this.dateRange[0]
        };

        this.onReportClick = this.onReportClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Excel Reports";

    componentDidMount() {
        try {
            console.devLog("ExcelReportsDataComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ExcelReports/Data", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReportClick(id, report) {
        try {
            this.setState({ selectedReport: report });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startDate: startDate,
                endDate: endDate
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onDownloadClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(selecteReport, startDate, endDate) {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                report: selecteReport.value.replace(" ", ""),
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate),
            };

            this.props.applicationInsights.trackTrace({ message: "/ExcelReportsDataComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ExcelReports/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ showSpinner: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ExcelReports/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let minDate = moment().date(1).month(0).year(moment().year()).format('MM/DD/YYYY'),
            maxDate = moment().format('MM/DD/YYYY')

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-block">
                        <div className="form-inline">
                            <DGDGFormDropdownComponent label="Report" data={this.state.reportNames} itemKey="key" value={this.state.selectedReport ? this.state.selectedReport.value : "Select Report"} onItemClick={this.onReportClick} onKeyDown={this.onKeyDown} />
                            <DGDGFormCalendarRangeComponent
                                applicationInsights={this.props.applicationInsights}
                                label="Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                                startId="startDate" startValue={this.state.startDate} startMin={minDate} startMax={maxDate}
                                endId="endDate" endValue={this.state.endDate} endMin={minDate} endMax={maxDate} />
                            <div style={{ "height": "33px", "marginTop": "10px" }}>
                                <button disabled={this.state.showSpinner || this.state.selectedReport === null || !(this.state.startDate._isValid && this.state.endDate._isValid)} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                                    Download<i className="far fa-download dgdg-icon" />
                                </button>
                            </div>
                            <div style={{ "marginTop": "15px" }}>
                                <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";

export class DemoChartLineComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Volume",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <div className="dgdg-widget-item-new">
            <DGDGChartLineComponent chartData={this.props.chartData ? this.props.chartData.data : null} chartOption={this.state.chartOption} />
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class UsedReconditioningInventoryDesktopComponent extends React.Component {
    render() {
        return <div>
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Service Out date"}
                bodyText={"Do you want to change the Service Out date to " + (this.props.serviceOutData !== null ? CommonService.formatDate(this.props.serviceOutData.service_out_date_time) : "") + "?"}
                showConfirmation={this.props.serviceOutData !== null}
                onOkClick={this.props.onOkServiceOut}
                onCancelClick={this.props.onCancelDateChange}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Body Out date"}
                bodyText={"Do you want to change the Body Out date to " + (this.props.bodyOutData !== null ? CommonService.formatDate(this.props.bodyOutData.body_out_date_time) : "") + "?"}
                showConfirmation={this.props.bodyOutData !== null}
                onOkClick={this.props.onOkBodyOut}
                onCancelClick={this.props.onCancelDateChange}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Detail Out date"}
                bodyText={"Do you want to change the Detail Out date to " + (this.props.detailOutData !== null ? CommonService.formatDate(this.props.detailOutData.detail_out_date_time) : "") + "?"}
                showConfirmation={this.props.detailOutData !== null}
                onOkClick={this.props.onOkDetailOut}
                onCancelClick={this.props.onCancelDateChange}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Recon Out date"}
                bodyText={"Do you want to change the Recon Out date to " + (this.props.reconOutData !== null ? CommonService.formatDate(this.props.reconOutData.recon_out_date_time) : "") + "?"}
                showConfirmation={this.props.reconOutData !== null}
                onOkClick={this.props.onOkReconOut}
                onCancelClick={this.props.onCancelDateChange}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Photo Out date"}
                bodyText={"Do you want to change the Photo Out date to " + (this.props.photoOutData !== null ? CommonService.formatDate(this.props.photoOutData.photo_out_date_time) : "") + "?"}
                showConfirmation={this.props.photoOutData !== null}
                onOkClick={this.props.onOkPhotoOut}
                onCancelClick={this.props.onCancelDateChange}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Undo changes to Stock #: " + (this.props.undoDetail !== null ? this.props.undoDetail.stock_number : "")}
                bodyText={"Do you want to undo the " + this.props.actionName + " on this vehicle?"}
                showConfirmation={this.props.undoDetail !== null}
                onOkClick={this.props.onUndoOkClick}
                onCancelClick={this.props.onCancelUndoClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change Retail/Wholesale"}
                bodyText={"Do you want to change vehicle " + (this.props.wholesaleRow !== null ? this.props.wholesaleRow.stock_number : "") + " to from " + (this.props.wholesaleRow !== null ? (this.props.wholesaleRow.is_wholesale ? "Retail" : "Wholesale") : "") + " to " + (this.props.wholesaleRow !== null ? (this.props.wholesaleRow.is_wholesale ? "Wholesale" : "Retail") : "") + "?"}
                showConfirmation={this.props.showWholesaleConfirmation}
                onOkClick={this.props.onOkWholesaleChange}
                onCancelClick={this.props.onCancelWholesaleClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Change CPO/Not CPO"}
                bodyText={"Do you want to change vehicle " + (this.props.cpoRow !== null ? this.props.cpoRow.stock_number : "") + " from " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "Not CPO" : "CPO") : "") + " to " + (this.props.cpoRow !== null ? (this.props.cpoRow.is_cpo ? "CPO" : "Not CPO") : "") + "?"}
                showConfirmation={this.props.showCPOConfirmation}
                onOkClick={this.props.onOkCPOChange}
                onCancelClick={this.props.onCancelCPOClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"OK to Publish/Do Not Publish"}
                bodyText={((this.props.publishRow !== null && !this.props.publishRow.ok_publish) ? "OK to publish vehicle " : "Do not publish vehicle ") + (this.props.publishRow !== null ? this.props.publishRow.stock_number : "") + "?"}
                showConfirmation={this.props.showPublishConfirmation}
                onOkClick={this.props.onOkPublishChange}
                onCancelClick={this.props.onCancelPublishClick}
            />

            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.props.tableTitle} tableData={this.props.tableData} paintTable={this.props.paintTable} legendText={this.props.legendText} cssName="dgdg-used-reconditioning-table" tablePageSize={CommonService.tablePageSize} stickyHeader
                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 22, 7, 8, 9, 10, 11, 12, 13, 14, 23, 15, 24, 16, 25, 17, 26, 18, 27, 19, 28], onCopyTableCallBack: this.props.onCopyTableCallBack }}
            >
                <DGDGTableRowComponent cssName="dgdg-header-top">
                    <DGDGTableColumnComponent colSpan={this.props.selectedStore && this.props.selectedStore.store_number !== 11 ? "15" : "16"} />
                    <DGDGTableColumnComponent dataColumnCallback={this.props.onServiceOutCarousalCallback} />
                    <DGDGTableColumnComponent dataColumnCallback={this.props.onBodyOutCarousalCallback} />
                    <DGDGTableColumnComponent dataColumnCallback={this.props.onDetailOutCarousalCallback} />
                    <DGDGTableColumnComponent dataColumnCallback={this.props.onReconOutCarousalCallback} />
                    <DGDGTableColumnComponent dataColumnCallback={this.props.onPhotoOutCarousalCallback} />
                    <DGDGTableColumnComponent />
                    <DGDGTableColumnComponent isHidden={this.props.selectedStore ? this.props.selectedStore.store_number !== 11 : true} />
                </DGDGTableRowComponent>
                <DGDGTableRowComponent hasHeader>
                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                    <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                    {/* CPO Link --- <DGDGTableColumnComponent headerText="Vin" dataColumnCallback={this.vinColumnCallback} dataColumn="vin" sortColumn="vin" filterColumn="vin" /> */}
                    <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                    <DGDGTableColumnComponent headerText="RO Number" dataColumn="recon_ro_number" sortColumn="recon_ro_number" filterColumn="recon_ro_number" dataColumnCallback={this.props.roNumberColumnCallback} />
                    <DGDGTableColumnCheckboxV2Component headerText="Publish" dataColumn="ok_publish" labelColumn="ok_publish_label" label2Column="ok_publish_label2" disabledColumn="disable_publish" sortColumn="ok_publish" dataType="bool" cssName="dgdg-used-reconditioning-publish" onChange={this.props.onPublishSelectChange} showSpinner />
                    <DGDGTableColumnCheckboxV2Component headerText="Retail / Wholesale" dataColumn="is_wholesale" labelColumn="wholesale_label" label2Column="wholesale_label2" disabledColumn="disable_wholesale" sortColumn="is_wholesale" dataType="bool" onChange={this.props.onWholesaleSelectChange} cssName="dgdg-used-reconditioning-wholesale" showSpinner />
                    <DGDGTableColumnCheckboxV2Component headerText="CPO" dataColumn="is_cpo" labelColumn="cpo_label" label2Column="cpo_label2" disabledColumn="disable_cpo" sortColumn="is_cpo" dataType="bool" onChange={this.props.onCPOSelectChange} cssName="dgdg-used-reconditioning-cpo" showSpinner />
                    <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                    <DGDGTableColumnComponent headerText="Color" dataColumn="color" sortColumn="color" filterColumn="color" />
                    <DGDGTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" />
                    <DGDGTableColumnComponent headerText="Exterior Color" dataColumn="ext_color" sortColumn="ext_color" filterColumn="ext_color" />
                    <DGDGTableColumnComponent headerText="Certified" dataColumn="certified" sortColumn="certified" filterColumn="certified" dataType="bool" />
                    <DGDGTableColumnComponent headerText="Age" dataColumn="age" sortColumn="age" filterColumn="age" dataType="number" />
                    <DGDGTableColumnComponent headerText="Entry Date" dataColumn="entry_date" sortColumn="entry_date" filterColumn="entry_date" dataType="date" />
                    <DGDGTableColumnComponent headerText="Estimated Date of Completion" dataColumnCallback={this.props.onEstCompletionDateColumnCallback} dataColumn="est_completion_date" filterColumn="est_completion_date" sortColumn="est_completion_date" showSpinner />
                    <DGDGTableColumnComponent headerText={"Service Out <br />(" + this.props.serviceActiveCount + " Active)"} dataColumnCallback={this.props.onServiceOutColumnCallback} filterColumn="service_hold_metric_name_sort" dataColumn="service_out_date_time" cssName="align-top" headerCssName="dgdg-used-reconditioning-column" rowSpan="2" showSpinner
                        sortColumn={[
                            { displayName: "Age", column: "sort_service_out", firstSortOrder: "Desc" },
                            { displayName: "Hold", column: "service_hold_metric_name_sort", firstSortOrder: "Desc" }
                        ]}
                    />
                    <DGDGTableColumnComponent headerText={"Body Out<br />(" + this.props.bodyActiveCount + " Active)"} dataColumnCallback={this.props.onBodyOutColumnCallback} dataColumn="body_out_date_time" sortColumn="sort_body_out" cssName="align-top" headerCssName="dgdg-used-reconditioning-column" rowSpan="2" showSpinner firstSortOrder="Desc" />
                    <DGDGTableColumnComponent headerText={"Detail Out <br />(" + (this.props.selectedStore && this.props.selectedStore.store_number === 11 ? this.props.paintActiveCount + " Paint, " : "") + this.props.detailActiveCount + " Active)"} dataColumnCallback={this.props.onDetailOutColumnCallback} dataColumn="detail_out_date_time" sortColumn="sort_detail_out" cssName="align-top" headerCssName="dgdg-used-reconditioning-column" rowSpan="2" showSpinner firstSortOrder="Desc" />
                    <DGDGTableColumnComponent headerText={"Recon Out <br />(" + this.props.reconActiveCount + " Active)"} dataColumnCallback={this.props.onReconOutColumnCallback} dataColumn="recon_out_date_time" sortColumn="sort_recon_out" cssName="align-top" headerCssName="dgdg-used-reconditioning-column" rowSpan="2" showSpinner firstSortOrder="Desc" />
                    <DGDGTableColumnComponent headerText={"Photo Out <br />(" + + this.props.photoActiveCount + " Active)"} dataColumnCallback={this.props.onPhotoOutColumnCallback} dataColumn="photo_out_date_time" sortColumn="sort_photo_out" cssName="align-top" headerCssName="dgdg-used-reconditioning-column" rowSpan="2" showSpinner firstSortOrder="Desc" />
                    <DGDGTableColumnComponent headerText="Photo Status" dataColumn="photo_status" sortColumn="photo_status" filterColumn="photo_status" cssName="align-top" rowSpan="2" firstSortOrder="Desc" />
                    <DGDGTableColumnComponent headerText="Store #" dataColumn="store_number" isHidden />
                    <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name" isHidden />
                    <DGDGTableColumnComponent headerText="Service Out By" dataColumn="service_out_by" isHidden />
                    <DGDGTableColumnComponent headerText="Body Out By" dataColumn="body_out_by" isHidden />
                    <DGDGTableColumnComponent headerText="Detail Out By" dataColumn="detail_out_by" isHidden />
                    <DGDGTableColumnComponent headerText="Recon Out By" dataColumn="recon_out_by" isHidden />
                    <DGDGTableColumnComponent headerText="Photo Out By" dataColumn="photo_out_by" isHidden />
                </DGDGTableRowComponent>
                <DGDGTableRowComponent>
                    <DGDGTableColumnComponent colSpan={this.props.selectedStore && this.props.selectedStore.store_number !== 11 ? "15" : "16"} dataColumnCallback={this.props.onAddCommentColumnCallback} />
                </DGDGTableRowComponent>
            </DGDGTableV3Component>
        </div>;
    }
}

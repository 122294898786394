import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { ServiceStoreReportMonthComponent } from "./ServiceStoreReportMonthComponent";
import { ServiceStoreReportDayComponent } from "./ServiceStoreReportDayComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class ServiceStoreReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ServiceStoreReportRouteComponent - componentDidMount");
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("ServiceStoreReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service Store Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "ServiceStoreReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/ServiceStoreReport/Month" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Month") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Month</Link>
                    <Link to="/ServiceStoreReport/Day" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Day") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Day</Link>
                    <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}</span>
                    </div>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-service-store-report-content">
                    <Switch>
                        <Route exact path="/ServiceStoreReport/Month" render={(routeProps) => <ServiceStoreReportMonthComponent {...this.props} {...routeProps} months={this.state.months} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/ServiceStoreReport/Day" render={(routeProps) => <ServiceStoreReportDayComponent {...this.props} {...routeProps} onShowAlert={this.props.onShowAlert} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}

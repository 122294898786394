import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class CacheRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            isLoadingData: false,
        };

        this.onRedisCacheTextChange = this.onRedisCacheTextChange.bind(this);
        this.onRedisCacheExpireClick = this.onRedisCacheExpireClick.bind(this);
        this.onRedisCacheGetClick = this.onRedisCacheGetClick.bind(this);
        this.onRedisValueColumnCallback = this.onRedisValueColumnCallback.bind(this);
        this.onRedisActionColumnCallback = this.onRedisActionColumnCallback.bind(this);

        this.onMemoryCacheTextChange = this.onMemoryCacheTextChange.bind(this);
        this.onMemoryCacheExpireClick = this.onMemoryCacheExpireClick.bind(this);
        this.onMemoryCacheGetClick = this.onMemoryCacheGetClick.bind(this);
        this.onMemoryValueColumnCallback = this.onMemoryValueColumnCallback.bind(this);
        this.onMemoryActionColumnCallback = this.onMemoryActionColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CacheRouteComponent - componentDidMount");
            this.onGetCache();
            CommonService.clientAzureStorageLog("CacheRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError("Eorror Error" + error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Cache Management";

    onGetCache() {
        try {
            this.setState({ isLoadingData: true });
            fetch("/Cache/GetCache")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse)
                        this.setState({
                            tableData: CommonService.addOrdinalColumn(parsedResponse),
                            paintTable: true,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    else {
                        this.setState({
                            tableData: [],
                            paintTable: true,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: [],
                        paintTable: true,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Cache/GetCache", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRedisValueColumnCallback(rowData) {
        return <DGDGFormTextAreaComponent id={rowData.redis_key} value={rowData.redis_value} onChange={this.onRedisCacheTextChange} rows="3" cssName="dgdg-client-cache-value" />;
    }

    onRedisActionColumnCallback(rowData) {
        return <Fragment>
            <button disabled={this.state.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onRedisCacheExpireClick(event, rowData)}>
                Expire <span className="fas fa-minus-circle dgdg-icon" />
            </button>
            <button disabled={this.state.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onRedisCacheGetClick(event, rowData)}>
                Get <i className="fas fa-redo-alt dgdg-icon" />
            </button>
        </Fragment>;
    }

    onRedisCacheExpireClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.redis_showSpinner_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = JSON.stringify({
                key: rowData.redis_key
            });

            fetch("/Cache/ExpireRedisCache", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.redis_value = "";
                    rowData.redis_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.redis_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Cache/ExpireRedisCache", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRedisCacheGetClick(event, rowData) {
        let tableData = this.state.tableData;
        rowData.redis_showSpinner_showSpinner = true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            this.onGetCache();
        });
    }

    onRedisCacheTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter(redisCache => redisCache.redis_key === id)[0];
            rowData.redis_value = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMemoryValueColumnCallback(rowData) {
        return <DGDGFormTextAreaComponent id={rowData.memory_key} value={rowData.memory_value} onChange={this.onMemoryCacheTextChange} rows="3" cssName="dgdg-client-cache-value" />;
    }

    onMemoryActionColumnCallback(rowData) {
        return <Fragment>
            <button disabled={this.state.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onMemoryCacheExpireClick(event, rowData)}>
                Expire <span className="fas fa-minus-circle dgdg-icon" />
            </button>
            <button disabled={this.state.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onMemoryCacheGetClick(event, rowData)}>
                Get <i className="fas fa-redo-alt dgdg-icon" />
            </button>
        </Fragment>;
    }

    onMemoryCacheExpireClick(event, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.memory_showSpinner_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = JSON.stringify({
                key: rowData.memory_key
            });

            fetch("/Cache/ExpireMemoryCache", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.memory_value = "";
                    rowData.memory_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.memory_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Cache/ExpireMemoryCache", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMemoryCacheGetClick(event, rowData) {
        let tableData = this.state.tableData;
        rowData.memory_showSpinner_showSpinner = true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            this.onGetCache();
        });
    }

    onMemoryCacheTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter(memoryCache => memoryCache.memory_key === id)[0];
            rowData.memory_value = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Redis and Memory Cache" tableData={this.state.tableData} paintTable={this.state.paintTable} stickyHeader>
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td className="dgdg-border-right" />
                                <td colSpan="4" className="dgdg-cache-head-redis-color text-center font-weight-bold  dgdg-border-right">Redis Cache</td>
                                <td colSpan="4" className="dgdg-cache-head-memory-color text-center font-weight-bold ">Memory Cache</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Key" dataColumn="redis_key" sortColumn="redis_key" filterColumn="redis_key" headerCssName="dgdg-client-cache-key dgdg-border-right" cssName="dgdg-border-right" />
                            <DGDGTableColumnComponent headerText="TTL (Seconds)" dataColumn="redis_ttl" sortColumn="redis_ttl" filterColumn="redis_ttl" cssName="text-right" headerCssName="dgdg-client-cache-key" />
                            <DGDGTableColumnComponent headerText="Expiration Time (PST)" dataColumn="redis_exp_time" sortColumn="redis_exp_time" filterColumn="redis_exp_time" headerCssName="dgdg-client-cache-key" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Value" dataColumnCallback={this.onRedisValueColumnCallback} headerCssName="dgdg-client-cache-value" />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onRedisActionColumnCallback} dataColumn="redis_showSpinner" headerCssName=" dgdg-border-right" cssName="text-nowrap dgdg-border-right" showSpinner />
                            <DGDGTableColumnComponent headerText="TTL (Seconds)" dataColumn="memory_ttl" sortColumn="memory_ttl" filterColumn="ttl" cssName="text-right" headerCssName="dgdg-client-cache-key" />
                            <DGDGTableColumnComponent headerText="Expiration Time (PST)" dataColumn="memory_exp_time" sortColumn="memory_exp_time" filterColumn="exp_time" headerCssName="dgdg-client-cache-key" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Value" dataColumnCallback={this.onMemoryValueColumnCallback} headerCssName="dgdg-client-cache-value" />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onMemoryActionColumnCallback} dataColumn="memory_showSpinner" showSpinner cssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { StoreQueryComponent } from "./StoreQueryComponent";
import { StoreContactMapComponent } from "./StoreContactMapComponent";

export class StoreRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            lastUpdatedDate: null,
            selectedContact: null,
            employeeSupervisorList: []
        };

        this.onRefreshClick = this.onRefreshClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onMapGMEmailListClick = this.onMapGMEmailListClick.bind(this);
        this.onMapSalesEmailListClick = this.onMapSalesEmailListClick.bind(this);
        this.onMapFIEmailListClick = this.onMapFIEmailListClick.bind(this);
        this.onMapServiceEmailListClick = this.onMapServiceEmailListClick.bind(this);
        this.onMapPartsEmailListClick = this.onMapPartsEmailListClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onMapGMEmailListCallBack = this.onMapGMEmailListCallBack.bind(this);
        this.onMapSalesEMailListCallBack = this.onMapSalesEMailListCallBack.bind(this);
        this.onMapFIEmailListCallBack = this.onMapFIEmailListCallBack.bind(this);
        this.onMapServiceEmailListCallBack = this.onMapServiceEmailListCallBack.bind(this);
        this.onMapPartsEmailListCallBack = this.onMapPartsEmailListCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("StoreRouteComponent - componentDidMount");
            this.getEmployeeSupervisor();
            CommonService.clientAzureStorageLog("StoreRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Store";

    getEmployeeSupervisor() {
        this.props.applicationInsights.trackTrace({ message: "/StoreRouteComponent/getEmployeeSupervisor", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Store/GetEmployeeSupervisor")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ employeeSupervisorList: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Store/GetEmployeeSupervisor", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onRefreshClick() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReport/onRefreshClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Store/GetStoreContacts")
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatMonthYear(moment()),
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Store/GetStoreContacts", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("StoreRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapService1Click(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }
            rowData.employee_type_title = "Service Director 1";
            rowData.employee_name = rowData.service_1_emp_number ? rowData.service_1_emp_first + " " + rowData.service_1_emp_last : "";
            rowData.employee_type = "service_1_emp_number";
            rowData.email_id = null;
            rowData.email_type = null;
            rowData.email_type_title = null;
            this.setState({ selectedContact: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapGMEmailListClick(event, rowData) {
        try {

            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            rowData.email_type_title = "GM Email List";
            rowData.email_type = "gm_email_list";
            rowData.email_id = rowData.gm_email_list;
            rowData.employee_name = null;
            rowData.employee_name = null;
            rowData.employee_type_title = null;
            this.setState({
                selectedContact: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapSalesEmailListClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            rowData.email_type_title = "Sales Email List";
            rowData.email_type = "sales_email_list";
            rowData.email_id = rowData.sales_email_list;
            rowData.employee_name = null;
            rowData.employee_name = null;
            rowData.employee_type_title = null;
            this.setState({
                selectedContact: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapFIEmailListClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            rowData.email_type_title = "FI Email List";
            rowData.email_type = "fi_email_list";
            rowData.email_id = rowData.fi_email_list;
            rowData.employee_name = null;
            rowData.employee_name = null;
            rowData.employee_type_title = null;
            this.setState({
                selectedContact: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapServiceEmailListClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            rowData.email_type_title = "Service Email List";
            rowData.email_type = "service_email_list";
            rowData.email_id = rowData.service_email_list;
            rowData.employee_name = null;
            rowData.employee_name = null;
            rowData.employee_type_title = null;
            this.setState({
                selectedContact: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapPartsEmailListClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            rowData.email_type_title = "Parts Email List";
            rowData.email_type = "parts_email_list";
            rowData.email_id = rowData.parts_email_list;
            rowData.employee_name = null;
            rowData.employee_name = null;
            rowData.employee_type_title = null;
            this.setState({
                selectedContact: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, inputData) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReport/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Store/SaveStoreContact", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        selectedEmployeSp: null,
                        employeeName: ""
                    });
                    this.onRefreshClick();
                    this.onCancelClick(event);
                    this.setState({ showSpinner: false });
                })
                .catch(notOKResponse => {
                    this.onCancelClick(event);
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Store/SaveStoreContact", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedContact: null,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMapGMEmailListCallBack(rowData) {
        return rowData.gm_email_list ? rowData.gm_email_list : "Add Email";
    }

    onMapSalesEMailListCallBack(rowData) {
        return rowData.sales_email_list ? rowData.sales_email_list : "Add Email";
    }

    onMapFIEmailListCallBack(rowData) {
        return rowData.fi_email_list ? rowData.fi_email_list : "Add Email";
    }

    onMapServiceEmailListCallBack(rowData) {
        return rowData.service_email_list ? rowData.service_email_list : "Add Email";
    }

    onMapPartsEmailListCallBack(rowData) {
        return rowData.parts_email_list ? rowData.parts_email_list : "Add Email";
    }

    render() {
        return <DGDGPageComponent>
            <StoreContactMapComponent
                applicationInsights={this.props.applicationInsights}
                store={this.state.selectedContact}
                employeeSupervisorList={this.state.employeeSupervisorList}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelClick}
            />
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <StoreQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData}
                    onRefreshClick={this.onRefreshClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Store Contact"} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="display_name" sortColumn="store_number" filterColumn="display_name" />
                            <DGDGTableColumnComponent headerText="CDK Code" dataColumn="cdk_code" sortColumn="cdk_code" filterColumn="cdk_code" />
                            <DGDGTableColumnComponent headerText="Paylocity Number" dataColumn="paylocity_number" sortColumn="paylocity_number" filterColumn="paylocity_number" />
                            <DGDGTableColumnComponent headerText="VIN Solutions Number" dataColumn="vin_solutions_number" sortColumn="vin_solutions_number" filterColumn="vin_solutions_number" />
                            <DGDGTableColumnComponent headerText="GM Email List" dataColumn="gm_email_list" sortColumn="gm_email_list" filterColumn="gm_email_list" dataColumnCallback={this.onMapGMEmailListCallBack} onCellClick={this.onMapGMEmailListClick} />
                            <DGDGTableColumnComponent headerText="Sales Email List" dataColumn="sales_email_list" sortColumn="sales_email_list" filterColumn="sales_email_list" dataColumnCallback={this.onMapSalesEMailListCallBack} onCellClick={this.onMapSalesEmailListClick} />
                            <DGDGTableColumnComponent headerText="FI Email List" dataColumn="fi_email_list" sortColumn="fi_email_list" filterColumn="fi_email_list" dataColumnCallback={this.onMapFIEmailListCallBack} onCellClick={this.onMapFIEmailListClick} />
                            <DGDGTableColumnComponent headerText="Service Email List" dataColumn="service_email_list" sortColumn="service_email_list" filterColumn="service_email_list" dataColumnCallback={this.onMapServiceEmailListCallBack} onCellClick={this.onMapServiceEmailListClick} />
                            <DGDGTableColumnComponent headerText="Parts Email List" dataColumn="parts_email_list" sortColumn="parts_email_list" filterColumn="parts_email_list" dataColumnCallback={this.onMapPartsEmailListCallBack} onCellClick={this.onMapPartsEmailListClick} />
                            <DGDGTableColumnComponent headerText="Store Number" dataColumn="store_number" isHidden />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name" isHidden />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { OrgChartDGDGQueryComponent } from "./OrgChartDGDGQueryComponent";

export class OrgChartDGDGComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            tableTitle: "",
            dgdgColumn1Tree: [],
            dgdgColumn2Tree: [],
            dgdgColumn3Tree: [],
            dgdgColumn4Tree: [],
            dgdgColumn5Tree: [],
            dgdgGroupTree: []
        };

        this.onGetChartClick = this.onGetChartClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("OrgChartDGDGComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/OrgChart/DGDG", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("OrgChartDGDGComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetChartClick(selectedMonth) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                chartDate: CommonService.formatServerDateTime(selectedMonth)
            };

            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/OrgChartDGDGComponent/onGetChartClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OrgChart/GetChart", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: parsedResponse.table.filter((rowData) => rowData.store_number === 99),
                        tableTitle: "Org Chart - DGDG - " + CommonService.formatMonthYear(moment(selectedMonth))
                    }, this.getDGDGData);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: ""
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/GetChart", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getDGDGData() {
        this.dgdgGroupCount = [];
        this.totalCount = 0;
        if (this.state.tableData !== null) {
            let dgdgTreeColumn1 = this.getDGDGTreeColumn1();
            let dgdgTreeColumn2 = this.getDGDGTreeColumn2();
            let dgdgTreeColumn3 = this.getDGDGTreeColumn3();
            let dgdgTreeColumn4 = this.getDGDGTreeColumn4();
            let dgdgTreeColumn5 = this.getDGDGTreeColumn5();

            this.formdgdgColumnTree(dgdgTreeColumn1, "dgdgColumn1Tree")
            this.formdgdgColumnTree(dgdgTreeColumn2, "dgdgColumn2Tree")
            this.formdgdgColumnTree(dgdgTreeColumn3, "dgdgColumn3Tree")
            this.formdgdgColumnTree(dgdgTreeColumn4, "dgdgColumn4Tree")
            this.formdgdgColumnTree(dgdgTreeColumn5, "dgdgColumn5Tree")
            this.formDGDGGroupTree();
        }
    }

    formdgdgColumnTree(dgdgTreeColumn, dgdgTree) {
        let tableRows = [];
        for (var i = 0; i < dgdgTreeColumn.length; i++) {
            tableRows.push(<tr className="text-center" key={"dgdgTreeRow" + i}>
                {
                    (dgdgTreeColumn.length > i && dgdgTreeColumn[i] !== null && dgdgTreeColumn[i].length === 1)
                        ? <td colSpan="2"> {dgdgTreeColumn[i][0]} </td>
                        : (dgdgTreeColumn.length > i && dgdgTreeColumn[i] !== null && dgdgTreeColumn[i].length === 2)
                            ? <Fragment>
                                <td className="dgdg-org-chart-table-header">Name</td>
                                <td className="dgdg-org-chart-table-header">Title/Position</td>
                            </Fragment>
                            : (dgdgTreeColumn.length > i && dgdgTreeColumn[i] !== null && dgdgTreeColumn[i].length === 3)
                                ? <Fragment>
                                    <td className={dgdgTreeColumn[i][2]}>
                                        {
                                            dgdgTreeColumn[i][0]
                                        }
                                    </td>
                                    <td className={dgdgTreeColumn[i][2]}>
                                        {
                                            dgdgTreeColumn[i][1]
                                        }
                                    </td>
                                </Fragment>
                                : <td colSpan="2" className="dgdg-no-border"></td>
                }
                <td className="dgdg-no-border"></td>
            </tr>);
        }
        this.setState({ [dgdgTree]: tableRows });
    }

    formDGDGGroupTree() {
        let tableRows = [];
        let dgdgGroupCount = this.dgdgGroupCount;
        for (var i = 0; i < dgdgGroupCount.length; i++) {
            tableRows.push(<tr className="text-center" key={"dgdgGroupRow" + i}>
                {
                    (i === dgdgGroupCount.length - 1)
                        ? <Fragment>
                            <td className="text-left dgdg-no-border-right dgdg-border-dotted-top  dgdg-border-thick-left dgdg-border-thick-bottom">
                                {
                                    dgdgGroupCount[i][0]
                                }
                            </td>
                            <td className="dgdg-no-border-left dgdg-border-dotted-top  dgdg-border-thick-right dgdg-border-thick-bottom">
                                {
                                    dgdgGroupCount[i][1]
                                }
                            </td>
                        </Fragment>
                        : <Fragment>
                            <td className="text-left dgdg-no-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom dgdg-border-thick-left">
                                {
                                    dgdgGroupCount[i][0]
                                }
                            </td>
                            <td className="dgdg-no-border-left dgdg-border-dotted-top dgdg-border-dotted-bottom dgdg-border-thick-right">
                                {
                                    dgdgGroupCount[i][1]
                                }
                            </td>
                        </Fragment>
                }
            </tr>);
        }

        this.setState({
            dgdgGroupTree: tableRows
        });
    }

    getDGDGTreeColumn1() {
        let positionCodeRows = null;
        let dataList = [];

        dataList.push(["Owners"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Owners");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Owners", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Leadership/Directors"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Group Mgmt");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Leadership", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["HR"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("HR Department");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["HR", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Business Manager"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Business Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Business Manager", positionCodeRows.length]);

        return dataList;
    }

    getDGDGTreeColumn2() {
        let positionCodeRows = null;
        let dataList = [];

        dataList.push(["Business Office"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Business Office");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Business Office", positionCodeRows.length]);

        return dataList;
    }

    getDGDGTreeColumn3() {
        let positionCodeRows = null;
        let dataList = [];

        dataList.push(["Acquisition Team"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Acquisition Team");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Acquisition Team", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["VIP Sales Team"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("VIP Sales");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["VIP Sales Team", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Training Team"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Training");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Training Team", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["BTP Team"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("BTP");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["BTP Team", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Support"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Support");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Support", positionCodeRows.length]);

        return dataList;
    }

    getDGDGTreeColumn4() {
        let positionCodeRows = null;
        let dataList = [];

        dataList.push(["Reconditioning"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Dent & Ding");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Reconditioning", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Facilites Team"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Facilities");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["Facilites", positionCodeRows.length]);

        return dataList;
    }

    getDGDGTreeColumn5() {
        let positionCodeRows = null;
        let dataList = [];

        dataList.push(["E-Commerce"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("E-Suite");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgGroupCount.push(["E-Commerce", positionCodeRows.length]);
        dataList.push(null);

        dataList.push(["Dealer Trade Drivers"]);
        dataList.push(["Name", "Title/Position"]);
        positionCodeRows = this.filterData("Dealer Trade Drivers");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.job_title ? CommonService.toTitleCase(rowData.job_title) : "",
                positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        this.totalCount += positionCodeRows.length;
        this.dgdgEmployeesCount = this.totalCount - this.state.tableData.length
        this.dgdgGroupCount.push(["Dealer Trade Drivers", positionCodeRows.length]);
        return dataList;
    }

    filterData(positionCode) {
        return this.state.tableData.filter(row => row.position_key === "99-" + positionCode);
    }

    pageTitle = "Org Chart";
    dgdgGroupCount = [];
    totalCount = 0;
    dgdgEmployeesCount = 0;

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <OrgChartDGDGQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                isDownLoading={this.props.isDownLoading}
                stores={this.props.stores}
                months={this.props.months}
                onGetChartClick={this.onGetChartClick}
                onDownloadClick={this.props.onDownloadClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-header">{this.state.tableTitle}</div>
                    <div className="card-block">
                        <table style={{ "width": "100%" }}>
                            <tbody>
                                <tr>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                {this.state.dgdgColumn1Tree}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border">&nbsp;</td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                {this.state.dgdgColumn2Tree}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border">&nbsp;</td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                {this.state.dgdgColumn3Tree}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border">&nbsp;</td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                {this.state.dgdgColumn4Tree}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border">&nbsp;</td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                {this.state.dgdgColumn5Tree}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border">&nbsp;</td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table" style={{ "width": "100%" }}>
                                            <tbody>
                                                <tr className="text-center" key="dgdgGroupHeaderRow">
                                                    <td className="text-left dgdg-border-thick-left  dgdg-org-chart-table-header"> Group </td>
                                                    <td className="dgdg-border-thick-right dgdg-org-chart-table-header">Team Members </td>
                                                </tr>
                                                {this.state.dgdgGroupTree}
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border">&nbsp;</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td className="text-right dgdg-no-border">Totals</td>
                                                    <td className="dgdg-no-border">{this.totalCount}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td className="text-right dgdg-no-border"></td>
                                                    <td className="dgdg-no-border">{this.dgdgEmployeesCount ? this.dgdgEmployeesCount : ""}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }
}

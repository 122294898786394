import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class InterCompanyBalancesQueryComponent extends React.Component {
    static StoreNumberCookie = "InterCompanyBalancesStoreNumberCookie";
    static MonthCookie = "InterCompanyBalancesMonthCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog(" InterCompanyBalancesQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            selectedMonth: null,
            showSpinner: false
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGenerateInterCompanyBalancesClick = this.onGenerateInterCompanyBalancesClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null && this.props.months && this.state.selectedMonth === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(InterCompanyBalancesQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                let selectedMonth = this.props.months[0];
                let cookieMonth = CommonService.getCookie(InterCompanyBalancesQueryComponent.MonthCookie);
                if (cookieMonth) {
                    let filterMonth = this.props.months.filter((month) => month.isSame(cookieMonth, 'date'));
                    if (filterMonth.length === 1) {
                        selectedMonth = filterMonth[0];
                    }
                }

                this.setState({
                    selectedStore: selectedStore,
                    selectedMonth: selectedMonth
                }, this.onGenerateInterCompanyBalancesClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(id, month) {
        try {
            this.setState({
                selectedMonth: month
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGenerateInterCompanyBalancesClick(event) {
        try {
            if (this.state.selectedStore !== null && this.state.selectedMonth !== null) {
                this.setState({ showSpinner: true });
                CommonService.setCookie(InterCompanyBalancesQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
                CommonService.setCookie(InterCompanyBalancesQueryComponent.MonthCookie, this.state.selectedMonth.format("MM/DD/YYYY"), 30);
                this.props.onGenerateInterCompanyBalancesClick(this.state.selectedStore, this.state.selectedMonth);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGenerateInterCompanyBalancesClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Month" displayFormat="monthYear" data={this.props.months} value={this.state.selectedMonth ?? ""} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.selectedMonth === null || this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGenerateInterCompanyBalancesClick(event)}>
                        Generate Inter-company Balances  <i className="fas fa-code-merge dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";
import { DGDGSpinnerComponent } from "../dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableFilterComponent } from "./DGDGTableFilterComponent";
import { DGDGTableRowComponent } from "./DGDGTableRowComponent";
import { DGDGTableHeaderComponent } from "./DGDGTableHeaderComponent";
import { DGDGTableColumnSparklineComponent } from "./DGDGTableColumnSparklineComponent";
import { DGDGTableCustomComponent } from "./DGDGTableCustomComponent";

export class DGDGTableV2Component extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.tableData !== state.prevTableData) {
                newState.tableData = props.tableData;
                newState.prevTableData = props.tableData;
                newState.maxRows = props.tablePageSize;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            tableData: props.tableData,
            prevTableData: props.tableData,
            showFilter: false,
            showCancelFilter: false,
            sortColumn: this.props.defaultSortColumn,
            sortOrder: this.props.defaultSortOrder
        };

        this.onSortClick = this.onSortClick.bind(this);
        this.onShowFilterClick = this.onShowFilterClick.bind(this);
        this.onHideFilterClick = this.onHideFilterClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onCancelFilterClick = this.onCancelFilterClick.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.onDocumentClick);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;

        if (nextProps.paintTable === undefined) {
            shouldUpdate = true;
        }
        // Force repaint on filter
        else {
            if (this.state.showFilter !== nextState.showFilter
                || this.state.showCancelFilter !== nextState.showCancelFilter) {
                shouldUpdate = true;
            }
            // Force repaint on sort
            else if (this.state.sortColumn !== nextState.sortColumn
                || this.state.sortOrder !== nextState.sortOrder) {
                shouldUpdate = true;
            }
            else if (nextProps.paintTable !== undefined) {
                shouldUpdate = nextProps.paintTable;
            }
        }

        return shouldUpdate;
    }

    componentDidUpdate(prevProps, prevState) {
        let rowComponent = null;
        if (Array.isArray(this.props.children)) {
            rowComponent = this.props.children.filter(item => item.type === DGDGTableRowComponent)[0];
        }
        else {
            rowComponent = this.props.children;
        }

        rowComponent.props.children.forEach((columnChild, columnIndex) => {
            if (columnChild.type === DGDGTableColumnSparklineComponent) {
                if (this.state.tableData) {
                    this.state.tableData.forEach((rowData, rowIndex) => {
                        let values = [];
                        if (columnChild.props.valueFields) {
                            columnChild.props.valueFields.forEach((valueField, index) => {
                                values.push(rowData[valueField]);
                            });
                        }

                        let sparkLineId = "sparkline_" + rowIndex + "_" + columnIndex;
                        $(ReactDOM.findDOMNode(this[sparkLineId])).sparkline(values, columnChild.props.options);
                    });
                }
            }
        });
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.onDocumentClick);
    }

    renderColumns(rowChild, rowData, rowIndex) {
        let columns = [];
        rowChild.props.children.forEach((dgdgColumn, columnIndex) => {
            let cssName = "";
            let cellStyle = {};
            if (dgdgColumn.type === DGDGTableColumnSparklineComponent) {
                cssName = this.getColumnCss(dgdgColumn.props);
                columns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle}>
                    <div ref={"sparkline_" + rowIndex + "_" + columnIndex} />
                </td>);
            }
            else {
                let data = "";

                // Get the cell data
                if (dgdgColumn.props.dataText) {
                    data = <span dangerouslySetInnerHTML={{ __html: dgdgColumn.props.dataText }} />;
                }
                else {
                    data = dgdgColumn.props.dataColumnCallback
                        ? dgdgColumn.props.dataColumnCallback(rowData)
                        : rowData[dgdgColumn.props.dataColumn];
                }

                // Apply cell formatting
                if (dgdgColumn.props.dataType && !dgdgColumn.props.dataColumnCallback) {
                    data = this.formatData(dgdgColumn.props.dataType, data);
                }

                if (dgdgColumn.props.ofDataColumn) {
                    data = data + " (" + this.formatData("percent", rowData[dgdgColumn.props.ofDataColumn]) + ")";
                }

                cssName = this.getColumnCss(dgdgColumn.props);
                if (dgdgColumn.props.backgroundColorPct) {
                    cellStyle.background = "linear-gradient(to right, " + rowData[dgdgColumn.props.backgroundColor] + " " + rowData[dgdgColumn.props.backgroundColorPct] + "%, transparent " + rowData[dgdgColumn.props.backgroundColorPct] + "%)";
                }
                else if (dgdgColumn.props.backgroundColor) {
                    cellStyle.backgroundColor = rowData[dgdgColumn.props.backgroundColor];
                }

                if (dgdgColumn.props.color) {
                    cellStyle.color = rowData[dgdgColumn.props.color];
                }

                columns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle}>
                    {
                        dgdgColumn.props.dataType === "anchor"
                            ? <a href={rowData[dgdgColumn.props.href]}>{data}</a>
                            : dgdgColumn.props.onCellClick
                                ? <button type="button" className="btn btn-link" disabled={rowData[dgdgColumn.props.disabledColumn]} onClick={(event) => dgdgColumn.props.onCellClick(event, rowData, dgdgColumn.props.cellData)}>{data}</button>
                                : data
                    }
                </td>);
            }
        });

        return columns;
    }

    formatData(dataType, data) {
        if (data === undefined || data === null) {
            return "";
        }

        switch (dataType) {
            case "number":
                data = Math.round(data);
                break;

            case "number_1":
                data = (Math.ceil(data * 10) / 10).toFixed(1);
                break;

            case "number_2":
                data = (Math.ceil(data * 100) / 100).toFixed(2);
                break;

            case "number_3":
                data = (Math.ceil(data * 1000) / 1000).toFixed(3);
                break;

            case "date":
                data = CommonService.formatDate(moment(data));
                break;

            case "dateTime":
                data = CommonService.formatDateTime(moment(data));
                break;

            case "shortMonth":
                data = CommonService.formatMonth(moment().date(1).month(data - 1).year(2017));
                break;

            case "time":
                data = CommonService.formatTime(moment(data));
                break;

            case "bool":
                data = CommonService.formatBoolString(data);
                break;

            case "money":
                data = CommonService.formatCurrency(data);
                break;

            case "signed_money":
                data = CommonService.formatSignedCurrency(data);
                break;

            case "money_2":
                data = CommonService.formatCurrency_2(data);
                break;

            case "money_2_simple":
                data = CommonService.formatCurrency_2_simple(data);
                break;

            case "accounting_amount":
                data = CommonService.formatAccountingAmount(data);
                break;

            case "accounting_amount_simple":
                data = CommonService.formatAccountingAmount_simple(data);
                break;

            case "percent":
                data = CommonService.formatPercentage(data);
                break;

            case "signed_percent":
                data = CommonService.formatSignedPercentage(data);
                break;

            case "percent_1":
                data = CommonService.formatPercentage_1(data);
                break;

            case "percent_2":
                data = CommonService.formatPercentage_2(data);
                break;

            case "formatted_number":
                data = CommonService.formatNumber(data);
                break;

            case "formatted_number_2":
                data = CommonService.formatNumber_2(data);
                break;

            case "html":
                data = <span dangerouslySetInnerHTML={{ __html: data }} />
                break;

            case "rate":
                data = CommonService.formatRate(data);
                break;

            default:
                break;
        }

        return data;
    }

    getColumnCss(dgdgColumnProps) {
        let cssName = dgdgColumnProps.cssName ? dgdgColumnProps.cssName : "";
        if (!dgdgColumnProps.isEditable && (dgdgColumnProps.dataType === "rate" || dgdgColumnProps.dataType === "number" || dgdgColumnProps.dataType === "number_1" || dgdgColumnProps.dataType === "number_2" || dgdgColumnProps.dataType === "number_3"
            || dgdgColumnProps.dataType === "formatted_number" || dgdgColumnProps.dataType === "formatted_number_2"
            || dgdgColumnProps.dataType === "money" || dgdgColumnProps.dataType === "signed_money" || dgdgColumnProps.dataType === "money_2" || dgdgColumnProps.dataType === "money_2_simple"
            || dgdgColumnProps.dataType === "accounting_amount" || dgdgColumnProps.dataType === "accounting_amount_simple"
            || dgdgColumnProps.dataType === "percent" || dgdgColumnProps.dataType === "signed_percent" || dgdgColumnProps.dataType === "percent_1" || dgdgColumnProps.dataType === "percent_2")) {

            cssName += " text-right";
        }

        return cssName;
    }

    prepareHeader(rowComponent) {
        let headerRow = [];
        let headerColSpan = 0;
        let columnComponents = rowComponent.props.children;
        columnComponents.forEach((columnComponent, index) => {
            if (headerColSpan > 1) {
                headerColSpan--;
                return;
            }

            if (columnComponent) {
                if (columnComponent.props.headerColSpan) {
                    headerColSpan = parseInt(columnComponent.props.headerColSpan);
                }

                headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerCssName === undefined ? "" : columnComponent.props.headerCssName)} colSpan={columnComponent.props.headerColSpan}>
                    {
                        columnComponent.props.sortColumn
                            ? <a href={(event) => false} role="button" className="font-weight-bold" onClick={(event) => this.onSortClick(event, columnComponent)}>
                                <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                                {
                                    this.state.sortColumn === columnComponent.props.sortColumn
                                        ? this.state.sortOrder === "Asc"
                                            ? <span style={{ marginLeft: "5px" }} className="far fa-sort-up" />
                                            : <span style={{ marginLeft: "5px" }} className="far fa-sort-down" />
                                        : null
                                }
                            </a>
                            : <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                    }
                    {
                        columnComponent.props.filterColumn
                            ? this.state.filterColumn === columnComponent.props.dataColumn
                                ? <button type="button" className="btn btn-link font-weight-bold" onClick={(event) => this.onCancelFilterClick(event)}>
                                    <span style={{ textDecoration: "line-through", marginLeft: "5px" }} className="fas fa-filter" />
                                </button>
                                : <button type="button" className="btn btn-link font-weight-bold" onClick={(event) => this.onShowFilterClick(event, "header-cell" + index, columnComponent.props.filterColumn, columnComponent.props.dataType)}>
                                    <span style={{ marginLeft: "5px" }} className="far fa-filter" />
                                </button>
                            : null
                    }
                    {
                        columnComponent.props.headerCallback
                            ? columnComponent.props.headerCallback(columnComponent)
                            : null
                    }
                    {
                        columnComponent.props.columnHelp
                            ? <div className="tooltip dgdg-icon" data-display="static">
                                <span className="far fa-info-circle" style={{ "color": "black", "fontWeight": "500" }} data-display="static" />
                                <div className="content" data-display="static">
                                    {columnComponent.props.columnHelp}
                                </div>
                            </div>
                            : null
                    }
                </td>);
            }
        });

        return <tr id="dgdgRwHeader" key="row-header" className="dgdg-table-v3-header font-weight-bold">
            {headerRow}
        </tr>;
    }

    prepareFooter(rowChild) {
        let footerRow = [];
        if (this.props.showFooter) {
            if (this.props.footerRow) {
                rowChild.props.children.forEach((dgdgColumn, columnIndex) => {
                    let footerValue = null;
                    let cssName = "";
                    if (dgdgColumn.props.footerText) {
                        footerValue = dgdgColumn.props.footerText;
                    }
                    else {
                        footerValue = this.props.footerRow[dgdgColumn.props.dataColumn];
                        footerValue = this.formatData(dgdgColumn.props.dataType, footerValue);
                    }

                    cssName = this.getColumnCss(dgdgColumn.props);

                    footerRow.push(<td key={"footer" + columnIndex} className={cssName}>{footerValue}</td>);
                });
            }
            else {
                rowChild.props.children.forEach((dgdgColumn, columnIndex) => {
                    let footerValue = null;
                    let cssName = "";
                    if (dgdgColumn.props.footerText) {
                        footerValue = dgdgColumn.props.footerText;
                    } else if (dgdgColumn.props.footerFunction) {
                        switch (dgdgColumn.props.footerFunction) {
                            case "sum":
                                footerValue = 0;
                                this.state.tableData.forEach((rowData, rowIndex) => {
                                    let value = parseInt(rowData[dgdgColumn.props.dataColumn]);
                                    if (!isNaN(value)) {
                                        footerValue += value;
                                    }
                                });

                                break;

                            case "average":
                                footerValue = 0;
                                if (this.state.tableData.length > 0) {
                                    this.state.tableData.forEach((rowData, rowIndex) => {
                                        let value = parseFloat(rowData[dgdgColumn.props.dataColumn]);
                                        if (!isNaN(value)) {
                                            footerValue += value;
                                        }
                                    });

                                    footerValue = footerValue / this.state.tableData.length;
                                }

                                break;

                            default:
                                break;
                        }

                        footerValue = this.formatData(dgdgColumn.props.dataType, footerValue);
                        cssName = this.getColumnCss(dgdgColumn.props);
                    }

                    footerRow.push(<td key={"footer" + columnIndex} className={cssName}>{footerValue}</td>);
                });
            }
        }

        return this.props.showFooter
            ? <Fragment>
                <tr className="dgdg-table-footer-seperator"><td colSpan={rowChild.props.children.length} /></tr>
                <tr key="row-footer">
                    {footerRow}
                </tr>
            </Fragment>
            : null;
    }

    onSortClick(event, dgdgColumn) {
        let columnSortOrder = "Asc";
        if (this.state.sortColumn && this.state.sortColumn === dgdgColumn.props.sortColumn) {
            columnSortOrder = this.state.sortOrder;
            if (columnSortOrder === "Desc") {
                columnSortOrder = "Asc";
            } else {
                columnSortOrder = "Desc";
            }
        }

        let sortedTableData = CommonService.sortJsonData(this.state.tableData, dgdgColumn.props.sortColumn, columnSortOrder, dgdgColumn.props.dataType);
        this.setState({
            tableData: sortedTableData,
            sortColumn: dgdgColumn.props.sortColumn,
            sortOrder: columnSortOrder
        });
    }

    onShowFilterClick(event, headerCellRef, fltrColumn, dtType) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        let dgdgTable = ReactDOM.findDOMNode(this.dgdgTable);
        let headerCell = ReactDOM.findDOMNode(this[headerCellRef]);
        this.setState({
            showFilter: true,
            showCancelFilter: true,
            filterColumn: fltrColumn,
            filterDataType: dtType,
            filterTop: dgdgTable.offsetTop,
            filterLeft: dgdgTable.offsetLeft + headerCell.offsetLeft + headerCell.offsetWidth - 15
        });
    }

    onHideFilterClick(event) {
        if (this.state.showFilter) {
            this.setState({ showFilter: false });
        }
    }

    onFilterClick(event, filterText) {
        let filteredTableData = CommonService.filterJsonData(this.props.tableData, this.state.filterColumn, filterText, this.state.filterDataType);
        this.setState({
            tableData: filteredTableData,
            showFilter: false
        });
    }

    onCancelFilterClick(event) {
        this.setState({
            tableData: this.props.tableData,
            filterColumn: null,
            showFilter: false,
            showCancelFilter: false
        });
    }

    onDocumentClick(event) {
        this.onHideFilterClick(event);
    }

    render() {
        let headerComponent = null;
        let rowComponent = null;
        let childTableComponent = null;
        let customComponent = null;
        if (Array.isArray(this.props.children)) {
            headerComponent = this.props.children.filter(item => item.type === DGDGTableHeaderComponent)[0];
            rowComponent = this.props.children.filter(item => item.type === DGDGTableRowComponent)[0];
            childTableComponent = this.props.children.filter(item => item.type === DGDGTableV2Component)[0];
            customComponent = this.props.children.filter(item => item.type === DGDGTableCustomComponent)[0];
        }
        else {
            rowComponent = this.props.children;
        }

        let header = this.prepareHeader(rowComponent);
        let footer = this.prepareFooter(rowComponent);
        let rows = [];
        if (this.state.tableData) {
            this.state.tableData.forEach((rowData, rowIndex) => {
                rows.push(<tbody key={"row-" + rowIndex} className="dgdg-table-v3-row">
                    <tr key={"row-1-" + rowIndex}>
                        {this.renderColumns(rowComponent, rowData, rowIndex)}
                    </tr>
                    {
                        childTableComponent
                            ? <tr key={"row-2-" + rowIndex}>
                                <td colSpan={rowComponent.props.children.length} style={{ "paddingBottom": "20px" }}>
                                    <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.props.childTableDataCallback(rowData)}>
                                        {childTableComponent.props.children}
                                    </DGDGTableV2Component>
                                </td>
                            </tr>
                            : null
                    }
                    {
                        customComponent
                            ? <tr key={"row-3-" + rowIndex}>
                                <td colSpan={rowComponent.props.children.length} style={{ "paddingRight": "8px", "paddingBottom": "8px" }}>
                                    {
                                        React.cloneElement(customComponent.props.children, {
                                            rowData: rowData
                                        })
                                    }
                                </td>
                            </tr>
                            : null
                    }
                </tbody>);
            });
        }

        return <div className="card">
            {
                this.props.headerText
                    ? <div className="card-header">
                        {this.props.headerText}
                        {
                            this.props.onCloseClick
                                ? <button type="button" className="btn btn-link" onClick={(event) => this.props.onCloseClick(event)}>
                                    <span className="fas fa-window-close dgdg-font-red float-right" />
                                </button>
                                : null
                        }
                        {
                            this.state.showCancelFilter
                                ? <button type="button" className="btn btn-link float-right" onClick={(event) => this.onCancelFilterClick(event)}>
                                    <span style={{ textDecoration: "line-through" }} className="fas fa-filter" /> Cancel filter
                                </button>
                                : null
                        }
                    </div>
                    : null
            }
            <div className="card-block">
                <DGDGTableFilterComponent applicationInsights={this.props.applicationInsights} showFilter={this.state.showFilter} filterColumn={this.state.filterColumn} dataType={this.state.filterDataType}
                    top={this.state.filterTop}
                    left={this.state.filterLeft}
                    onFilterClick={this.onFilterClick}
                    onHideFilterClick={this.onHideFilterClick}
                />

                <DGDGSpinnerComponent showSpinner={this.props.showSpinner} cssName="sk-cube-center" />
                <table className={"dgdg-table-v3 " + (this.props.cssName ? this.props.cssName : "")} ref={refElement => this.dgdgTable = refElement}>
                    <thead>
                        {headerComponent}
                        {header}
                    </thead>
                    {rows}
                    <tfoot>
                        {footer}
                    </tfoot>
                </table>
            </div>
        </div>;
    }
}

import React from "react";
import { Link } from "react-router-dom";

export class DGDGHeaderComponent extends React.Component {
    render() {
        return <div className="container-fluid font-weight-bold dgdg-header">
            <div className="d-inline-block dgdg-page-title">
                {this.props.title}
            </div>
            <div className="float-right dgdg-header-right-content">
                <div className="form-inline">
                    {
                        this.props.canShowUsers || this.props.canShowCache || this.props.canShowStore || this.props.canShowControllerPolicy
                            ? <div className="form-group dropdown">
                                <div className="btn-link dropdown-toggle" style={{ "cursor": "pointer" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="far fa-cog" />
                                </div>
                                <div className="dropdown-menu">
                                    {
                                        this.props.canShowStore
                                            ? <Link className="dropdown-item" to="/Store">Store</Link>
                                            : null
                                    }
                                    {
                                        this.props.canShowUsers
                                            ? <Link className="dropdown-item" to="/User">Users</Link>
                                            : null
                                    }
                                    {
                                        this.props.canShowCache
                                            ? <Link className="dropdown-item" to="/Cache">Cache</Link>
                                            : null
                                    }
                                    {
                                        this.props.canShowControllerPolicy
                                            ? <Link className="dropdown-item" to="/ControllerPolicy">Controller Policy</Link>
                                            : null
                                    }
                                </div>
                            </div>
                            : null
                    }
                    {
                        this.props.isAuthenticated
                            ? <div className="form-group dropdown dgdg-icon" data-flag-red={this.props.canExitImpersonation}>
                                <div className="btn-link font-weight-bold dropdown-toggle dgdg-desktop" style={{ "cursor": "pointer" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.props.identityName}
                                </div>
                                <div className="btn-link font-weight-bold dropdown-toggle dgdg-mobile" style={{ "cursor": "pointer" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.props.identityShortName}
                                </div>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="dropdown-header">
                                        <table className="dgdg-font-size-14 text-nowrap">
                                            <tbody>
                                                <tr>
                                                    <td className="dgdg-menu-profile">
                                                        {this.props.identityShortName}
                                                    </td>
                                                    <td>
                                                        {this.props.firstName + " " + this.props.lastName}<br />
                                                        {this.props.remoteUpn}<br />
                                                        {this.props.tenantName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td>
                                                        {this.props.storeName}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        (this.props.canExitImpersonation)
                                            ? <a className="dropdown-item" href="/Account/ExitImpersonation">Exit Impersonation</a>
                                            : null

                                    }
                                    <a className="dropdown-item" href="/Account/Logout">Sign Out</a>
                                </div>
                            </div>
                            : <div className="nav-item">
                                <a className="nav-link" href={"/Account/Login?returnUrl=" + window.location.pathname}>Sign In</a>
                            </div>

                    }
                </div>
            </div>
        </div>;
    }
}
import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGImportWizardComponent } from "../common/dgdgimportwizard/DGDGImportWizardComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableColumnCheckboxComponent } from "../common/dgdgtable/DGDGTableColumnCheckboxComponent";

import { USWarrantyReconciliationQueryComponent } from "./USWarrantyReconciliationQueryComponent";
import { USWarrantyReconciliationJournalEntryDetailsComponent } from "./USWarrantyReconciliationJournalEntryDetailsComponent";
import { USWarrantyReconciliationSalesDealDetailsComponent } from "./USWarrantyReconciliationSalesDealDetailsComponent";
import { USWarrantyReconciliationGenerateCDKConcatenateView } from "./USWarrantyReconciliationGenerateCDKConcatenateView";

export class USWarrantyReconciliationRouteComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("USWarrantyReconciliationRouteComponent - getDerivedStateFromProps");
            if (state.tableData.filter((rowData) => { return rowData.isSelected; }).length > 0) {
                newState.isInvoiceSelected = true;
            } else {
                newState.isInvoiceSelected = false;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        let months = CommonService.getMonths();
        super(props);
        this.state = {
            months: months,
            previousMonths: months.slice(1),
            selectedStore: null,
            billingMonths: [],
            selectedMonth: null,
            stores: null,
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            isDownLoading: false,
            lastSavedRowData: null,
            stmt_balance: 0,
            concatenateViewTotal: 0,

            showImportUSWarrantyInvoiceDialog: false,
            importUSWarrantyInvoiceIsValid: false,
            importUSWarrantyInvoiceTableColumns: null,
            importUSWarrantyInvoiceTableData: [],
            importUSWarrantyInvoiceShowTableSpinner: false,
            importUSWarrantyInvoicePaintTable: false,
            importUSWarrantyInvoiceVerifyStatus: "",
            importUSWarrantyInvoiceStatus: "",

            statusData: [
                { key: "Pay 2025 - USW", value: "Pay 2025 - USW" },
                { key: "Pay 2040 - Acc Due", value: "Pay 2040 - Acc Due" },
                { key: "Pay 2197 - No Brainer", value: "Pay 2197 - No Brainer" },
                { key: "Pay 2026 - Cancel Svc-Gap", value: "Pay 2026 - Cancel Svc-Gap" },
                { key: "Credit 2025 - USW", value: "Credit 2025 - USW" },
                { key: "Credit 2040 - Acc Due", value: "Credit 2040 - Acc Due" },
                { key: "Credit 2197 - No Brainer", value: "Credit 2197 - No Brainer" },
                { key: "Credit 2026 - Cancel Svc-Gap", value: "Credit 2026 - Cancel Svc-Gap" },
                { key: "Credit 1121 - Service Contract", value: "Credit 1121 - Service Contract" },
                { key: "Adj Pay 14279 - New", value: "Adj Pay 14279 - New" },
                { key: "Adj Pay 14296 - New", value: "Adj Pay 14296 - New" },
                { key: "Adj Pay 14285 - New", value: "Adj Pay 14285 - New" },
                { key: "Adj Pay 14298 - New", value: "Adj Pay 14298 - New" },
                { key: "Adj Pay 14294 - New", value: "Adj Pay 14294 - New" },
                { key: "Adj Pay 14392 - Used", value: "Adj Pay 14392 - Used" },
                { key: "Adj Pay 14394 - Used", value: "Adj Pay 14394 - Used" },
                { key: "Adj Pay 14399 - Used", value: "Adj Pay 14399 - Used" },
                { key: "Adj Pay 14379 - Used", value: "Adj Pay 14379 - Used" },
                { key: "No Pay", value: "No Pay" },
                { key: null, value: "Clear Status" }
            ],

            journalEntryDetailsTitle: "",
            totalPostingAmount: 0,
            journalEntryDetailsTableData: [],
            journalEntryDetailsPaintTable: false,
            showjournalEntryDetails: false,

            salesDealDetailsTitle: "",
            salesDealDetailsTableData: [],
            salesDealDetailsPaintTable: false,
            showSalesDealDetails: false,

            cdkConcatenateViewPageTitle: "",
            cdkConcatenateViewTableTitle: "",
            cdkConcatenateViewDataTitle: "",
            cdkConcatenateViewTableData: [],
            cdkConcatenateViewPaintTable: true,
            showCDKConcatenateViewDetails: false,
            cdkConcatenateViewText: "",
            showDeleteAllConfirmation: false,
            deleteRow: null,

            isSelectedAll: false,
            isInvoiceSelected: false
        };

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.onBlillingMonthClick = this.onBlillingMonthClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onAdjustmentAmountChange = this.onAdjustmentAmountChange.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveUSWarrantyCommentClick = this.onSaveUSWarrantyCommentClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onDeleteOkClick = this.onDeleteOkClick.bind(this);
        this.onDeleteAllClick = this.onDeleteAllClick.bind(this);
        this.onDeleteAllCancelClick = this.onDeleteAllCancelClick.bind(this);

        this.onGenerateCDKConcatenateViewClick = this.onGenerateCDKConcatenateViewClick.bind(this);
        this.onGenerateCDKConcatenateCancelClick = this.onGenerateCDKConcatenateCancelClick.bind(this);

        this.onImportUSWarrantyInvoiceClick = this.onImportUSWarrantyInvoiceClick.bind(this);
        this.onVerifyImportUSWarrantyInvoiceClick = this.onVerifyImportUSWarrantyInvoiceClick.bind(this);
        this.onConfirmImportUSWarrantyInvoiceClick = this.onConfirmImportUSWarrantyInvoiceClick.bind(this);
        this.onCancelImportUSWarrantyInvoiceClick = this.onCancelImportUSWarrantyInvoiceClick.bind(this);

        this.onPostingAmountCellClick = this.onPostingAmountCellClick.bind(this);
        this.onPostingAmountCancelClick = this.onPostingAmountCancelClick.bind(this);

        this.onCustomerNumberChange = this.onCustomerNumberChange.bind(this);

        this.onDealNumberCellClick = this.onDealNumberCellClick.bind(this);
        this.onDealNumberCancelClick = this.onDealNumberCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        console.devLog("USWarrantyReconciliationRouteComponent - componentDidMount");
        this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/USWarrantyReconciliation", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        this.getStores();
        this.getControllerPolicy();
        this.setState({
            billingMonths: [this.state.months[0], this.state.months[1]],
            selectedMonth: this.state.previousMonths[0]
        })
        CommonService.clientAzureStorageLog("CITRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }

    pageTitle = "US Warranty Reconciliation";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "USWarrantyReconciliationRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/USWarrantyReconciliation/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onSelectChange(event, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.isSelected = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSelectAllChange(id, isChecked) {
        try {
            let tableData = this.state.tableData;
            tableData.forEach((rowData) => {
                rowData.isSelected = isChecked;
            });

            this.setState({
                isSelectedAll: isChecked,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onBlillingMonthClick(id, month) {
        try {
            this.setState({ selectedMonth: month });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore, selectedMonth) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                startDate: CommonService.formatServerDateTime(selectedMonth),
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    let concatenateViewTotal = this.updateRowData(tableData);
                    this.setState({
                        isLoadingData: false,
                        stmt_balance: parsedResponse.table1[0].stmt_balance,
                        concatenateViewTotal: concatenateViewTotal,
                        tableTitle: (selectedStore ? selectedStore.display_name : "") + " - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true,
                        selectedMonth: selectedMonth
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustmentAmountChange(id, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.adjustment_amount = text;
            rowData.adjustment_amount_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                contractNumber: rowData.contracT_NUMBER,
                billingDate: rowData.billing_date,
                adjustmentAmount: rowData.adjustment_amount,
                comment: rowData.comment,
                status: rowData.status,
                customerNumber: rowData.customeR_NUMBER,
                transactionType: rowData.transactioN_TYPE
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onAdjustmentAmountChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/SaveUSWarrantyAdjustmentAmount", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    let concatenateViewTotal = this.updateRowData(tableData);
                    rowData.adjustment_amount_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        concatenateViewTotal: concatenateViewTotal,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "US Warranty Reconciliation Warranty #" + rowData.contracT_NUMBER + " Adjustment Amount saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.adjustment_amount_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/SaveUSWarrantyAdjustmentAmount", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberChange(id, customerNumber, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.customeR_NUMBER = customerNumber;
            rowData.customeR_NUMBER_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                contractNumber: rowData.contracT_NUMBER,
                billingDate: rowData.billing_date,
                adjustmentAmount: rowData.adjustment_amount,
                comment: rowData.comment,
                status: rowData.status,
                customerNumber: customerNumber,
                transactionType: rowData.transactioN_TYPE
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onCustomerNumberChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/SaveUSWarrantyCustomerNumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.customeR_NUMBER_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "US Warranty Reconciliation Warranty #" + rowData.contracT_NUMBER + " Customer saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.customeR_NUMBER_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/SaveUSWarrantyCustomerNumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        return <Fragment>
            <div className="form-inline">
                <DGDGFormTextAreaComponent id={"comment_" + rowData.store_number + "_" + rowData.contracT_NUMBER} value={rowData.comment} rows="3" additionalData={rowData} onChange={this.onCommentTextChange} colSpan="col-10" controlSpan="col-12" />
                <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveUSWarrantyCommentClick(event, rowData)}>
                    <span className="fas fa-save" />
                </button>
                <DGDGSpinnerComponent showSpinner={rowData.comment_showSpinner} />
            </div>
        </Fragment>;
    }

    onCommentTextChange(id, value, rowData) {
        try {
            rowData.comment = value;
            this.setState({
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveUSWarrantyCommentClick(event, rowData) {
        if (this.state.lastSavedRowData
            && this.state.lastSavedRowData.contracT_NUMBER === rowData.contracT_NUMBER
            && moment().diff(this.state.lastSavedRowData.last_updated_on) < 500) {

            return;
        }

        let tableData = this.state.tableData;
        try {
            rowData.comment_showSpinner = true;
            rowData.last_updated_on = moment();

            this.setState({
                lastSavedRowData: rowData,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                contractNumber: rowData.contracT_NUMBER,
                billingDate: rowData.billing_date,
                adjustmentAmount: rowData.adjustment_amount,
                comment: rowData.comment ? rowData.comment.replace(/\n|\r/g, " ") : rowData.comment,
                status: rowData.status,
                customerNumber: rowData.customeR_NUMBER,
                transactionType: rowData.transactioN_TYPE
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onSaveUSWarrantyCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/SaveUSWarrantyComment", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.original_comment = rowData.comment;
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.comment_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "US Warranty Reconciliation Warranty #" + rowData.contracT_NUMBER + " Comment saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.comment_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/SaveUSWarrantyComment", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                contractNumber: rowData.contracT_NUMBER,
                billingDate: rowData.billing_date,
                adjustmentAmount: rowData.adjustment_amount,
                comment: rowData.comment,
                status: rowData.status,
                customerNumber: rowData.customeR_NUMBER,
                transactionType: rowData.transactioN_TYPE
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/SaveUSWarrantyStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    let concatenateViewTotal = this.updateRowData(tableData);
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        concatenateViewTotal: concatenateViewTotal,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "US Warranty Reconciliation Warranty #" + rowData.contracT_NUMBER + " Status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/SaveUSWarrantyStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteOkClick(event) {
        let inputData = [];
        try {
            this.state.tableData.forEach((rowData, index) => {
                if (rowData.isSelected)
                    inputData.push({
                        storeNumber: rowData.store_number,
                        billingDate: rowData.billing_date,
                        contractNumber: rowData.contracT_NUMBER,
                        status: rowData.status
                    });
            });

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onDeleteOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/DeleteUSWarrantyStatementItem", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let selectedStore = null;
                    let billingDate = null;
                    selectedStore = this.state.stores.filter(storeRow => {
                        return storeRow.store_number === this.state.tableData.filter((row) => row.isSelected === true)[0].store_number;
                    })[0];
                    billingDate = this.state.tableData.filter((row) => row.isSelected === true)[0].billing_date;

                    this.onGetReportClick(selectedStore, billingDate);
                    this.onDeleteAllCancelClick(event);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/DeleteUSWarrantyStatementItem", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteAllClick(event) {
        try {
            this.setState({
                showDeleteAllConfirmation: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteAllCancelClick(event) {
        try {
            this.setState({
                showDeleteAllConfirmation: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("USWarrantyReconciliationRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    importBulkUpdateTSVHeader = "COMPANY_CODE\tDEALER_NUMBER\tREPORT_YEARMONTH\tPRODUCT\tTRANSACTION_TYPE\tCONTRACT_NUMBER\tVIN_11\tVIN_LAST_6\tAMOUNT_DUE_TO_WARRANTY_COMPAY\tLAST_NAME\tFIRST_NAME\tEFFECTIVE_MILEAGE\tEFFECTIVE_DATE\tSALE_DATE\tMONTH_TERM\tCOVERAGE_TYPE\tAMOUNT_CHARGED\tENTRY_YEARMONTH\tCANCEL_AMOUNT\tCANCEL_YEARMONTH\tCANCEL_DATE\tIDCOL\tRECORD_INSERT_TIME\tRO_NUMBER\tVIN\tSTOCK_NUMBER\tDEAL_NUMBER\tCUSTOMER_NUMBER\tPLAN_DESCRIPTION\tSUB_PLAN_DESCRIPTION\tREFUND_FACTOR";
    onImportUSWarrantyInvoiceClick(event) {
        try {
            this.setState({ showImportUSWarrantyInvoiceDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVerifyImportUSWarrantyInvoiceClick(event, tsvText) {
        try {
            this.setState({
                importUSWarrantyInvoiceShowTableSpinner: true,
                importUSWarrantyInvoicePaintTable: true
            }, () => {
                this.setState({ importUSWarrantyInvoicePaintTable: false });
            });
            let inputData = { tsvText: tsvText };
            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onDeleteOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/VerifyImportUSWarrantyInvoice", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableColumns = [];
                    tableColumns.push(<DGDGTableColumnComponent key="Ordinal" headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />);
                    this.importBulkUpdateTSVHeader.split("\t").forEach((header, key) => {
                        if (parsedResponse.missingFields && parsedResponse.missingFields.includes(header)) {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-us-warranty-reconciliation-missing-col" />);
                        }
                        else {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} backgroundColor={header + "BgColor"} />);
                        }
                    });

                    if (parsedResponse.additionalFields && parsedResponse.additionalFields.length > 0) {
                        parsedResponse.additionalFields.forEach((header, key) => {
                            tableColumns.push(<DGDGTableColumnComponent key={header} headerText={header} dataColumn={header} sortColumn={header} cssName="dgdg-us-warranty-reconciliation-extra-col" />);
                        });
                    }

                    this.setState({
                        importUSWarrantyInvoiceIsValid: parsedResponse.isValid,
                        importUSWarrantyInvoiceTableColumns: tableColumns,
                        importUSWarrantyInvoiceTableData: CommonService.addOrdinalColumn(parsedResponse.tsvDataTable),
                        importUSWarrantyInvoiceShowTableSpinner: false,
                        importUSWarrantyInvoicePaintTable: true,
                        importUSWarrantyInvoiceVerifyStatus: parsedResponse.message
                    }, () => {
                        this.setState({ importUSWarrantyInvoicePaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/VerifyImportUSWarrantyInvoice", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    importUSWarrantyInvoiceVerifyStatus: "Failed to verify data. Reason: " + parsedError.message
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmImportUSWarrantyInvoiceClick(event, tsvText) {
        try {
            let inputData = {
                billingDate: CommonService.formatServerDateTime(this.state.selectedMonth),
                tsvText: tsvText
            };
            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onConfirmImportUSWarrantyInvoiceClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/ConfirmImportUSWarrantyInvoice", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        importUSWarrantyInvoiceStatus: parsedResponse.message
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/ConfirmImportUSWarrantyInvoice", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.setState({
                                    importUSWarrantyInvoiceStatus: "Failed to import data. Reason: " + parsedError.message
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelImportUSWarrantyInvoiceClick(event) {
        try {
            this.setState({
                showImportUSWarrantyInvoiceDialog: false,
                importUSWarrantyInvoiceIsValid: false,
                importUSWarrantyInvoiceTableColumns: null,
                importUSWarrantyInvoiceTableData: [],
                importUSWarrantyInvoiceShowTableSpinner: false,
                importUSWarrantyInvoicePaintTable: true,
                importUSWarrantyInvoiceVerifyStatus: "",
                importUSWarrantyInvoiceStatus: ""
            }, () => {
                this.setState({ importUSWarrantyInvoicePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGenerateCDKConcatenateViewClick(selectedStore, startDate) {
        try {
            this.setState({
                isLoadingData: true
            });

            let inputData = {
                storeNumber: selectedStore.store_number,
                startDate: CommonService.formatServerDateTime(startDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onGenerateCDKConcatenateViewClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/GenerateCDKConcatenateView", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.amounT_DUE_TO_WARRANTY_COMPAY_drcr = responseItem.amounT_DUE_TO_WARRANTY_COMPAY;
                        responseItem.amounT_DUE_TO_WARRANTY_COMPAY_drcr_cellCssName = responseItem.amounT_DUE_TO_WARRANTY_COMPAY > 0 ? "dgdg-us-warranty-reconciliation-table-dr-cell" : "dgdg-us-warranty-reconciliation-table-cr-cell";
                    });
                    this.setState({
                        isLoadingData: false,
                        showCDKConcatenateViewDetails: true,
                        cdkConcatenateViewPageTitle: "CDK Concatenate View - " + CommonService.formatMonthYear(moment(startDate)),
                        cdkConcatenateViewTableTitle: "Pay Warranties for " + CommonService.formatMonthYear(moment(startDate)) + " - " + parsedResponse.table.length + " Rows",
                        cdkConcatenateViewDataTitle: "Pay Warranties for " + CommonService.formatMonthYear(moment(startDate)) + "<br/>(CDK Concatenate)",
                        cdkConcatenateViewTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        cdkConcatenateViewPaintTable: true,
                        cdkConcatenateViewText: parsedResponse.cdkConcatenateData
                    }, () => {
                        this.setState({ cdkConcatenateViewPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        cdkConcatenateViewPageTitle: "",
                        cdkConcatenateViewTableTitle: "",
                        cdkConcatenateViewDataTitle: "",
                        cdkConcatenateViewtTableData: [],
                        cdkConcatenateViewPaintTable: true,
                        cdkConcatenateViewText: ""
                    }, () => {
                        this.setState({ cdkConcatenateViewPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GenerateCDKConcatenateView", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGenerateCDKConcatenateCancelClick(event) {
        try {
            this.setState({
                showCDKConcatenateViewDetails: false,
                cdkConcatenateViewTableTitle: "",
                cdkConcatenateViewtTableData: [],
                cdkConcatenateViewPaintTable: true,
                cdkConcatenateViewText: ""
            }, () => {
                this.setState({ cdkConcatenateViewPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPostingAmountCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                contractNumber: rowData.contracT_NUMBER
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onPostingAmountCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/GetUSWarrantyReconciliationJournalEntryDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalPostingAmount = 0;
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.store_web_name = rowData.store_web_name;
                        totalPostingAmount += responseItem.posting_amount;
                    });
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.contracT_NUMBER + " - Posting Amount: " + CommonService.formatCurrency(rowData.posting_amount),
                        journalEntryDetailsTableData: CommonService.addOrdinalColumn(parsedResponse),
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true,
                        totalPostingAmount: totalPostingAmount
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.contracT_NUMBER + " - Posting Amount: " + CommonService.formatCurrency(rowData.posting_amount),
                        journalEntryDetailsTableData: [],
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true,
                        totalPostingAmount: 0
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GetUSWarrantyReconciliationJournalEntryDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPostingAmountCancelClick(event) {
        try {
            this.setState({
                journalEntryDetailsTitle: "",
                journalEntryDetailsTableData: [],
                journalEntryDetailsPaintTable: true,
                showjournalEntryDetails: false
            }, () => {
                this.setState({ journalEntryDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                dealNumber: rowData.deaL_NUMBER
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReconciliationRouteComponent/onDealNumberCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReconciliation/GetUSWarrantyReconciliationSalesDealDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        salesDealDetailsTitle: "Sales Deal Detail for " + rowData.deaL_NUMBER,
                        salesDealDetailsTableData: CommonService.addOrdinalColumn(parsedResponse),
                        showSalesDealDetails: true,
                        salesDealDetailsPaintTable: true,
                    }, () => {
                        this.setState({ salesDealDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        salesDealDetailsTitle: "Sales Deal Detail for " + rowData.deaL_NUMBER,
                        salesDealDetailsTableData: [],
                        showSalesDealetails: true,
                        salesDealDetailsPaintTable: true
                    }, () => {
                        this.setState({ salesDealDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReconciliation/GetUSWarrantyReconciliationSalesDealDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberCancelClick(event) {
        try {
            this.setState({
                salesDealDetailsTitle: "",
                salesDealDetailsTableData: [],
                salesDealDetailsPaintTable: true,
                showSalesDealDetails: false
            }, () => {
                this.setState({ salesDealDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    setRowColor(responseItem) {
        switch (responseItem.status) {
            case "Pay 2025 - USW":
            case "Pay 2040 - Acc Due":
            case "Pay 2197 - No Brainer":
            case "Pay 2026 - Cancel Svc-Gap":
            case "Credit 2025 - USW":
            case "Credit 2040 - Acc Due":
            case "Credit 2197 - No Brainer":
            case "Credit 2026 - Cancel Svc-Gap":
            case "Credit 1121 - Service Contract":
            case "Prev Mo Pay 2025 - USW":
            case "Prev Mo Pay 2026 - Cancel":
            case "Prev Mo Credit 2025 - USW":
            case "Prev Mo Credit 2026 - Cancel":
            case "Adj Pay 14279 - New":
            case "Adj Pay 14296 - New":
            case "Adj Pay 14285 - New":
            case "Adj Pay 14298 - New":
            case "Adj Pay 14294 - New":
            case "Adj Pay 14392 - Used":
            case "Adj Pay 14394 - Used":
            case "Adj Pay 14399 - Used":
            case "Adj Pay 14379 - Used":
                responseItem.rowCssName = "dgdg-us-warranty-reconciliation-status-others";
                responseItem.lastRowCssName = "dgdg-border-bottom-white";
                break;

            case "No Pay":
                responseItem.rowCssName = "dgdg-us-warranty-reconciliation-status-no-pay";
                responseItem.lastRowCssName = "dgdg-border-bottom-white";
                break;

            default:
                responseItem.rowCssName = null;
        }
    }

    updateRowData(tableData) {
        let totalConcatenateAmount = 0
        tableData.forEach((responseItem) => {
            if (responseItem.status === "Prev Mo Pay 2025 - USW" || responseItem.status === "Prev Mo Pay 2026 - Cancel" || responseItem.status === "Prev Mo Credit 2025 - USW" || responseItem.status === "Prev Mo Credit 2026 - Cancel") {
                responseItem.status_disabled = true;
            } else {
                responseItem.adjustment_amount_hideCell = true;
            }

            if (responseItem.status === "Adj Pay 14279 - New" || responseItem.status === "Adj Pay 14296 - New" || responseItem.status === "Adj Pay 14285 - New" || responseItem.status === "Adj Pay 14298 - New" || responseItem.status === "Adj Pay 14294 - New" || responseItem.status === "Adj Pay 14392 - Used" || responseItem.status === "Adj Pay 14394 - Used" || responseItem.status === "Adj Pay 14399 - Used" || responseItem.status === "Adj Pay 14379 - Used") {
                responseItem.adjustment_amount_hideCell = false;
                if (!responseItem.adjustment_amount) {
                    responseItem.adjustment_amount = 0;
                }
            } else {
                responseItem.adjustment_amount_hideCell = true;
            }

            this.setRowColor(responseItem);

            if ((responseItem.amounT_DUE_TO_WARRANTY_COMPAY && (responseItem.status === 'Pay 2025 - USW' || responseItem.status === 'Pay 2040 - Acc Due' || responseItem.status === 'Pay 2197 - No Brainer' || responseItem.status === 'Pay 2026 - Cancel Svc-Gap' || responseItem.status === 'Credit 2025 - USW' || responseItem.status === 'Credit 2040 - Acc Due' || responseItem.status === 'Credit 2197 - No Brainer' || responseItem.status === 'Credit 2026 - Cancel Svc-Gap' || responseItem.status === 'Credit 1121 - Service Contract' || responseItem.status === 'Prev Mo Pay 2025 - USW' || responseItem.status === 'Prev Mo Pay 2026 - Cancel' || responseItem.status === 'Prev Mo Credit 2025 - USW' || responseItem.status === 'Prev Mo Credit 2026 - Cancel'))
                || (responseItem.adjustment_amount && (responseItem.status === 'Adj Pay 14279 - New' || responseItem.status === 'Adj Pay 14296 - New' || responseItem.status === 'Adj Pay 14285 - New' || responseItem.status === 'Adj Pay 14298 - New' || responseItem.status === 'Adj Pay 14294 - New' || responseItem.status === 'Adj Pay 14392 - Used' || responseItem.status === 'Adj Pay 14394 - Used' || responseItem.status === 'Adj Pay 14399 - Used' || responseItem.status === 'Adj Pay 14379 - Used'))) {
                let amountDue = parseFloat(responseItem.amounT_DUE_TO_WARRANTY_COMPAY);
                let status = responseItem.status;
                if (status === 'Adj Pay 14279 - New'
                    || status === 'Adj Pay 14296 - New'
                    || status === 'Adj Pay 14285 - New'
                    || status === 'Adj Pay 14298 - New'
                    || status === 'Adj Pay 14294 - New'
                    || status === 'Adj Pay 14392 - Used'
                    || status === 'Adj Pay 14394 - Used'
                    || status === 'Adj Pay 14399 - Used'
                    || status === 'Adj Pay 14379 - Used') {
                    amountDue = parseFloat(responseItem.adjustment_amount)
                }

                //if (responseItem.amounT_DUE_TO_WARRANTY_COMPAY && status === 'Credit 2025 - USW'
                //    || status === 'Credit 2040 - Acc Due'
                //    || status === 'Credit 2197 - No Brainer'
                //    || status === 'Credit 2026 - Cancel Svc-Gap'
                //    || status === 'Prev Mo Credit 2025 - USW'
                //    || status === 'Prev Mo Credit 2026 - Cancel') {
                //    amountDue = parseFloat(responseItem.amounT_DUE_TO_WARRANTY_COMPAY) * -1
                //}

                totalConcatenateAmount += amountDue;
            }
        });

        return totalConcatenateAmount;
    }

    render() {
        return <DGDGPageComponent>
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Delete Invoice"
                bodyText="Confirm delete of the selected invoices."
                showConfirmation={this.state.showDeleteAllConfirmation}
                onOkClick={this.onDeleteOkClick}
                onCancelClick={this.onDeleteAllCancelClick}
            />
            <USWarrantyReconciliationJournalEntryDetailsComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showjournalEntryDetails}
                tableData={this.state.journalEntryDetailsTableData}
                tableTitle={this.state.journalEntryDetailsTitle}
                paintTable={this.state.journalEntryDetailsPaintTable}
                totalPostingAmount={this.state.totalPostingAmount}
                onCancelClick={this.onPostingAmountCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <USWarrantyReconciliationSalesDealDetailsComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showSalesDealDetails}
                tableData={this.state.salesDealDetailsTableData}
                tableTitle={this.state.salesDealDetailsTitle}
                paintTable={this.state.salesDealDetailsPaintTable}
                onCancelClick={this.onDealNumberCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <USWarrantyReconciliationGenerateCDKConcatenateView
                showDialog={this.state.showCDKConcatenateViewDetails}
                tableData={this.state.cdkConcatenateViewTableData}
                pageTitle={this.state.cdkConcatenateViewPageTitle}
                tableTitle={this.state.cdkConcatenateViewTableTitle}
                dataTitle={this.state.cdkConcatenateViewDataTitle}
                paintTable={this.state.cdkConcatenateViewPaintTable}
                cdkConcatenateViewText={this.state.cdkConcatenateViewText}
                applicationInsights={this.props.applicationInsights}
                onCancelClick={this.onGenerateCDKConcatenateCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGImportWizardComponent
                title="Import US Warranty Invoice"
                isMultipleLines="true"
                showBillingDate="true"
                months={this.state.billingMonths}
                selectedMonth={this.state.selectedMonth}
                onMonthClick={this.onBlillingMonthClick}
                tsvColumnHeader={this.importBulkUpdateTSVHeader}
                showDialog={this.state.showImportUSWarrantyInvoiceDialog} importStatus={this.state.importUSWarrantyInvoiceStatus} verifyStatus={this.state.importUSWarrantyInvoiceVerifyStatus} isValid={this.state.importUSWarrantyInvoiceIsValid}
                tableColumns={this.state.importUSWarrantyInvoiceTableColumns} tableData={this.state.importUSWarrantyInvoiceTableData} showTableSpinner={this.state.importUSWarrantyInvoiceShowTableSpinner} paintTable={this.state.importUSWarrantyInvoicePaintTable}
                onVerifyClick={this.onVerifyImportUSWarrantyInvoiceClick}
                onImportClick={this.onConfirmImportUSWarrantyInvoiceClick}
                onCancelClick={this.onCancelImportUSWarrantyInvoiceClick}
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
            />
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <USWarrantyReconciliationQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    months={this.state.previousMonths}
                    stores={this.state.stores}
                    isLoadingData={this.state.isLoadingData}
                    isDownLoading={this.state.isDownLoading}
                    isInvoiceSelected={this.state.isInvoiceSelected}
                    onGetReportClick={this.onGetReportClick}
                    onImportUSWarrantyInvoiceClick={this.onImportUSWarrantyInvoiceClick}
                    onGenerateCDKConcatenateViewClick={this.onGenerateCDKConcatenateViewClick}
                    onDeleteAllClick={this.onDeleteAllClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        headerText2={"<b>Stmt Balance: " + CommonService.formatCurrency_2(this.state.stmt_balance) + "&nbsp;&nbsp;&nbsp;&nbsp;Concatenate Balance: " + CommonService.formatCurrency_2(this.state.concatenateViewTotal) + "</b>"}
                        tablePageSize={CommonService.tablePageSize} cssName="dgdg-us-warranty-reconciliation-table"
                        copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent hasHeader>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnCheckboxComponent dataColumn="isSelected" selectAll={this.state.isSelectedAll} sortColumn="isSelected" dataType="bool" onChange={this.onSelectChange} onChangeAll={this.onSelectAllChange} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" cssName="dgdg-store-name" />
                            <DGDGTableColumnComponent headerText="Billing Date" dataColumn="billing_date" sortColumn="billing_date" filterColumn="billing_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Contract Number" dataColumn="contracT_NUMBER" sortColumn="contracT_NUMBER" filterColumn="contracT_NUMBER" />
                            <DGDGTableColumnComponent headerText="Transaction Type" dataColumn="transactioN_TYPE" sortColumn="transactioN_TYPE" filterColumn="transactioN_TYPE" />
                            <DGDGTableColumnComponent headerText="Posting Amount" dataColumn="posting_amount" sortColumn="posting_amount" filterColumn="posting_amount" dataType="money_2" onCellClick={this.onPostingAmountCellClick} />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stocK_NUMBER" sortColumn="stocK_NUMBER" filterColumn="stocK_NUMBER" />
                            <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" filterColumn="vin" sortColumn="vin" />
                            <DGDGTableColumnComponent headerText="Cutomer Number" dataColumn="customeR_NUMBER" sortColumn="customeR_NUMBER" filterColumn="customeR_NUMBER" onChange={this.onCustomerNumberChange} isEditable showSpinner headerCssName="dgdg-us-warranty-reconciliation-customer-number-width" />
                            <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deaL_NUMBER" sortColumn="deaL_NUMBER" filterColumn="deaL_NUMBER" onCellClick={this.onDealNumberCellClick} />
                            <DGDGTableColumnComponent headerText="Plan Description" dataColumn="plaN_DESCRIPTION" sortColumn="plaN_DESCRIPTION" filterColumn="plaN_DESCRIPTION" />
                            <DGDGTableColumnComponent headerText="Sub Plan Description" dataColumn="suB_PLAN_DESCRIPTION" sortColumn="suB_PLAN_DESCRIPTION" filterColumn="suB_PLAN_DESCRIPTION" />
                            <DGDGTableColumnComponent headerText="PRODUCT" dataColumn="product" sortColumn="product" filterColumn="product" />
                            <DGDGTableColumnComponent headerText="AmounT Due to Warranty Compay" dataColumn="amounT_DUE_TO_WARRANTY_COMPAY" sortColumn="amounT_DUE_TO_WARRANTY_COMPAY" filterColumn="amounT_DUE_TO_WARRANTY_COMPAY" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Last Name" dataColumn="lasT_NAME" sortColumn="lasT_NAME" filterColumn="lasT_NAME" />
                            <DGDGTableColumnComponent headerText="First Name" dataColumn="firsT_NAME" sortColumn="firsT_NAME" filterColumn="firsT_NAME" />
                            <DGDGTableColumnComponent headerText="Report Year Month" dataColumn="reporT_YEARMONTH" sortColumn="reporT_YEARMONTH" />
                            <DGDGTableColumnComponent headerText="Effective Mileage" dataColumn="effectivE_MILEAGE" sortColumn="effectivE_MILEAGE" filterColumn="effectivE_MILEAGE" />
                            <DGDGTableColumnComponent headerText="Effective Date" dataColumn="effectivE_DATE" sortColumn="effectivE_DATE" filterColumn="effectivE_DATE" dataType="date" />
                            <DGDGTableColumnComponent headerText="Sale Date" dataColumn="salE_DATE" sortColumn="salE_DATE" filterColumn="salE_DATE" dataType="date" />
                            <DGDGTableColumnComponent headerText="Amount Charged" dataColumn="amounT_CHARGED" filterColumn="amounT_CHARGED" sortColumn="amounT_CHARGED" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Entry Year Month" dataColumn="entrY_YEARMONTH" filterColumn="entrY_YEARMONTH" sortColumn="entrY_YEARMONTH" />
                            <DGDGTableColumnComponent headerText="Cancel Amount" dataColumn="canceL_AMOUNT" sortColumn="canceL_AMOUNT" filterColumn="canceL_AMOUNT" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Cancel Year Month" dataColumn="canceL_YEARMONTH" sortColumn="canceL_YEARMONTH" filterColumn="canceL_YEARMONTH" />
                            <DGDGTableColumnComponent headerText="Cancel Date" dataColumn="canceL_DATE" sortColumn="canceL_DATE" filterColumn="canceL_DATE" dataType="date" />
                            <DGDGTableColumnComponent headerText="IDCOL" dataColumn="idcol" sortColumn="idcol" filterColumn="idcol" />
                            <DGDGTableColumnComponent headerText="Record Insert Time" dataColumn="recorD_INSERT_TIME" sortColumn="recorD_INSERT_TIME" filterColumn="recorD_INSERT_TIME" dataType="date" />
                            <DGDGTableColumnComponent headerText="RO Number" dataColumn="rO_NUMBER" filterColumn="rO_NUMBER" sortColumn="rO_NUMBER" />
                            <DGDGTableColumnComponent headerText="Refund Factor" dataColumn="refunD_FACTOR" filterColumn="refunD_FACTOR" sortColumn="refunD_FACTOR" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="status" isHidden />
                            <DGDGTableColumnComponent headerText="Adjustment Amount" dataColumn="adjustment_amount" isHidden />
                            <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" isHidden />
                            <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" isHidden />
                        </DGDGTableRowComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent />
                            <DGDGTableColumnComponent colSpan="1" />
                            <DGDGTableColumnDropdownComponent colSpan="4" headerText="Status" dataColumn="status" data={this.state.statusData} itemKey="value" onItemClick={this.onStatusChange} disabledColumn="status_disabled" showSpinner />
                            <DGDGTableColumnComponent colSpan="2" dataColumn="adjustment_amount" dataType="money_2" cssName="text-nowrap text-left" headerText="Adj" onChange={this.onAdjustmentAmountChange} isEditable showHeader showSpinner />
                            <DGDGTableColumnComponent colSpan="5" dataColumnCallback={this.onAddCommentColumnCallback} />
                            <DGDGTableColumnComponent colSpan="5" headerText="Last Updated By" dataColumn="last_updated_by" headerCssName="dgdg-us-warranty-reconciliation-acc-desc-col" showHeader />
                            <DGDGTableColumnComponent colSpan="12" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

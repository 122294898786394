import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class PageNotFoundRouteComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Page Not Found";

    render() {
        return <DGDGPageComponent>
            <h2 className="text-danger">Page not found or access has not been granted</h2>
        </DGDGPageComponent>;
    }
}

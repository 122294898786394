import React from "react";
import moment from "moment";
import $ from "jquery";
import "bootstrap";
import "jqueryui";

import { DGDGModalDialogHeaderButtonComponent } from "./DGDGModalDialogHeaderButtonComponent";

export class DGDGModalDialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        $(this.modalContent).draggable({
            handle: ".card-header"
        });

        window.addEventListener("popstate", this.onPopState);
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.showDialog) {
                $(this.modal).modal("show");
            } else {
                $(this.modal).modal("hide");
                this.resetDialogPosition();
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onSaveClick(event) {
        this.props.onSaveClick(event);
    }

    onCancelClick(event) {
        this.props.onCancelClick(event);
        this.resetDialogPosition();
    }

    resetDialogPosition() {
        $(this.modalContent).css({
            left: "",
            top: ""
        });
    }

    onDocumentKeyDown(event) {
        if (this.props.showDialog) {
            switch (event.keyCode) {
                case 27:
                    if (this.props.childModelClosedTime !== undefined
                        && this.props.childModelClosedTime !== null) {
                        let timeNow = moment();
                        if (timeNow - this.props.childModelClosedTime > 500) {
                            this.props.onCancelClick(event);
                        }
                    } else {
                        this.props.onCancelClick(event);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onPopState(event) {
        $(".modal").modal("hide");
        $(".modal-backdrop").remove();
        $(".in").remove();
    }

    render() {
        let headerButton = null;
        if (Array.isArray(this.props.children)) {
            headerButton = this.props.children.filter(item => item && item.type === DGDGModalDialogHeaderButtonComponent)[0];
        }

        let modalCssName = "modal fade " + this.props.modalClassName ?? "";
        let modalDialogCssName = "modal-dialog " + this.props.cssName ?? "";
        return <div ref={refElement => this.modal = refElement} className={modalCssName} style={this.props.style} role="dialog" tabIndex="-1" data-backdrop="static" data-keyboard="false" aria-hidden="true">
            <div ref={refElement => this.modalDialog = refElement} className={modalDialogCssName} role="document">
                <div ref={refElement => this.modalContent = refElement} className="modal-content">
                    <div className="card">
                        <div className="card-header dgdg-modal-dialog-header">
                            <span dangerouslySetInnerHTML={{ __html: this.props.title }} />
                            <div className="float-right">
                                {
                                    headerButton
                                        ? headerButton.props.children
                                        : null
                                }
                                {
                                    !this.props.hideCancel
                                        ? <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onCancelClick(event)}>
                                            <i className="fas fa-window-close text-danger dgdg-font-size-18" />
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="card-block">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

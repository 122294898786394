import React from "react";

export class DGDGQueryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showQuery: true
        }
        this.onToggleClick = this.onToggleClick.bind(this);
    }

    onToggleClick() {
        this.setState(prevState => ({
            showQuery: !prevState.showQuery
        }));
    }

    render() {
        return <div className={"card " + this.props.cssName}>
            <div className="card-block">
                <div className="text-center" onClick={(event) => this.onToggleClick(event)}>
                    {
                        !this.state.showQuery
                            ? <label>Filter Criteria</label>
                            : null
                    }
                    <button className="btn btn-link dgdg-query-toggle" aria-expanded={this.state.showQuery ? "true" : "false"}>
                        <i className={"fas " + (this.state.showQuery ? "fa-chevron-double-up" : "fa-chevron-double-down")}></i>
                    </button>
                </div>
                {
                    this.state.showQuery
                        ? <div className="form-inline">
                            <div className="form-row dgdg-query">
                                {this.props.children}
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    }
}
import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Link } from "react-router-dom";
import { CommonService } from "../js_modules/dgdgjs/CommonService";
import moment from "moment";
import $ from 'jquery';

import { DGDGAlertComponent } from "../common/dgdgalert/DGDGAlertComponent";
import { DGDGFooterComponent } from "../common/dgdgfooter/DGDGFooterComponent";
import { DGDGHeaderComponent } from "../common/dgdgheader/DGDGHeaderComponent";
import { DGDGLeftBarComponent } from "../common/dgdgleftbar/DGDGLeftBarComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { ActiveDirectoryUserRouteComponent } from "../activedirectoryuser/ActiveDirectoryUserRouteComponent";
import { AutoCheckVHRRouteComponent } from "../autocheckvhr/AutoCheckVHRRouteComponent";
import { BadDebtRouteComponent } from "../baddebt/BadDebtRouteComponent";
import { BuyCenterRouteComponent } from "../buycenter/BuyCenterRouteComponent";
import { CacheRouteComponent } from "../cache/CacheRouteComponent"
import { CalculateMMRRouteComponent } from "../calculatemmr/CalculateMMRRouteComponent"
import { CarvanaExportRouteComponent } from "../carvanaexport/CarvanaExportRouteComponent"
import { ControllerPolicyRouteComponent } from "../controllerpolicy/ControllerPolicyRouteComponent"
import { CovidRouteComponent } from "../covid/CovidRouteComponent";
import { CovidSubmitComponent } from "../covid/CovidSubmitComponent";
import { CITRouteComponent } from "../cit/CITRouteComponent";
import { CSIRouteComponent } from '../csi/CSIRouteComponent';
import { CPOInspectionRouteComponent } from "../cpoinspection/CPOInspectionRouteComponent";
import { DashboardRouteComponent } from "../dashboard/DashboardRouteComponent";
import { DemoChartRouteComponent } from "../demochart/DemoChartRouteComponent";
import { DemoPageRouteComponent } from "../demopage/DemoPageRouteComponent";
import { DMVRegistrationRouteComponent } from "../dmvregistration/DMVRegistrationRouteComponent";
import { DirectoryRouteComponent } from "../directory/DirectoryRouteComponent";
import { NonSalesEmployeeTimeTrackRouteComponent } from "../nonsalesemployeetimetrack/NonSalesEmployeeTimeTrackRouteComponent";
import { SalesEmployeeTimeTrackRouteComponent } from "../salesemployeetimetrack/SalesEmployeeTimeTrackRouteComponent";
import { ExcelReportsRouteComponent } from "../excelreports/ExcelReportsRouteComponent";
import { DealIssuesRouteComponent } from "../dealIssues/DealIssuesRouteComponent";
import { DGDGDataRouteComponent } from "../dgdgdata/DGDGDataRouteComponent";
import { FacebookSalesCSVRouteComponent } from "../facebooksalescsv/FacebookSalesCSVRouteComponent";
import { FIBankPartnershipReportRouteComponent } from "../fibankpartnershipreport/FIBankPartnershipReportRouteComponent";
import { FIDealReportRouteComponent } from "../fidealreport/FIDealReportRouteComponent";
import { FILenderReportRouteComponent } from "../filenderreport/FILenderReportRouteComponent";
import { FinancialStatementRouteComponent } from "../financialstatement/FinancialStatementRouteComponent";
import { FIPayrollRouteComponent } from "../fipayroll/FIPayrollRouteComponent";
import { FISalesPenetrationReportRouteComponent } from "../fisalespenetrationreport/FISalesPenetrationReportRouteComponent";
import { FIStoreReportRouteComponent } from "../fistorereport/FIStoreReportRouteComponent";
import { FrontEndAdjustmentsRouteComponent } from "../frontendadjustments/FrontEndAdjustmentsRouteComponent";
import { InterCompanyBalancesRouteComponent } from "../intercompanybalances/InterCompanyBalancesRouteComponent";
import { OnboardingRouteComponent } from "../onboarding/OnboardingRouteComponent";
import { ManualCheckRouteComponent } from "../manualcheck/ManualCheckRouteComponent";
import { NewCarMerchandisingRouteComponent } from "../newcarmerchandising/NewCarMerchandisingRouteComponent";
import { NewPDIRouteComponent } from "../newpdi/NewPDIRouteComponent";
import { OPDSalesRouteComponent } from "../opdsales/OPDSalesRouteComponent";
import { OpenROsRouteComponent } from "../openros/OpenROsRouteComponent";
import { OrgChartRouteComponent } from "../orgchart/OrgChartRouteComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";
import { PartsPayrollRouteComponent } from "../partspayroll/PartsPayrollRouteComponent";
import { PartsReportRouteComponent } from "../partsreport/PartsReportRouteComponent";
import { ProductReserveAdjustmentsRouteComponent } from "../productreserveadjustments/ProductReserveAdjustmentsRouteComponent";
import { PSOPSSalesRouteComponent } from "../psopssales/PSOPSSalesRouteComponent";
import { ManageReportsRouteComponent } from "../managereports/ManageReportsRouteComponent";
import { ReportsRouteComponent } from "../reports/ReportsRouteComponent";
import { SalesDealReportRouteComponent } from "../salesdealreport/SalesDealReportRouteComponent";
import { RORouteComponent } from "../ro/RORouteComponent";
import { SalesforceRouteComponent } from "../salesforce/SalesforceRouteComponent";
import { SalesforceUserRouteComponent } from "../salesforceuser/SalesforceUserRouteComponent";
import { SalesManagerPayrollRouteComponent } from "../salesmanagerpayroll/SalesManagerPayrollRouteComponent";
import { SalesStoreReportRouteComponent } from "../salesstorereport/SalesStoreReportRouteComponent";
import { EVOXImagesRouteComponent } from "../evoximages/EVOXImagesRouteComponent";
import { ServiceAdvisorReportRouteComponent } from "../serviceadvisorreport/ServiceAdvisorReportRouteComponent";
import { ServiceManagerPayrollRouteComponent } from "../servicemanagerpayroll/ServiceManagerPayrollRouteComponent";
import { SalesPayrollRouteComponent } from "../salespayroll/SalesPayrollRouteComponent";
import { SellToDGDGRouteComponent } from "../selltodgdg/SellToDGDGRouteComponent";
import { ServicePayrollRouteComponent } from "../servicepayroll/ServicePayrollRouteComponent";
import { ServiceScheduleRouteComponent } from "../serviceschedule/ServiceScheduleRouteComponent";
import { ServiceStoreReportRouteComponent } from "../servicestorereport/ServiceStoreReportRouteComponent";
import { StoreRouteComponent } from "../store/StoreRouteComponent"
import { TechnicianPayrollRouteComponent } from "../technicianpayroll/TechnicianPayrollRouteComponent";
import { TechnicianScoreCardRouteComponent } from '../technicianscorecard/TechnicianScoreCardRouteComponent';
import { TeamMemberProfileRouteComponent } from "../teammemberprofile/TeamMemberProfileRouteComponent";
import { UsedCarMerchandisingRouteComponent } from "../usedcarmerchandising/UsedCarMerchandisingRouteComponent";
import { UsedReconditioningRouteComponent } from "../usedreconditioning/UsedReconditioningRouteComponent";
import { UsedReconditioningMobileRouteComponent } from "../usedreconditioningmobile/UsedReconditioningMobileRouteComponent";
import { UserRouteComponent } from "../user/UserRouteComponent";
import { USWarrantyReconciliationRouteComponent } from "../uswarrantyreconciliation/USWarrantyReconciliationRouteComponent";
import { USWarrantyReportRouteComponent } from "../uswarrantyreport/USWarrantyReportRouteComponent";
import { VinSolutionsLegacyNotesRouteComponent } from "../vinsolutionslegacynotes/VinSolutionsLegacyNotesRouteComponent";
import { WholesaleYOYReportRouteComponent } from "../wholesaleyoyreport/WholesaleYOYReportRouteComponent";
import { WorkFlowRouteComponent } from "../workflow/WorkFlowRouteComponent";

export class SiteRootComponent extends React.Component {
    constructor(props) {
        super(props);

        let consoleRows = [];
        this.state = {
            isSigningIn: false,
            showHeader: true,
            showBreadCrumb: true,
            showLeftBar: true,
            showFooter: true,
            consoleData: consoleRows,
            menuItems: null,
            isAuthenticated: null,
            canShowUsers: null,
            canShowCache: null,
            canShowStore: null,
            canShowControllerPolicy: null,
            canExitImpersonation: false,
            canShowDevOptions: false,
            canShowAppExAdminOptions: false,
            canAddEditUser: false,
            identityShortName: null,
            identityName: null,
            firstName: null,
            lastName: null,
            remoteUpn: null,
            tenantName: null,
            positionCode: null,
            storeNumber: null,
            instanceId: null,
            title: "Home",
            leftBarState: "Expanded",
            breadCrumbState: [],
            alertType: "",
            message: "",
            txnId: "",
            storeName: ""
        };

        console.devLog = function (log, componentName, functionName) {
            let logString = "";
            if (log instanceof Object) {
                logString = JSON.stringify(log);
            } else {
                logString = log ? log.toString() : "";
            }

            if (!componentName || !functionName) {
                let stack = Error().stack;
                let traceString = stack.split('\n')[2].trim();
                let traceComponentRegex = /^at (.*) (.*)$/g;
                let traceMatch = traceComponentRegex.exec(traceString);
                if (traceMatch) {
                    if (!componentName) {
                        componentName = traceMatch[1].split('.');
                        componentName = componentName[0].replace("new ", "");
                    }

                    if (!functionName) {
                        functionName = traceMatch[1].split('.');
                        if (functionName.length === 1) {
                            functionName = functionName[0];
                        } else {
                            functionName = functionName[1];
                        }

                        if (functionName.indexOf("new ") > -1) {
                            functionName = "constructor";
                        }
                    }
                }
            }

            let className = "";
            if (logString && logString.startsWith("Props:")) {
                className = "dgdg-console-log-props";
            }
            else if (logString && logString.startsWith("State:")) {
                className = "dgdg-console-log-state";
            }
            else if (logString && logString.startsWith("ACL:")) {
                className = "dgdg-console-log-roles";
            }

            let infoRow = {
                [CommonService.ordinalColumnName]: consoleRows.length + 1,
                timestamp: moment().toISOString(),
                logType: "Info",
                message: logString,
                rowCssName: className,
                componentName: componentName,
                functionName: functionName
            };

            //add the logData into array.
            consoleRows.push(infoRow);

            //console.log(log);
        };

        console.devError = function (error, parsedError, applicationInsights) {
            let componentName = "", functionName = "", message = "", timestamp = moment().toISOString();
            if (parsedError && parsedError.message) {
                message = parsedError.message;
            }
            else {
                if (error.stack) {
                    message = error.stack.split('\n')[0].replace("TypeError: ", "");
                }
            }

            if (error.url) {
                let errorUrl = (new URL(error.url)).pathname.split('/');
                componentName = errorUrl[1];
                functionName = errorUrl[errorUrl.length - 1];
            }
            else {
                let traceString = "";
                if (error.stack.split('\n').length > 1) {
                    traceString = error.stack.split('\n')[1].trim();
                }

                let traceComponentRegex = /^at (.*) (.*)$/g;
                let traceMatch = traceComponentRegex.exec(traceString);
                if (traceMatch) {
                    componentName = traceMatch[1].split('.')[0];
                    functionName = traceMatch[1].split('.')[1];
                }
            }

            let errorRow = {
                [CommonService.ordinalColumnName]: consoleRows.length + 1,
                timestamp: timestamp,
                logType: "Error",
                message: message,
                rowCssName: "dgdg-font-red",
                componentName: componentName,
                functionName: functionName
            };

            if (!parsedError) { // this is not a server error
                CommonService.clientAzureStorageLog(componentName, functionName, "JsError", message, null, applicationInsights);
            }

            //add the logData into array.
            consoleRows.push(errorRow);
            console.error(error);
        };

        this.onBreadCrumbStateChanged = this.onBreadCrumbStateChanged.bind(this);
        this.onLeftBarStateChanged = this.onLeftBarStateChanged.bind(this);
        this.onShowAlert = this.onShowAlert.bind(this);
        this.onHideAlert = this.onHideAlert.bind(this);
        this.onCovidMount = this.onCovidMount.bind(this);
        this.onCovidUnmount = this.onCovidUnmount.bind(this);
        this.onSalesforceMount = this.onSalesforceMount.bind(this);
        this.onSalesforceUnmount = this.onSalesforceUnmount.bind(this);
        this.menuRef = React.createRef();
        this.handleOnMenuBlur = this.handleOnMenuBlur.bind(this);
    }

    componentDidMount() {
        try {
            console.devLog("SiteRootComponent - componentDidMount");
            this.setState({
                isSigningIn: true
            }, this.getSiteData);

            if (this.state.isAuthenticated) {
                CommonService.clientAzureStorageLog("SiteRootComponent", "componentDidMount", null, null, null, this.state.applicationInsights);
            }

            document.addEventListener('mousedown', this.handleOnMenuBlur);
        }
        catch (error) {
            console.devError(error);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOnMenuBlur);
    }

    pageTitle = "Home";
    autoHideTimeout;

    getSiteData() {
        fetch("/Site/GetSiteData")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                let applicationInsights = new ApplicationInsights({
                    config: {
                        connectionString: parsedResponse.applicationInsightConnectionString,
                        enableAutoRouteTracking: false
                    }
                });
                applicationInsights.loadAppInsights();
                this.setState({
                    isSigningIn: false,
                    menuItems: parsedResponse.menuItems,
                    isAuthenticated: parsedResponse.isAuthenticated,
                    canShowUsers: parsedResponse.canShowUsers,
                    canShowCache: parsedResponse.canShowCache,
                    canShowStore: parsedResponse.canShowStore,
                    canShowControllerPolicy: parsedResponse.canShowControllerPolicy,
                    canExitImpersonation: parsedResponse.canExitImpersonation,
                    canShowDevOptions: parsedResponse.canShowDevOptions,
                    canShowAppExAdminOptions: parsedResponse.canShowAppExAdminOptions,
                    canAddEditUser: parsedResponse.canAddEditUser,
                    identityShortName: parsedResponse.identityShortName,
                    identityName: parsedResponse.identityName,
                    firstName: parsedResponse.givenName,
                    lastName: parsedResponse.surname,
                    remoteUpn: parsedResponse.remoteUpn,
                    tenantName: parsedResponse.tenantName,
                    positionCode: parsedResponse.positionCode,
                    storeNumber: parsedResponse.storeNumber,
                    instanceId: parsedResponse.instanceId,
                    storeName: parsedResponse.storeName,
                    applicationInsights: applicationInsights,
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError);
                        });
                }
            });
    }

    onBreadCrumbStateChanged(brdCrumbState) {
        if (this.state.breadCrumbState !== brdCrumbState) {
            document.title = brdCrumbState.pageTitle;
            this.setState({
                title: brdCrumbState.pageTitle,
                breadCrumbState: brdCrumbState
            });
        }

        this.onHideAlert();
    }

    onLeftBarStateChanged(event, lftBarState) {
        if (JSON.stringify(this.state.leftBarState) !== JSON.stringify(lftBarState)) {
            this.setState({
                leftBarState: lftBarState,
                activeMenuId: "Collapsed"
            });
        }
    }

    onShowAlert(alertType, alertObj, alertTimeout) {
        let message = "";
        let txnId = "";
        if (alertObj.responseJSON !== undefined || alertObj.message !== undefined) {
            alertObj = alertObj.responseJSON ? alertObj.responseJSON : alertObj;
            message = alertObj.message ? alertObj.message : alertObj.toString();
            txnId = alertObj.txnId ? alertObj.txnId : "";
        }
        else if (alertObj.status && alertObj.status === "403") {
            message = "Unauthorized request. Please contact your System Administrator.";
        }

        this.setState({
            alertType: alertType,
            message: message,
            txnId: txnId
        });

        if (alertTimeout > 0) {
            this.autoHideTimeout = setTimeout(this.onHideAlert, alertTimeout);
        } else {
            if (this.autoHideTimeout) {
                clearTimeout(this.autoHideTimeout);
                this.autoHideTimeout = null;
            }
        }
    }

    onHideAlert() {
        this.onShowAlert("", "", "", 0);
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
            this.autoHideTimeout = null;
        }
    }

    onCovidMount() {
        this.setState({
            showHeader: false,
            showBreadCrumb: false,
            showLeftBar: false
        });
    }

    onCovidUnmount() {
        this.setState({
            showHeader: true,
            showBreadCrumb: true,
            showLeftBar: true
        });
    }

    onSalesforceMount() {
        this.setState({
            showLeftBar: false,
            showBreadCrumb: false,
            showFooter: false
        });
    }

    onSalesforceUnmount() {
        this.setState({
            showLeftBar: true,
            showBreadCrumb: true,
            showFooter: true
        });
    }

    handleOnMenuBlur(event) {
        if (this.menuRef && this.menuRef.current && !this.menuRef.current.contains(event.target)) {
            $('#mobileMenu').removeClass('show');
        }
    }

    render() {
        return <Fragment>
            <BrowserRouter>
                <div className={"dgdg-flex dgdg-flex-row " + (this.state.showFooter ? "dgdg-site" : "dgdg-site-no-footer")}>
                    {
                        this.state.showLeftBar
                            ? <div className="dgdg-flex-item dgdg-desktop">
                                <DGDGLeftBarComponent applicationInsights={this.state.applicationInsights} menuItems={this.state.menuItems} leftBarState={this.state.leftBarState} onLeftBarStateChanged={this.onLeftBarStateChanged} showLeftBar={this.state.showLeftBar} />
                            </div>
                            : null
                    }
                    <div className={this.state.showLeftBar ? this.state.leftBarState === "Expanded" ? "dgdg-site-collapsed" : "dgdg-site-expanded" : "dgdg-site-content"}>
                        <div className="dgdg-flex dgdg-flex-column dgdg-site-content">
                            <DGDGAlertComponent alertType={this.state.alertType} message={this.state.message} txnId={this.state.txnId} onHideAlert={this.onHideAlert} />
                            <div className="dgdg-mobile">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            {
                                                this.state.showLeftBar && this.state.menuItems
                                                    ? <td style={{ "width": "35px" }}>
                                                        <nav className="navbar navbar-light">
                                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobileMenu" aria-controls="mobileMenu" aria-expanded="false" aria-label="Toggle navigation">
                                                                <span className="navbar-toggler-icon" />
                                                            </button>
                                                            <div className="collapse navbar-collapse dgdg-mobile-menu-main" id="mobileMenu" ref={this.menuRef}>
                                                                <DGDGLeftBarComponent menuItems={this.state.menuItems} leftBarState={this.state.leftBarState} onLeftBarStateChanged={this.onLeftBarStateChanged} showLeftBar={this.state.showLeftBar} />
                                                            </div>
                                                        </nav>
                                                    </td>
                                                    : null
                                            }
                                            {
                                                this.state.showLeftBar
                                                    ? <td style={{ "width": "30px", "paddingLeft": "12px" }}>
                                                        <Link to="/">
                                                            <img src="images/dgdg_plate.png" style={{ height: "30px" }} alt="DGDG" />
                                                        </Link>
                                                    </td>
                                                    : null
                                            }
                                            <td>
                                                {
                                                    this.state.showHeader
                                                        ? <DGDGHeaderComponent isAuthenticated={this.state.isAuthenticated} canShowUsers={this.state.canShowUsers} canShowCache={this.state.canShowCache} canShowStore={this.state.canShowStore} canShowControllerPolicy={this.state.canShowControllerPolicy}
                                                            canExitImpersonation={this.state.canExitImpersonation} identityShortName={this.state.identityShortName}
                                                            identityName={this.state.identityName} firstName={this.state.firstName} lastName={this.state.lastName} remoteUpn={this.state.remoteUpn} tenantName={this.state.tenantName} title={this.state.title} storeName={this.state.storeName}
                                                        />
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.showHeader
                                    ? <div className="dgdg-desktop">
                                        <DGDGHeaderComponent isAuthenticated={this.state.isAuthenticated} canShowUsers={this.state.canShowUsers} canShowCache={this.state.canShowCache} canShowStore={this.state.canShowStore} canShowControllerPolicy={this.state.canShowControllerPolicy}
                                            canExitImpersonation={this.state.canExitImpersonation} identityShortName={this.state.identityShortName}
                                            identityName={this.state.identityName} firstName={this.state.firstName} lastName={this.state.lastName} remoteUpn={this.state.remoteUpn} tenantName={this.state.tenantName} title={this.state.title} storeName={this.state.storeName}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                !this.state.isAuthenticated
                                    ? <div className="dgdg-signin">
                                        {
                                            this.state.isSigningIn
                                                ? <DGDGSpinnerComponent showSpinner={this.state.isSigningIn} />
                                                : <a type="button" className="btn btn-primary" href={"/Account/Login?returnUrl=" + window.location.pathname}>Sign In</a>
                                        }
                                    </div>
                                    : null
                            }
                            <Switch>
                                <Route exact path="/" render={(routeProps) => <DashboardRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                <Route exact path="/Covid" render={(routeProps) => <CovidRouteComponent {...this.props} {...routeProps} {...this.state} onShowAlert={this.onShowAlert} onCovidMount={this.onCovidMount} onCovidUnmount={this.onCovidUnmount} />} />
                                <Route exact path="/CovidSubmit" render={(routeProps) => <CovidSubmitComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                {
                                    this.state.isAuthenticated
                                        ? <Switch>
                                            <Route exact path="/ActiveDirectoryUser" render={(routeProps) => < ActiveDirectoryUserRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/AutoCheckVHR" render={(routeProps) => <AutoCheckVHRRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/BadDebt" render={(routeProps) => <BadDebtRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/BuyCenter" render={(routeProps) => <BuyCenterRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Cache" render={(routeProps) => <CacheRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/CalculateMMR" render={(routeProps) => <CalculateMMRRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/CarvanaExport" render={(routeProps) => <CarvanaExportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/CIT" render={(routeProps) => <CITRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/CSI" render={(routeProps) => <CSIRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ControllerPolicy" render={(routeProps) => <ControllerPolicyRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/CPOInspection" render={(routeProps) => <CPOInspectionRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/CPOInspection/:vin" render={(routeProps) => <CPOInspectionRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Dashboard" render={(routeProps) => <DashboardRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/DemoChart" render={(routeProps) => <DemoChartRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/DemoPage" render={(routeProps) => <DemoPageRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/Directory" render={(routeProps) => <DirectoryRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/NonSalesEmployeeTimeTrack" render={(routeProps) => <NonSalesEmployeeTimeTrackRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/SalesEmployeeTimeTrack" render={(routeProps) => <SalesEmployeeTimeTrackRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/ExcelReports" render={(routeProps) => <ExcelReportsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/DealIssues" render={(routeProps) => <DealIssuesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/DGDGData" render={(routeProps) => <DGDGDataRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FacebookSalesCSV" render={(routeProps) => <FacebookSalesCSVRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FIBankPartnershipReport" render={(routeProps) => <FIBankPartnershipReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FIDealReport" render={(routeProps) => <FIDealReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FILenderReport" render={(routeProps) => <FILenderReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FinancialStatement" render={(routeProps) => <FinancialStatementRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/FIPayroll" render={(routeProps) => <FIPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/FISalesPenetrationReport" render={(routeProps) => <FISalesPenetrationReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/FIStoreReport" render={(routeProps) => <FIStoreReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/FrontEndAdjustments" render={(routeProps) => <FrontEndAdjustmentsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/InterCompanyBalances" render={(routeProps) => <InterCompanyBalancesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Onboarding" render={(routeProps) => <OnboardingRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ManageReports" render={(routeProps) => <ManageReportsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ManualCheck" render={(routeProps) => <ManualCheckRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/NewCarMerchandising" render={(routeProps) => <NewCarMerchandisingRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/NewPDI" render={(routeProps) => <NewPDIRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/OPDSales" render={(routeProps) => <OPDSalesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/OpenROs" render={(routeProps) => <OpenROsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/OrgChart" render={(routeProps) => <OrgChartRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/PartsPayroll" render={(routeProps) => <PartsPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/PartsReport" render={(routeProps) => <PartsReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/ProductReserveAdjustments" render={(routeProps) => <ProductReserveAdjustmentsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/PSOPSSales" render={(routeProps) => <PSOPSSalesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/SalesDealReport" render={(routeProps) => <SalesDealReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/Reports/:Name?" render={(routeProps) => <ReportsRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/RO" render={(routeProps) => <RORouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Salesforce" render={(routeProps) => <SalesforceRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/SalesforceUser" render={(routeProps) => <SalesforceUserRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/SalesManagerPayroll" render={(routeProps) => <SalesManagerPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/SalesStoreReport" render={(routeProps) => <SalesStoreReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/DMVRegistration" render={(routeProps) => <DMVRegistrationRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/SalesPayroll" render={(routeProps) => <SalesPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/SellToDGDG" render={(routeProps) => <SellToDGDGRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/EVOXImages" render={(routeProps) => <EVOXImagesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ServiceAdvisorReport" render={(routeProps) => <ServiceAdvisorReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ServiceManagerPayroll" render={(routeProps) => <ServiceManagerPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/ServicePayroll" render={(routeProps) => <ServicePayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/ServiceSchedule" render={(routeProps) => <ServiceScheduleRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/ServiceStoreReport" render={(routeProps) => <ServiceStoreReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Site" render={(routeProps) => <DashboardRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/Store" render={(routeProps) => <StoreRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/TeamMemberProfile" render={(routeProps) => <TeamMemberProfileRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/TechnicianPayroll" render={(routeProps) => <TechnicianPayrollRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/TechnicianScoreCard" render={(routeProps) => <TechnicianScoreCardRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/UsedCarMerchandising" render={(routeProps) => <UsedCarMerchandisingRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/UsedReconditioning" render={(routeProps) => <UsedReconditioningRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/UsedReconditioningMobile" render={(routeProps) => <UsedReconditioningMobileRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route path="/User" render={(routeProps) => <UserRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/USWarrantyReconciliation" render={(routeProps) => <USWarrantyReconciliationRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/USWarrantyReport" render={(routeProps) => <USWarrantyReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/VinSolutionsLegacyNotes" render={(routeProps) => <VinSolutionsLegacyNotesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/VinSolutionsLegacyNotes/:autoLeadId" render={(routeProps) => <VinSolutionsLegacyNotesRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} onSalesforceMount={this.onSalesforceMount} onSalesforceUnmount={this.onSalesforceUnmount} />} />
                                            <Route exact path="/WholesaleYOYReport" render={(routeProps) => <WholesaleYOYReportRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route exact path="/WorkFlow" render={(routeProps) => <WorkFlowRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                            <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} {...this.state} onBreadCrumbStateChanged={this.onBreadCrumbStateChanged} onShowAlert={this.onShowAlert} />} />
                                        </Switch>
                                        : null
                                }
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
            {
                this.state.showFooter
                    ? <DGDGFooterComponent canShowDevOptions={this.state.canShowDevOptions} consoleData={this.state.consoleData} instanceId={this.state.instanceId} onShowAlert={this.onShowAlert} applicationInsights={this.state.applicationInsights} />
                    : null
            }
        </Fragment>;
    }
}
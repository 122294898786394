import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { OPDSalesEditComponent } from "./OPDSalesEditComponent";
import { OPDSalesQueryComponent } from "./OPDSalesQueryComponent";

export class OPDSalesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            stores: null,
            selectedStore: null,
            tableData: [],
            teamMembersTableData: [],
            employeeList: null,
            rowData: null,
            showDialog: false,
            paintTable: false
        }

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onVolumeGoalColumnCallback = this.onVolumeGoalColumnCallback.bind(this);
        this.onClosingGoalColumnCallback = this.onClosingGoalColumnCallback.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveEmployeeClick = this.onSaveEmployeeClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ManageReportsRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ManageReports", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "ManageReportsRouteComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "OPD Sales";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "BadDebtRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                <i className="far fa-edit" />
            </button>
            <DGDGSpinnerComponent showSpinner={rowData.action_showSpinner} />
        </Fragment>
    }

    onActionEditClick(event, rowData) {
        try {
            this.getEmployeeList(rowData.store_number);
            this.getTeamMembers(rowData.opd_sales_team_id);
            this.setState({
                rowData: rowData,
                showDialog: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVolumeGoalColumnCallback(rowData) {
        return <Fragment>
            <div>
                {rowData.volume_goal_1}
                < br />
                {rowData.volume_goal_2}
                < br />
                {rowData.volume_goal_3}
            </div>
        </Fragment >
    }

    onClosingGoalColumnCallback(rowData) {
        return <Fragment>
            <div>
                {rowData.closing_ratio_goal_1}
                < br />
                {rowData.closing_ratio_goal_2}
                < br />
                {rowData.closing_ratio_goal_3}
                < br />
                {rowData.closing_ratio_goal_4}
            </div>
        </Fragment >
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null
            };

            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, opdSales) {
        try {
            let inputData = opdSales;
            this.props.applicationInsights.trackTrace({ message: "/OPDSales/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/SaveOPDSales", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick();
                    this.props.onShowAlert("success", { message: "OPD Saled saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/SaveOPDSales", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEmployeeClick(event, opdTeamMember) {
        try {
            let inputData = opdTeamMember;
            this.props.applicationInsights.trackTrace({ message: "/OPDSales/onSaveEmployeeClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/SaveTeamMember", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.getTeamMembers(opdTeamMember.opdSalesTeamId);
                    this.props.onShowAlert("success", { message: "Team member added." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/SaveTeamMember", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                rowData: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getEmployeeList(storeNumber) {
        let inputData = {
            storeNumber: storeNumber
        };
        this.props.applicationInsights.trackTrace({ message: "OPDSalesRouteComponent/getEmployeeList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetEmployeeList", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse.forEach((responseItem, index) => {
                    responseItem.employee = responseItem.employee_name + ", " + responseItem.employee_number
                });
                this.setState({ employeeList: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getTeamMembers(opdSalesTeamId) {
        let inputData = {
            opdSalesTeamId: opdSalesTeamId
        };
        this.props.applicationInsights.trackTrace({ message: "OPDSalesRouteComponent/getTeamMembers", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetTeamMembers", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ teamMembersTableData: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <OPDSalesQueryComponent {...this.props}
                    applicationInsights={this.props.applicationInsights}
                    stores={this.state.stores}
                    isLoadingData={this.state.isLoadingData}
                    onGetReportClick={this.onGetReportClick}
                />
                <OPDSalesEditComponent
                    applicationInsights={this.props.applicationInsights}
                    rowData={this.state.rowData}
                    employeeList={this.state.employeeList}
                    teamMembersTableData={this.state.teamMembersTableData}
                    onSaveClick={this.onSaveClick}
                    onSaveEmployeeClick={this.onSaveEmployeeClick}
                    onCancelClick={this.onCancelClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-card-gutter">
                        <DGDGTableV3Component cssName="dgdg-reports-table" applicationInsights={this.props.applicationInsights} headerText="OPD Sales" tableData={this.state.tableData} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" showSpinner />
                                <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_web_name" filterColumn="store_web_name" />
                                <DGDGTableColumnComponent headerText="Team" dataColumn="team_name" sortColumn="team_name" filterColumn="team_name" />
                                <DGDGTableColumnComponent headerText="Closing Percentages" />
                                <DGDGTableColumnComponent headerText="Volume Goal" dataColumnCallback={this.onVolumeGoalColumnCallback} />
                                <DGDGTableColumnComponent headerText="Closing Goal" dataColumnCallback={this.onClosingGoalColumnCallback} />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div >
            </div >
        </DGDGPageComponent >;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { SalesDealReportQueryComponent } from "./SalesDealReportQueryComponent";

export class SalesDealReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            isLoadingData: false,
            isDownloading: false,
            tableTitle: "",
            paintTable: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("SalesDealReportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesDealReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            this.getStores();
            CommonService.clientAzureStorageLog("SalesDealReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Deal Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SalesDealReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesDealReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "SalesDealReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesDealReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/SalesDealReportRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/SalesDealReport/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesDealReport/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore, startDate, endDate) {
        try {
            this.setState({ isLoadingData: true });
            var inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString()),
                endDate: CommonService.formatServerDateTime(endDate.toString())
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesDealReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesDealReport/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table.filter(rowData => rowData.store_number === selectedStore.number);

                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore.display_name + " - " +
                            CommonService.formatDate(moment(startDate)) + " - " +
                            CommonService.formatDate(moment(endDate)),
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesDealReport/GetStores", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(selectedStore, startDate, endDate) {
        try {
            this.setState({ isDownloading: true });
            let inputData = {
                storeNumber: selectedStore.number,
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesDealReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesDealReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownloading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownloading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesDealReport/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SalesDealReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <SalesDealReportQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData} isDownloading={this.state.isDownloading} stores={this.state.stores}
                    onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                            <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Sale Type" dataColumn="sale_type" sortColumn="retail_lease_pr" filterColumn="retail_lease_pr" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                            <DGDGTableColumnComponent headerText="Customer First" dataColumn="customer_first" sortColumn="customer_first" filterColumn="customer_first" />
                            <DGDGTableColumnComponent headerText="Customer Middle" dataColumn="customer_middle" sortColumn="customer_middle" filterColumn="customer_middle" />
                            <DGDGTableColumnComponent headerText="Customer Last" dataColumn="customer_last" sortColumn="customer_last" filterColumn="customer_last" />
                            <DGDGTableColumnComponent headerText="Home Phone" dataColumn="home_phone" sortColumn="home_phone" filterColumn="home_phone" />
                            <DGDGTableColumnComponent headerText="Business Phone" dataColumn="business_phone" sortColumn="business_phone" filterColumn="business_phone" />
                            <DGDGTableColumnComponent headerText="Email 1" dataColumn="customer_email_1" sortColumn="customer_email_1" filterColumn="customer_email_1" />
                            <DGDGTableColumnComponent headerText="Email 2" dataColumn="customer_email_2" sortColumn="customer_email_2" filterColumn="customer_email_2" />
                            <DGDGTableColumnComponent headerText="New Used Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                            <DGDGTableColumnComponent headerText="Vin" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="Vehicle Mileage" dataColumn="vehicle_mileage" sortColumn="vehicle_mileage" filterColumn="vehicle_mileage" dataType="number" />
                            <DGDGTableColumnComponent headerText="Sales Rep 1 Name" dataColumn="sales_rep_1_name" sortColumn="sales_rep_1_name" filterColumn="sales_rep_1_name" />
                            <DGDGTableColumnComponent headerText="Sales Rep 2 Name" dataColumn="sales_rep_2_name" sortColumn="sales_rep_2_name" filterColumn="sales_rep_2_name" />
                            <DGDGTableColumnComponent headerText="Sales Mgr Name" dataColumn="sales_mgr_name" sortColumn="sales_mgr_name" filterColumn="sales_mgr_name" />
                            <DGDGTableColumnComponent headerText="Finance Mgr Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" filterColumn="finance_mgr_name" />
                            <DGDGTableColumnComponent headerText="Finance Mgr Number" dataColumn="finance_mgr_number" sortColumn="finance_mgr_number" filterColumn="finance_mgr_number" />
                            <DGDGTableColumnComponent headerText="VSC Name" dataColumn="vsc_name" sortColumn="vsc_name" filterColumn="vsc_name" />
                            <DGDGTableColumnComponent headerText="VSC Sale Amount" dataType="money" dataColumn="vsc_sale_amount" sortColumn="vsc_sale_amount" filterColumn="vsc_sale_amount" />
                            <DGDGTableColumnComponent headerText="Term Care Name" dataColumn="term_care_name" sortColumn="term_care_name" filterColumn="term_care_name" />
                            <DGDGTableColumnComponent headerText="Term Care Sale Amount" dataType="money" dataColumn="term_care_sale_amount" sortColumn="term_care_sale_amount" filterColumn="term_care_sale_amount" />
                            <DGDGTableColumnComponent headerText="Wear Care Sale Amount" dataType="money" dataColumn="wear_care_sale_amount" sortColumn="wear_care_sale_amount" filterColumn="wear_care_sale_amount" />
                            <DGDGTableColumnComponent headerText="Maintenance Sale Amount" dataType="money" dataColumn="maint_sale_amount" sortColumn="maint_sale_amount" filterColumn="maint_sale_amount" />
                            <DGDGTableColumnComponent headerText="GAP Sale Amount" dataType="money" dataColumn="gap_sale_amount" sortColumn="gap_sale_amount" filterColumn="gap_sale_amount" />
                            <DGDGTableColumnComponent headerText="Road Hazard Sale Amount" dataType="money" dataColumn="road_haz_sale_amount" sortColumn="road_haz_sale_amount" filterColumn="road_haz_sale_amount" />
                            <DGDGTableColumnComponent headerText="Paint Sale Amount" dataType="money" dataColumn="paint_sale_amount" sortColumn="paint_sale_amount" filterColumn="paint_sale_amount" />
                            <DGDGTableColumnComponent headerText="Key Replace Sale Amount" dataType="money" dataColumn="key_replace_sale_amount" sortColumn="key_replace_sale_amount" filterColumn="key_replace_sale_amount" />
                            <DGDGTableColumnComponent headerText="Dent Ding Sale Amount" dataType="money" dataColumn="dent_ding_sale_amount" sortColumn="dent_ding_sale_amount" filterColumn="dent_ding_sale_amount" />
                            <DGDGTableColumnComponent headerText="Shadow Sale Amount" dataType="money" dataColumn="shadow_sale_amount" sortColumn="shadow_sale_amount" filterColumn="shadow_sale_amount" />
                            <DGDGTableColumnComponent headerText="Windshield Sale Amount" dataType="money" dataColumn="windshield_sale_amount" sortColumn="windshield_sale_amount" filterColumn="windshield_sale_amount" />
                            <DGDGTableColumnComponent headerText="Accessories Sale Amount" dataType="money" dataColumn="accessories_sale_amount" sortColumn="accessories_sale_amount" filterColumn="accessories_sale_amount" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

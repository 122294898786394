import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class DemoPageView1GlyphsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCodeColumnCallback = this.onCodeColumnCallback.bind(this);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    dashboardGlyphs = CommonService.addOrdinalColumn([
        { code: "fas fa-home", description: "Home" },
        { code: "fas fa-car", description: "Inventory" },
        { code: "fas fa-users", description: "Employee" },
        { code: "fas fa-dollar-sign", description: "Sales" },
        { code: "fas fa-shield-alt", description: "F&I" },
        { code: "fas fa-wrench", description: "Service & Parts" },
        { code: "fas fa-dot-circle", description: "Marketing" },
        { code: "fas fa-user", description: "HR" },
        { code: "fas fa-credit-card", description: "Accounting" },
        { code: "fas fa-network-wired", description: "IT" },
        { code: "fas fa-laptop", description: "Demo Page" }
    ]);

    otherGlyphs = CommonService.addOrdinalColumn([
        { code: "fas fa-plus", description: "Add" },
        { code: "fas fa-user-plus", description: "Add User" },
        { code: "fas fa-code-merge", description: "CDK Concatenate View" },
        { code: "far fa-calendar-alt", description: "Calendar" },
        { code: "far fa-times", description: "Clear text (Input Control)" },
        { code: "fas fa-times", description: "Close / Delete" },
        { code: "fas fa-times text-danger", description: "Close / Delete" },
        { code: "fas fa-window-close", description: "Close / Delete" },
        { code: "fas fa-window-close text-danger", description: "Close / Delete" },
        { code: "far fa-chevron-double-left", description: "Collpase Menu" },
        { code: "far fa-chevron-double-right", description: "Expand Menu" },
        { code: "far fa-copy", description: "Copy" },
        { code: "far fa-download", description: "Download" },
        { code: "fas fa-edit", description: "Edit" },
        { code: "fas fa-minus-circle", description: "Expire" },
        { code: "far fa-file", description: "File" },
        { code: "far fa-filter", description: "Filter" },
        { code: "fas fa-filter", description: "Clear Filter", style: { textDecoration: "line-through" } },
        { code: "fas fa-ban", description: "Filter Exclude" },
        { code: "fa fa-gas-pump", description: "Gas Pump" },
        { code: "far fa-sync-alt", description: "Refresh" },
        { code: "fas fa-redo-alt", description: "Get / Refresh" },
        { code: "fas fa-chart-line", description: "Get Report" },
        { code: "fas fa-image", description: "Image" },
        { code: "fas fa-user", description: "Impersonate" },
        { code: "far fa-info-circle", description: "Info Circle" },
        { code: "fas fa-align-justify", description: "Mobile Menu" },
        { code: "fas fa-chevron-circle-left", description: "Previous" },
        { code: "fas fa-chevron-circle-right", description: "Next" },
        { code: "fas fa-check", description: "Ok" },
        { code: "far fa-sitemap", description: "Org Chart" },
        { code: "far fa-print", description: "Print" },
        { code: "fas fa-sync-alt", description: "Process" },
        { code: "fas fa-upload", description: "Publish" },
        { code: "fas fa-save", description: "Save" },
        { code: "fas fa-chevron-double-up", description: "Scroll to Top" },
        { code: "fas fa-chevron-double-down", description: "Scroll to Down" },
        { code: "far fa-search", description: "Search" },
        { code: "far fa-cog", description: "Setting Menu" },
        { code: "far fa-sort-down", description: "Sort Down" },
        { code: "far fa-sort-up", description: "Sort Up" },
        { code: "fas fa-undo", description: "Undo" },
        { code: "fas fa-clipboard-check", description: "Verify (Import Wizard)" },
        { code: "far fa-window-maximize", description: "Window Maximize" },
        { code: "fal fa-window-restore", description: "Window Restore" },
        { code: "far fa-window-restore", description: "Window Restore" },
    ]);

    onCodeColumnCallback(rowData) {
        return <i className={rowData.code} style={rowData.style} />
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showGlyphs}
            cssName="dgdg-demo-page-glyphs-dialog"
            title="Glyphs"
            onCancelClick={this.props.onCancelGlyphsClick}
        >
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width dgdg-card-gutter" defaultSortColumn={CommonService.ordinalColumnName} headerText="Menu Glyphys" tableData={this.dashboardGlyphs}>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Code" dataColumn="code" dataColumnCallback={this.onCodeColumnCallback} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Description" dataColumn="description" sortColumn="description" filterColumn="description" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                        <br />
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" defaultSortColumn={CommonService.ordinalColumnName} headerText="Other Glyphys" tableData={this.otherGlyphs}>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Code" dataColumn="code" dataColumnCallback={this.onCodeColumnCallback} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Description" dataColumn="description" sortColumn="description" filterColumn="description" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}

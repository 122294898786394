import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class FIPayrollPlanEditComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("FIEmployeePayEditComponent - getDerivedStateFromProps");
            if (state.showProgress) {
                newState.showProgress = !props.isSaveComplete;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    hourlyData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" },
    ]
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            tier1PPRRange: "",
            tier1PPRPercentage: "",
            tier2PPRRange: "",
            tier2PPRPercentage: "",
            tier3PPRRange: "",
            tier3PPRPercentage: "",
            tier4PPRRange: "",
            tier4PPRPercentage: "",

            tier1PVRRange: "",
            tier1PVRPercentage: "",
            tier2PVRRange: "",
            tier2PVRPercentage: "",
            tier3PVRRange: "",
            tier3PVRPercentage: "",
            tier4PVRRange: "",
            tier4PVPercentage: "",

            tier1WarrantyPenPercentage: "",
            tier1WarrantyPercentage: "",
            tier2WarrantyPenPercentage: "",
            tier2WarrantyPercentage: "",
            tier3WarrantyPenPercentage: "",
            tier3WarrantyPercentage: "",
            tier4WarrantyPenPercentage: "",
            tier4Warrantyercentage: "",
            tier5WarrantyPenPercentage: "",
            tier5Warrantyercentage: "",

            tier1MaintenancePenPercentage: "",
            tier1MaintenancePercentage: "",
            tier2MaintenancePenPercentage: "",
            tier2MaintenancePercentage: "",
            tier3MaintenancePenPercentage: "",
            tier3MaintenancePercentage: "",

            allyYes: "",
            allyNo: "",
            csiYes: "",
            csiNo: "",
            citYesPenPercentage: "",
            citNoPenPercentage: "",

            productBonus: "",
            grossBonus: "",
            selectedHourly: "",
            hourly: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onHourlyChange = this.onHourlyChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.planDetail
                && (!prevProps.planDetail || prevProps.planDetail !== this.props.planDetail)) {
                this.setState({
                    tier1PPRRange: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier1.range),
                    tier1PPRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier1.value * 100),
                    tier2PPRRange: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier2.range),
                    tier2PPRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier2.value * 100),
                    tier3PPRRange: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier3.range),
                    tier3PPRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier3.value * 100),
                    tier4PPRRange: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier4.range),
                    tier4PPRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.ppr_income.tier4.value * 100),

                    tier1PVRRange: this.props.planDetail.plan.pvr_income.tier1.range,
                    tier1PVRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.pvr_income.tier1.value * 100),
                    tier2PVRRange: this.props.planDetail.plan.pvr_income.tier2.range,
                    tier2PVRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.pvr_income.tier2.value * 100),
                    tier3PVRRange: this.props.planDetail.plan.pvr_income.tier3.range,
                    tier3PVRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.pvr_income.tier3.value * 100),
                    tier4PVRRange: this.props.planDetail.plan.pvr_income.tier4.range,
                    tier4PVRPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.pvr_income.tier4.value * 100),

                    tier1WarrantyPenPercentage: this.props.planDetail.plan.warranty_income.tier1.range,
                    tier1WarrantyPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.warranty_income.tier1.value * 100),
                    tier2WarrantyPenPercentage: this.props.planDetail.plan.warranty_income.tier2.range,
                    tier2WarrantyPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.warranty_income.tier2.value * 100),
                    tier3WarrantyPenPercentage: this.props.planDetail.plan.warranty_income.tier3.range,
                    tier3WarrantyPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.warranty_income.tier3.value * 100),
                    tier4WarrantyPenPercentage: this.props.planDetail.plan.warranty_income.tier4.range,
                    tier4WarrantyPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.warranty_income.tier4.value * 100),
                    tier5WarrantyPenPercentage: this.props.planDetail.plan.warranty_income.tier5.range,
                    tier5WarrantyPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.warranty_income.tier5.value * 100),

                    tier1MaintenancePenPercentage: this.props.planDetail.plan.maintenance.tier1.range,
                    tier1MaintenancePercentage: CommonService.formatNumber_2(this.props.planDetail.plan.maintenance.tier1.value * 100),
                    tier2MaintenancePenPercentage: this.props.planDetail.plan.maintenance.tier2.range,
                    tier2MaintenancePercentage: CommonService.formatNumber_2(this.props.planDetail.plan.maintenance.tier2.value * 100),
                    tier3MaintenancePenPercentage: this.props.planDetail.plan.maintenance.tier3.range,
                    tier3MaintenancePercentage: CommonService.formatNumber_2(this.props.planDetail.plan.maintenance.tier3.value * 100),

                    allyYes: CommonService.formatNumber_2(this.props.planDetail.plan.ally.Y),
                    allyNo: CommonService.formatNumber_2(this.props.planDetail.plan.ally.N),
                    csiYes: CommonService.formatNumber_2(this.props.planDetail.plan.csi.Yes),
                    csiNo: CommonService.formatNumber_2(this.props.planDetail.plan.csi.No),

                    citYesPenPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.cit.Yes * 100),
                    citNoPenPercentage: CommonService.formatNumber_2(this.props.planDetail.plan.cit.No * 100),

                    productBonus: CommonService.formatNumber_2(this.props.planDetail.plan.ev.product_bonus_value),
                    grossBonus: CommonService.formatNumber_2(this.props.planDetail.plan.ev.gross_bonus_value),
                    selectedHourly: this.props.planDetail.plan.ev.hourly_value ? this.hourlyData.filter(hourly => hourly.key === this.props.planDetail.plan.ev.hourly_value)[0] : "Select Plan"
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "FI Payroll";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onHourlyChange(id, hourly) {
        try {
            this.setState({ selectedHourly: hourly });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (this.props.planDetail !== null) {
                this.setState({ showProgress: true });

                let plan = this.props.planDetail.plan;
                plan.ppr_income.tier1.range = this.state.tier1PPRRange;
                plan.ppr_income.tier1.value = this.state.tier1PPRPercentage / 100;
                plan.ppr_income.tier2.range = this.state.tier2PPRRange;
                plan.ppr_income.tier2.value = this.state.tier2PPRPercentage / 100;
                plan.ppr_income.tier3.range = this.state.tier3PPRRange;
                plan.ppr_income.tier3.value = this.state.tier3PPRPercentage / 100;
                plan.ppr_income.tier4.range = this.state.tier4PPRRange;
                plan.ppr_income.tier4.value = this.state.tier4PPRPercentage / 100;

                plan.pvr_income.tier1.range = this.state.tier1PVRRange;
                plan.pvr_income.tier1.value = this.state.tier1PVRPercentage / 100;
                plan.pvr_income.tier2.range = this.state.tier2PVRRange;
                plan.pvr_income.tier2.value = this.state.tier2PVRPercentage / 100;
                plan.pvr_income.tier3.range = this.state.tier3PVRRange;
                plan.pvr_income.tier3.value = this.state.tier3PVRPercentage / 100;
                plan.pvr_income.tier4.range = this.state.tier4PVRRange;
                plan.pvr_income.tier4.value = this.state.tier4PVRPercentage / 100;

                plan.warranty_income.tier1.range = this.state.tier1WarrantyPenPercentage
                plan.warranty_income.tier1.value = this.state.tier1WarrantyPercentage / 100;
                plan.warranty_income.tier2.range = this.state.tier2WarrantyPenPercentage;
                plan.warranty_income.tier2.value = this.state.tier2WarrantyPercentage / 100;
                plan.warranty_income.tier3.range = this.state.tier3WarrantyPenPercentage;
                plan.warranty_income.tier3.value = this.state.tier3WarrantyPercentage / 100;
                plan.warranty_income.tier4.range = this.state.tier4WarrantyPenPercentage;
                plan.warranty_income.tier4.value = this.state.tier4WarrantyPercentage / 100;
                plan.warranty_income.tier5.range = this.state.tier5WarrantyPenPercentage;
                plan.warranty_income.tier5.value = this.state.tier5WarrantyPercentage / 100;

                plan.maintenance.tier1.range = this.state.tier1MaintenancePenPercentage;
                plan.maintenance.tier1.value = this.state.tier1MaintenancePercentage / 100;
                plan.maintenance.tier2.range = this.state.tier2MaintenancePenPercentage;
                plan.maintenance.tier2.value = this.state.tier2MaintenancePercentage / 100;
                plan.maintenance.tier3.range = this.state.tier3MaintenancePenPercentage;
                plan.maintenance.tier3.value = this.state.tier3MaintenancePercentage / 100;

                plan.ally.Y = this.state.allyYes;
                plan.ally.N = this.state.allyNo;
                plan.csi.Yes = this.state.csiYes;
                plan.csi.No = this.state.csiNo;
                plan.cit.Yes = this.state.citYesPenPercentage / 100;
                plan.cit.No = this.state.citNoPenPercentage / 100;

                plan.ev.product_bonus_value = this.state.productBonus;
                plan.ev.gross_bonus_value = this.state.grossBonus;
                plan.ev.hourly_value = this.state.selectedHourly.value;

                this.props.onSaveClick(event);
            }
        }
        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                tier1PPRRange: "",
                tier1PPRPercentage: "",
                tier2PPRRange: "",
                tier2PPRPercentage: "",
                tier3PPRRange: "",
                tier3PPRPercentage: "",
                tier4PPRRange: "",
                tier4PPRPercentage: "",

                tier1PVRRange: "",
                tier1PVRPercentage: "",
                tier2PVRRange: "",
                tier2PVRPercentage: "",
                tier3PVRRange: "",
                tier3PVRPercentage: "",
                tier4PVRRange: "",
                tier4PVPercentage: "",

                tier1WarrantyPenPercentage: "",
                tier1WarrantyPercentage: "",
                tier2WarrantyPenPercentage: "",
                tier2WarrantyPercentage: "",
                tier3WarrantyPenPercentage: "",
                tier3WarrantyPercentage: "",
                tier4WarrantyPenPercentage: "",
                tier4Warrantyercentage: "",
                tier5WarrantyPenPercentage: "",
                tier5Warrantyercentage: "",

                tier1MaintenancePenPercentage: "",
                tier1MaintenancePercentage: "",
                tier2MaintenancePenPercentage: "",
                tier2MaintenancePercentage: "",
                tier3MaintenancePenPercentage: "",
                tier3MaintenancePercentage: "",

                allyYes: "",
                allyNo: "",
                csiYes: "",
                csiNo: "",
                citYesPenPercentage: "",
                citNoPenPercentage: "",

                productBonus: "",
                grossBonus: "",
                hourly: "",
                selectedHourly: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.planDetail) {
            title = "Edit " + this.props.planType + " - " + this.props.planDetail.plan_name;
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.planDetail !== null}
            cssName="dgdg-fi-payroll-plan-dialog"
            title={title}
            onCancelClick={this.onCancelClick}>
            <DGDGSpinnerComponent showSpinner={this.state.showProgress} />
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                (this.props.planDetail)
                    ? <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <table className="dgdg-fi-payroll-plan-table">
                                    <tbody>
                                        <tr className="dgdg-fi-payroll-plan-font" style={{ "textAlign": "center" }}>
                                            <td />
                                            <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                                                PPR Income
                                            </td>
                                            <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                                                PVR Income
                                            </td>
                                            <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                                                Warranty Income
                                            </td>
                                            <td colSpan="2">
                                                Maintenance
                                            </td>
                                        </tr>
                                        <tr className="dgdg-fi-payroll-plan-table-td-width dgdg-fi-payroll-plan-font">
                                            <td />
                                            <td>
                                                &nbsp;Range
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                &nbsp;Percentage
                                            </td>
                                            <td>
                                                &nbsp;Range
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                &nbsp;Percentage
                                            </td>
                                            <td>
                                                &nbsp;Range
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                &nbsp;Percentage
                                            </td>
                                            <td>
                                                &nbsp;Range
                                            </td>
                                            <td>
                                                &nbsp;Percentage
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="dgdg-fi-payroll-plan-table-td-width">
                                                Tier 1
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier1PPRRange" value={this.state.tier1PPRRange} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier1PPRPercentage" value={this.state.tier1PPRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier1PVRRange" value={this.state.tier1PVRRange} onChange={this.onTextChange} inputType="currency" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier1PVRPercentage" value={this.state.tier1PVRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier1WarrantyPenPercentage" value={this.state.tier1WarrantyPenPercentage} onChange={this.onTextChange} inputType="number" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier1WarrantyPercentage" value={this.state.tier1WarrantyPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier1MaintenancePenPercentage" value={this.state.tier1MaintenancePenPercentage} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier1MaintenancePercentage" value={this.state.tier1MaintenancePercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tier 2
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier2PPRRange" value={this.state.tier2PPRRange} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier2PPRPercentage" value={this.state.tier2PPRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier2PVRRange" value={this.state.tier2PVRRange} onChange={this.onTextChange} inputType="currency" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier2PVRPercentage" value={this.state.tier2PVRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier2WarrantyPenPercentage" value={this.state.tier2WarrantyPenPercentage} onChange={this.onTextChange} inputType="number" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier2WarrantyPercentage" value={this.state.tier2WarrantyPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier2MaintenancePenPercentage" value={this.state.tier2MaintenancePenPercentage} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier2MaintenancePercentage" value={this.state.tier2MaintenancePercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tier 3
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier3PPRRange" value={this.state.tier3PPRRange} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier3PPRPercentage" value={this.state.tier3PPRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier3PVRRange" value={this.state.tier3PVRRange} onChange={this.onTextChange} inputType="currency" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier3PVRPercentage" value={this.state.tier3PVRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier3WarrantyPenPercentage" value={this.state.tier3WarrantyPenPercentage} onChange={this.onTextChange} inputType="number" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier3WarrantyPercentage" value={this.state.tier3WarrantyPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier3MaintenancePenPercentage" value={this.state.tier3MaintenancePenPercentage} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier3MaintenancePercentage" value={this.state.tier3MaintenancePercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tier 4
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier4PPRRange" value={this.state.tier4PPRRange} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier4PPRPercentage" value={this.state.tier4PPRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier4PVRRange" value={this.state.tier4PVRRange} onChange={this.onTextChange} inputType="currency" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier4PVRPercentage" value={this.state.tier4PVRPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td>
                                                <DGDGFormInputComponent id="tier4WarrantyPenPercentage" value={this.state.tier4WarrantyPenPercentage} onChange={this.onTextChange} inputType="number" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier4WarrantyPercentage" value={this.state.tier4WarrantyPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tier 5
                                            </td>
                                            <td />
                                            <td className="dgdg-fi-payroll-plan-td-border"></td>
                                            <td />
                                            <td className="dgdg-fi-payroll-plan-td-border"></td>
                                            <td>
                                                <DGDGFormInputComponent id="tier5WarrantyPenPercentage" value={this.state.tier5WarrantyPenPercentage} onChange={this.onTextChange} inputType="number" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="tier5WarrantyPercentage" value={this.state.tier5WarrantyPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <br />
                                <table className="dgdg-fi-payroll-plan-table">
                                    <tbody>
                                        <tr className="dgdg-fi-payroll-plan-table-td-width dgdg-fi-payroll-plan-font" style={{ "textAlign": "center" }}>
                                            <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                                                &nbsp;Ally
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                                                &nbsp;CSI
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                                                &nbsp;CIT
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                                                &nbsp;EV
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                                                &nbsp;Hourly
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="dgdg-fi-payroll-plan-table-td-width">
                                                &nbsp;Yes
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="allyYes" value={this.state.allyYes} onChange={this.onTextChange} inputType="money" />
                                            </td>
                                            <td>
                                                &nbsp;Yes
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="csiYes" value={this.state.csiYes} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td>
                                                &nbsp;Yes
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="citYesPenPercentage" value={this.state.citYesPenPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td style={{ "width": "95px" }}>
                                                &nbsp;Product Bonus
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="productBonus" value={this.state.productBonus} onChange={this.onTextChange} inputType="money" />
                                            </td>
                                            <td style={{ "width": "60px" }}>
                                                &nbsp;Hourly
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width">
                                                <DGDGFormDropdownComponent data={this.hourlyData} itemKey="key" value={this.state.selectedHourly ? this.state.selectedHourly.value : "Select Hourly"} onItemClick={this.onHourlyChange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="dgdg-fi-payroll-plan-table-td-width">
                                                &nbsp;No
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="allyNo" value={this.state.allyNo} onChange={this.onTextChange} inputType="money" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-td-width">
                                                &nbsp;No
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="csiNo" value={this.state.csiNo} onChange={this.onTextChange} inputType="decimal" />
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-td-width">
                                                &nbsp;No
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="citNoPenPercentage" value={this.state.citNoPenPercentage} onChange={this.onTextChange} inputType="percent_2" />
                                            </td>
                                            <td style={{ "width": "90px" }}>
                                                &nbsp;Gross Bonus
                                            </td>
                                            <td className="dgdg-fi-payroll-plan-table-textbox-width dgdg-fi-payroll-plan-td-border">
                                                <DGDGFormInputComponent id="grossBonus" value={this.state.grossBonus} onChange={this.onTextChange} inputType="money" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}

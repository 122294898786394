import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCalendarExComponent } from "../common/dgdgcalendar/DGDGFormCalendarExComponent";
import { DGDGFormCalendarRangeComponent } from "../common/dgdgcalendar/DGDGFormCalendarRangeComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGInputFileComponent } from "../common/dgdginputfile/DGDGInputFileComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class DemoPageView1QueryComponent extends React.Component {
    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf("month"), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf("month"), endDate: moment().subtract(1, "months").endOf("month") },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DemoPageView1QueryComponent - getDerivedStateFromProps");
            if (props.isLoadingData || props.isSettingCache || props.isGettingCache) {
                newState.showSpinner = true;
            }
            else if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isSettingCache || props.isGettingCache;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            stringQuery: "",
            dateQuery: null,
            dateExQuery: null,
            ddpData: [
                { key: "key1", value: "Value 1" },
                { key: "key2", value: "Value 2" },
                { key: "key3", value: "Value 3" },
                { key: "key4", value: "Value 4" },
                { key: "key5", value: "Value 5" }
            ],
            selectedDdpData: null,
            selectedDateRange: null,
            showSpinner: false,
            showQuery: true
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSetCacheClick = this.onSetCacheClick.bind(this);
        this.onGetCacheClick = this.onGetCacheClick.bind(this);
        this.onFileChange = this.onFileChange.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            console.devLog("Lookup for: " + value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                dateStartQuery: startDate,
                dateEndQuery: endDate
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDropdownChange(id, selectedDdpData) {
        try {
            this.setState({ selectedDdpData: selectedDdpData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(event, {
                stringQuery: this.state.stringQuery,
                dateQuery: this.state.dateQuery,
                selectedDdpData: this.state.selectedDdpData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSetCacheClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onSetCacheClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetCacheClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetCacheClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFileChange(event) {
        try {
            let fileUpload = $("#demoBrowseFile")[0];
            this.setState({ showSpinner: true });
            this.props.onFileChange(event, fileUpload);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let minDate = moment().date(1).month(0).year(moment().year()).format("MM/DD/YYYY"),
            maxDate = moment().format("MM/DD/YYYY");

        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormInputComponent id="stringQuery" label="String Query" value={this.state.stringQuery} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
            <DGDGFormDropdownComponent label="Dropdown Query" data={this.state.ddpData} itemKey="value" value={this.state.selectedDdpData ? this.state.selectedDdpData.value : "Select Value"} onItemClick={this.onDropdownChange} />
            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="dateQuery" label="Date Query" value={this.state.dateQuery} onDateChange={this.onDateChange} />
            <DGDGFormCalendarExComponent applicationInsights={this.props.applicationInsights} id="dateExQuery" label="Date Ex Query" value={this.state.dateExQuery} onDateChange={this.onDateChange} onDatePrevChange={this.onDateChange} onDateNextChange={this.onDateChange} dayCount={7} />
            <DGDGFormCalendarRangeComponent
                applicationInsights={this.props.applicationInsights} row2CssName="dgdg-demo-page-date"
                label="Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                startId="dateStartQuery" startValue={this.state.dateStartQuery} startMin={minDate} startMax={maxDate}
                endId="dateEndQuery" endValue={this.state.dateEndQuery} endMin={minDate} endMax={maxDate} />
            <DGDGInputFileComponent id="demoBrowseFile" accept="text" onFileChange={this.onFileChange} colSpan="dgdg-input-file-button-only" isButtonOnly />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get <span className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Refresh <span className="far fa-sync-alt dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Chart <span className="fas fa-chart-line dgdg-icon" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onSetCacheClick(event)}>Set Cache</button>
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetCacheClick(event)}>Get Cache</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onAlertClick(event)}>Alert</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onWarningClick(event)}>Warning</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onErrorClick(event)}>Error</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onRouteComponentExClick(event)}>Route Component Ex</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onFetchDownloadClick(event)}>Fetch Download</button>
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onGlyphsClick(event)}>Glyphs</button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

import React from "react";

export class DGDGSpinnerComponent extends React.Component {
    render() {
        return ((this.props.showSpinner)
            ? <div className={"sk-cube-grid " + (this.props.cssName ? this.props.cssName : "")}>
                <span className="sk-cube sk-cube1" />
                <span className="sk-cube sk-cube2" />
                <span className="sk-cube sk-cube3" />
                <span className="sk-cube sk-cube4" />
                <span className="sk-cube sk-cube5" />
                <span className="sk-cube sk-cube6" />
                <span className="sk-cube sk-cube7" />
                <span className="sk-cube sk-cube8" />
                <span className="sk-cube sk-cube9" />
            </div>
            : null
        );
    }
}

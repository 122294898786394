import React from "react";
import ReactDOM from "react-dom";
import { GoogleCharts } from 'google-charts';

export class DGDGChartAreaComponent extends React.Component {
    constructor(props) {
        super(props);

        this.drawChart = this.drawChart.bind(this);
    }

    resizeObserver = null;

    componentDidMount() {
        try {
            this.resizeObserver = new ResizeObserver(() => { this.drawChart(); });
            this.resizeObserver.observe(ReactDOM.findDOMNode(this));
            GoogleCharts.load(this.drawChart, { packages: ["corechart"] });
        }
        catch (error) {
            console.devError(error)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props &&
            (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
            this.drawChart();
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    drawChart() {
        if (this.props.chartOption && this.props.chartData
            && GoogleCharts.api && GoogleCharts.api.visualization && GoogleCharts.api.visualization.arrayToDataTable && GoogleCharts.api.visualization.AreaChart) {

            let visualizationData = GoogleCharts.api.visualization.arrayToDataTable(this.props.chartData);
            this.props.chartOption.hAxis.ticks = visualizationData.getDistinctValues(0);
            let visualization = new GoogleCharts.api.visualization.AreaChart(ReactDOM.findDOMNode(this));
            visualization.draw(visualizationData, this.props.chartOption);
        }
    }

    render() {
        return (<div className="dgdg-widget-item" />);
    }
}
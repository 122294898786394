import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

export class UsedReconditioningSummaryDetailsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Used Car Prep";

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("UsedReconditioningSummaryDetailsComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.showDialog}
            title={this.props.tableTitle}
            cssName="dgdg-used-reconditioning-summary-detail-dialog"
            onCancelClick={this.props.onCancelClick}>

            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Start Date" dataColumn="start_date" sortColumn="start_date" filterColumn="start_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="End Date" dataColumn="end_date" sortColumn="end_date" filterColumn="end_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                        <DGDGTableColumnComponent headerText="In Date" dataColumn="in_date_time" sortColumn="in_date_time" filterColumn="in_date_time" dataType="datetime" />
                        <DGDGTableColumnComponent headerText="Out Date" dataColumn="out_date_time" sortColumn="out_date_time" filterColumn="out_date_time" dataType="datetime" />
                        <DGDGTableColumnComponent headerText="Out By" dataColumn="out_by" sortColumn="out_by" filterColumn="out_by" />
                        <DGDGTableColumnComponent headerText={this.props.summaryMetricName} dataColumn="metric_data" sortColumn="metric_data" filterColumn="metric_data" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class OpenROsSummaryQueryComponent extends React.Component {
    static StoreNumberCookie = "BadDebtDetailStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("OpenROsSummaryQueryComponent - getDerivedStateFromProps");
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("OpenROsSummaryQueryComponent - componentDidMount");
            this.onGetReportClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.props.onGetReportClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                        Get Report <i className="fas fa-chart-line dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class ReportsQueryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterValue: "",
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onGetReportClick(event) {
        try {
            this.props.onGetReportClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value })
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="filterValue" label="Report Name" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                    <button disabled={this.props.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                        Get Reports Listing<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.props.showSpinner} />
                </div>
            </div>
        </DGDGQueryComponent>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarExComponent } from "../common/dgdgcalendar/DGDGFormCalendarExComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class ServiceStoreReportDayQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ServiceStoreReportDayQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            day: null,
            showSpinner: false
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onDateShift = this.onDateShift.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.day === null) {
                let day = moment().date(moment().date() - 1);
                this.setState({
                    day: day
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateShift(id, value) {
        try {
            this.setState({
                [id]: moment(value)
            }, () => {
                this.onGetReportClick(null);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.day);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormCalendarExComponent applicationInsights={this.props.applicationInsights} id="day" label="Date" value={this.state.day} onDateChange={this.onDateChange} onDatePrevChange={this.onDateShift} onDateNextChange={this.onDateShift} numberOfMonths={1} maxDate={moment().subtract(1, "days").format("MM/DD/YYYY")} dayCount={1} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner || this.state.disableGetReport} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <span className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class CarvanaExportRouteComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: moment(),
            tableData: [],
            paintTable: true,
            showSpinner: false,
            showSpinnerLast7Days: false
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onGetCarvanaUploadClick = this.onGetCarvanaUploadClick.bind(this);
        this.onGetCanvasBlobNameClick = this.onGetCanvasBlobNameClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.getBlobNames = this.getBlobNames.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CarvanaExportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CarvanaExport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            this.getBlobNames();
            CommonService.clientAzureStorageLog("CarvanaExportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Carvana Export";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CarvanaExportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CarvanaExport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "CarvanaExportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CarvanaExport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getBlobNames() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CarvanaExportRouteComponent/getBlobNames", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.setState({ showSpinnerLast7Days: true });
            fetch("/CarvanaExport/GetBlobNames", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true,
                        showSpinnerLast7Days: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: [],
                        paintTable: true,
                        showSpinnerLast7Days: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CarvanaExport/GetBlobNames", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetCarvanaUploadClick(event) {
        try {
            if (CommonService.isDateValid(this.state.date)) {
                this.setState({ showSpinner: true });
                let inputData = {
                    date: CommonService.formatServerDateTime(this.state.date.toString())
                };

                this.props.applicationInsights.trackTrace({ message: "/CarvanaExportRouteComponent/onGetCarvanaUploadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/CarvanaExport/GetCarvanaUpload", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response } else { throw response; } })
                    .then(response => {
                        CommonService.downloadBlob(response);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CarvanaExport/GetCarvanaUpload", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("warning", parsedError, 4000);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    })
                    .finally(() => {
                        this.setState({ showSpinner: false });
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetCanvasBlobNameClick(event, rowdata) {
        this.setState({ date: rowdata.date }, this.onGetCarvanaUploadClick);
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onDownloadClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return <button type="button" className="btn btn-link" onClick={(event) => this.onGetCanvasBlobNameClick(event, rowData)}>
            <i className="far fa-download" />
        </button>;
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("CarvanaExportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <div className="card dgdg-card-gutter">
                    <div className="card-block">
                        <div className="form-inline">
                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="date" label="Start Date" value={this.state.date} maxDate={moment().format("MM/DD/YYYY")} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetCarvanaUploadClick(event)}>
                                Get Carvana Upload  <span className="far fa-download dgdg-icon" />
                            </button>
                            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                        </div>
                    </div>
                </div>
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Carvana Inventory(Last 7 Days)" tableData={this.state.tableData} showSpinner={this.state.showSpinnerLast7Days} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 2, 3, 4], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" />
                            <DGDGTableColumnComponent headerText="Date" dataColumn="date" sortColumn="date" dataType="dateTime" filterColumn="date" />
                            <DGDGTableColumnComponent headerText="Blob Size" dataColumn="blobSize" sortColumn="blobSize" dataType="formatted_number" filterColumn="blobSize" />
                            <DGDGTableColumnComponent headerText="Blob Name" dataColumn="blobName" sortColumn="blobName" filterColumn="blobName" cssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { UsedReconditioningSummaryQueryComponent } from "./UsedReconditioningSummaryQueryComponent";
import { UsedReconditioningChartComponent } from "./UsedReconditioningChartComponent";
import { UsedReconditioningChartColumnComponent } from "./UsedReconditioningChartColumnComponent"

export class UsedReconditioningSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedStore: null,
            chartData: null,
            columnChartData: null,
            columnChartTitle: "Delay Summary",
            chartTitle: "Frontline Ready Cars",
            paintColumnChart: false,
            paintChart: false,
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Used Car Prep";

    getFrontlineReadyCars() {
        let inputData = JSON.stringify({
            storeNumber: this.state.selectedStore ? this.state.selectedStore.store_number : null
        });

        fetch("/UsedReconditioning/GetFrontlineReadyCars", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: inputData
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (parsedResponse) {
                    parsedResponse[0][1] = this.state.selectedStore.display_name;
                    parsedResponse[0][2] = "Thirty Day Avg Count";
                }

                this.setState({
                    chartTitle: this.state.selectedStore.display_name + " - Frontline Ready Cars for Last 30 days",
                    chartData: parsedResponse,
                    paintChart: true
                }, () => {
                    this.setState({ paintChart: false });
                });
            })
            .catch(notOKResponse => {
                this.setState({
                    chartTitle: "",
                    chartData: null,
                    paintChart: true
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore) {
        try {
            let columnChartData = [
                ["Store", "Service Out", "Body Out", "Detail Out", "Recon Out", "Photo Out", "Total Out"]
            ];

            this.setState({ isLoadingData: true });
            let inputData = JSON.stringify({
                storeNumber: selectedStore ? selectedStore.store_number : null
            });

            fetch("/UsedReconditioning/GetSummaryReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        columnChartData.push([responseItem.store_code,
                        responseItem.service_out_age_avg === null ? 0 : responseItem.service_out_age_avg,
                        responseItem.body_out_age_avg === null ? 0 : responseItem.body_out_age_avg,
                        responseItem.detail_out_age_avg === null ? 0 : responseItem.detail_out_age_avg,
                        responseItem.recon_out_age_avg === null ? 0 : responseItem.recon_out_age_avg,
                        responseItem.photo_out_age_avg === null ? 0 : responseItem.photo_out_age_avg,
                        responseItem.total_out_age_avg === null ? 0 : responseItem.total_out_age_avg])
                    });

                    this.setState({
                        selectedStore: selectedStore,
                        columnChartTitle: selectedStore === null ? "All Stores" : selectedStore.display_name + " - Delay for last 30 days",
                        columnChartData: columnChartData,
                        isLoadingData: false,
                        paintColumnChart: true
                    }, () => {
                        this.setState({ paintColumnChart: false });
                        this.getFrontlineReadyCars();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        selectedStore: selectedStore,
                        columnChartTitle: "",
                        columnChartData: null,
                        isLoadingData: false,
                        paintColumnChart: true
                    }, () => {
                        this.setState({ paintColumnChart: true });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningSummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card dgdg-card-gutter">
                    <UsedReconditioningChartComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={this.state.chartTitle}
                        chartData={this.state.chartData}
                        paintChart={this.state.paintChart}
                    />
                </div>
                <div className="card">
                    <UsedReconditioningChartColumnComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={this.state.columnChartTitle}
                        chartData={this.state.columnChartData}
                        paintChart={this.state.paintColumnChart}
                    />
                </div>
            </div>
        </div>;
    }
}

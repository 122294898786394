import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ServiceStoreReportMonthQueryComponent } from "./ServiceStoreReportMonthQueryComponent";
import { ServiceStoreReportMonthDetailComponent } from "./ServiceStoreReportMonthDetailComponent";

export class ServiceStoreReportMonthComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            footerRow: null,
            tableTitle: "",
            paintTable: false,
            showMore: true,
            detailTableData: [],
            detailTableTitle: "",
            detailPaintTable: false,
            amountDetailTableData: [],
            amountDetailTableTitle: "",
            amountDetailPaintTable: false,
            selectedMonth: null,
            selectedStoreName: null,
            selectedStoreNumber: null,
            categoryName: null,
            totalAmount: null,
            childModelClosedTime: null,
            detailTotalAmount: null,
            blobName: "",
            accountDetailBlobName: "",
            allBlobs: ""
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onToggleShowMoreClick = this.onToggleShowMoreClick.bind(this);
        this.onAdjustGrossHeaderCallback = this.onAdjustGrossHeaderCallback.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
        this.onOtherShopLaborGrossCellClick = this.onOtherShopLaborGrossCellClick.bind(this);
        this.onSubletRepairGrossCellClick = this.onSubletRepairGrossCellClick.bind(this);
        this.onGOAndGGrossCellClick = this.onGOAndGGrossCellClick.bind(this);
        this.onLoanerIncomeExpenseCellClick = this.onLoanerIncomeExpenseCellClick.bind(this);
        this.onAdjToLaborGrossCellClick = this.onAdjToLaborGrossCellClick.bind(this);
        this.onLoanerIncomeExpenseCellClick = this.onLoanerIncomeExpenseCellClick.bind(this);
        this.onCPLaborPartsGrossCellClick = this.onCPLaborPartsGrossCellClick.bind(this);
        this.onUSWarrantyLaborGrossCellClick = this.onUSWarrantyLaborGrossCellClick.bind(this);
        this.onDetailCancelClick = this.onDetailCancelClick.bind(this);
        this.onDetailAmountClick = this.onDetailAmountClick.bind(this);
        this.onDetailAmountCancelClick = this.onDetailAmountCancelClick.bind(this);
        this.onDetailTotalAmountClick = this.onDetailTotalAmountClick.bind(this);
        this.onDetailTotalAmountCancelClick = this.onDetailTotalAmountCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Service Store Report";

    componentDidMount() {
        try {
            console.devLog("ServiceStoreReportMonthComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceStoreReport/Month", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("ServiceStoreReportMonthComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    formatFooterNumber(key) {
        return CommonService.formatNumber(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    formatFooterPercetage(key) {
        return CommonService.formatPercentage(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    formatFooterSignedPercetage(key) {
        return CommonService.formatSignedPercentage(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    formatFooterCurrency(key) {
        return CommonService.formatCurrency(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    formatFooterSignedCurrency(key) {
        return CommonService.formatSignedCurrency(this.state.footerRow && this.state.footerRow[key] ? this.state.footerRow[key] : 0);
    }

    onAdjustGrossHeaderCallback(columnComponent) {
        if (this.state.showMore)
            return <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowMoreClick(event)}>
                <span className="far fa-chevron-double-right" />
            </button>;
        else
            return <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowMoreClick(event)}>
                <span className="far fa-chevron-double-left" />
            </button>;
    }

    getLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleShowMoreClick(event) {
        try {
            this.setState({
                showMore: !this.state.showMore,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedMonth) {
        try {
            this.setState({
                isLoadingData: true,
                selectedMonth: selectedMonth
            });
            this.getLastUpdatedDate(selectedMonth);
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.filter(tableRow => tableRow.store_number !== 99);
                    tableData.forEach((responseItem, index) => {
                        responseItem.store_web_name = responseItem.store_number + " | " + responseItem.store_name;
                    });
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableTitle: CommonService.formatMonthYear(moment(selectedMonth)),
                        tableData: CommonService.addOrdinalColumn(tableData),
                        footerRow: parsedResponse.filter(tableRow => tableRow.store_number === 99)[0]
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableTitle: "",
                        tableData: [],
                        footerRow: null
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-service-payroll", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceStoreReportMonthComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onPrintClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/PrintClick")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<div class="table-title">Service Store - Performance - ' + this.state.tableTitle
                        + '</div><table class="table table-bordered">' + $("#Performance  .dgdg-main-table").html()
                        + '</table><div class="dgdg-page-break" /><div class="table-title">Service Store - Financials - ' + this.state.tableTitle
                        + '</div><table class="table table-bordered dgdg-service-store-report-print-table-font-size">' + $("#Financials  .dgdg-main-table").html()
                        + '</table><div class="dgdg-page-break" /><div class="table-title">Service Store - Revenue Stream - ' + this.state.tableTitle
                        + '</div><table class="table table-bordered">' + $("#RevenueStream  .dgdg-main-table").html() + '</table>';
                    CommonService.showPrint("Service Store Report - " + this.state.tableTitle, printContent, true, true);
                })
                .catch(notOKResponse => {
                    console.devError(notOKResponse, null, this.props.applicationInsights);
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOtherShopLaborGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "ServiceGross",
                subCategory: "ServiceGross",
                description: "GrossOtherShopLabor"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onOtherShopLaborGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: ServiceGross"
                            + ", Sub-Category: ServiceGross"
                            + ", Description:Gross Other Shop Labor."
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.other_shop_labor_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.other_shop_labor_gross) + "</span>" : CommonService.formatCurrency_2(rowData.other_shop_labor_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Gross Other Shop Labor - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: "Gross Other Shop Labor",
                            detailTotalAmount: rowData.other_shop_labor_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableTitle: "",
                        detailTableData: [],
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSubletRepairGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "ServiceGross",
                subCategory: "ServiceGross",
                description: "GrossSubletRepairs"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onSubletRepairGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: ServiceGross"
                            + ", Sub-Category: ServiceGross"
                            + ", Description: Gross Sublet Repairs.";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.sublet_repairs_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.sublet_repairs_gross) + "</span>" : CommonService.formatCurrency_2(rowData.sublet_repairs_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Gross Sublet Repairs - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: "Gross Sublet Repairs",
                            detailTotalAmount: rowData.sublet_repairs_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableTitle: "",
                        detailTableData: [],
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGOAndGGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "ServiceGross",
                subCategory: "ServiceGross",
                description: "GrossGas,Oil&Grease"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onGOAndGGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: ServiceGross"
                            + ", Sub-Category: ServiceGross"
                            + ", Description: Gross Gas,Oil & Grease.";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.gas_oil_grease_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.gas_oil_grease_gross) + "</span>" : CommonService.formatCurrency_2(rowData.gas_oil_grease_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Gross Gas,Oil & Grease - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: "Gross Gas,Oil & Grease",
                            detailTotalAmount: rowData.gas_oil_grease_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableTitle: "",
                        detailTableData: [],
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjToLaborGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "ServiceGross",
                subCategory: "ServiceGross",
                description: "GrossAdjustmentsCostofSales-Labor"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onAdjToLaborGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: ServiceGross"
                            + ", Sub-Category: ServiceGross"
                            + ", Description: Gross Adjustments Cost of Sales - Labor.";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.adjustments_to_labor_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.adjustments_to_labor_gross) + "</span>" : CommonService.formatCurrency_2(rowData.adjustments_to_labor_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Gross Adjustments Cost of Sales - Labor - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: "Gross Adjustments Cost of Sales - Labor",
                            detailTotalAmount: rowData.adjustments_to_labor_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableData: [],
                        detailTableTitle: "",
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLoanerIncomeExpenseCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "GrossRecast",
                subCategory: "OtherAddDed",
                description: "ServiceGross"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onLoanerIncomeExpenseCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: GrossRecast"
                            + ", Sub-Category: OtherAddDed"
                            + ", Description: ServiceGross";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.loaner_income_expense < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.loaner_income_expense) + "</span>" : CommonService.formatCurrency_2(rowData.loaner_income_expense);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Loaner Income Expense - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: "Loaner Income Expense",
                            detailTotalAmount: rowData.loaner_income_expense,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableData: [],
                        detailTableTitle: "",
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCPLaborPartsGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                partsCategory: "PartsGross",
                partsSubCategory: "PartsGross",
                partsDescription: "GrossP&A-R.O.-Mech.",
                serviceCategory: "ServiceGross",
                serviceSubCategory: "ServiceGross",
                serviceDescription: "GrossCustomerLaborMech."
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onCPLaborPartsGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetCPLaborPartsGrossDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: PartsGross - ServiceGross"
                            + ", Sub-Category: PartsGross - ServiceGross"
                            + ", Description:  GrossP&A-R.O.-Mech. - GrossCustomerLaborMech.";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.cp_labor_parts_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.cp_labor_parts_gross) + "</span>" : CommonService.formatCurrency_2(rowData.cp_labor_parts_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - GrossP&A-R.O.-Mech. - GrossCustomerLaborMech. - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: " GrossP&A-R.O.-Mech. - GrossCustomerLaborMech.",
                            detailTotalAmount: rowData.cp_labor_parts_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableData: [],
                        detailTableTitle: "",
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetCPLaborPartsGrossDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUSWarrantyLaborGrossCellClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_number,
                oEM: 1,
                yM: "M",
                category: "ServiceGross",
                subCategory: "ServiceGross",
                description: "GrossService/MaintenanceContractSales"
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onUSWarrantyLaborGrossCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.store_number
                            + ", OEM: 1"
                            + ", YM: M"
                            + ", Category: ServiceGross"
                            + ", Sub-Category: ServiceGross"
                            + ", Description:  Gross Service/Maintenance Contract Sales.";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = rowData.us_warranty_labor_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.us_warranty_labor_gross) + "</span>" : CommonService.formatCurrency_2(rowData.us_warranty_labor_gross);
                        this.setState({
                            detailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - Gross Service/Maintenance Contract Sales - " + titleSuffix,
                            detailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            detailPaintTable: true,
                            selectedStoreName: rowData.store_web_name,
                            selectedStoreNumber: rowData.store_number,
                            categoryName: " Gross Service/Maintenance Contract Sales",
                            detailTotalAmount: rowData.us_warranty_labor_gross,
                            blobName: parsedResponse.blobName
                        }, () => {
                            this.setState({ detailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableData: [],
                        detailTableTitle: "",
                        detailPaintTable: true
                    }, () => {
                        this.setState({ detailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailCancelClick(event) {
        this.setState({
            detailTableTitle: "",
            detailTableData: []
        });
    }

    onDetailAmountClick(event, rowData) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.sn,
                ym: "M",
                accountNumber: rowData.an
            };
            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onDetailAmountClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetailAmount", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status && parsedResponse.status === "DetailNotFound") {
                        let message = "Details Not Found. Month: " + CommonService.formatMonthYear(moment(this.state.selectedMonth))
                            + ", Store Number: " + rowData.sn
                            + ", YM: M" +
                            + ", Account: " + rowData.an + ".";
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        parsedResponse.detail.forEach((rowItem) => {
                            rowItem.display_amt = rowItem.amt * -1;
                        });
                        let titleSuffix = CommonService.formatCurrency_2(rowData.amt * -1)
                        if ((rowData.amt * -1) < 0) {
                            titleSuffix = "<span class='dgdg-font-red'>" + titleSuffix + "</span>";
                        }
                        this.setState({
                            amountDetailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                            amountDetailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + this.state.selectedStoreName + " - " + rowData.ld + " - A/C " + rowData.an + " - " + titleSuffix,
                            amountDetailPaintTable: true,
                            totalAmount: rowData.amt,
                            accountDetailBlobName: parsedResponse.blobName,
                            allBlobs: ""
                        }, () => {
                            this.setState({ amountDetailPaintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        amountDetailTableTitle: "",
                        amountDetailTableData: [],
                        amountDetailPaintTable: true
                    }, () => {
                        this.setState({ amountDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetailAmount", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailAmountCancelClick(event) {
        try {
            this.setState({
                amountDetailTableData: [],
                amountDetailTableTitle: ""
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailTotalAmountClick(event) {
        try {
            let accountNumbers = [];
            this.state.detailTableData.forEach((rowData, index) => { accountNumbers.push(rowData.an); });
            let inputData = {
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: this.state.selectedStoreNumber,
                ym: "M",
                accountNumbers: accountNumbers
            };

            this.props.applicationInsights.trackTrace({ message: "/ServiceStoreReportDayComponent/onDetailTotalAmountClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceStoreReport/GetDetailTotalAmount", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.detail.forEach((rowItem) => {
                        rowItem.display_amt = rowItem.amt * -1;
                    });
                    let titleSuffix = CommonService.formatCurrency_2(this.state.detailTotalAmount);
                    if (this.state.totalAmount > 0) {
                        titleSuffix = "<span class='dgdg-font-red'>" + titleSuffix + "</span>";
                    }
                    this.setState({
                        amountDetailTableData: CommonService.addOrdinalColumn(parsedResponse.detail),
                        amountDetailTableTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + this.state.selectedStoreName + " - " + this.state.categoryName + " - " + titleSuffix,
                        amountDetailPaintTable: true,
                        totalAmount: this.state.detailTotalAmount * -1,
                        allBlobs: parsedResponse.blobName
                    }, () => {
                        this.setState({ amountDetailPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        amountDetailTableData: [],
                        amountDetailTableTitle: "",
                        amountDetailPaintTable: true
                    }, () => {
                        this.setState({ amountDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceStoreReport/GetDetailTotalAmount", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailTotalAmountCancelClick(event) {
        try {
            this.setState({
                amountDetailTableTitle: "",
                amountDetailTableData: [],
                amountDetailPaintTable: true,
                childModelClosedTime: moment()
            }, () => {
                this.setState({ amountDetailPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let financialColSpan = "0";
        if (this.state.showMore) {
            financialColSpan = "8";
        } else {
            financialColSpan = "13";
        }

        let financialColumns = [];
        financialColumns.push(<DGDGTableColumnComponent key="storeName" headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="DGDG" cssName="text-nowrap" />);
        if (moment(this.state.selectedMonth).year() >= 2023) {
            financialColumns.push(<DGDGTableColumnComponent key="serviceCommGross" headerText="Service Comm Gross" dataColumn="service_commissionable_gross" sortColumn="service_commissionable_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("service_commissionable_gross")} columnHelp="Service Commissionable Gross: OEM Service Gross excluding GO&G, including Loaner income/expense items" />);
        } else {
            financialColumns.push(<DGDGTableColumnComponent key="serviceGross" headerText="Service Gross" dataColumn="service_gross" sortColumn="service_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("service_gross")} columnHelp="Service Gross: OEM Service Gross excluding GO&G" />);
        }
        financialColumns.push(<DGDGTableColumnComponent key="trackingServiceCommGross" headerText="Tracking Service Comm Gross" dataColumn="service_commissionable_gross_tracking" sortColumn="service_commissionable_gross_tracking" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("service_commissionable_gross_tracking")} />);
        financialColumns.push(<DGDGTableColumnComponent key="serviceCommGrossBudget" headerText="Service Comm Gross Budget" dataColumn="service_gross_goal" sortColumn="service_gross_goal" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("service_gross_goal")} />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingServiceCommGrossvsBudget1" headerText="Tracking Service Comm Gross vs Budget" dataColumn="service_gross_track_goal_diff" sortColumn="service_gross_track_goal_diff" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("service_gross_track_goal_diff")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingServiceCommGrossvsBudget2" headerText="" dataColumn="service_gross_track_goal_pct_diff" sortColumn="service_gross_track_goal_pct_diff" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("service_gross_track_goal_pct_diff")} backgroundColor="service_gross_track_goal_pct_diff_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="serviceGrossYOYDiff1" headerText="Service Comm Gross YOY Diff" dataColumn="service_commissionable_gross_yoy" sortColumn="service_commissionable_gross_yoy" dataType="signed_money" footerCssName="text-right" footerText={this.formatFooterSignedCurrency("service_commissionable_gross_yoy")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="serviceGrossYOYDiff2" headerText="" dataColumn="service_commissionable_gross_yoy_pct" sortColumn="service_commissionable_gross_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("service_commissionable_gross_yoy_pct")} backgroundColor="service_commissionable_gross_yoy_pct_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="othLaborGross" headerText="Oth Labor Gross" dataColumn="other_shop_labor_gross" sortColumn="other_shop_labor_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("other_shop_labor_gross")} isHidden={this.state.showMore} onCellClick={this.onOtherShopLaborGrossCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="subletRepairGross" headerText="Sublet Repair Gross" dataColumn="sublet_repairs_gross" sortColumn="sublet_repairs_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("sublet_repairs_gross")} isHidden={this.state.showMore} onCellClick={this.onSubletRepairGrossCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="gOGGross" headerText="GO&G Gross" dataColumn="gas_oil_grease_gross" sortColumn="gas_oil_grease_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("gas_oil_grease_gross")} isHidden={this.state.showMore} onCellClick={this.onGOAndGGrossCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="adjtoLaborGross" headerText="Adj to Labor Gross" dataColumn="adjustments_to_labor_gross" sortColumn="adjustments_to_labor_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("adjustments_to_labor_gross")} isHidden={this.state.showMore} onCellClick={this.onAdjToLaborGrossCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="loanerIncomeExpense" headerText="Loaner Income / Expense" dataColumn="loaner_income_expense" sortColumn="loaner_income_expense" dataType="money" headerCssName="dgdg-service-store-report-loaner-income-header" footerCssName="text-right" footerText={this.formatFooterCurrency("loaner_income_expense")} isHidden={this.state.showMore} columnHelp="Includes Rental income/cost, Service retention bonus, Gain/loss on disposal of asset." onCellClick={this.onLoanerIncomeExpenseCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="grossAdjs" headerText="Gross Adjs" dataColumn="adjust_gross" sortColumn="adjust_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("adjust_gross")} headerCallback={this.onAdjustGrossHeaderCallback} />);
        financialColumns.push(<DGDGTableColumnComponent key="partsGross" headerText="Parts Gross" dataColumn="parts_gross" sortColumn="parts_gross" dataType="money" columnHelp="Parts Gross: OEM Parts Gross including GO&G and including Inventory Adjustments" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_gross")} />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingPartsGross" headerText="Tracking Parts Gross" dataColumn="parts_gross_tracking" sortColumn="parts_gross_tracking" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_gross_tracking")} />);
        financialColumns.push(<DGDGTableColumnComponent key="partsGrossBudget" headerText="Parts Gross Budget" dataColumn="parts_gross_goal" sortColumn="parts_gross_goal" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_gross_goal")} />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingPartsGrossvsBudget1" headerText="Tracking Parts Gross vs Budget" dataColumn="parts_gross_track_goal_diff" sortColumn="parts_gross_track_goal_diff" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_gross_track_goal_diff")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingPartsGrossvsBudget2" headerText="" dataColumn="parts_gross_track_goal_pct_diff" sortColumn="parts_gross_track_goal_pct_diff" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("parts_gross_track_goal_pct_diff")} backgroundColor="parts_gross_track_goal_pct_diff_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="partsCommGross" headerText="Parts Comm Gross" dataColumn="parts_commissionable_gross" sortColumn="parts_commissionable_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_commissionable_gross")} columnHelp="Parts Commissionable Gross for Parts Couterperson: OEM Parts Gross including GO&G, but excluding Inventory Adjustments" />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingPartsCommGross" headerText="Tracking Parts Comm Gross" dataColumn="parts_commissionable_gross_tracking" sortColumn="parts_commissionable_gross_tracking" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("parts_commissionable_gross_tracking")} />);
        financialColumns.push(<DGDGTableColumnComponent key="partsGrossYOYDiff1" headerText="Parts Comm Gross YOY Diff" dataColumn="parts_commissionable_gross_yoy" sortColumn="parts_commissionable_gross_yoy" dataType="signed_money" footerCssName="text-right" footerText={this.formatFooterSignedCurrency("parts_commissionable_gross_yoy")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="partsGrossYOYDiff2" headerText="" dataColumn="parts_commissionable_gross_yoy_pct" sortColumn="parts_commissionable_gross_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("parts_commissionable_gross_yoy_pct")} backgroundColor="parts_commissionable_gross_yoy_pct_bg_color" />);
        if (moment(this.state.selectedMonth).year() >= 2023) {
            financialColumns.push(<DGDGTableColumnComponent key="fixedCommGross" headerText="Fixed Comm Gross" dataColumn="fixed_commissionable_gross" sortColumn="fixed_commissionable_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("fixed_commissionable_gross")} />);
        }
        else {
            financialColumns.push(<DGDGTableColumnComponent key="fixedComm" headerText="Fixed Gross" dataColumn="fixed_gross" sortColumn="fixed_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("fixed_gross")} />);
        }
        financialColumns.push(<DGDGTableColumnComponent key="trackingFixedCommGross" headerText="Tracking Fixed Comm Gross" dataColumn="fixed_commissionable_gross_tracking" sortColumn="fixed_commissionable_gross_tracking" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("fixed_commissionable_gross_tracking")} />);
        financialColumns.push(<DGDGTableColumnComponent key="fixedCommGrossBudget" headerText="Fixed Comm Gross Budget" dataColumn="fixed_gross_goal" sortColumn="fixed_gross_goal" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("fixed_gross_goal")} />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingFixedCommGrossvsBudget1" headerText="Tracking Fixed Comm Gross vs Budget" dataColumn="fixed_gross_track_goal_diff" sortColumn="fixed_gross_track_goal_diff" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("fixed_gross_track_goal_diff")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="trackingFixedCommGrossvsBudget2" headerText="" dataColumn="fixed_gross_track_goal_pct_diff" sortColumn="fixed_gross_track_goal_pct_diff" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("fixed_gross_track_goal_pct_diff")} backgroundColor="fixed_gross_track_goal_pct_diff_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="fixedGrossYOYDiff1" headerText="Fixed Gross YOY Diff" dataColumn="fixed_gross_yoy" sortColumn="fixed_gross_yoy" dataType="signed_money" footerCssName="text-right" footerText={this.formatFooterSignedCurrency("fixed_gross_yoy")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="fixedGrossYOYDiff2" headerText="" dataColumn="fixed_gross_yoy_pct" sortColumn="fixed_gross_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("fixed_gross_yoy_pct")} backgroundColor="fixed_gross_yoy_pct_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="cpLaborPartsGrossandYOY1" headerText="CP Labor & Parts Gross and YOY %" dataColumn="cp_labor_parts_gross" sortColumn="cp_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("cp_labor_parts_gross")} headerColSpan="2" columnHelp="CP Labor & Parts Gross: excluding GO&G" onCellClick={this.onCPLaborPartsGrossCellClick} />);
        financialColumns.push(<DGDGTableColumnComponent key="cpLaborPartsGrossandYOY2" headerText="" dataColumn="cp_labor_parts_gross_yoy_pct" sortColumn="cp_labor_parts_gross_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("cp_labor_parts_gross_yoy_pct")} backgroundColor="cp_labor_parts_gross_yoy_pct_bg_color" />);
        financialColumns.push(<DGDGTableColumnComponent key="cpLaborPartsGrossperROandYOY1" headerText="CP Labor & Parts Gross per RO and YOY %" dataColumn="cp_labor_parts_gross_per_ro" sortColumn="cp_labor_parts_gross_per_ro" dataType="money" footerCssName="text-right" headerfooterText={this.formatFooterCurrency("cp_labor_parts_gross_per_ro")} headerColSpan="2" />);
        financialColumns.push(<DGDGTableColumnComponent key="cpLaborPartsGrossperROandYOY2" headerText="" dataColumn="cp_labor_parts_gross_per_ro_yoy_pct" sortColumn="cp_labor_parts_gross_per_ro_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("cp_labor_parts_gross_per_ro_yoy_pct")} headerbackgroundColor="cp_labor_parts_gross_per_ro_yoy_pct_bg_color" />);

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceStoreReportMonthDetailComponent
                applicationInsights={this.props.applicationInsights}
                blobName={this.state.blobName}
                accountDetailBlobName={this.state.accountDetailBlobName}
                allBlobs={this.state.allBlobs}
                tableData={this.state.detailTableData}
                paintTable={this.state.detailPaintTable}
                tableTitle={this.state.detailTableTitle}
                totalAmount={this.state.totalAmount}
                childModelClosedTime={this.state.childModelClosedTime}
                detailTableData={this.state.amountDetailTableData}
                paintDetailTable={this.state.amountDetailPaintTable}
                detailTableTitle={this.state.amountDetailTableTitle}
                onCancelClick={this.onDetailCancelClick}
                onDetailAmountClick={this.onDetailAmountClick}
                onDetailAmountCancelClick={this.onDetailAmountCancelClick}
                onDetailTotalAmountClick={this.onDetailTotalAmountClick}
                onDetailTotalAmountCancelClick={this.onDetailTotalAmountCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <ServiceStoreReportMonthQueryComponent
                applicationInsights={this.props.applicationInsights}
                months={this.props.months}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onPrintClick={this.onPrintClick}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#Performance")}>Performance</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#Financials")}>Financials</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#RevenueStream")}>Revenue Stream</button>
            </nav>
            <div className="dgdg-service-payroll dgdg-site-scrollable-content">
                <div id="Performance" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Service Store - Performance - " + this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} cardCssName="dgdg-service-store-table"
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17], onCopyTableCallBack: this.onCopyTableCallBack }} showFooter
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="17" className="text-center font-weight-bold dgdg-service-store-report-bg-head">Service Performance</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Actual Hours Worked" dataColumn="actual_hours" sortColumn="actual_hours" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("actual_hours")} />
                            <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("sold_hours")} />
                            <DGDGTableColumnComponent headerText="Available Hours" dataColumn="available_hours" sortColumn="available_hours" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("available_hours")} />
                            <DGDGTableColumnComponent headerText="Available Hours Sold %" dataColumn="available_hours_pct" sortColumn="available_hours_pct" dataType="percent" footerCssName="text-right" footerText={this.formatFooterPercetage("available_hours_pct")} />
                            <DGDGTableColumnComponent headerText="Tracking EOM Hours %" dataColumn="available_hours_pct_tracking" sortColumn="available_hours_pct_tracking" dataType="percent" footerCssName="text-right" footerText={this.formatFooterPercetage("available_hours_pct_tracking")} />
                            <DGDGTableColumnComponent headerText="Open ROs" dataColumn="open_ro_count" sortColumn="open_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("open_ro_count")} />
                            <DGDGTableColumnComponent headerText="Closed ROs" dataColumn="ro_count" sortColumn="ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("ro_count")} />
                            <DGDGTableColumnComponent headerText="Current RO Track Month End" dataColumn="ro_count_tracking" sortColumn="ro_count_tracking" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("ro_count_tracking")} />
                            <DGDGTableColumnComponent headerText="LY Closed ROs" dataColumn="ro_count_ly" sortColumn="ro_count_ly" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("ro_count_ly")} />
                            <DGDGTableColumnComponent headerText="Month End RO Count YOY %Δ" dataColumn="ro_count_yoy_pct" sortColumn="ro_count_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("ro_count_yoy_pct")} />
                            <DGDGTableColumnComponent headerText="CP ROs" dataColumn="cp_ro_count" sortColumn="cp_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("cp_ro_count")} />
                            <DGDGTableColumnComponent headerText="LY CP ROs" dataColumn="cp_ro_count_ly" sortColumn="cp_ro_count_ly" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("cp_ro_count_ly")} />
                            <DGDGTableColumnComponent headerText="CP RO YOY %Δ" dataColumn="cp_ro_count_yoy_pct" sortColumn="cp_ro_count_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("cp_ro_count_yoy_pct")} />
                            <DGDGTableColumnComponent headerText="SDS %" dataColumn="sds_pct" sortColumn="sds_pct" dataType="percent" footerCssName="text-right" footerText={this.formatFooterPercetage("sds_pct")} />
                            <DGDGTableColumnComponent headerText="Menu %" dataColumn="menu_penetration" sortColumn="menu_penetration" dataType="percent" footerCssName="text-right" footerText={this.formatFooterPercetage("menu_penetration")} />
                            <DGDGTableColumnComponent headerText="ASR %" dataColumn="asr_pct" sortColumn="asr_pct" dataType="percent" footerCssName="text-right" footerText={this.formatFooterPercetage("asr_pct")} />
                            <DGDGTableColumnComponent headerText="Used Avg Days in Service & Detail" dataColumn="used_days_in_shop_avg" sortColumn="used_days_in_shop_avg" dataType="number_1" footerCssName="text-right" footerText={this.formatFooterNumber("used_days_in_shop_avg")} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div id="Financials" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Service Store - Financials - " + this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33], onCopyTableCallBack: this.onCopyTableCallBack }} showFooter
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan={financialColSpan} className="text-center font-weight-bold dgdg-service-store-report-bg-head">Service Gross</td>
                                <td colSpan="9" className="text-center font-weight-bold dgdg-service-store-report-bg-head">Parts Gross</td>
                                <td colSpan="7" className="text-center font-weight-bold dgdg-service-store-report-header-cyan">Fixed Gross</td>
                                <td colSpan="4" className="text-center font-weight-bold dgdg-service-store-report-header-green">Customer Pay</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            {financialColumns}
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div id="RevenueStream">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Service Store - Revenue Stream - " + this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow} cardCssName="dgdg-service-store-table"
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }} showFooter
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="8" className="text-center font-weight-bold dgdg-service-store-report-header-green">Customer Pay</td>
                                <td colSpan="3" className="text-center font-weight-bold dgdg-service-store-report-header-yellow">Warranty Pay</td>
                                <td colSpan="3" className="text-center font-weight-bold dgdg-service-store-report-header-white">Internal Pay</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="CP ROs" dataColumn="cp_ro_count" sortColumn="cp_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("cp_ro_count")} />
                            <DGDGTableColumnComponent headerText="CP Labor & Parts Gross" dataColumn="cp_labor_parts_gross" sortColumn="cp_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("cp_labor_parts_gross")} columnHelp="CP Labor & Parts Gross: excluding GO&G" onCellClick={this.onCPLaborPartsGrossCellClick} />
                            <DGDGTableColumnComponent headerText="CP ELR" dataColumn="cp_elr" sortColumn="cp_elr" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("cp_elr")} />
                            <DGDGTableColumnComponent headerText="Ext/US Warranty ROs" dataColumn="us_warranty_ro_count" sortColumn="us_warranty_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("us_warranty_ro_count")} />
                            <DGDGTableColumnComponent headerText="Ext/US Warranty Gross" dataColumn="us_warranty_labor_gross" sortColumn="us_warranty_labor_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("us_warranty_labor_gross")} columnHelp="OEM Service/Maintenance Contract Gross" onCellClick={this.onUSWarrantyLaborGrossCellClick} />
                            <DGDGTableColumnComponent headerText="Ext/US Warranty ELR" dataColumn="us_warranty_elr" sortColumn="us_warranty_elr" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("us_warranty_elr")} />
                            <DGDGTableColumnComponent headerText="CP & Ext/US Warr Gross and YOY %" dataColumn="cp_us_warranty_gross" sortColumn="dgdg-border-right" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("cp_us_warranty_gross")} headerColSpan="2" />
                            <DGDGTableColumnComponent dataColumn="cp_us_warranty_gross_yoy_pct" sortColumn="cp_us_warranty_gross_yoy_pct" dataType="signed_percent" footerCssName="text-right" footerText={this.formatFooterSignedPercetage("cp_us_warranty_gross_yoy_pct")} backgroundColor="cp_us_warranty_gross_yoy_pct_bg_color" />
                            <DGDGTableColumnComponent headerText="WP ROs" dataColumn="wp_ro_count" sortColumn="wp_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("wp_ro_count")} />
                            <DGDGTableColumnComponent headerText="WP Labor & Parts Gross" dataColumn="wp_labor_parts_gross" sortColumn="wp_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("wp_labor_parts_gross")} />
                            <DGDGTableColumnComponent headerText="WP ELR" dataColumn="wp_elr" sortColumn="wp_elr" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("wp_elr")} />
                            <DGDGTableColumnComponent headerText="IP ROs" dataColumn="ip_ro_count" sortColumn="ip_ro_count" dataType="formatted_number" footerCssName="text-right" footerText={this.formatFooterNumber("ip_ro_count")} />
                            <DGDGTableColumnComponent headerText="IP Labor & Parts Gross" dataColumn="ip_labor_parts_gross" sortColumn="ip_labor_parts_gross" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("ip_labor_parts_gross")} />
                            <DGDGTableColumnComponent headerText="IP ELR" dataColumn="ip_elr" sortColumn="ip_elr" dataType="money" footerCssName="text-right" footerText={this.formatFooterCurrency("ip_elr")} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}

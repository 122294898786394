import React from "react";

export class DGDGFormCheckboxV2Component extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.checked, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "";
        let label = "";
        let label2 = "";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        if (this.props.label) {
            label = <span dangerouslySetInnerHTML={{ __html: this.props.label }} />;
        }

        if (this.props.label2) {
            label2 = <span dangerouslySetInnerHTML={{ __html: this.props.label2 }} />;
        }

        return <div className={"form-inline form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan}>{label}&nbsp;</label>
                    : null
            }
            <div className={"input-group " + controlSpan} style={{ "width": "35px", "paddingLeft": "8px" }}>
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id={this.props.id} disabled={this.props.disabled} checked={this.props.value ? this.props.value : false} onChange={(event) => this.onChange(event)} />
                    <label className="custom-control-label" htmlFor={this.props.id} />
                </div>
            </div>
            {
                this.props.label2
                    ? <label className="dgdg-font-blue">{label2}&nbsp;</label>
                    : null
            }
            {
                this.props.onClick
                    ? <span className="input-group-append">
                        <button type="button" className="btn btn-link float-right dgdg-toggle-box-button" onClick={(event) => this.props.onClick(event, this.props.id, this.props.value)}>
                            <span className="fas fa-check dgdg-font-blue dgdg-font-size-18 dgdg-toggle-box-span" />
                        </button>
                    </span>
                    : null
            }
            {
                this.props.onHideClick
                    ? <span className="input-group-append">
                        <button type="button" className="btn btn-link float-right dgdg-toggle-box-button" onClick={(event) => this.props.onHideClick(event)}>
                            <span className="fas fa-times text-danger dgdg-font-size-18 dgdg-icon dgdg-toggle-box-span" />
                        </button>
                    </span>
                    : null
            }
        </div>;
    }
}

import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ControllerPolicyQueryComponent } from "./ControllerPolicyQueryComponent";

export class ControllerPolicyRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            controllerList: [],
            assignedRoles: [],
            unAssignedRoles: [],
            assignRole: null,
            paintTable: false,
            groupList: [
                { key: "Accounting", value: "Accounting" },
                { key: "F&I", value: "F&I" },
                { key: "Home", value: "Home" },
                { key: "HR", value: "HR" },
                { key: "Inventory", value: "Inventory" },
                { key: "IT", value: "IT" },
                { key: "Marketing", value: "Marketing" },
                { key: "Reporting", value: "Reporting" },
                { key: "Sales", value: "Sales" },
                { key: "ServiceAndParts", value: "Service & Parts" },
                { key: "Settings", value: "Settings" }
            ],
            permissionList: [
                { key: "Contributor", value: "Contributor" },
                { key: "Reader", value: "Reader" },
            ],
            deleteAssignedRole: null,
            deleteControllerPolicy: false,
            controllerName: "",
            selectedGroup: null,
            selectedPermission: null
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        this.onTextChange = this.onTextChange.bind(this);
        this.onGroupListChange = this.onGroupListChange.bind(this);
        this.onPermissionListChange = this.onPermissionListChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onAssignedRoleColumnCallback = this.onAssignedRoleColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onUnassignedRoleChange = this.onUnassignedRoleChange.bind(this);
        this.onAssignRoleClick = this.onAssignRoleClick.bind(this);
        this.onUnassignRoleClick = this.onUnassignRoleClick.bind(this);
        this.onShowConfirmationClick = this.onShowConfirmationClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);
        this.onShowConfirmationControllPolicyClick = this.onShowConfirmationControllPolicyClick.bind(this);
        this.onConfirmationControllPolicyOkClick = this.onConfirmationControllPolicyOkClick.bind(this);
        this.onConfirmationControllPolicyCancelClick = this.onConfirmationControllPolicyCancelClick.bind(this);
        this.onGetAssignedRoles = this.onGetAssignedRoles.bind(this);
        this.onAssignedPermissionColumnCallback = this.onAssignedPermissionColumnCallback.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ControllerPolicyRouteComponent - componentDidMount");
            this.onGetControllerPolicy();
            this.getUnAssignedRoles();
            CommonService.clientAzureStorageLog("ControllerPolicyRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError("Eorror Error" + error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Controller Policy";

    onTextChange(id, text) {
        try {
            this.setState({ [id]: text });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGroupListChange(id, selectedItem) {
        try {
            this.setState({ selectedGroup: selectedItem });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPermissionListChange(id, selectedItem) {
        try {
            this.setState({
                selectedPermission: selectedItem,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            let asgndRoles = this.state.assignedRoles;
            if (asgndRoles.length === 0 || asgndRoles[asgndRoles.length - 1].id !== undefined) {
                asgndRoles.push({});
                this.setState({
                    assignedRoles: asgndRoles,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAssignedRoleColumnCallback(rowData) {
        return rowData.id === undefined
            ? <DGDGFormDropdownComponent data={this.state.unAssignedRoles} itemKey="application_role_ext_name" value={this.state.assignRole ? this.state.assignRole.application_role_ext_name : "Select Role"} onItemClick={this.onUnassignedRoleChange} />
            : rowData.application_role_ext_name;
    }

    onAssignedPermissionColumnCallback(rowData) {
        return rowData.id === undefined
            ? <DGDGFormDropdownComponent data={this.state.permissionList} itemKey="value" onItemClick={this.onPermissionListChange} value={this.state.selectedPermission ? this.state.selectedPermission.value : "Select Permission"} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
            : rowData.permission;
    }

    onGetAssignedRoles(event, rowData) {
        try {
            let controllerName = rowData ? rowData.controller_name : this.state.controllerName;
            let selectedGroup = rowData ? this.state.groupList.filter(group => group.key === rowData.group_name)[0] : this.state.selectedGroup;

            this.setState({
                isLoadingData: true,
                controllerName: controllerName,
                selectedGroup: selectedGroup
            });
            let inputData = {
                controllerName: controllerName,
                groupName: selectedGroup !== null ? selectedGroup.key : ""
            };

            this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/onGetAssignedRoles", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ControllerPolicy/ControllerPolicyApplicationRole", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        assignedRoles: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.getUnAssignedRoles();
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        assignedRoles: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/ControllerPolicyApplicationRole", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUnassignedRoleChange(id, selectedItem) {
        try {
            this.setState({
                assignRole: selectedItem,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return rowData.id === undefined
            ? <Fragment>
                <button type="button" disabled={!this.state.selectedPermission || !this.state.assignRole} className="btn btn-link" onClick={(event) => this.onAssignRoleClick(event, rowData)}>
                    <span className="fas fa-save dgdg-font-blue dgdg-font-size-18" aria-hidden="true" />
                </button>
                <button type="button" className="btn btn-link" onClick={(event) => this.onUnassignRoleClick(event, rowData)}>
                    <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
                </button>
            </Fragment>
            : <button type="button" className="btn btn-link" onClick={(event) => this.onShowConfirmationClick(event, rowData)}>
                <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
            </button>;
    }

    onShowConfirmationClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationOkClick(event, rowData) {
        try {
            this.onUnassignRoleClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationCancelClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowConfirmationControllPolicyClick(event, rowData) {
        try {
            this.setState({ deleteControllerPolicy: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationControllPolicyCancelClick(event, rowData) {
        try {
            this.setState({ deleteControllerPolicy: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetControllerPolicy() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/onGetControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ControllerPolicy/GetControllerPolicy", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.display_name = responseItem.group_name + " | " + responseItem.controller_name
                    });

                    this.setState({
                        isLoadingData: false,
                        controllerList: parsedResponse.table,
                        assignedRoles: [],
                        unAssignedRoles: [],
                        assignRole: null,
                        paintTable: true,
                        deleteAssignedRole: null,
                        deleteControllerPolicy: false,
                        controllerName: null,
                        selectedGroup: null,
                        selectedPermission: null
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        controllerList: [],
                        assignedRoles: [],
                        unAssignedRoles: [],
                        assignRole: null,
                        paintTable: true,
                        deleteAssignedRole: null,
                        deleteControllerPolicy: false,
                        controllerName: null,
                        selectedGroup: null,
                        selectedPermission: null
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getUnAssignedRoles() {
        try {
            let inputData = {
                controllerName: this.state.controllerName,
                groupName: this.state.selectedGroup ? this.state.selectedGroup.key : "",
                isLoadingData: true
            };
            this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/getUnAssignedRoles", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ControllerPolicy/GetUnassignedRoles", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        unAssignedRoles: parsedResponse.table,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/GetUnassignedRoles", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAssignRoleClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                id: rowData.id,
                controllerName: this.state.controllerName,
                groupName: this.state.selectedGroup.key,
                permission: this.state.selectedPermission.key,
                applicationRoleExtId: this.state.assignRole.id,
                deleteControllerPolicy: false
            };

            this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/onAssignRoleClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ControllerPolicy/SaveControllerPolicy", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        assignRole: null,
                        selectedPermission: null
                    });
                    rowData = (rowData && rowData.id === undefined) ? null : rowData;
                    this.onGetAssignedRoles(event, rowData);
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/SaveControllerPolicy", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUnassignRoleClick(event, rowData) {
        try {
            if (rowData && rowData.id === undefined) {
                let asgndRoles = this.state.assignedRoles;
                asgndRoles.pop();
                this.setState({
                    assignedRoles: asgndRoles,
                    assignRole: null,
                    selectedPermission: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });

            } else {
                let inputData = {
                    id: this.state.deleteAssignedRole.id,
                    controllerName: this.state.controllerName,
                    groupName: this.state.selectedGroup.key,
                    permission: this.state.selectedPermission ? this.state.selectedPermission.key : this.state.deleteAssignedRole.permission,
                    applicationRoleExtId: this.state.deleteAssignedRole.id,
                    deleteControllerPolicy: true
                };

                this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/onUnassignRoleClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/ControllerPolicy/SaveControllerPolicy", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.onGetAssignedRoles(event, rowData);
                        this.setState({
                            assignRole: null,
                            selectedPermission: null
                        }, this.onConfirmationCancelClick);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/onUnassignRoleClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    })
            }
            if (this.state.deleteAssignedRole !== null) {
                this.setState({ deleteAssignedRole: null });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationControllPolicyOkClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                controllerName: this.state.controllerName,
                groupName: this.state.selectedGroup.key,
                permission: this.state.selectedPermission.key,
                applicationRoleExtId: 0,
                deleteControllerPolicy: true
            };

            this.props.applicationInsights.trackTrace({ message: "/ControllerPolicyRouteComponent/onConfirmationControllPolicyOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ControllerPolicy/SaveControllerPolicy", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetControllerPolicy();
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ControllerPolicy/onConfirmationControllPolicyOkClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Remove Controller Policy: " + this.state.controllerName}
                    bodyText={"Are you sure?"}
                    showConfirmation={this.state.deleteControllerPolicy === true}
                    onOkClick={this.onConfirmationControllPolicyOkClick}
                    onCancelClick={this.onConfirmationControllPolicyCancelClick} />
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Remove role: " + (this.state.deleteAssignedRole !== null ? this.state.deleteAssignedRole.application_role_ext_name : "")}
                    bodyText={"Are you sure?"}
                    showConfirmation={this.state.deleteAssignedRole !== null}
                    onOkClick={this.onConfirmationOkClick}
                    onCancelClick={this.onConfirmationCancelClick} />
                <ControllerPolicyQueryComponent applicationInsights={this.props.applicationInsights} controllerList={this.state.controllerList} onGetAssignedRoles={this.onGetAssignedRoles} isLoadingData={this.state.isLoadingData} />
                <div className="dgdg-site-scrollable-content">
                    <div className="card dgdg-card-gutter" style={{ "width": "50%" }}>
                        <div className="card-header">
                            Controller
                            <button className="btn btn-danger dgdg-icon" onClick={(event) => this.onShowConfirmationControllPolicyClick(event)} disabled={!this.state.controllerName}>
                                Delete <span className="fas fa-window-close dgdg-icon" />
                            </button>
                        </div>
                        <div>
                            <div className="form-inline">
                                <DGDGFormInputComponent id="controllerName" label="Controller Name" value={this.state.controllerName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                <DGDGFormDropdownComponent label="Group Name" data={this.state.groupList} itemKey="value" onItemClick={this.onGroupListChange} value={this.state.selectedGroup ? this.state.selectedGroup.value : "Select Group"} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{ "width": "50%" }}>
                        <div className="card-header">
                            Controller Roles & Permission
                            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onAddClick(event)}>
                                Add <span className="fas fa-plus dgdg-icon" />
                            </button>
                        </div>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.assignedRoles} paintTable={this.state.paintTable}>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Role" dataColumn="application_role_ext_name" dataColumnCallback={this.onAssignedRoleColumnCallback} />
                                <DGDGTableColumnComponent headerText="Permission" dataColumn="permission" dataColumnCallback={this.onAssignedPermissionColumnCallback} />
                                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

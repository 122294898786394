import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { DemoPageView1Component } from "./DemoPageView1Component";
import { DemoPageView2Component } from "./DemoPageView2Component";
import { DemoPageView3Component } from "./DemoPageView3Component";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class DemoPageRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event1Response: null,
            event2Response: null,
            cancelEvent1: false
        };

        this.onEvent1Changed = this.onEvent1Changed.bind(this);
        this.onEvent2Changed = this.onEvent2Changed.bind(this);
        this.onCancelEvent1 = this.onCancelEvent1.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Demo Page";

    onEvent1Changed(eventData) {
        try {
            this.setState({ event1Response: eventData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCan

    onEvent2Changed(eventData) {
        try {
            this.setState({ event2Response: eventData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelEvent1(cancelEvent) {
        try {
            this.setState({ cancelEvent1: cancelEvent });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/DemoPage/View1" className={"nav-item nav-link" + (window.location.pathname.indexOf("/View1") !== -1 ? " btn btn-dark active" : " btn btn-link")}>View 1</Link>
                    <Link to="/DemoPage/View2" className={"nav-item nav-link" + (window.location.pathname.indexOf("/View2") !== -1 ? " btn btn-dark active" : " btn btn-link")}>View 2</Link>
                    <Link to="/DemoPage/View3" className={"nav-item nav-link" + (window.location.pathname.indexOf("/View3") !== -1 ? " btn btn-dark active" : " btn btn-link")}>View 3</Link>
                    <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge dgdg-icon">
                            Data2 Event: {this.state.event2Response ? CommonService.formatDateTimeZone(this.state.event2Response.DataObject2) : ""}
                        </span>
                        <span className="float-right badge badge-primary dgdg-badge">
                            Data1 Event: {this.state.event1Response ? moment(this.state.event1Response.DataObject1).format("MM/DD/YYYY hh:mm:ss A") : ""}
                            <button type="button" className="btn btn-link dgdg-demo-page-data-event-cancel-btn" onClick={(event) => this.onCancelEvent1(event)}>
                                <i className="fas fa-times text-danger dgdg-icon" style={{ "verticalAlign": "top", "marginTop": "-3px" }} />
                            </button>
                        </span>
                    </div>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-demo-page-content">
                    <Switch>
                        <Route exact path="/DemoPage/View1" render={(routeProps) => <DemoPageView1Component {...this.props} {...routeProps} onEvent1Changed={this.onEvent1Changed} cancelEvent1={this.state.cancelEvent1} onEvent2Changed={this.onEvent2Changed} />} />
                        <Route exact path="/DemoPage/View2" render={(routeProps) => <DemoPageView2Component {...this.props} {...routeProps} />} />
                        <Route exact path="/DemoPage/View3" render={(routeProps) => <DemoPageView3Component {...this.props} {...routeProps} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}

import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";

import { NewPDIInventoryQueryComponent } from "./NewPDIInventoryQueryComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class NewPDIInventoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            isLoadingData: false,
            selectedStore: null,
            techList: [
                { key: "696877", value: "ANA CORONA" },
                { key: "37510", value: "ANABELLE SALANGAD" },
                { key: "703404", value: "JOHN DOWNEY" },
                { key: "682967", value: "NOLAN SPARKS" }
            ],
            legendText: null,
            tableTitle: "",
            tableData: [],
            paintTable: false,

            showToggle: false,
            toggleId: null,
            editTop: null,
            editLeft: null,
            toggleValue: false,
            showPublishConfirmation: false,
            publishRow: null,
            showReservedConfirmation: false,
            reservedRow: null,
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onRONumberTextChange = this.onRONumberTextChange.bind(this)
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onPDIDoneSelectChange = this.onPDIDoneSelectChange.bind(this);
        this.onLPODoneSelectChange = this.onLPODoneSelectChange.bind(this);
        this.onLPOArrivedSelectChange = this.onLPOArrivedSelectChange.bind(this);
        this.roNumberColumnCallback = this.roNumberColumnCallback.bind(this);
        this.onPDIDoneColumnCallback = this.onPDIDoneColumnCallback.bind(this);
        this.onLPOArrivedColumnCallback = this.onLPOArrivedColumnCallback.bind(this);
        this.onLPODoneColumnCallback = this.onLPODoneColumnCallback.bind(this);
        this.onPhotoDoneColumnCallback = this.onPhotoDoneColumnCallback.bind(this);
        this.onPhotoDoneSelectChange = this.onPhotoDoneSelectChange.bind(this);

        this.onPublishSelectChange = this.onPublishSelectChange.bind(this);
        this.onOkPublishChange = this.onOkPublishChange.bind(this);
        this.onCancelPublishClick = this.onCancelPublishClick.bind(this);

        this.onReservedSelectChange = this.onReservedSelectChange.bind(this);
        this.onReservedChange = this.onReservedChange.bind(this);
        this.onCancelReservedClick = this.onCancelReservedClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "New Car Prep";

    componentDidMount() {
        try {
            console.devLog("NewPDIInventoryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/NewPDI/Inventory", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getLegendText();
            CommonService.clientAzureStorageLog("NewPDIInventoryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/NewPDI/GetInventoryStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/GetInventoryStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    setRowColor(table) {
        table.forEach((responseItem, index) => {
            responseItem.rowCssName = "";
            if (responseItem.status === "G") {
                responseItem.rowCssName = !responseItem.is_lpo_done ? "dgdg-new-pdi-inventory-bg-orange" : "";
            }
            else {
                responseItem.rowCssName = responseItem.ok_publish
                    ? "dgdg-new-pdi-inventory-bg-gray"
                    : responseItem.is_reserved
                        ? "dgdg-new-pdi-inventory-bg-light-purple"
                        : responseItem.is_pdi_done && responseItem.is_lpo_done && responseItem.is_photo_done
                            ? "dgdg-new-pdi-inventory-bg-light-blue"
                            : responseItem.is_pdi_done && responseItem.is_lpo_done && !responseItem.is_photo_done
                                ? "dgdg-new-pdi-inventory-bg-light-green"
                                : responseItem.is_lpo_arrived && !responseItem.is_lpo_done
                                    ? "dgdg-new-pdi-inventory-bg-pink"
                                    : responseItem.is_pdi_done || responseItem.is_lpo_done
                                        ? "dgdg-new-pdi-inventory-bg-yellow"
                                        : "";
            }

            switch (responseItem.rowCssName) {
                case "dgdg-new-pdi-inventory-bg-gray":
                    responseItem.color = "Grey";
                    break;
                case "dgdg-new-pdi-inventory-bg-pink":
                    responseItem.color = "Pink";
                    break;
                case "dgdg-new-pdi-inventory-bg-light-blue":
                    responseItem.color = "Blue";
                    break;
                case "dgdg-new-pdi-inventory-bg-light-green":
                    responseItem.color = "Green";
                    break;
                case "dgdg-new-pdi-inventory-bg-orange":
                    responseItem.color = "Orange";
                    break;
                case "dgdg-new-pdi-inventory-bg-yellow":
                    responseItem.color = "Yellow";
                    break;
                default:
                    responseItem.color = "White";
                    break;
            }
        });
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-white" />&nbsp;&nbsp;&nbsp;Pending PDI, DIO </div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-yellow" />&nbsp;&nbsp;&nbsp;PDI or DIO done</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-pink" />&nbsp;&nbsp;&nbsp;DIO arrived, not done</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-light-green" />&nbsp;&nbsp;&nbsp;PDI, DIO done, pending Photo</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-light-blue" />&nbsp;&nbsp;&nbsp;PDI, DIO, Photo done, not sold</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-orange" />&nbsp;&nbsp;&nbsp;Sold, pending DIO</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-gray" />&nbsp;&nbsp;&nbsp;Do Not Publish</div>
            <div className="d-inline-block dgdg-new-pdi-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-new-pdi-inventory-bg-light-purple" />&nbsp;&nbsp;&nbsp;Reserved</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetReportClick(selectedStore, showPDIDone) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });
            let inputData = {
                storeNumber: selectedStore.store_number,
                showPDIDone: showPDIDone
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.is_pdi_done = responseItem.pdi_done_date_time ? true : false;
                        responseItem.is_lpo_done = responseItem.lpo_done_date_time ? true : false;
                        responseItem.is_lpo_arrived = responseItem.lpo_arrived_date_time ? true : false;
                        responseItem.is_photo_done = responseItem.photo_done_date_time ? true : false;
                        responseItem.ok_publish = responseItem.inventory_state === "InPrep" ? true : false;
                        responseItem.ok_publish_label = "OK to <br /> Publish";
                        responseItem.ok_publish_label2 = "Do Not <br /> Publish";
                        responseItem.is_reserved_label = "Available";
                        responseItem.is_reserved_label2 = "Reserved";
                        responseItem.vin_href = "/RO/All/All/All/" + responseItem.vin;
                        responseItem.disable_all = (responseItem.edit_all !== null && responseItem.edit_all !== undefined) ? (responseItem.edit_all ? false : true) : true;
                    })
                    this.setRowColor(parsedResponse);
                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRONumberTextChange(id, value, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.ro_number_showSpinner = true;
            rowData.ro_number = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                stockNumber: rowData.stock_number,
                roNumber: value
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onRONumberTextChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveRONumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.ro_number_showSpinner = false;
                    rowData.ro_number_entered_date_time = moment();
                    rowData.ro_number_entered_by = this.props.remoteUpn;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "Stock #" + rowData.stock_number + " RO Number saved"}, 4000);
                })
                .catch(notOKResponse => {
                    rowData.ro_number_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveRONumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPDIDoneSelectChange(id, isChecked, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.is_pdi_done_showSpinner = true;
            rowData.is_pdi_done = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                pdiDoneDateTime: isChecked ? moment() : null
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onPDIDoneSelectChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SavePDIDone", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.is_pdi_done_showSpinner = false;
                    rowData.pdi_done_date_time = isChecked ? moment() : null;
                    rowData.pdi_done_by = isChecked ? this.props.remoteUpn : "";
                    this.setRowColor(tableData);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_pdi_done_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SavePDIDone", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLPODoneSelectChange(id, isChecked, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.is_lpo_done_showSpinner = true;
            rowData.is_lpo_done = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                lpoDoneDateTime: isChecked ? moment() : null
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onLPODoneSelectChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveLPODone", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    //this.onGetReportClick(this.state.selectedStore);
                    rowData.is_lpo_done_showSpinner = false;
                    rowData.lpo_done_date_time = isChecked ? moment() : null;
                    rowData.lpo_done_by = isChecked ? this.props.remoteUpn : "";
                    this.setRowColor(tableData);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_lpo_done_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveLPODone", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLPOArrivedSelectChange(id, isChecked, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.is_lpo_arrived_showSpinner = true;
            rowData.is_lpo_arrived = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                lpoArrivedDateTime: isChecked ? moment() : null
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onLPOArrivedSelectChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveLPOArrived", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    //this.onGetReportClick(this.state.selectedStore);
                    rowData.is_lpo_arrived_showSpinner = false;
                    rowData.lpo_arrived_date_time = isChecked ? moment() : null;
                    rowData.lpo_arrived_by = isChecked ? this.props.remoteUpn : "";
                    this.setRowColor(tableData);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_lpo_arrived_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveLPOArrived", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPhotoDoneSelectChange(id, isChecked, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.is_photo_done_showSpinner = true;
            rowData.is_photo_done = isChecked;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                photoDoneDateTime: isChecked ? moment() : null
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onPhotoDoneSelectChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SavePhotoDone", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.is_photo_done_showSpinner = false;
                    rowData.photo_done_date_time = isChecked ? moment() : null;
                    rowData.photo_done_by = isChecked ? this.props.remoteUpn : null;
                    this.setRowColor(tableData);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    console.devError(notOKResponse, null, this.props.applicationInsights);
                    rowData.is_photo_done_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json().then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SavePhotoDone", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(parsedError, null, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });

            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comments);
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={rowData.disable_all} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.edit_all && rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.stock_number} value={rowData["comments_" + rowData.stock_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-8" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comments_" + rowData.stock_number;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData["comments_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                stockNumber: rowData.stock_number,
                comments: rowData["comments_" + rowData.stock_number].replace(/\n|\r/g, " ")
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comments = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.stock_number] + (rowData.comments ? '\r' + rowData.comments : "");
                    rowData["comments_" + rowData.stock_number] = "";
                    rowData.comments_showSpinner = false;
                    rowData.showViewComments = true;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "Stock #" + rowData.stock_number + " Comments saved"}, 4000);
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveComments", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("NewPDIInventoryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    roNumberColumnCallback(rowData) {
        return <Fragment> <a href={"/RO/" + rowData.store_number + "/" + rowData.ro_number} target="_self" disabled={rowData.disable_all} > {rowData.ro_number}</a>
            <br />
            {rowData.ro_number_entered_date_time
                ? <Fragment>
                    {CommonService.formatDate(moment(rowData.ro_number_entered_date_time))}
                    <br />
                    {"By " + rowData.ro_number_entered_by}
                </Fragment>
                : null
            }
        </Fragment>
    }

    onPDIDoneColumnCallback(rowData) {
        let rowId = "pdiDone_" + rowData.stock_number;
        let entryDate = moment(rowData.entry_date)
        let days = 0;
        if (rowData.pdi_done_date_time !== null) {
            let pdiDate = moment(rowData.pdi_done_date_time)
            days = pdiDate.diff(entryDate, 'days');
        }
        return <Fragment>
            <DGDGFormCheckboxV2Component label="Done:" disabled={rowData.disable_all} id={rowId + "_pdiDone"} value={rowData.is_pdi_done} onChange={this.onPDIDoneSelectChange} additionalData={rowData} />
            {rowData.pdi_done_date_time
                ? <div style={{ "paddingLeft": "5px" }}>
                    {CommonService.formatDate(rowData.pdi_done_date_time) + " (" + days + " days)"}
                    <br />
                    {"By " + rowData.pdi_done_by}
                </div>
                : null
            }
        </Fragment>;

    }

    onLPOArrivedColumnCallback(rowData) {
        let rowId = "lpoArrived_" + rowData.stock_number;
        return <Fragment>
            <DGDGFormCheckboxV2Component label="Arrived:" disabled={rowData.disable_all} id={rowId + "_lpoArrived"} value={rowData.is_lpo_arrived} onChange={this.onLPOArrivedSelectChange} additionalData={rowData} />
            {rowData.lpo_arrived_date_time
                ? <div style={{ "paddingLeft": "5px" }}>
                    {CommonService.formatDate(moment(rowData.lpo_arrived_date_time))}
                    <br />
                    {" By " + rowData.lpo_arrived_by}
                </div>
                : null
            }
        </Fragment>
    }

    onLPODoneColumnCallback(rowData) {
        let rowId = "lpoDone_" + rowData.stock_number;
        return <Fragment>
            <DGDGFormCheckboxV2Component label="Done:" disabled={rowData.disable_all} id={rowId + "_lpoDone"} value={rowData.is_lpo_done} onChange={this.onLPODoneSelectChange} additionalData={rowData} />
            {rowData.lpo_done_date_time
                ? <div style={{ "paddingLeft": "5px" }}>
                    {CommonService.formatDate(moment(rowData.lpo_done_date_time))}
                    <br />
                    {" By " + rowData.lpo_done_by}
                </div>
                : null
            }
        </Fragment>
    }

    onPhotoDoneColumnCallback(rowData) {
        let rowId = "photoDone_" + rowData.stock_number;
        let entryDate = moment(rowData.entry_date)
        let days = 0;
        if (rowData.photo_done_date_time !== null) {
            let photoDate = moment(rowData.photo_done_date_time)
            days = photoDate.diff(entryDate, 'days');
        }
        return <Fragment>
            <DGDGFormCheckboxV2Component label="Done:" disabled={rowData.disable_all} id={rowId + "_photoDone"} value={rowData.is_photo_done} onChange={this.onPhotoDoneSelectChange} additionalData={rowData} />
            {rowData.photo_done_date_time
                ? <div style={{ "paddingLeft": "5px" }}>
                    {CommonService.formatDate(rowData.photo_done_date_time) + " (" + days + " days)"}
                    <br />
                    {"By " + rowData.photo_done_by}
                </div>
                : null
            }
        </Fragment>;
    }

    onPublishSelectChange(id, isChecked, rowData) {
        try {
            rowData.ok_publish = isChecked;
            this.setState({
                publishRow: rowData,
                showPublishConfirmation: true,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkPublishChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.publishRow.stock_number)[0];
        try {
            rowData.ok_publish = this.state.publishRow.ok_publish;
            rowData.ok_publish_showSpinner = true;
            rowData.inventory_state = this.state.publishRow.ok_publish === true ? "InPrep" : "Live";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
                this.onCancelPublishClick();
            });

            let inputData = {
                stockNumber: this.state.publishRow.stock_number,
                homenetState: rowData.inventory_state
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onOkPublishChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveHomenetState", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setRowColor(tableData);
                    rowData.ok_publish_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveHomenetState", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelPublishClick() {
        try {
            this.setState({
                showPublishConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReservedSelectChange(id, isChecked, rowData) {
        try {
            rowData.is_reserved = isChecked;
            this.setState({
                reservedRow: rowData,
                showReservedConfirmation: true,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReservedChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.reservedRow.stock_number)[0];
        try {
            rowData.is_reserved = this.state.reservedRow.is_reserved;
            rowData.is_reserved_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
                this.onCancelReservedClick();
            });

            let inputData = {
                stockNumber: this.state.reservedRow.stock_number,
                isReserved: this.state.reservedRow.is_reserved
            };
            this.props.applicationInsights.trackTrace({ message: "NewPDIInventoryComponent/onReservedChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/NewPDI/SaveIsReserved", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setRowColor(tableData);
                    rowData.is_reserved_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/NewPDI/SaveIsReserved", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelReservedClick() {
        try {
            this.setState({
                showReservedConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"OK to Publish/Do Not Publish"}
                bodyText={((this.state.publishRow !== null && !this.state.publishRow.ok_publish) ? "OK to publish stock number " : "Do not publish stock number ") + (this.state.publishRow !== null ? this.state.publishRow.stock_number : "") + "?"}
                showConfirmation={this.state.showPublishConfirmation}
                onOkClick={this.onOkPublishChange}
                onCancelClick={this.onCancelPublishClick}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Available/Reserved"}
                bodyText={((this.state.reservedRow !== null && !this.state.reservedRow.is_reserved) ? "Do not reserve stock number " : "Reserve stock number ") + (this.state.reservedRow !== null ? this.state.reservedRow.stock_number : "") + "?"}
                showConfirmation={this.state.showReservedConfirmation}
                onOkClick={this.onReservedChange}
                onCancelClick={this.onCancelReservedClick}
            />
            <NewPDIInventoryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.state.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText}
                    tablePageSize={CommonService.tablePageSize} stickyHeader
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-new-pdi-inventory-sl" />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="dgdg-new-pdi-store-width" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" cssName="dgdg-new-pdi-inventory-stock-number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" cssName="dgdg-new-pdi-inventory-vin" />
                        <DGDGTableColumnCheckboxV2Component headerText="Publish" dataColumn="ok_publish" disabledColumn="disable_all" labelColumn="ok_publish_label" label2Column="ok_publish_label2" sortColumn="ok_publish" dataType="bool" cssName="dgdg-new-pdi-inventory-status-override" onChange={this.onPublishSelectChange} showSpinner />
                        <DGDGTableColumnCheckboxV2Component headerText="Reserved" dataColumn="is_reserved" disabledColumn="disable_all" labelColumn="is_reserved_label" label2Column="is_reserved_label2" sortColumn="is_reserved" dataType="bool" cssName="dgdg-new-pdi-inventory-reserved" onChange={this.onReservedSelectChange} showSpinner />
                        <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" cssName="dgdg-new-pdi-inventory-status" />
                        <DGDGTableColumnComponent headerText="Color" dataColumn="color" sortColumn="color" filterColumn="color" cssName="dgdg-new-pdi-inventory-status" />
                        <DGDGTableColumnComponent headerText="YMMT" dataColumn="model" sortColumn="model" filterColumn="model" cssName="dgdg-new-pdi-inventory-model" />
                        <DGDGTableColumnComponent headerText="MSRP" dataColumn="msrp" sortColumn="msrp" filterColumn="msrp" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="PDI Age (Days)" dataType="number" dataColumn="pdi_age" sortColumn="pdi_age" filterColumn="pdi_age" cssName="dgdg-new-pdi-age" />
                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" onChange={this.onRONumberTextChange} showSpinner disabledColumn="disable_all" isEditable dataColumnCallback={this.roNumberColumnCallback} cssName="dgdg-new-pdi-inventory-ro-number" />
                        <DGDGTableColumnComponent headerText="PDI" dataColumn="is_pdi_done" sortColumn="is_pdi_done" dataType="bool" dataColumnCallback={this.onPDIDoneColumnCallback} disabledColumn="disable_all" showSpinner cssName="dgdg-new-pdi-inventory-toggle-width" />
                        <DGDGTableColumnComponent headerText="DIO Arrived" dataColumn="is_lpo_arrived" sortColumn="is_lpo_arrived" dataType="bool" dataColumnCallback={this.onLPOArrivedColumnCallback} cssName="dgdg-new-pdi-inventory-toggle-width" disabledColumn="disable_all" columnHelp="Dealer Installed Option (same as GM LPO)" showSpinner />
                        <DGDGTableColumnComponent headerText="DIO Done" dataColumn="is_lpo_done" sortColumn="is_lpo_done" dataType="bool" dataColumnCallback={this.onLPODoneColumnCallback} cssName="dgdg-new-pdi-inventory-toggle-width" disabledColumn="disable_all" columnHelp="Dealer Installed Option (same as GM LPO)" showSpinner />
                        <DGDGTableColumnComponent headerText="Photo" dataColumn="is_photo_done" sortColumn="is_photo_done" dataType="bool" dataColumnCallback={this.onPhotoDoneColumnCallback} disabledColumn="disable_all" showSpinner cssName="dgdg-new-pdi-inventory-toggle-width" />
                        <DGDGTableColumnComponent headerText="Photo Status" dataColumn="photo_status" sortColumn="photo_status" filterColumn="photo_status" cssName="text-nowrap" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent colSpan="11" dataColumnCallback={this.onAddCommentColumnCallback} />
                        <DGDGTableColumnComponent headerText="VIN" dataText="List VIN ROs" dataType="anchor" href="vin_href" target="_self" />
                        <DGDGTableColumnComponent colSpan="6" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}

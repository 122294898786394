import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

import { ReportsQueryComponent } from "./ReportsQueryComponent";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export class ReportsRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            maximizeReport: false,
            reportTitle: "",
            rightTitle: "",
            tableData: [],
            primarytableData: [],
            paintTable: false,
            type: "",
            lastUpdatedDate: null,
            showFilter: false
        }

        this.onLoadReportClick = this.onLoadReportClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ReportsRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Report", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.onGetReportClick();
            this.getLastUpdatedDate();
            CommonService.clientAzureStorageLog("ReportsRouteComponent", "ReportsRouteComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Reports";

    onMaximizeClick(event) {
        try {
            this.setState({ maximizeReport: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizeReport: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLoadReportClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.action_showSpinner = true;
            this.setState({
                embedParams: null,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                tenant: rowData.tenant,
                workspaceId: rowData.workspaceId,
                reportId: rowData.id,
                datasetId: rowData.datasetId,
                reportName: rowData.name,
                embedUrl: rowData.embedUrl,
                type: rowData.type
            };
            this.props.applicationInsights.trackTrace({ message: "/ReportsRouteComponent/onLoadReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Reports/EmbedReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.action_showSpinner = false;
                    tableData.forEach((rowData, index) => {
                        rowData.rowCssName = "";
                    });
                    rowData.rowCssName = "dgdg-reports-table-row";
                    this.setState({
                        embedParams: JSON.parse(parsedResponse),
                        reportName: rowData.name,
                        reportTitle: rowData.name,
                        type: rowData.type,
                        rightTitle: "Report Last Updated Date: " + (rowData.lastUpdatedDate ? CommonService.formatDateTimeZone(rowData.lastUpdatedDate) : ""),
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                        this.props.history.replace({ pathname: "/Reports/" + rowData.name });
                    });
                })
                .catch(notOKResponse => {
                    rowData.action_showSpinner = false;
                    this.setState({
                        embedParams: null,
                        reportTitle: "",
                        rightTitle: "",
                        type: "",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Reports/onLoadReportClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(filterValue) {
        try {
            this.setState({
                showSpinner: true,
                tableData: [],
                primarytableData: [],
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.props.applicationInsights.trackTrace({ message: "/ReportsRouteComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Reports/GetReport")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        showSpinner: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        primarytableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false }, () => {
                            this.loadReport();
                        });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        showSpinner: false,
                        tableData: [],
                        primarytableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Reports/GetReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(filterValue) {
        try {
            this.setState({
                showSpinner: true,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let tableData = this.state.primarytableData.filter(rowData => rowData.name.search(new RegExp(filterValue, "i")) !== -1)
            this.setState({
                showSpinner: false,
                tableData: tableData,
                paintTable: true,
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckboxChange() {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    getLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ReportsRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Reports/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ReportsRouteComponent/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    loadReport() {
        if (this.props.match.params.Name) {
            let tableData = this.state.tableData;
            if (this.state.embedParams) {
                let rowData = tableData.filter(rowData => rowData.id === this.state.embedParams.EmbedReport[0].ReportId)[0];
                if (rowData) {
                    rowData.rowCssName = "dgdg-reports-table-row";
                }
            }
            else {
                let rowData = tableData.filter(rowData => rowData.name === this.props.match.params.Name)[0];
                this.onLoadReportClick(null, rowData)
            }
            this.setState({
                tableData: tableData,
                paintTable: true,
            }, () => {
                this.setState({ paintTable: false });
            });
        }
    }

    render() {
        let reportWidth = "80%";
        if (this.state.maximizeReport) {
            reportWidth = "100%";
        }
        let embedUrl = "";
        if (this.state.embedParams) {
            embedUrl = this.state.embedParams?.EmbedReport[0].EmbedUrl;
            if (this.state.reportName === "Sales") {
                embedUrl += "&filter=Sales/contract_date ge 2023-01-01 and Sales/contract_date le 2025-01-01"
            }
        }

        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <div>
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <ReportsQueryComponent {...this.props}
                    applicationInsights={this.props.applicationInsights}
                    showSpinner={this.state.showSpinner}
                    onGetReportClick={this.onGetReportClick}
                    onLookupClick={this.onLookupClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-flex dgdg-flex-row" style={{ "height": "100%" }}>
                        {
                            !this.state.maximizeReport
                                ? <div className="dgdg-reports">
                                    <DGDGTableV3Component cardCssName="dgdg-full-width dgdg-reports-card" cssName="dgdg-reports-table" applicationInsights={this.props.applicationInsights} headerText="Reports & Dashboard" tableData={this.state.tableData} paintTable={this.state.paintTable}>
                                        <DGDGTableRowComponent onRowClick={this.onLoadReportClick}>
                                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                            <DGDGTableColumnComponent headerText="Action" dataColumn="action" dataText="<i class='fas fa-redo-alt dgdg-icon' />" cssName="btn-link d-flex" showSpinner />
                                            <DGDGTableColumnComponent headerText="Name" dataColumn="name" sortColumn="name" filterColumn="name" cssName="btn-link" showSpinner />
                                            <DGDGTableColumnComponent headerText="Last Updated Date" dataColumn="lastUpdatedDate" sortColumn="lastUpdatedDate" filterColumn="lastUpdatedDate" dataType="dateTime" />
                                            <DGDGTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                        </DGDGTableRowComponent>
                                    </DGDGTableV3Component>
                                </div>
                                : null
                        }
                        {
                            this.state.embedParams
                                ? <div style={{ flexGrow: "1" }}>
                                    <DGDGWidgetComponent
                                        title={this.state.reportTitle}
                                        rightTitle={this.state.rightTitle}
                                        widgetHeaderCss = "dgdg-widget-reports-header"
                                        toggleEnabled={true}
                                        checkboxId={"showFilter"}
                                        checkboxValue={this.state.showFilter}
                                        checkboxLabel="Show Filter"
                                        onCheckboxChange={this.onCheckboxChange}
                                        maximizedCss="dgdg-widget-maximized" restoredCss="dgdg-widget-maximized"
                                        isMaximized={this.state.maximizeReport} isVisible={true}
                                        onMaximizeClick={(event) => this.onMaximizeClick(event)} onRestoreClick={(event) => this.onRestoreClick(event)}
                                    >
                                        <PowerBIEmbed
                                            embedConfig={
                                                {
                                                    type: this.state.embedParams?.Type.toLowerCase(),
                                                    id: this.state.embedParams?.EmbedReport[0].ReportId,
                                                    embedUrl: embedUrl,
                                                    accessToken: this.state.embedParams?.EmbedToken.Token,
                                                    tokenType: models.TokenType.Embed,
                                                    settings: {
                                                        panes: {
                                                            bookmarks: {
                                                                visible: false
                                                            },
                                                            fields: {
                                                                expanded: false
                                                            },
                                                            filters: {
                                                                expanded: this.state.showFilter,
                                                                visible: this.state.showFilter
                                                            },
                                                            pageNavigation: {
                                                                visible: true
                                                            },
                                                            selection: {
                                                                visible: true
                                                            },
                                                            syncSlicers: {
                                                                visible: true
                                                            },
                                                            visualizations: {
                                                                expanded: true
                                                            }
                                                        },
                                                        bars: {
                                                            statusBar: {
                                                                visible: true
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            cssClassName={"dgdg-demo-page-powerbi-report-window"}
                                        />
                                    </DGDGWidgetComponent>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportWeekendSummaryRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <tr className="dgdg-table-v3-row font-weight-bold text-right">
            <td colSpan="10" className="dgdg-sales-store-bg dgdg-no-border-top" />
            <td className="text-left text-nowrap dgdg-sales-store-bg-row-alt dgdg-font-blue">
                {this.props.title}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].wow_volume_delta_pct ? this.props.salesData[3].wow_volume_delta_pct_color : null }}>
                {CommonService.formatPercentage(this.props.salesData[3].wow_volume_delta_pct)}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].yoy_volume_delta_pct ? this.props.salesData[3].yoy_volume_delta_pct_color : null }}>
                {CommonService.formatPercentage(this.props.salesData[3].yoy_volume_delta_pct)}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].wow_total_gross_delta_pct ? this.props.salesData[3].wow_total_gross_delta_pct_color : null }}>
                {CommonService.formatPercentage(this.props.salesData[3].wow_total_gross_delta_pct)}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].yoy_total_gross_delta_pct ? this.props.salesData[3].yoy_total_gross_delta_pct_color : null }}>
                {CommonService.formatPercentage(this.props.salesData[3].yoy_total_gross_delta_pct)}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].wow_total_pvr_delta ? this.props.salesData[3].wow_total_pvr_delta_color : null }}>
                {CommonService.formatCurrency(this.props.salesData[3].wow_total_pvr_delta)}
            </td>
            <td style={{ backgroundColor: this.props.salesData[3].yoy_total_pvr_delta ? this.props.salesData[3].yoy_total_pvr_delta_color : null }}>
                {CommonService.formatCurrency(this.props.salesData[3].yoy_total_pvr_delta)}
            </td>
        </tr>;
    }
}

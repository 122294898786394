import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { FSCommon } from "./FSCommon";
import { FinancialStatementValueCellComponent } from "./FinancialStatementValueCellComponent";

export class BalanceSheetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        let oem1NewInventoryAmount = null, oem2NewInventoryAmount = null, oem3NewInventoryAmount = null, oem4NewInventoryAmount = null;
        let lifoNewVehicles = null, lifoUsedVehicles = null, lifoParts = null, totalLIFOReserve = null, netWorkingCapital = null;
        let totalOEM1 = null, totalUsedRetail = null, totalUsedWHSL = null, totalProfitLoss = null;
        let totalLiabilitiesNetWorth = null, totalLiabilities = null, totalNetWorth = null, recastAdjustment = null;

        if (this.props.fsDict) {
            totalOEM1 = FSCommon.sumByKey(this.props.fsDict, "M", "Balance Sheet Stat", "Sales",
                ["Jan Count Total New Vehicle Dept", "Feb Count Total New Vehicle Dept", "Mar Count Total New Vehicle Dept", "Apr Count Total New Vehicle Dept",
                    "May Count Total New Vehicle Dept", "Jun Count Total New Vehicle Dept", "Jul Count Total New Vehicle Dept", "Aug Count Total New Vehicle Dept",
                    "Sep Count Total New Vehicle Dept", "Oct Count Total New Vehicle Dept", "Nov Count Total New Vehicle Dept", "Dec Count Total New Vehicle Dept"
                ]);

            totalUsedRetail = FSCommon.sumByKey(this.props.fsDict, "M", "Balance Sheet Stat", "Sales",
                ["Jan Count Total Used Vehicle Retail", "Feb Count Total Used Vehicle Retail", "Mar Count Total Used Vehicle Retail", "Apr Count Total Used Vehicle Retail",
                    "May Count Total Used Vehicle Retail", "Jun Count Total Used Vehicle Retail", "Jul Count Total Used Vehicle Retail", "Aug Count Total Used Vehicle Retail",
                    "Sep Count Total Used Vehicle Retail", "Oct Count Total Used Vehicle Retail", "Nov Count Total Used Vehicle Retail", "Dec Count Total Used Vehicle Retail"
                ]);

            totalUsedWHSL = FSCommon.sumByKey(this.props.fsDict, "M", "Balance Sheet Stat", "Sales",
                ["Jan Count Used Vehicle - Wholesale", "Feb Count Used Vehicle - Wholesale", "Mar Count Used Vehicle - Wholesale", "Apr Count Used Vehicle - Wholesale",
                    "May Count Used Vehicle - Wholesale", "Jun Count Used Vehicle - Wholesale", "Jul Count Used Vehicle - Wholesale", "Aug Count Used Vehicle - Wholesale",
                    "Sep Count Used Vehicle - Wholesale", "Oct Count Used Vehicle - Wholesale", "Nov Count Used Vehicle - Wholesale", "Dec Count Used Vehicle - Wholesale"
                ]);

            totalProfitLoss = FSCommon.sumByKey(this.props.fsDict, "M", "Balance Sheet", "Net Worth",
                ["Jan Net Profit or Loss Before Bonus & Taxes", "Feb Net Profit or Loss Before Bonus & Taxes", "Mar Net Profit or Loss Before Bonus & Taxes", "Apr Net Profit or Loss Before Bonus & Taxes",
                    "May Net Profit or Loss Before Bonus & Taxes", "Jun Net Profit or Loss Before Bonus & Taxes", "Jul Net Profit or Loss Before Bonus & Taxes", "Aug Net Profit or Loss Before Bonus & Taxes",
                    "Sep Net Profit or Loss Before Bonus & Taxes", "Oct Net Profit or Loss Before Bonus & Taxes", "Nov Net Profit or Loss Before Bonus & Taxes", "Dec Net Profit or Loss Before Bonus & Taxes"
                ]);

            oem1NewInventoryAmount = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet Stat~Assets~Inventories - New Car")
                + FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet Stat~Assets~Inventories - New Truck");
            if (oem1NewInventoryAmount === 0) {
                oem1NewInventoryAmount = null;
            }

            oem2NewInventoryAmount = FSCommon.parseDecimal(this.props.fsDict, "2~M~Balance Sheet Stat~Assets~Inventories - New Car")
                + FSCommon.parseDecimal(this.props.fsDict, "2~M~Balance Sheet Stat~Assets~Inventories - New Truck");
            if (oem2NewInventoryAmount === 0) {
                oem2NewInventoryAmount = null;
            }

            oem3NewInventoryAmount = FSCommon.parseDecimal(this.props.fsDict, "3~M~Balance Sheet Stat~Assets~Inventories - New Car")
                + FSCommon.parseDecimal(this.props.fsDict, "3~M~Balance Sheet Stat~Assets~Inventories - New Truck");
            if (oem3NewInventoryAmount === 0) {
                oem3NewInventoryAmount = null;
            }

            oem4NewInventoryAmount = FSCommon.parseDecimal(this.props.fsDict, "4~M~Balance Sheet Stat~Assets~Inventories - New Car")
                + FSCommon.parseDecimal(this.props.fsDict, "4~M~Balance Sheet Stat~Assets~Inventories - New Truck");
            if (oem4NewInventoryAmount === 0) {
                oem4NewInventoryAmount = null;
            }

            lifoNewVehicles = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Assets~Inventories - LIFO Reserve-New Vehicles") * -1;
            lifoUsedVehicles = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Assets~Inventories - LIFO Reserve - Used Vehicles") * -1;
            lifoParts = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Assets~Inventories - LIFO Reserve-Parts & Accessories") * -1;
            totalLIFOReserve = lifoNewVehicles + lifoUsedVehicles + lifoParts;

            netWorkingCapital = totalLIFOReserve;
            netWorkingCapital += FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Assets~Total Current Assets");
            netWorkingCapital -= FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Liabilities~Total Current Liabilities");

            //totalLiabilitiesNetWorth = CommonService.parseDecimal(this.props.fsDict["1~M~Balance Sheet~Net Worth~Total Liabilities & Net Worth"]);
            totalLiabilitiesNetWorth = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Assets~Total Assets");
            totalLiabilities = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Liabilities~Total Liabilities");
            totalNetWorth = FSCommon.parseDecimal(this.props.fsDict, "1~M~Balance Sheet~Net Worth~Total Net Worth");
            recastAdjustment = totalLiabilitiesNetWorth - (totalLiabilities + totalNetWorth);
        }

        let rowNumber = 1;
        return <table className="dgdg-fs-table">
            <tbody>
                <tr className="font-weight-bold">
                    <td colSpan="6">Store Name: {this.props.storeName}</td>
                    <td className="dgdg-no-border" />
                    <td colSpan="3">Statement Month: {this.props.selectedMonth === null ? "" : CommonService.formatDate(moment(this.props.selectedMonth))}</td>
                </tr>
                <tr style={{ height: "15px" }}>
                    <td colSpan="6" className="dgdg-no-border-right" />
                    <td className="dgdg-no-border" />
                    <td colSpan="3" className="dgdg-no-border-left" />
                </tr>
                <tr className="text-center font-weight-bold">
                    <td colSpan="5">ASSETS</td>
                    <td>AMOUNT</td>
                    <td>LINE NO.</td>
                    <td colSpan="2">LIABILITIES</td>
                    <td>AMOUNT</td>
                </tr>
                <tr className="text-center">
                    <td colSpan="6" className="font-weight-bold dgdg-fs-heading-2-bg-color">CASH</td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold dgdg-fs-heading-2-bg-color">ACCOUNTS PAYABLE</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">In Bank and on Hand</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Cash - In Bank and on Hand"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Vendors</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accounts Payable - Vendors"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Contracts in Transit</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Cash - Contracts in Transit"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Customers Deposits</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accounts Payable - Customers Deposits"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL CASH AND CONTRACTS</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Cash & Contracts"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Vehicle Lien Payoff</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accounts Payable - Vehicle Lien Payoff"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-table-pad-left-2 dgdg-fs-heading-2-bg-color">RECEIVABLES</td>
                    <td colSpan="2" className="text-center dgdg-fs-heading-2-bg-color" style={{ fontSize: "9px" }}>Memo Past-Due-Over 30 Days</td>
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Other</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accounts Payable - Other"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">Vehicle Accounts</td>
                    <td colSpan="2" className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Receivables - Vehicle Accounts Memo Past Due"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Receivables - Vehicle Accounts"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL ACCOUNTS PAYABLE</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Accounts Payable"} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">Service, Parts & Body Shop</td>
                    <td colSpan="2" className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Receivables - Service, Parts & Body Shop Memo Past Due"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Receivables - Service, Parts & Body Shop"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold text-center dgdg-fs-heading-2-bg-color">NOTES PAYABLE</td>
                </tr>
                <tr>
                    <td colSpan="2">Other Customer Accounts</td>
                    <td colSpan="2" className="dgdg-fs-value" />
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Receivables - Other Customer Accounts"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">New Vehicles & Demonstrators</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Notes Payable - New Vehicles & Demonstrators"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL CUSTOMER RECEIVABLES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Customer Receivables"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Used Vehicles</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Notes Payable - Used Vehicles"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">LESS:  Allowance for Doubtful Accounts</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~LESS: Allowance for Doubtful Accounts"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Other</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Notes Payable - Other"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">NET CUSTOMER RECEIVABLES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Net Customer Receivables"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Current Amount-Long Term Debt</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Notes Payable - Current Amount-Long Term Debt"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Finance & Insurance Co. Receivables</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Finance & Insurance Co. Receivables"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL NOTES PAYABLE</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Notes Payable"} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Other  Factory Receivables</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Factory Receivables"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold text-center dgdg-fs-heading-2-bg-color">ACCRUED LIABILITIES</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Incentives - OEM</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Incentives"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Interest</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Interest"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Incentives - Other</td>
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Payroll</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Payroll"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="6" className="dgdg-fs-bg-black" />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Insurance</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Insurance"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Warr Claims Recv-OEM</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Warranty Claims Receivables-Mazda"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Taxes-Payroll</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Taxes-Payroll"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Warr Claims Recv-Other</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Warranty Claims Receivables-Other"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Taxes-Sales</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Taxes-Sales"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Transportation Claims Receivable</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Transportation Claims Receivable"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Taxes-Other</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Taxes-Other"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Pre-Delivery Inspection</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Pre-Delivery Inspection"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Income Taxes-Prior Year</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Income Taxes-Prior Year"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL RECEIVABLES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Receivables"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Income Taxes-Current Year</td>
                    <td />
                </tr>
                <tr>
                    <td className="font-weight-bold dgdg-fs-table-pad-left-2 dgdg-fs-heading-2-bg-color">INVENTORIES</td>
                    <td className="dgdg-fs-heading-2-bg-color">Car</td>
                    <td className="dgdg-fs-heading-2-bg-color">Truck</td>
                    <td className="dgdg-fs-heading-2-bg-color">Total</td>
                    <td className="dgdg-fs-heading-2-bg-color">% of Age</td>
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Bonuses-Employees</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Bonuses-Employees"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td>Demonstrators</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Demonstrators"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Bonuses-Owners</td>
                    <td />
                </tr>
                <tr>
                    <td>New - OEM 1</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet Stat~Assets~Inventories - New Car"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet Stat~Assets~Inventories - New Truck"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={oem1NewInventoryAmount} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Pension Fund</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Pension Fund"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td>New - OEM 2</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"2~M~Balance Sheet Stat~Assets~Inventories - New Car"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"2~M~Balance Sheet Stat~Assets~Inventories - New Truck"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={oem2NewInventoryAmount} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"2~M~Balance Sheet~Assets~Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Other</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Other"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td>New - OEM 3</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"3~M~Balance Sheet Stat~Assets~Inventories - New Car"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"3~M~Balance Sheet Stat~Assets~Inventories - New Truck"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={oem3NewInventoryAmount} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"3~M~Balance Sheet~Assets~Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Reserve for Repossession Losses</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Accrued Liabilities - Reserve for Repossession Losses"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td>New - OEM 4</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"4~M~Balance Sheet Stat~Assets~Inventories - New Car"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"4~M~Balance Sheet Stat~Assets~Inventories - New Truck"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={oem4NewInventoryAmount} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"4~M~Balance Sheet~Assets~Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL ACCRUED LIABILITIES</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Accrued Liabilities"} />
                    </td>
                </tr>
                <tr>
                    <td>*Memo-Service Units:</td>
                    <td colSpan="2" className="text-center">
                        Cars:
                    </td>
                    <td colSpan="2" className="text-center">
                        Trucks:
                    </td>
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL CURRENT LIABILITIES</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Current Liabilities"} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">Total New Inventory</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Sub Total New & Demo Inventory"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold text-center dgdg-fs-heading-2-bg-color">LONG TERM DEBT</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">LIFO Reserve - New Vehicles</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - LIFO Reserve-New Vehicles"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Long Term Debt</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Long Term Debt - Long Term Debt"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">NET NEW & DEMO INVENTORY</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Net New & Demo Inventory"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Mortgages Payable-Real Estate</td>
                    <td />
                </tr>
                <tr>
                    <td colSpan="3" className="font-weight-bold dgdg-fs-table-pad-left-2 dgdg-fs-heading-2-bg-color">INVENTORIES</td>
                    <td className="dgdg-fs-heading-2-bg-color">Total</td>
                    <td className="dgdg-fs-heading-2-bg-color">% of Age</td>
                    <td className="dgdg-fs-heading-2-bg-color">Lease & Rental Vehicles</td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Lease & Rental Vehicles</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Long Term Debt - Lease & Rental Vehicles"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="3">Used Vehicle Inventory</td>
                    <td />
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Used Vehicles"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">Notes-Officers/Owners</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Long Term Debt - Notes-Officers/Owners"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="3">OEM Certified Inventory</td>
                    <td />
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Certified Pre-Owned"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL LONG TERM DEBT</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Long Term Debt"} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="3" className="dgdg-fs-table-pad-left-2">LIFO Reserve - Used Vehicles</td>
                    <td />
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - LIFO Reserve - Used Vehicles"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL LIABILITIES</td>
                    <td className="font-weight-bold dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Liabilities~Total Liabilities"} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">NET USED INVENTORY</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Net Used Inventory"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" rowSpan="4" className="dgdg-no-padding">
                        <table className="dgdg-no-border" width="100%" style={{ "lineHeight": "18px" }}>
                            <tbody>
                                <tr>
                                    <td className="dgdg-no-border-left dgdg-no-border-top text-center">NET WORKING CAPITAL</td>
                                    <td rowSpan="4" className="dgdg-no-border-top dgdg-no-border-bottom">
                                        <p className="dgdg-text-vertical">MEMO</p>
                                    </td>
                                    <td className="dgdg-no-border-top">LIFO NEW VEHICLES</td>
                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={lifoNewVehicles} />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-border-left font-weight-bold dgdg-fs-value" style={{ "fontSize": "15px" }}>
                                        {CommonService.formatCurrency(netWorkingCapital)}
                                    </td>
                                    <td>LIFO USED VEHICLES</td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={lifoUsedVehicles} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>LIFO PARTS</td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={lifoParts} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left  dgdg-no-border-bottom">(ASSETS & LIFO MINUS LIABILITIES)</td>
                                    <td className="dgdg-no-border-bottom">TOTAL LIFO RESERVE</td>
                                    <td className="dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalLIFOReserve} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Parts & Accessories</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Parts & Accessories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">SUB TOTAL PARTS & ACCESSORIES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Sub Total Parts & Accessories"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Tires</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Tires"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">LIFO Reserve-Parts & Accessories</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - LIFO Reserve-Parts & Accessories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold text-center dgdg-fs-heading-2-bg-color">NET WORTH</td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">NET PARTS & ACCESSORIES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Net Parts & Accessories"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="font-weight-bold text-center">CORPORATION ONLY</td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Gas, Oil & Grease</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Gas, Oil & Grease"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">PREFERRED STOCK</td>
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Paint & Body Shop Materials</td>
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">COMMON STOCK</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Corporation Only - Common Stock"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Sublet Repairs</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Sublet Repairs"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">TREASURY STOCK</td>
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Work in Process-Labor</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Work In Process-Labor"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">ADDITIONAL PAID IN CAPITAL</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Corporation Only - Additional Paid in Capital"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Miscellaneous Inventories</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Inventories - Miscellaneous Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">RETAINED EARNINGS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Corporation Only - Retained Earnings"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL INVENTORIES</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Inventories"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">DIVIDENDS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Corporation Only - Dividends"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-2-bg-color">OTHER CURRENT ASSETS</td>
                    <td className="font-weight-bold dgdg-fs-heading-2-bg-color" />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">PROPRIETOR OR PARTNER</td>
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Securities</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Current Assets - Securities"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">INVESTMENTS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Proprietor or Partner - Investments"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">Prepaid Expense</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Current Assets - Prepaid Expense"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" />
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL OTHER CURRENT ASSETS</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Other Current Assets"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-table-pad-left-1">DRAWINGS</td>
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL CURRENT ASSETS</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Current Assets"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2" />
                    <td />
                </tr>
                <tr>
                    <td colSpan="5" className="dgdg-fs-table-pad-left-1">*Lease and Rental Units</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Lease and Rental Units"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" rowSpan="17" className="dgdg-no-padding align-top">
                        <table className="dgdg-no-border" width="100%" style={{ "lineHeight": "18px" }}>
                            <tbody>
                                <tr>
                                    <td className="dgdg-no-border-top dgdg-no-border-left" />
                                    <td className="dgdg-no-border-top" colSpan="4">NEW</td>
                                    <td className="dgdg-no-border-top" colSpan="2">USED</td>
                                    <td className="dgdg-no-border-top dgdg-no-border-right" rowSpan="2">PROFIT OR (LOSS)</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left" />
                                    <td>OEM 1</td>
                                    <td>OEM 2</td>
                                    <td>OEM 3</td>
                                    <td>OEM 4</td>
                                    <td>RETAIL</td>
                                    <td>WHSL.</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">JAN</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jan Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jan Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jan Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Jan Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">FEB</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Feb Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Feb Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Feb Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Feb Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">MAR</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Mar Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Mar Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Mar Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Mar Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">APR</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Apr Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Apr Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Apr Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Apr Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">MAY</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~May Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~May Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~May Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~May Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">JUN</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jun Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jun Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jun Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Jun Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">JUL</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jul Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jul Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Jul Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Jul Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">AUG</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Aug Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Aug Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Aug Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Aug Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">SEP</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Sep Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Sep Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Sep Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Sep Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">OCT</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Oct Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Oct Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Oct Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Oct Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">NOV</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Nov Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Nov Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Nov Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Nov Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold dgdg-no-border-left">DEC</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Dec Count Total New Vehicle Dept" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Dec Count Total Used Vehicle Retail" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet Stat~Sales~Dec Count Used Vehicle - Wholesale" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Balance Sheet~Net Worth~Dec Net Profit or Loss Before Bonus & Taxes" onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                                    </td>
                                </tr>
                                <tr key="count-total">
                                    <td className="font-weight-bold dgdg-no-border-left">TOTAL</td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalOEM1} />
                                    </td>
                                    <td /><td /><td />
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalUsedRetail} />
                                    </td>
                                    <td className="dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalUsedWHSL} />
                                    </td>
                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalProfitLoss} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan="5">L&R Accumulated Write Down</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~L & R Accumulated Write Down"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="6" className="font-weight-bold dgdg-fs-heading-2-bg-color" />
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3" />
                    <td>COST</td>
                    <td>ACCUMULATED DEPRECIATION</td>
                    <td>NET</td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">LAND</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - Land"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Land"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">BLDGS. & IMPRVM</td>
                    <td />
                    <td />
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">M & S EQUIP</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - M & S Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - M & S Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - M & S Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">P & A EQUIP</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - P & A Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - P & A Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - P & A Equip"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">FURN. & FIXT.</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - Furn & Fixt"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - Furn & Fixt"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Furn & Fixt"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">SERVICE UNITS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - Service Units"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - Service Units"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Service Units"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">LEASE-HOLDS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - Lease Holds"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - Lease Holds"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Lease Holds"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3">SIGNS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets Cost - Signs"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - Signs"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Signs"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="3" className="dgdg-fs-heading-1-bg-color">TOTAL</td>
                    <td className="dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Total Cost"} />
                    </td>
                    <td className="dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets AD - Total"} />
                    </td>
                    <td className="dgdg-fs-heading-1-bg-color dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Fixed Assets - Total"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="6" className="font-weight-bold text-center dgdg-fs-heading-2-bg-color">OTHER ASSETS</td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5">Deposits</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Assets - Deposits"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5">Life Insurance-Cash Value</td>
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5">Notes and Accounts Receivable-Officers</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Assets - Notes and Accounts Receivable-Officers"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                </tr>
                <tr>
                    <td colSpan="5">Advances to Employees</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Assets - Advances to Employees"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">PROFIT OR (LOSS) BEFORE TAXES</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalProfitLoss} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5">Other Assets & Investments</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Assets - Other Assets & Investments"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">ESTIMATED INCOME TAX</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Estimated Income Tax"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5">Other Notes & Accounts Receivable</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Other Assets - Other Notes & Accounts Receivable"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">NET PROFIT OR (LOSS)</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Net Profit or Loss After Taxes"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5">Intercompany Transfer</td>
                    <td />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="3" className="dgdg-fs-bg-black" />
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL OTHER ASSETS</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Other Assets"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">TOTAL NET WORTH</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Net Worth~Total Net Worth"} onFinancialStatementDetailClick={this.props.onFinancialStatementDetailClick} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="6" className="dgdg-fs-bg-black" />
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">RECAST ADJUSTMENT</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={recastAdjustment} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5" className="font-weight-bold dgdg-fs-heading-1-bg-color">TOTAL ASSETS</td>
                    <td className="font-weight-bold dgdg-fs-value dgdg-fs-heading-1-bg-color">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Balance Sheet~Assets~Total Assets"} />
                    </td>
                    <td className="text-center">{rowNumber++}</td>
                    <td colSpan="2">TOTAL LIABILITIES & NET WORTH</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalLiabilitiesNetWorth} />
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGFormMultiSelectComponent } from "../common/dgdgmultiselect/DGDGFormMultiSelectComponent";
import { DGDGFormRadioComponent } from "../common/dgdgradiobutton/DGDGFormRadioComponent";

export class DemoPageEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stringInput: "",
            numberInput: "",
            moneyInput: "",
            decimalInput: "",
            percentInput: "",
            dateInput: null,
            ddpData: this.ddpData,
            selectedDdpData: null,
            selectedCmbBoxData: "",
            selectedRaidoButtonData: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onComboBoxChange = this.onComboBoxChange.bind(this);
        this.onComboBoxClick = this.onComboBoxClick.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.onRadioButtonListChange = this.onRadioButtonListChange.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    ddpData = [
        { key: "key1", value: "ABCD", disabled: false },
        { key: "key2", value: "EFGH", disabled: false },
        { key: "key3", value: "IJKL", disabled: false },
        { key: "key4", value: "MNOP", disabled: false },
        { key: "key5", value: "QRST", disabled: true },
        { key: "key6", value: "UVWX", disabled: true },
        { key: "key7", value: "YZ", disabled: true }
    ];

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDropdownChange(id, selectedDdpData) {
        try {
            this.setState({ selectedDdpData: selectedDdpData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onComboBoxChange(id, filterValue) {
        try {
            let filteredData = this.ddpData.filter(row => {
                return row.value.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
            });

            this.setState({
                selectedCmbBoxData: filterValue,
                ddpData: filteredData
            });

            this.setState({ selectedCmbBoxData: filterValue });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onComboBoxClick(id, selectedCmbBoxItem) {
        try {
            this.setState({ selectedCmbBoxData: selectedCmbBoxItem.value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMultiSelectChange(id, items) {
        try {
            let ddpData = this.state.ddpData;
            this.setState({ ddpData: ddpData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRadioButtonListChange(id, value) {
        try {
            this.setState({ selectedRaidoButtonData: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.rowData !== null}
            cssName="dgdg-demo-page-edit-dialog"
            title={this.props.rowData ? "Edit Record.Row # - " + this.props.rowData.number_col : ""}
            onCancelClick={this.props.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.props.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <div className="form-inline">
                            <div className="form-row">
                                <DGDGFormInputComponent id="stringInput" label="String Input" value={this.state.stringInput} onChange={this.onTextChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="numberInput" label="Number Input" value={this.state.numberInput} onChange={this.onTextChange} inputType="number" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                <DGDGFormInputComponent id="moneyInput" label="Money Input" value={this.state.moneyInput} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="decimalInput" label="Decimal Input" value={this.state.decimalInput} onChange={this.onTextChange} inputType="decimal" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="percentInput" label="Percent Input" value={this.state.percentInput} onChange={this.onTextChange} inputType="percent" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="dateInput" label="Date Input" value={this.state.dateInput} onDateChange={this.onDateChange} numberOfMonths={2} showDays={[3]} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormCheckboxV2Component id="checkV2Input" label="Check Input:" value={this.state.checkV2Input} onChange={this.onCheckChange} colSpan="col-2" labelSpan="col-9" controlSpan="col-3" />
                                <DGDGFormCheckboxComponent id="checkInput" label="Check Input" value={this.state.checkInput} onChange={this.onCheckChange} colSpan="col-2" labelSpan="col-9" controlSpan="col-3" />
                                <DGDGFormDropdownComponent label="Dropdown Input" data={this.state.ddpData} itemKey="value" value={this.state.selectedDdpData ? this.state.selectedDdpData.value : "Select Value"} onItemClick={this.onDropdownChange} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                <DGDGFormComboBoxComponent id="cmbInput" label="Combobox Input" data={this.state.ddpData} itemKey="value" value={this.state.selectedCmbBoxData} onItemClick={this.onComboBoxClick} onItemChange={this.onComboBoxChange} autoDrop colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormMultiSelectComponent applicationInsights={this.props.applicationInsights} id="ddlMultiSelect" label="Multti Select" data={this.state.ddpData} itemKey="value" value="Select Value" onChange={this.onMultiSelectChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormRadioComponent id="selectedRaidoButtonData" name="rblSelectName" label="Radio Button List" data={this.state.ddpData} itemKey="value" value={this.state.selectedRaidoButtonData ? this.state.selectedRaidoButtonData.value : null} onChange={this.onRadioButtonListChange} colSpan="col-8" controlSpan="col-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}

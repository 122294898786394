import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ActiveDirectoryUserQueryComponent } from "./ActiveDirectoryUserQueryComponent";

export class ActiveDirectoryUserRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      paintTable: false,
      isLoadingData: false,
      lastUpdatedDate: null
    }

    this.onLookupClick = this.onLookupClick.bind(this);
    this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

    props.onBreadCrumbStateChanged({
      pageTitle: this.pageTitle,
      override: true,
      link: {
        name: this.pageTitle,
        action: "add"
      }
    });

    console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
    console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
  }

  componentDidMount() {
    try {
      console.devLog("ActiveDirectoryUserRouteComponent - componentDidMount");
      this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ActiveDirectoryUser", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      this.getControllerPolicy();
      this.onLookupClick();
      this.getLastUpdatedDate();
      CommonService.clientAzureStorageLog("ActiveDirectoryUserRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  pageTitle = "Active Directory Users";

  getControllerPolicy() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetControllerPolicy")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          console.devLog("ACL: " + parsedResponse.acl.join(), "ActiveDirectoryUserRouteComponent", "getControllerPolicy");
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  getLastUpdatedDate() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetLastUpdated")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          let lastUpdatedDate = "";
          if (CommonService.isDateValid(parsedResponse))
            lastUpdatedDate = moment.utc(parsedResponse);
          this.setState({ lastUpdatedDate: lastUpdatedDate });
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onLookupClick(id, value) {
    try {
      let inputData = {
        filterValue: value,
      };
      this.setState({ isLoadingData: true });
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/onLookupClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetUsers", {
        method: "POST",
        headers: {
          'Content-Type': "application/json; charset=utf-8;"
        },
        body: JSON.stringify(inputData)
      })
        .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
        .then(parsedResponse => {
          parsedResponse.forEach(responseItem => {
            responseItem.CreatedDate = responseItem.CreatedDate === "" ? null : responseItem.CreatedDate;
            responseItem.HireDate = responseItem.HireDate === "" ? null : responseItem.HireDate;
            responseItem.TermDate = responseItem.TermDate === "" ? null : responseItem.TermDate;
            responseItem.PaylocityStatus_cellCssName += responseItem.PaylocityStatus === "T" ? " dgdg-activedirectory-user-cell-font" : " dgdg-activedirectory-user-palocity-color";
            responseItem.AccountEnabled_cellCssName += responseItem.AccountEnabled === false ? " dgdg-activedirectory-user-cell-font" : " dgdg-activedirectory-user-activedirectory-color";
          });
          this.setState({
            tableData: CommonService.addOrdinalColumn(parsedResponse),
            paintTable: true,
            isLoadingData: false
          }, () => {
            this.setState({ paintTable: false });
          });
        })
        .catch(notOKResponse => {
          this.setState({
            isLoadingData: false,
            tableData: [],
            paintTable: true
          }, () => {
            this.setState({ paintTable: false });
          });
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetUsers", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onCopyTableCallBack(event) {
    try {
      this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
      CommonService.clientAzureStorageLog("ActiveDirectoryUserRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  render() {
    return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
      <div>
        <span className="float-right badge badge-primary dgdg-badge">
          Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
        </span>
      </div>
      <div className="dgdg-flex-item dgdg-site-content">
        <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
          <ActiveDirectoryUserQueryComponent
            applicationInsights={this.props.applicationInsights}
            isLoadingData={this.state.isLoadingData}
            onLookupClick={this.onLookupClick}
          />
          <div className="dgdg-site-scrollable-content">
            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Active Directory Users" tableData={this.state.tableData} paintTable={this.state.paintTable}
              tablePageSize={CommonService.tablePageSize} stickyHeader
              copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17], onCopyTableCallBack: this.onCopyTableCallBack }}
            >
              <DGDGTableHeaderComponent position="PreHeader">
                <tr className="dgdg-table-v3-header">
                  <td />
                  <td colSpan="8" className="text-center font-weight-bol dgdg-advisor-bg-head dgdg-activedirectory-user-activedirectory-color">Active Directory</td>
                  <td colSpan="9" className="text-center font-weight-bol dgdg-advisor-bg-head dgdg-activedirectory-user-palocity-color">Paylocity</td>
                </tr>
              </DGDGTableHeaderComponent>
              <DGDGTableRowComponent>
                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-directory-bg-white" />
                <DGDGTableColumnComponent headerText="User Principal Name" dataColumn="UserPrincipalName" sortColumn="UserPrincipalName" filterColumn="UserPrincipalName" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Display Name" dataColumn="DisplayName" sortColumn="DisplayName" filterColumn="DisplayName" cssName="dgdg-activedirectory-user-activedirectory-color" />
                <DGDGTableColumnComponent headerText="Mail" dataColumn="Mail" sortColumn="Mail" filterColumn="Mail" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Other Mails" dataColumn="OtherMails" sortColumn="OtherMails" filterColumn="OtherMails" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-logon-email-width" />
                <DGDGTableColumnComponent headerText="Proxy Addresses" dataColumn="ProxyAddresses" sortColumn="ProxyAddresses" filterColumn="ProxyAddresses" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Business Phones" dataColumn="BusinessPhones" sortColumn="BusinessPhones" filterColumn="BusinessPhones" dataType="phone" cssName="dgdg-activedirectory-user-activedirectory-color text-nowrap" />
                <DGDGTableColumnComponent headerText="Created Date" dataColumn="CreatedDate" sortColumn="CreatedDate" filterColumn="CreatedDate" dataType="date" cssName="dgdg-activedirectory-user-activedirectory-color" />
                <DGDGTableColumnComponent headerText="Account Enabled" dataColumn="AccountEnabled" sortColumn="AccountEnabled" filterColumn="AccountEnabled" dataType="bool" />
                <DGDGTableColumnComponent headerText="Store Number" dataColumn="StoreNumber" sortColumn="StoreNumber" filterColumn="StoreNumber" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Work Email" dataColumn="WorkEmail" sortColumn="WorkEmail" filterColumn="WorkEmail" cssName="dgdg-activedirectory-user-palocity-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Status" dataColumn="PaylocityStatus" sortColumn="PaylocityStatus" filterColumn="PaylocityStatus" />
                <DGDGTableColumnComponent headerText="Start Date" dataColumn="HireDate" sortColumn="HireDate" filterColumn="HireDate" dataType="date" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Term Date" dataColumn="TermDate" sortColumn="TermDate" filterColumn="TermDate" dataType="date" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Work Phone" dataColumn="WorkPhone" sortColumn="WorkPhone" filterColumn="WorkPhone" dataType="phone" cssName="dgdg-activedirectory-user-palocity-color text-nowrap" />
                <DGDGTableColumnComponent headerText="Job Title" dataColumn="JobTitle" sortColumn="JobTitle" filterColumn="JobTitle" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Employee Number" dataColumn="EmployeeNumber" sortColumn="EmployeeNumber" filterColumn="EmployeeNumber" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Logon Email" dataColumn="LogonEmail" sortColumn="LogonEmail" filterColumn="LogonEmail" cssName="dgdg-activedirectory-user-palocity-color dgdg-activedirectory-logon-email-width" />
              </DGDGTableRowComponent>
            </DGDGTableV3Component>
          </div>
        </div>
      </div>
    </DGDGPageComponent>;
  }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class EVOXImagesEVOXVehiclesQueryComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("EVOXImagesEVOXVehiclesQueryComponent - getDerivedStateFromProps");
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    viewData = [
        { key: "Unmapped", value: "Unmapped Active" },
        { key: "Mapped Inactive", value: "Mapped Inactive" },
        { key: "Mapped", value: "Mapped" },
        { key: "Active", value: "Active" },
        { key: "Hidden", value: "Hidden" },
        { key: "Inactive", value: "Inactive" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            vifNumber: null,
            selectedView: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("EVOXImagesEVOXVehiclesQueryComponent - componentDidMount");

            let vifNumber = null;
            if (this.props.match.params.vifNumber) {
                vifNumber = this.props.match.params.vifNumber;
            }

            this.setState({
                selectedView: this.viewData[0],
                vifNumber: vifNumber
            }, this.onGetReportClick);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onViewClick(id, view) {
        try {
            this.setState({ selectedView: view });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.props.onGetReportClick(this.state.vifNumber, this.state.selectedView);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Mapped" data={this.viewData} itemKey="value" onItemClick={this.onViewClick} value={this.state.selectedView !== null ? this.state.selectedView.value : "Select View"} />
                    <DGDGFormInputComponent id="vifNumber" label="VIF" value={this.state.vifNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}

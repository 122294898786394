import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class ServiceStoreReportMonthQueryComponent extends React.Component {
    static MonthCookie = "ServiceStoreReportMonthCookie";

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.months && this.state.selectedMonth === null) {
                let selectedMonth = this.props.months[0];
                let cookieMonth = CommonService.getCookie(ServiceStoreReportMonthQueryComponent.MonthCookie);
                if (cookieMonth) {
                    let filterMonth = this.props.months.filter((month) => month.isSame(cookieMonth, 'date'));
                    if (filterMonth.length === 1) {
                        selectedMonth = filterMonth[0];
                    }
                }

                this.setState({
                    selectedMonth: selectedMonth
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(event, month) {
        try {
            this.setState({ selectedMonth: month });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(ServiceStoreReportMonthQueryComponent.MonthCookie, this.state.selectedMonth.format("MM/DD/YYYY"), 30);
            this.props.onGetReportClick(this.state.selectedMonth);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Month" displayFormat="monthYear" data={this.props.months} value={this.state.selectedMonth ?? ""} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
            <button disabled={this.props.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button disabled={this.props.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onPrintClick(event)}>
                Print<i className="far fa-print dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.props.isLoadingData} />
        </DGDGQueryComponent>;
    }
}

import React from "react";
import { DGDGFormCheckboxV2Component } from "../dgdgcheckbox/DGDGFormCheckboxV2Component";

export class DGDGToggleBoxComponent extends React.Component {

    constructor(props) {
        super(props);

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                this.props.onHideClick();
                break;
            default:
                break;
        }
    }

    render() {
        return (
            this.props.showToggle
                ? <div style={{ position: "absolute", top: this.props.top, left: this.props.left, zIndex: "101" }} className={this.props.cssName}>
                    <div className="form-control">
                        <DGDGFormCheckboxV2Component id={this.props.id} label={this.props.label} label2={this.props.label2} value={this.props.value} onChange={this.props.onChange} onClick={this.props.onClick} onHideClick={this.props.onHideClick} />
                    </div>
                </div>
                : null
        );
    }
}

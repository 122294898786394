import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

import { FIStoreReportFIManagerTopTenComponent } from "./FIStoreReportFIManagerTopTenComponent";
import { FIStoreReportFIManagerQueryComponent } from "./FIStoreReportFIManagerQueryComponent";

export class FIStoreReportFIManagerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            tableData: [],
            footerRow: null,
            paintTable: false,
            pvrData: null,
            pprData: null,
            warrantyData: null,
            maintenanceData: null,
            canShowCount: false,
            showCount: false,
            startDate: null,
            endDate: null,
            paintDrilldownTable: false,
            drilldownTableData: [],
            drilldownTableTitle: null
        };

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onShowCountChange = this.onShowCountChange.bind(this);
        this.onDrilldownCancelClick = this.onDrilldownCancelClick.bind(this);
        this.onTotalProductChargebacksCellClick = this.onTotalProductChargebacksCellClick.bind(this);
        this.onTotalProductAdjustmentsCellClick = this.onTotalProductAdjustmentsCellClick.bind(this);
        this.getProductAdjustmentData = this.getProductAdjustmentData.bind(this);
        this.onTotalReserveChargebacksCellClick = this.onTotalReserveChargebacksCellClick.bind(this);
        this.onTotalReserveAdjustmentsCellClick = this.onTotalReserveAdjustmentsCellClick.bind(this);
        this.getReserveAdjustmentData = this.getReserveAdjustmentData.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "F&I Store Report";

    componentDidMount() {
        try {
            console.devLog("FIStoreReportFIManagerComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIStoreReport/FIManager", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-fi-store-store", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getTitleBasedOnStoreSelection(selectedStores, defaultTitle) {
        let stores = this.props.stores;
        if (selectedStores && stores && Array.isArray(selectedStores) && Array.isArray(stores)) {
            if (selectedStores.length === 1) {
                return selectedStores[0].display_name;
            } else if (selectedStores.length === stores.length) {
                return "All Stores";
            }
            return "Multiple Stores";
        }
        return defaultTitle;
    }

    onGetReportClick(selectedStores, selectedVehicleType, selectedDealType, startDate, endDate) {
        try {
            if (selectedStores && Array.isArray(selectedStores) && selectedStores.length > 0) {
                this.setState({
                    isLoadingData: true,
                    startDate: startDate,
                    endDate: endDate
                });
                let inputData = {
                    storeNumbers: selectedStores && Array.isArray(selectedStores) && selectedStores.length > 0 ?
                        selectedStores.map(store => store.store_number) : null,
                    vehicleType: selectedVehicleType ? selectedVehicleType.key : null,
                    dealType: selectedDealType ? selectedDealType.key : null,
                    startDate: CommonService.formatServerDateTime(startDate),
                    endDate: CommonService.formatServerDateTime(endDate),
                };

                this.props.applicationInsights.trackTrace({ message: "/FIStoreReportFIManagerComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/FIStoreReport/GetFIManagerReport", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        if (CommonService.isDateValid(parsedResponse.lastUpdatedDate)) {
                            this.props.onLastUpdatedDateChanged("(" + parsedResponse.lastUpdatedDatePrefix + "): " + CommonService.formatDateTimeZone(moment.utc(parsedResponse.lastUpdatedDate)));
                        }
                        let tableData = !(Array.isArray(selectedStores) && selectedStores.length > 0) ? parsedResponse.table : parsedResponse.table.filter(tableRow => selectedStores.some((store) => tableRow.store_number === store.store_number));
                        tableData = tableData.filter((row) => { return row.store_number !== 99; });
                        let footerRow = parsedResponse.table.filter((row) => { return row.store_number === 99; })[0];
                        let pvrData = tableData.map(({ finance_mgr_name, store_number, back_pvr }) => ({ finance_mgr_name, store_number, back_pvr }));
                        let pprData = tableData.map(({ finance_mgr_name, store_number, ppr }) => ({ finance_mgr_name, store_number, ppr }));
                        let warrantyData = tableData.map(({ finance_mgr_name, store_number, vsc_penetration }) => ({ finance_mgr_name, store_number, vsc_penetration }));
                        let maintenanceData = tableData.map(({ finance_mgr_name, store_number, maint_penetration }) => ({ finance_mgr_name, store_number, maint_penetration }));

                        tableData.forEach((tableRow, index) => {
                            if (!tableRow.finance_mgr_name) {
                                tableRow.finance_mgr_name = tableRow.finance_mgr_number;
                            }
                        });


                        this.setState({
                            isLoadingData: false,
                            tableTitle: (this.getTitleBasedOnStoreSelection(selectedStores, "All Stores"))
                                + " - " + (selectedVehicleType === null ? "All Vehicles" : selectedVehicleType.value)
                                + " - " + (selectedDealType === null ? "All Deals" : selectedDealType.value),
                            tableData: CommonService.addOrdinalColumn(tableData),
                            footerRow: footerRow,
                            paintTable: true,
                            canShowCount: parsedResponse.canShowCount,
                            pvrData: CommonService.addOrdinalColumn(CommonService.sortJsonData(pvrData, "back_pvr", "Desc", "money").slice(0, 10)),
                            pprData: CommonService.addOrdinalColumn(CommonService.sortJsonData(pprData, "ppr", "Desc", "decimal").slice(0, 10)),
                            warrantyData: CommonService.addOrdinalColumn(CommonService.sortJsonData(warrantyData, "vsc_penetration", "Desc", "decimal").slice(0, 10)),
                            maintenanceData: CommonService.addOrdinalColumn(CommonService.sortJsonData(maintenanceData, "maint_penetration", "Desc", "decimal").slice(0, 10))
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "",
                            tableData: [],
                            footerRow: null,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIStoreReportFIManagerComponent/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            } else {
                this.setState({
                    isLoadingData: false,
                    tableTitle: "",
                    tableData: [],
                    footerRow: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FIStoreReportFIManagerComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowCountChange(isChecked) {
        this.setState({
            showCount: isChecked,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onDrilldownCancelClick(event) {
        this.setState({
            drilldownTableTitle: "",
            drilldownTableData: []
        });
    }

    onTotalReserveChargebacksCellClick(event, rowData) {
        try {
            this.getReserveAdjustmentData(rowData, 'Cancel', "Total Reserve Chargebacks");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalReserveAdjustmentsCellClick(event, rowData) {
        try {
            this.getReserveAdjustmentData(rowData, 'Adjust', "Total Reserve Adjustments");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getReserveAdjustmentData(rowData, type, titlePrefix) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(this.state.startDate),
                endDate: CommonService.formatServerDateTime(this.state.endDate),
                storeNumber: rowData.store_number,
                financeManagerNumber: rowData.finance_mgr_number,
                type: type
            };

            this.props.applicationInsights.trackTrace({ message: "/FIStoreReportFIManagerComponent/getReserveAdjustmentData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIStoreReport/GetReserveAdjustmentData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.last_updated = responseItem.last_updated_by + "\n(" + CommonService.formatDateTime(responseItem.updated_date) + ")";
                    });

                    this.setState({
                        drilldownTableTitle: titlePrefix + " - " + CommonService.formatDate(this.state.startDate) + " to " + CommonService.formatDate(this.state.endDate) + " - " + rowData.finance_mgr_name + " (" + rowData.finance_mgr_number + ")",
                        drilldownTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintDrilldownTable: true
                    }, () => {
                        this.setState({ paintDrilldownTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        drilldownTableTitle: "",
                        drilldownTableData: [],
                        paintDrilldownTable: true
                    }, () => {
                        this.setState({ paintDrilldownTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIStoreReportFIManagerComponent/GetReserveAdjustmentData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalProductChargebacksCellClick(event, rowData, cellData) {
        try {
            this.getProductAdjustmentData(rowData, cellData, 'Cancel', "Total Product Chargebacks");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalProductAdjustmentsCellClick(event, rowData, cellData) {
        try {
            this.getProductAdjustmentData(rowData, cellData, 'Adjust', "Total Product Adjustments");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getProductAdjustmentData(rowData, cellData, type, titlePrefix) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(this.state.startDate),
                endDate: CommonService.formatServerDateTime(this.state.endDate),
                storeNumber: rowData.store_number,
                financeManagerNumber: rowData.finance_mgr_number,
                type: type,
                dealAgeRange: cellData
            };

            this.props.applicationInsights.trackTrace({ message: "/FIStoreReportFIManagerComponent/getProductAdjustmentData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIStoreReport/GetProductAdjustmentData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.last_updated = responseItem.last_updated_by + "\n(" + CommonService.formatDateTime(responseItem.updated_date) + ")";
                    });

                    this.setState({
                        drilldownTableTitle: titlePrefix + " - " + CommonService.formatDate(this.state.startDate) + " to " + CommonService.formatDate(this.state.endDate) + " - " + rowData.finance_mgr_name + " (" + rowData.finance_mgr_number + ")",
                        drilldownTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintDrilldownTable: true
                    }, () => {
                        this.setState({ paintDrilldownTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        drilldownTableTitle: "",
                        drilldownTableData: [],
                        paintDrilldownTable: true
                    }, () => {
                        this.setState({ paintDrilldownTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIStoreReportFIManagerComponent/GetProductAdjustmentData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let amount = 0;
            let tableText = "#\tStore Name\tStock Number\tAdjustment Date\tDeal Number\tDeal Date\tCustomer\tNew/Used\tAccount Number\tAccount Description\tAdjustment Amount\tBank\tComments\tComments 2\r\n";
            this.state.drilldownTableData.forEach((row) => {
                amount += (row.adjustment_amount ? row.adjustment_amount : 0);
                tableText += row[CommonService.ordinalColumnName] + "\t" + (row.store_web_name ? row.store_web_name : "") + "\t" + (row.stock_number ? row.stock_number : "") + "\t" + (row.adjustment_date ? row.adjustment_date : "") + "\t" +
                    (row.deal_number ? row.deal_number : "") + "\t" + (row.deal_date ? row.deal_date : "") + "\t" + (row.customer ? row.customer : "") + "\t" + (row.new_used ? row.new_used : "") + "\t" +
                    (row.account_number ? row.account_number : "") + "\t" + (row.account_description ? row.account_description : "") + "\t" + (row.adjustment_amount ? CommonService.formatCurrency_2(row.adjustment_amount) : "") + "\t" +
                    (row.bank ? row.bank : "") + "\t" + (row.comments ? row.comments : "") + "\t" + (row.comments_2 ? row.comments_2 : "") + "\r\n";
            });

            tableText += "Total\t\t\t\t\t\t\t\t\t\t" + CommonService.formatCurrency_2(amount) + "\t\t";
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FIStoreReportFIManagerComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.drilldownTableData.length > 0}
                cssName="dgdg-fi-store-report-drilldown-dialog"
                title={this.state.drilldownTableTitle}
                onCancelClick={this.onDrilldownCancelClick}
            >
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary" onClick={(event) => this.onCopyClick(event)}>
                        Copy<span className="far fa-copy dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.drilldownTableData} paintTable={this.state.paintDrilldownTable} showFooter>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} footerText="Total" />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" dataType="number" />
                            <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Adjustment Date" dataColumn="adjustment_date" sortColumn="adjustment_date" filterColumn="adjustment_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Months Difference" dataColumn="month_difference" sortColumn="month_difference" filterColumn="month_difference" dataType="number" />
                            <DGDGTableColumnComponent headerText="Customer" dataColumn="customer" sortColumn="customer" filterColumn="customer" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="New/Used" dataColumn="new_used" sortColumn="new_used" filterColumn="new_used" />
                            <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" dataType="number" />
                            <DGDGTableColumnComponent headerText="Account Description" dataColumn="account_description" sortColumn="account_description" filterColumn="account_description" />
                            <DGDGTableColumnComponent headerText="Adjustment Amount" dataColumn="adjustment_amount" sortColumn="adjustment_amount" filterColumn="adjustment_amount" dataType="money_2" footerFunction="sum" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Bank" dataColumn="bank" sortColumn="bank" filterColumn="bank" />
                            <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" />
                            <DGDGTableColumnComponent headerText="Comments 2" dataColumn="comments_2" sortColumn="comments_2" filterColumn="comments_2" />
                            <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated" sortColumn="last_updated" filterColumn="last_updated" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </DGDGModalDialogComponent>
            <FIStoreReportFIManagerQueryComponent
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                canShowCount={this.state.canShowCount}
                onShowCountChange={this.onShowCountChange}
                {...this.props}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#fiManagerTopTenReport")}>Top 10</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#fiManagerChargebacksReport")}>Chargebacks</button>
            </nav>
            <div className="dgdg-fi-store-store dgdg-site-scrollable-content">
                <div id="fiManagerReport" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} stickyHeader footerRow={this.state.footerRow}
                        tablePageSize={CommonService.tablePageSize} showFooter
                        copyTableConfig={{ "columns": this.state.showCount ? [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 45, 46, 47, 48] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        {
                            this.state.showCount
                                ?
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" filterColumn="finance_mgr_name" />
                                    <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Deal Count" dataColumn="deal_count" sortColumn="deal_count" filterColumn="deal_count" cssName="text-center" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Back Gross" dataColumn="back_gross" sortColumn="back_gross" filterColumn="back_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Back PVR" dataColumn="back_pvr" sortColumn="back_pvr" filterColumn="back_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Overall PPR" dataColumn="ppr" sortColumn="ppr" filterColumn="ppr" cssName="text-center" dataType="number_2" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Other PPR" dataColumn="other_ppr" sortColumn="other_ppr" filterColumn="other_ppr" cssName="text-center" dataType="number_2" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="VSC" dataColumn="vsc_penetration_count" sortColumn="vsc_penetration_count" filterColumn="vsc_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Maintenance" dataColumn="maint_penetration_count" sortColumn="maint_penetration_count" filterColumn="maint_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="GAP" dataColumn="gap_penetration_count" sortColumn="gap_penetration_count" filterColumn="gap_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Road Hazard" dataColumn="road_haz_penetration_count" sortColumn="road_haz_penetration_count" filterColumn="road_haz_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Paint" dataColumn="paint_penetration_count" sortColumn="paint_penetration_count" filterColumn="paint_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Key" dataColumn="key_replace_penetration_count" sortColumn="key_replace_penetratio_count" filterColumn="key_replace_penetration_count" editHeaderColumn="edit_header_text_description" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Dent & Ding" dataColumn="dent_ding_penetration_count" sortColumn="dent_ding_penetration_count" filterColumn="dent_ding_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Shadow Mark" dataColumn="shadow_penetration_count" sortColumn="shadow_penetration_count" filterColumn="shadow_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Windshield" dataColumn="windshield_penetration_count" sortColumn="windshield_penetration_count" filterColumn="windshield_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="MISC" dataColumn="misc_penetration_count" sortColumn="misc_penetration_count" filterColumn="misc_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Wear Care" dataColumn="wear_care_penetration_count" sortColumn="wear_care_penetration_count" filterColumn="wear_care_penetration_count" cssName="text-center text-nowrap" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Lease" dataColumn="lease_penetration_count" sortColumn="lease_penetration_count" filterColumn="lease_penetration_count" cssName="text-center text-nowrap" headerCssName="text-center" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="VSC Pct" dataColumn="vsc_penetration" sortColumn="vsc_penetration" filterColumn="vsc_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="VSC Count" dataColumn="vsc_count" sortColumn="vsc_count" filterColumn="vsc_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Maintenance Pct" dataColumn="maint_penetration" sortColumn="maint_penetration" filterColumn="maint_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Maintenance Count" dataColumn="maint_count" sortColumn="maint_count" filterColumn="maint_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="GAP Pct" dataColumn="gap_penetration" sortColumn="gap_penetration" filterColumn="gap_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="GAP Count" dataColumn="gap_count" sortColumn="gap_count" filterColumn="gap_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Road Hazard Pct" dataColumn="road_haz_penetration" sortColumn="road_haz_penetration" filterColumn="road_haz_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Road Hazard Count" dataColumn="road_haz_count" sortColumn="road_haz_count" filterColumn="road_haz_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Paint Pct" dataColumn="paint_penetration" sortColumn="paint_penetration" filterColumn="paint_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Paint Count" dataColumn="paint_count" sortColumn="paint_count" filterColumn="paint_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Key Pct" dataColumn="key_replace_penetration" sortColumn="key_replace_penetration" filterColumn="key_replace_penetration" editHeaderColumn="edit_header_text_description" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Key Count" dataColumn="key_replace_count" sortColumn="key_replace_count" filterColumn="key_replace_count" editHeaderColumn="edit_header_text_description" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Dent & Ding Pct" dataColumn="dent_ding_penetration" sortColumn="dent_ding_penetration" filterColumn="dent_ding_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Dent & Ding Count" dataColumn="dent_ding_count" sortColumn="dent_ding_count" filterColumn="dent_ding_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Shadow Mark Pct" dataColumn="shadow_penetration" sortColumn="shadow_penetration" filterColumn="shadow_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Shadow Mark Count" dataColumn="shadow_count" sortColumn="shadow_count" filterColumn="shadow_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Windshield Pct" dataColumn="windshield_penetration" sortColumn="windshield_penetration" filterColumn="windshield_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Windshield Count" dataColumn="windshield_count" sortColumn="windshield_count" filterColumn="windshield_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="MISC Pct" dataColumn="misc_penetration" sortColumn="misc_penetration" filterColumn="misc_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="MISC Count" dataColumn="accessories_count" sortColumn="accessories_count" filterColumn="accessories_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Wear Care Pct" dataColumn="wear_care_penetration" sortColumn="wear_care_penetration" filterColumn="wear_care_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Wear Care Count" dataColumn="wear_care_count" sortColumn="wear_care_count" filterColumn="wear_care_count" cssName="text-center text-nowrap" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Lease Pct" dataColumn="lease_penetration" sortColumn="lease_penetration" filterColumn="lease_penetration" cssName="text-center text-nowrap" headerCssName="text-center" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent isHidden={true} headerText="Lease Count" dataColumn="lease_count" sortColumn="lease_count" filterColumn="lease_count" cssName="text-center text-nowrap" headerCssName="text-center" dataType="number" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent key="gap1" headerText="" dataText="" cssName="dgdg-column-gap dgdg-print-table-hide-cell" headerCssName="dgdg-column-gap dgdg-print-table-hide-cell" footerCssName="dgdg-column-gap dgdg-print-table-hide-cell" />
                                    <DGDGTableColumnComponent headerText="Reserve Gross" dataColumn="reserve_gross" sortColumn="reserve_gross" filterColumn="reserve_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Product Gross" dataColumn="product_gross" sortColumn="product_gross" filterColumn="product_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Reserve PVR" dataColumn="reserve_pvr" sortColumn="reserve_pvr" filterColumn="reserve_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Product PVR" dataColumn="product_pvr" sortColumn="product_pvr" filterColumn="product_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                </DGDGTableRowComponent>
                                : <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" filterColumn="finance_mgr_name" />
                                    <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Deal Count" dataColumn="deal_count" sortColumn="deal_count" filterColumn="deal_count" cssName="text-center" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Back Gross" dataColumn="back_gross" sortColumn="back_gross" filterColumn="back_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Back PVR" dataColumn="back_pvr" sortColumn="back_pvr" filterColumn="back_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Overall PPR" dataColumn="ppr" sortColumn="ppr" filterColumn="ppr" cssName="text-center" dataType="number_2" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Other PPR" dataColumn="other_ppr" sortColumn="other_ppr" filterColumn="other_ppr" cssName="text-center" dataType="number_2" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="VSC" dataColumn="vsc_penetration" sortColumn="vsc_penetration" filterColumn="vsc_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Maintenance" dataColumn="maint_penetration" sortColumn="maint_penetration" filterColumn="maint_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="GAP" dataColumn="gap_penetration" sortColumn="gap_penetration" filterColumn="gap_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Road Hazard" dataColumn="road_haz_penetration" sortColumn="road_haz_penetration" filterColumn="road_haz_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Paint" dataColumn="paint_penetration" sortColumn="paint_penetration" filterColumn="paint_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Key" dataColumn="key_replace_penetration" sortColumn="key_replace_penetration" filterColumn="key_replace_penetration" editHeaderColumn="edit_header_text_description" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Dent & Ding" dataColumn="dent_ding_penetration" sortColumn="dent_ding_penetration" filterColumn="dent_ding_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Shadow Mark" dataColumn="shadow_penetration" sortColumn="shadow_penetration" filterColumn="shadow_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Windshield" dataColumn="windshield_penetration" sortColumn="windshield_penetration" filterColumn="windshield_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="MISC" dataColumn="misc_penetration" sortColumn="misc_penetration" filterColumn="misc_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Wear Care" dataColumn="wear_care_penetration" sortColumn="wear_care_penetration" filterColumn="wear_care_penetration" cssName="text-center text-nowrap" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent headerText="Lease" dataColumn="lease_penetration" sortColumn="lease_penetration" filterColumn="lease_penetration" cssName="text-center text-nowrap" headerCssName="text-center" dataType="percent" footerCssName="text-center" footerFunction="average" />
                                    <DGDGTableColumnComponent key="gap1" headerText="" dataText="" cssName="dgdg-column-gap dgdg-print-table-hide-cell" headerCssName="dgdg-column-gap dgdg-print-table-hide-cell" footerCssName="dgdg-column-gap dgdg-print-table-hide-cell" />
                                    <DGDGTableColumnComponent headerText="Reserve Gross" dataColumn="reserve_gross" sortColumn="reserve_gross" filterColumn="reserve_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Product Gross" dataColumn="product_gross" sortColumn="product_gross" filterColumn="product_gross" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Reserve PVR" dataColumn="reserve_pvr" sortColumn="reserve_pvr" filterColumn="reserve_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                    <DGDGTableColumnComponent headerText="Product PVR" dataColumn="product_pvr" sortColumn="product_pvr" filterColumn="product_pvr" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                                </DGDGTableRowComponent>
                        }
                    </DGDGTableV3Component>
                </div>
                <div id="fiManagerTopTenReport" className="dgdg-card-gutter">
                    <div className="card">
                        <FIStoreReportFIManagerTopTenComponent
                            headerText={this.state.tableTitle}
                            pvrData={this.state.pvrData}
                            pprData={this.state.pprData}
                            warrantyData={this.state.warrantyData}
                            maintenanceData={this.state.maintenanceData}
                            paintTable={this.state.paintTable}
                            applicationInsights={this.props.applicationInsights}
                        />
                    </div>
                </div>
                <div id="fiManagerChargebacksReport">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Chargebacks - " + this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} stickyHeader footerRow={this.state.footerRow} showFooter
                        tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                                <td colSpan="6" />
                                <td colSpan="3" className="text-center font-weight-bold">0 - 6 Months</td>
                                <td colSpan="2" className="text-center font-weight-bold">6 Months - 1 Year</td>
                                <td colSpan="2" className="text-center font-weight-bold">1 Year - 3 Years</td>
                                <td colSpan="2" className="text-center font-weight-bold">&gt; 3 Years</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="F&I Manager Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" filterColumn="finance_mgr_name" />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Reserve Chargebacks" dataColumn="reserve_cancel_count" sortColumn="reserve_cancel_count" filterColumn="reserve_cancel_count" cssName="text-center" footerCssName="text-center" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Total Reserve Chargebacks" onCellClick={this.onTotalReserveChargebacksCellClick} dataColumn="reserve_cancel_amount" sortColumn="reserve_cancel_amount" filterColumn="reserve_cancel_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Total Reserve Adjustments" onCellClick={this.onTotalReserveAdjustmentsCellClick} dataColumn="reserve_adj_amount" sortColumn="reserve_adj_amount" filterColumn="reserve_adj_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Product Chargebacks" dataColumn="month_0_6_product_cancel_count" sortColumn="month_0_6_product_cancel_count" filterColumn="month_0_6_product_cancel_count" cssName="text-center" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Total Product Chargebacks" onCellClick={this.onTotalProductChargebacksCellClick} cellData="0Mth-6Mth" dataColumn="month_0_6_product_cancel_amount" sortColumn="month_0_6_product_cancel_amount" filterColumn="month_0_6_product_cancel_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Total Product Adjustments" onCellClick={this.onTotalProductAdjustmentsCellClick} cellData="0Mth-6Mth" dataColumn="month_0_6_product_adj_amount" sortColumn="month_0_6_product_adj_amount" filterColumn="month_0_6_product_adj_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Product Chargebacks" dataColumn="month_6_12_product_cancel_count" sortColumn="month_6_12_product_cancel_count" filterColumn="month_6_12_product_cancel_count" cssName="text-center" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Total Product Chargebacks" onCellClick={this.onTotalProductChargebacksCellClick} cellData="6Mth-1Yr" dataColumn="month_6_12_product_cancel_amount" sortColumn="month_6_12_product_cancel_amount" filterColumn="month_6_12_product_cancel_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Product Chargebacks" dataColumn="year_1_3_product_cancel_count" sortColumn="year_1_3_product_cancel_count" filterColumn="year_1_3_product_cancel_count" cssName="text-center" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Total Product Chargebacks" onCellClick={this.onTotalProductChargebacksCellClick} cellData="1Yr-3Yr" dataColumn="year_1_3_product_cancel_amount" sortColumn="year_1_3_product_cancel_amount" filterColumn="year_1_3_product_cancel_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Product Chargebacks" dataColumn="year_3_plus_product_cancel_count" sortColumn="year_3_plus_product_cancel_count" filterColumn="year_3_plus_product_cancel_count" cssName="text-center" footerCssName="text-center" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Total Product Chargebacks" onCellClick={this.onTotalProductChargebacksCellClick} cellData="3+Yr" dataColumn="year_3_plus_product_cancel_amount" sortColumn="year_3_plus_product_cancel_amount" filterColumn="year_3_plus_product_cancel_amount" cssName="text-center" dataType="money" footerCssName="text-center" footerFunction="average" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}
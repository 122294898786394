import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class OpenRODetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roDetailTitle: "",
            roTableData: []
        };

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "RO Detail Report";

    componentDidMount() {
        try {
            console.devLog("OpenRODetailComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/RO/OpenRO/Detail/", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getDetails();
            CommonService.clientAzureStorageLog("OpenRODetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getDetails() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: this.props.match.params.StoreNumber,
                rONumber: this.props.match.params.RONumber,
                vehicleId: this.props.match.params.VehicleId
            };

            this.props.applicationInsights.trackTrace({ message: "/OpenRODetailComponent/getDetails", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/RO/GetOpenRODetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        roTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        roDetailTitle: "RO - "
                            + this.props.match.params.StoreNumber + " - "
                            + this.props.match.params.RONumber + " - "
                            + this.props.match.params.VehicleId
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        roTableData: [],
                        roDetailTableData: []
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/RO/GetOpenRODetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("OpenRODetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.roDetailTitle} tableData={this.state.roTableData}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                            <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" filterColumn="ro_number" dataColumnCallback={this.roNumberColumnCallback} />
                            <DGDGTableColumnComponent headerText=" Date" dataColumn="_date" sortColumn="_date" filterColumn="_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" sortColumn="vehicle_id" filterColumn="vehicle_id" />
                            <DGDGTableColumnComponent headerText="Service Advisor Name" dataColumn="service_advisor_name" sortColumn="service_advisor_name" filterColumn="service_advisor_name" />
                            <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                            <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Days In Shop" dataColumn="days_in_shop" sortColumn="days_in_shop" filterColumn="days_in_shop" dataType="number" />
                            <DGDGTableColumnComponent headerText="RO Status Code" dataColumn="ro_status_code" sortColumn="ro_status_code" filterColumn="ro_status_code" />
                            <DGDGTableColumnComponent headerText="RO Status Description" dataColumn="ro_status_desc" sortColumn="ro_status_desc" filterColumn="ro_status_desc" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

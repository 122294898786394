import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnInputComponent } from "../common/dgdgtable/DGDGTableColumnInputComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { CSIQueryComponent } from "./CSIQueryComponent";

export class CSIRouteComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            saveTableData: [],
            savePaintTable: false,
            saveTableColumnComponents: null,
            saveSelectedMonth: null,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            canEdit: false,
            tableColumnComponents: null,
            tableFooterColumns: [],
            isLoadingData: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.onConfirmSaveAllClick = this.onConfirmSaveAllClick.bind(this);
        this.onCancelSaveAllClick = this.onCancelSaveAllClick.bind(this);
        this.onCSITableTextboxChange = this.onCSITableTextboxChange.bind(this);
        this.csiValueChange = this.csiValueChange.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CSIRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CSI", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("CSIRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "CSI";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CSIRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CSI/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "CSIRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CSI/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedMonth) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
            };

            this.props.applicationInsights.trackTrace({ message: "/CSIRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CSI/GetCSIData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = [];
                    let tableFooterColumns = [];
                    let tableTitle = "";
                    tableData = CommonService.addOrdinalColumn(parsedResponse.data.table);
                    tableFooterColumns.push(<td key="footer-cell-id" />);
                    tableTitle = "CSI - " + CommonService.formatMonthYear(new Date(selectedMonth));

                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        tableTitle: tableTitle,
                        canEdit: parsedResponse.canEdit,
                        tableColumnComponents: this.getTableColumnComponents(selectedMonth, parsedResponse.canEdit),
                        tableFooterColumns: tableFooterColumns,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: "",
                        canEdit: false,
                        tableColumnComponents: null,
                        tableFooterColumns: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CSI/GetCSIData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getTableColumnComponents(selectedMonth, canEdit) {
        let tableColumnComponents = [];
        if (canEdit === true) {
            tableColumnComponents.push(<DGDGTableColumnComponent key="store_name" headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="sales_store_score" headerText="Store Score" dataColumn="sales_store_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="sales_district_score" headerText="District Score" dataColumn="sales_district_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="sales_state_score" headerText="State Score" dataColumn="sales_state_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="sales_nation_score" headerText="Nation Score" dataColumn="sales_nation_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="service_store_score" headerText="Store Score" dataColumn="service_store_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="serice_district_score" headerText="District Score" dataColumn="service_district_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="service_state_score" headerText="State Score" dataColumn="service_state_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />);
            tableColumnComponents.push(<DGDGTableColumnInputComponent key="service_nation_score" headerText="Nation Score" dataColumn="service_nation_score" onChange={this.onCSITableTextboxChange} inputType="decimal" />)
        }
        else {
            tableColumnComponents.push(<DGDGTableColumnComponent key="store_name" headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="sales_store_score" headerText="Store Score" dataColumn="sales_store_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="sales_district_score" headerText="District Score" dataColumn="sales_district_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="sales_state_score" headerText="State Score" dataColumn="sales_state_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="sales_nation_score" headerText="Nation Score" dataColumn="sales_nation_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="service_store_score" headerText="Store Score" dataColumn="service_store_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="serice_district_score" headerText="District Score" dataColumn="service_district_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="service_state_score" headerText="State Score" dataColumn="service_state_score" onChange={this.onCSITableTextboxChange} />);
            tableColumnComponents.push(<DGDGTableColumnComponent key="service_nation_score" headerText="Nation Score" dataColumn="service_nation_score" onChange={this.onCSITableTextboxChange} />)
        }
        return tableColumnComponents;
    }

    onSaveAllClick(selectedMonth) {
        try {
            let saveTableData = [];
            saveTableData = this.state.tableData.filter(item => item.rowCssName === "dgdg-csi-bg-modified");
            saveTableData = JSON.parse(JSON.stringify(saveTableData));
            saveTableData.forEach((rowData) => {
                rowData.rowCssName = null;
            });

            let saveTableColumnComponents = [];
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="store_name" headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_web_name" cssName="text-nowrap" />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="sales_store_score" headerText="Store Score" dataColumn="sales_store_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="sales_district_score" headerText="District Score" dataColumn="sales_district_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="sales_state_score" headerText="State Score" dataColumn="sales_state_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="sales_nation_score" headerText="Nation Score" dataColumn="sales_nation_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="service_store_score" headerText="Store Score" dataColumn="service_store_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="service_district_score" headerText="District Score" dataColumn="service_district_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="service_state_score" headerText="State Score" dataColumn="service_state_score" onChange={this.onCSITableTextboxChange} />);
            saveTableColumnComponents.push(<DGDGTableColumnComponent key="service_nation_score" headerText="Nation Score" dataColumn="service_nation_score" onChange={this.onCSITableTextboxChange} />)

            this.setState({
                saveTableColumnComponents: saveTableColumnComponents,
                saveTableData: saveTableData,
                savePaintTable: true,
                saveSelectedMonth: selectedMonth
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmSaveAllClick(event) {
        try {
            this.setState({ isLoadingData: true });
            let selectedMonth = this.state.saveSelectedMonth;

            let saveTableData = this.state.saveTableData;

            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
                lastUpdatedBy: this.props.remoteUpn,
                csiDataDetails: saveTableData
            };

            this.props.applicationInsights.trackTrace({ message: "/CSIRouteComponent/onConfirmSaveAllClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CSI/SaveCSIData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(selectedMonth);
                    this.onCancelSaveAllClick(event);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CSI/SaveCSIData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelSaveAllClick(event) {
        try {
            this.setState({
                saveTableData: [],
                savePaintTable: true,
                saveSelectedMonth: null,
                isLoadingData: false
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    csiValueChange(text, tableData, rowData, columnName) {
        try {
            rowData[columnName] = text;
            rowData[columnName + "~isChanged"] = true;
            rowData.rowCssName = "dgdg-csi-bg-modified";
            return tableData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCSITableTextboxChange(id, text, rowData) {
        try {
            let columnName = id.split('-')[0];
            let tableData = this.csiValueChange(text, this.state.tableData, rowData, columnName);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.saveTableData.length > 0}
                cssName="dgdg-csi-dialog"
                title="Save CSI details"
                onCancelClick={this.onCancelSaveAllClick}
            >
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary" onClick={this.onConfirmSaveAllClick}>
                        Confirm <span className="fas fa-save dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.state.saveTableData} paintTable={this.state.savePaintTable}>
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="4" className="text-center font-weight-bold dgdg-csi-sales-color">Sales</td>
                                <td colSpan="4" className="text-center font-weight-bold dgdg-csi-service-color">Service</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            {this.state.saveTableColumnComponents}
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </DGDGModalDialogComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <CSIQueryComponent applicationInsights={this.props.applicationInsights} stores={this.props.stores} months={this.state.months} isLoadingData={this.state.isLoadingData} canEdit={true}
                    onGetReportClick={this.onGetReportClick} onSaveAllClick={this.onSaveAllClick} onShowAlert={this.props.onShowAlert} />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}>
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="4" className="text-center font-weight-bold dgdg-csi-sales-color">Sales</td>
                                <td colSpan="4" className="text-center font-weight-bold dgdg-csi-service-color">Service</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            {this.state.tableColumnComponents}
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";
import { Route, Switch } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { UserListComponent } from "./UserListComponent";
import { UserDetailComponent } from "./UserDetailComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class UserRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <Switch>
            <Route exact path="/User/Detail/:guid?" render={(routeProps) => <UserDetailComponent {...this.props} {...routeProps} />} />
            <Route exact path="/User" render={(routeProps) => <UserListComponent {...this.props} {...routeProps} />} />
            <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
        </Switch>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class ClosedRODetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roDetailTitle: "",
            roLineTitle: "",
            roMLSTitle: "",
            roTableData: [],
            roDetailTableData: [],
            roMLSTableData: []
        };

        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "RO Detail Report";

    componentDidMount() {
        try {
            console.devLog("ClosedRODetailComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/RO/ClosedRO/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getDetails();
            CommonService.clientAzureStorageLog("ClosedRODetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getDetails() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: this.props.match.params.StoreNumber,
                rONumber: this.props.match.params.RONumber,
                vehicleId: this.props.match.params.VehicleId
            };

            this.props.applicationInsights.trackTrace({ message: "/ClosedRODetailComponent/getDetails", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/RO/GetClosedRODetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        roTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        roDetailTableData: CommonService.addOrdinalColumn(parsedResponse.table1),
                        roMLSTableData: CommonService.addOrdinalColumn(parsedResponse.table2),
                        roDetailTitle: "RO - "
                            + this.props.match.params.StoreNumber + " - "
                            + this.props.match.params.RONumber + " - "
                            + this.props.match.params.VehicleId,
                        roLineTitle: "RO Line - "
                            + this.props.match.params.StoreNumber + " - "
                            + this.props.match.params.RONumber + " - "
                            + this.props.match.params.VehicleId,
                        roMLSTitle: "MLS - "
                            + this.props.match.params.StoreNumber + " - "
                            + this.props.match.params.RONumber + " - "
                            + this.props.match.params.VehicleId,
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        roTableData: [],
                        roDetailTableData: []
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/RO/GetClosedRODetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ClosedRODetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-card-gutter">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.roDetailTitle} tableData={this.state.roTableData}
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Open Date" dataColumn="open_date" dataType="date" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Close Date" dataColumn="close_date" dataType="date" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" dataType="money_2" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_gross" dataType="money_2" cssName="text-center" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-card-gutter">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.roLineTitle} tableData={this.state.roDetailTableData} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Line Code" dataColumn="line_code" sortColumn="line_code" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" footerText="Total" footerCssName="text-center" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Op Code" dataColumn="op_code" sortColumn="op_code" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Op Code Description" dataColumn="op_code_description" sortColumn="op_code_description" />
                                <DGDGTableColumnComponent headerText="Technician" dataColumn="tech_number" sortColumn="tech_number" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="number_1" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" sortColumn="labor_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" sortColumn="labor_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" sortColumn="parts_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" sortColumn="parts_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" sortColumn="misc_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" sortColumn="misc_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_gross" sortColumn="total_gross" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.roMLSTitle} tableData={this.state.roMLSTableData}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                            <DGDGTableColumnComponent headerText="Line Code" dataColumn="line_code" sortColumn="line_code" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="MLS Type" dataColumn="mls_type" sortColumn="mls_type" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="OP Code" dataColumn="op_code" sortColumn="op_code" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="OP Code Description" dataColumn="op_code_desc" sortColumn="op_code_desc" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" sortColumn="misc_sale" dataType="money_2" cssName="text-center" />
                            <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_code" sortColumn="misc_code" dataType="money_2" cssName="text-center" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class TeamMemberProfileQueryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeName: "Select Team Member",
            employeeProfiles: [],
            selectedEmployeeProfile: null
        };

        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFindEmployeeClick = this.onFindEmployeeClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.employeeProfiles.length === 0 && this.props.employeeProfiles.length > 0) {
                this.setState({
                    employeeProfiles: this.props.employeeProfiles
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.employeeProfiles) {
                filteredData = this.props.employeeProfiles.filter(row => {
                    return row.store_number.toString().toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || row.store_name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || row.employee_first.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || row.employee_last.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                        || row.position_description.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
                });
            }

            this.setState({
                employeeName: filterValue,
                employeeProfiles: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeClick(id, employeProfile) {
        try {
            this.setState({
                employeeName: employeProfile.text,
                selectedEmployeeProfile: employeProfile
            });
            $("#ddpEmployeeProfile").focus();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onFindEmployeeClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFindEmployeeClick(event) {
        if (this.state.selectedEmployeeProfile) {
            this.props.onFindEmployeeClick(event, this.state.selectedEmployeeProfile);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormComboBoxComponent id="ddpEmployeeProfile" data={this.state.employeeProfiles} itemKey="text" value={this.state.employeeName} colSpan="col-4" controlSpan="col-12"
                        onItemClick={this.onEmployeeClick} onItemChange={this.onEmployeeChange} onKeyDown={this.onKeyDown}
                        autoDrop />
                    <button className="btn btn-primary" onClick={this.onFindEmployeeClick}>
                        Get Profile  <i className="fas fa-user dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.props.isLoadingData} />
                </div>
            </div>
        </div>;
    }
}

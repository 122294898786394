import React from "react";
import $ from "jquery";

export class DGDGModalConfirmationComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        if (this.props.isFixed !== true) {
            $(this.modalConfirmationDialog).draggable({ containment: "body", handle: ".dgdg-modal-confirmation-dialog-header" });
        }

        window.addEventListener("popstate", this.onPopState);
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.showConfirmation) {
                $(this.modal).modal("show");
            } else {
                $(this.modal).modal("hide");
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                if (this.props.onCancelClick) {
                    this.props.onCancelClick(event);
                }
                break;

            default:
                break;
        }
    }

    onPopState(event) {
        $(".modal").modal("hide");
        $(".modal-backdrop").remove();
        $(".in").remove();
    }

    render() {
        return <div className="modal fade" ref={refElement => this.modal = refElement} tabIndex="-1" role="dialog" data-backdrop="static" aria-hidden="true">
            <div className="modal-dialog dgdg-modal-dialog" role="document">
                <div ref={refElement => this.modalConfirmationDialog = refElement} className="modal-content">
                    <div className="card">
                        <div className="card-header dgdg-modal-confirmation-dialog-header" style={{ "padding": ".75rem" }}>
                            {this.props.headerText}
                        </div>
                        <div className="card-block" style={{ "padding": ".75rem" }}>
                            <div className="dgdg-site-content">
                                {this.props.bodyText}
                                <br />
                                <br />
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={this.props.onOkClick} style={{ "width": "90px" }}>
                                        OK <i className="fas fa-check dgdg-icon" />
                                    </button>
                                    <button className="btn btn-primary dgdg-icon" onClick={this.props.onCancelClick}>
                                        Cancel <span className="fas fa-times dgdg-icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

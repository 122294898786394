import React from "react";
import moment from "moment";

import { DGDGFormCalendarComponent } from "./DGDGFormCalendarComponent";
import { DGDGFormDropdownComponent } from "../dgdgdropdown/DGDGFormDropdownComponent";

export class DGDGFormCalendarRangeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    onDateRangeChange(id, selectedDateRange) {
        this.props.onDateChange(selectedDateRange,
            selectedDateRange.startDate ? selectedDateRange.startDate : this.props.startValue,
            selectedDateRange.endDate ? selectedDateRange.endDate : this.props.endValue);
    }

    onDateChange(id, value) {
        let startDate = this.props.startValue;
        let endDate = this.props.endValue;
        if (id === this.props.startId) {
            startDate = moment(value);
        }
        else {
            endDate = moment(value);
        }

        let selectedDateRange = null;
        if (startDate && endDate) {
            selectedDateRange = this.props.dateRange.filter(range => {
                if (range.startDate && range.endDate)
                    return moment(range.startDate.format("YYYY-MM-DD")).isSame(startDate.format("YYYY-MM-DD")) && moment(range.endDate.format("YYYY-MM-DD")).isSame(endDate.format("YYYY-MM-DD"))
                return false;
            });

            if (selectedDateRange.length === 1) {
                selectedDateRange = selectedDateRange[0];
            }
        }

        if (!selectedDateRange || selectedDateRange.length === 0) {
            selectedDateRange = this.props.dateRange.filter(dateRange => !dateRange.startDate && !dateRange.endDate)[0];
        }

        this.props.onDateChange(selectedDateRange, startDate, endDate)
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }

        return <div className={"form-group " + colSpan}>
            <div>
                <div className={"form-row justify-content-center " + (this.props.row1CssName ? this.props.row1CssName : "")}>
                    <DGDGFormDropdownComponent label={this.props.label} data={this.props.dateRange} itemKey="value" value={this.props.selectedDateRange ? this.props.selectedDateRange.value : "Select"} controlSpan="dropdown-range-control" onItemClick={this.onDateRangeChange} />
                </div>
                <div className={"form-inline " + (this.props.row2CssName ? this.props.row2CssName : "")}>
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} disabled={this.props.disabled} label="Start" id={this.props.startId} value={this.props.startValue}
                        minDate={this.props.startMin} maxDate={this.props.startMax}
                        onDateChange={this.onDateChange} />
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} disabled={this.props.disabled} label="End" id={this.props.endId} value={this.props.endValue}
                        minDate={this.props.endMin} maxDate={this.props.endMax}
                        onDateChange={this.onDateChange} />
                </div>
            </div>
        </div>;
    }
}

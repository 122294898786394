import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { DMVRegistrationQueryComponent } from "./DMVRegistrationQueryComponent";

export class DMVRegistrationRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            startDate: null,
            endDate: null,
            vin: null,
            tableData: [],
            paintTable: false
        }

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DMVRegistrationRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DMVRegistration", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("DMVRegistrationRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "DMV Registration";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/DMVRegistrationRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DMVRegistration/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "DMVRegistrationRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DMVRegistration/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(vin, startDate, endDate) {
        try {
            if (vin || startDate || endDate) {
                this.setState({
                    isLoadingData: true,
                    startDate: startDate,
                    endDate: endDate,
                    vin: vin
                });

                let inputData = {
                    startDate: CommonService.isDateValid(startDate) ? CommonService.formatServerDateTime(startDate) : "",
                    endDate: CommonService.isDateValid(endDate) ? CommonService.formatServerDateTime(endDate) : "",
                    vin: vin !== "" ? vin : ""
                };

                this.props.applicationInsights.trackTrace({ message: "/DMVRegistrationRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/DMVRegistration/GetReport", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        parsedResponse.table.forEach((responseItem, index) => {
                            responseItem.image_href = "DMVRegistration/DMVImage?Image=" + responseItem.imageNameFront;
                        });

                        this.setState({
                            isLoadingData: false,
                            tableTitle: CommonService.formatDate_mmddyyyy(startDate) + " - " +
                                CommonService.formatDate_mmddyyyy(endDate) + (vin ? " - " + vin : ""),
                            tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            tableTitle: "",
                            isLoadingData: false,
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DMVRegistration/GetReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DMVRegistrationRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <DMVRegistrationQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData}
                    onGetReportClick={this.onGetReportClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} stickyHeader
                        tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Date Of Sale" dataColumn="dateOfSale" dataType="date_mmddyyyy" sortColumn="dateOfSale" filterColumn="dateOfSale" />
                            <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="License Plate" dataColumn="licensePlate" sortColumn="licensePlate" filterColumn="licensePlate" />
                            <DGDGTableColumnComponent headerText="Image Name" dataColumn="imageNameFront" sortColumn="imageNameFront" filterColumn="imageNameFront" dataType="anchor" href="image_href" target="_self" />
                            <DGDGTableColumnComponent headerText="Transaction Code" dataColumn="transactionCode" sortColumn="transactionCode" filterColumn="transactionCode" />
                            <DGDGTableColumnComponent headerText="VR BarCode Number" dataColumn="vrBarCodeNumber" sortColumn="vrBarCodeNumber" filterColumn="vrBarCodeNumber" />
                            <DGDGTableColumnComponent headerText="Vehicle Type" dataColumn="vehicleType" sortColumn="vehicleType" filterColumn="vehicleType" />
                            <DGDGTableColumnComponent headerText="Office Work Date" dataColumn="officeWorkDate" dataType="date_mmddyy" sortColumn="officeWorkDate" filterColumn="officeWorkDate" />
                            <DGDGTableColumnComponent headerText="Type Trans Code" dataColumn="typeTransCode" sortColumn="typeTransCode" filterColumn="typeTransCode" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DashboardComponent } from "./DashboardComponent";

export class DashboardRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DashboardRouteComponent - componentDidMount");
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("DashboardRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Dashboard";

    getControllerPolicy() {
        try {
            this.props.applicationInsights?.trackTrace({ message: "/DashboardRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Dashboard/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "DashboardRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Dashboard/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        if (this.props.isAuthenticated) {
            return <DashboardComponent {...this.props} />;
        }

        return null;
    }
}

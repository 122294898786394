import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class PartsPayrollDetailEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlanName: "",
            wage_rate: "",
            mid_month_advance: "",
            monthly_guarantee: "",
            csi: "",
            pay_1_commission_bonus: "",
            pay_1_training_labor_rate: "",
            pay_1_sick_labor_rate: "",
            pay_1_pto_labor_rate: "",
            pay_1_bereavement_labor_rate: "",
            pay_1_holiday_labor_rate: "",
            pay_1_non_commission_bonus: "",
            pay_1_mealtime_premium_hours: "",
            pay_1_comments: "",
            pay_2_commission_bonus: "",
            pay_2_training_labor_rate: "",
            pay_2_sick_labor_rate: "",
            pay_2_pto_labor_rate: "",
            pay_2_bereavement_labor_rate: "",
            pay_2_holiday_labor_rate: "",
            pay_2_non_commission_bonus: "",
            pay_2_mealtime_premium_hours: "",
            pay_2_comments: "",
            planNames: ["Entry", "Mid", "Senior", "Senior Plus", "Assistant Manager"]
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onPlanNameChange = this.onPlanNameChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.payrollDetail
                && (!prevProps.payrollDetail || prevProps.payrollDetail !== this.props.payrollDetail)) {
                this.setState({
                    selectedPlanName: this.props.payrollDetail.plan_name ? this.props.payrollDetail.plan_name : "Select Plan",
                    wage_rate: this.props.payrollDetail.wage_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.wage_rate) : "",
                    mid_month_advance: this.props.payrollDetail.mid_month_advance !== null ? CommonService.formatNumber_2(this.props.payrollDetail.mid_month_advance) : "",
                    monthly_guarantee: this.props.payrollDetail.monthly_guarantee !== null ? CommonService.formatNumber_2(this.props.payrollDetail.monthly_guarantee) : "",
                    csi: this.props.payrollDetail.csi,
                    pay_1_commission_bonus: this.props.payrollDetail.pay_1_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_commission_bonus) : "",
                    pay_1_sick_labor_rate: this.props.payrollDetail.pay_1_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_sick_labor_rate) : "",
                    pay_1_pto_labor_rate: this.props.payrollDetail.pay_1_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_pto_labor_rate) : "",
                    pay_1_bereavement_labor_rate: this.props.payrollDetail.pay_1_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_bereavement_labor_rate) : "",
                    pay_1_holiday_labor_rate: this.props.payrollDetail.pay_1_holiday_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_holiday_labor_rate) : "",
                    pay_1_training_labor_rate: this.props.payrollDetail.pay_1_training_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_training_labor_rate) : "",
                    pay_1_non_commission_bonus: this.props.payrollDetail.pay_1_non_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_non_commission_bonus) : "",
                    pay_1_mealtime_premium_hours: this.props.payrollDetail.pay_1_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_mealtime_premium_hours) : "",
                    pay_1_comments: this.props.payrollDetail.pay_1_comments,
                    pay_2_commission_bonus: this.props.payrollDetail.pay_2_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_commission_bonus) : "",
                    pay_2_sick_labor_rate: this.props.payrollDetail.pay_2_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_sick_labor_rate) : "",
                    pay_2_pto_labor_rate: this.props.payrollDetail.pay_2_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_pto_labor_rate) : "",
                    pay_2_bereavement_labor_rate: this.props.payrollDetail.pay_2_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_bereavement_labor_rate) : "",
                    pay_2_holiday_labor_rate: this.props.payrollDetail.pay_2_holiday_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_holiday_labor_rate) : "",
                    pay_2_training_labor_rate: this.props.payrollDetail.pay_2_training_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_training_labor_rate) : "",
                    pay_2_non_commission_bonus: this.props.payrollDetail.pay_2_non_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_non_commission_bonus) : "",
                    pay_2_mealtime_premium_hours: this.props.payrollDetail.pay_2_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_mealtime_premium_hours) : "",
                    pay_2_comments: this.props.payrollDetail.pay_2_comments
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Parts Payroll";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChanged(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanNameChange(id, planName) {
        try {
            this.setState({ selectedPlanName: planName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showProgress: true });
            let inputData = {
                payrollMonth: CommonService.formatServerDateTime(this.props.payrollMonth),
                storeNumber: this.props.payrollDetail.store_number,
                employeeNumber: this.props.payrollDetail.employee_number,
                planName: this.state.selectedPlanName === "Select Plan" ? null : this.state.selectedPlanName,
                wageRate: this.state.wage_rate,
                midMonthAdvance: this.state.mid_month_advance,
                monthlyGuarantee: this.state.monthly_guarantee,
                csi: this.state.csi,

                pay1CommissionBonus: this.state.pay_1_commission_bonus,
                pay1TrainingLaborRate: this.state.pay_1_training_labor_rate,
                pay1SickLaborRate: this.state.pay_1_sick_labor_rate,
                pay1PtoLaborRate: this.state.pay_1_pto_labor_rate,
                pay1BereavementLaborRate: this.state.pay_1_bereavement_labor_rate,
                pay1HolidayLaborRate: this.state.pay_1_holiday_labor_rate,
                pay1NonCommissionBonus: this.state.pay_1_non_commission_bonus,
                pay1MealtimePremiumHours: this.state.pay_1_mealtime_premium_hours,
                pay1Comments: this.state.pay_1_comments,

                pay2CommissionBonus: this.state.pay_2_commission_bonus,
                pay2TrainingLaborRate: this.state.pay_2_training_labor_rate,
                pay2SickLaborRate: this.state.pay_2_sick_labor_rate,
                pay2PtoLaborRate: this.state.pay_2_pto_labor_rate,
                pay2BereavementLaborRate: this.state.pay_2_bereavement_labor_rate,
                pay2HolidayLaborRate: this.state.pay_2_holiday_labor_rate,
                pay2NonCommissionBonus: this.state.pay_2_non_commission_bonus,
                pay2MealtimePremiumHours: this.state.pay_2_mealtime_premium_hours,
                pay2Comments: this.state.pay_2_comments
            };

            this.props.applicationInsights.trackTrace({ message: "PartsPayrollDetailEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/PartsPayroll/UpdatePartsPayroll", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onSaveClick(event);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PartsPayroll/UpdatePartsPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ showProgress: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedPlanName: "",
                wage_rate: "",
                mid_month_advance: "",
                monthly_guarantee: "",
                csi: "",
                pay_1_commission_bonus: "",
                pay_1_training_labor_rate: "",
                pay_1_sick_labor_rate: "",
                pay_1_pto_labor_rate: "",
                pay_1_bereavement_labor_rate: "",
                pay_1_holiday_labor_rate: "",
                pay_1_non_commission_bonus: "",
                pay_1_mealtime_premium_hours: "",
                pay_1_comments: "",
                pay_2_commission_bonus: "",
                pay_2_training_labor_rate: "",
                pay_2_sick_labor_rate: "",
                pay_2_pto_labor_rate: "",
                pay_2_bereavement_labor_rate: "",
                pay_2_holiday_labor_rate: "",
                pay_2_non_commission_bonus: "",
                pay_2_mealtime_premium_hours: "",
                pay_2_comments: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.payrollDetail) {
            title = "Edit Employee Pay Month & Pay Periods - " + this.props.payrollDetail.employee_name + " (" + this.props.payrollDetail.employee_number + ") - " + this.props.payrollDetail.store_name + " - " + CommonService.formatMonthYear(moment(this.props.payrollMonth));
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.payrollDetail !== null}
            cssName="dgdg-parts-payroll-detail-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.payrollDetail
                    ? <div className="dgdg-payroll">
                        <div className="form-inline">
                            &nbsp;Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormDropdownComponent label="Plan Name" data={this.state.planNames} value={this.state.selectedPlanName} onItemClick={this.onPlanNameChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="Wage Rate" controlCssName="dgdg-money" id="wage_rate" inputType="money" value={this.state.wage_rate} onChange={this.onTextChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="Mid Month Advance" controlCssName="dgdg-money" id="mid_month_advance" inputType="money" value={this.state.mid_month_advance} onChange={this.onTextChange} />
                            </span>
                            <span>
                                <DGDGFormInputComponent label="Monthly Guarantee" controlCssName="dgdg-money" id="monthly_guarantee" inputType="money" value={this.state.monthly_guarantee} onChange={this.onTextChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormCheckboxComponent label="CSI" id="csi" value={this.state.csi} onChange={this.onCheckChanged} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>Parts Commissionable Gross: {this.props.payrollDetail.parts_commissionable_gross}</span>
                        </div>
                        <table className="dgdg-payroll-period-edit">
                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 1</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Commission Bonus" controlCssName="dgdg-money" id="pay_1_commission_bonus" inputType="money" value={this.state.pay_1_commission_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_1_sick_labor_rate" inputType="money" value={this.state.pay_1_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_1_pto_labor_rate" inputType="money" value={this.state.pay_1_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_1_bereavement_labor_rate" inputType="money" value={this.state.pay_1_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_1_holiday_labor_rate" inputType="money" value={this.state.pay_1_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Training Rate" controlCssName="dgdg-money" id="pay_1_training_labor_rate" inputType="decimal" value={this.state.pay_1_training_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Bonus" labelCssName="dgdg-payroll-width" id="pay_1_non_commission_bonus" inputType="money" value={this.state.pay_1_non_commission_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_1_mealtime_premium_hours" inputType="decimal" value={this.state.pay_1_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="7" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_1_comments" value={this.state.pay_1_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 2</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Commission Bonus" controlCssName="dgdg-money" id="pay_2_commission_bonus" inputType="money" value={this.state.pay_2_commission_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_2_sick_labor_rate" inputType="money" value={this.state.pay_2_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_2_pto_labor_rate" inputType="money" value={this.state.pay_2_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_2_bereavement_labor_rate" inputType="money" value={this.state.pay_2_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_2_holiday_labor_rate" inputType="money" value={this.state.pay_2_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Training Rate" controlCssName="dgdg-money" id="pay_2_training_labor_rate" inputType="decimal" value={this.state.pay_2_training_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Bonus" labelCssName="dgdg-payroll-width" id="pay_2_non_commission_bonus" inputType="money" value={this.state.pay_2_non_commission_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_2_mealtime_premium_hours" inputType="decimal" value={this.state.pay_2_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="7" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_2_comments" value={this.state.pay_2_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}

import moment from "moment";
import tz from "moment-timezone";
import $ from "jquery";

export class CommonService {
    static currencyFormatter = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    static formatCurrency(value) {
        let formattedValue = CommonService.currencyFormatter.format(value ? value : 0);
        if (formattedValue.indexOf("-") === 0) {
            formattedValue = "(" + formattedValue.replace("-", "") + ")";
        }

        return formattedValue;
    }

    static formatSignedCurrency(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.currencyFormatter.format(value ? value : 0);
    }

    static currencyFormatter_1 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    });

    static currencyFormatter_2 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
    });

    static formatCurrency_1(value) {
        let formattedValue = CommonService.currencyFormatter_1.format(value ? value : 0);
        if (formattedValue.indexOf("-") === 0) {
            formattedValue = "(" + formattedValue.replace("-", "") + ")";
        }

        return formattedValue;
    }

    static formatCurrency_2(value) {
        let formattedValue = CommonService.currencyFormatter_2.format(value ? value : 0);
        if (formattedValue.indexOf("-") === 0) {
            formattedValue = "(" + formattedValue.replace("-", "") + ")";
        }

        return formattedValue;
    }

    static formatCurrency_2_simple(value) {
        return CommonService.currencyFormatter.format(value ? value : 0);
    }

    static formatAccountingAmount(value) {
        return CommonService.currencyFormatter_2.format(value ? value : 0).replace("-", "");
    }

    static formatAccountingAmount_simple(value) {
        return CommonService.currencyFormatter.format(value ? value : 0).replace("-", "");
    }

    static formatRate(value) {
        let formattedValue = CommonService.currencyFormatter_2.format(value ? value : 0);
        if (formattedValue.indexOf("-") === 0) {
            formattedValue = "(" + formattedValue.replace("-", "") + ")";
        }
        formattedValue = formattedValue + "/hr";
        return formattedValue;
    }

    static formatNumber(value) {
        return CommonService.formatCurrency_2_simple(value ? value : 0).replace("$", "");
    }

    static formatSignedNumber(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.formatCurrency_2_simple(value ? value : 0).replace("$", "");
    }

    static formatNumber_1(value) {
        return CommonService.formatCurrency_1(value ? value : 0).replace("$", "");
    }

    static formatNumber_2(value) {
        return CommonService.formatCurrency_2(value ? value : 0).replace("$", "");
    }

    static formatNumber_paran(value) {
        let formattedValue = CommonService.formatCurrency(value ? value : 0).replace("$", "");
        if (formattedValue.indexOf("-") === 0) {
            formattedValue = "(" + formattedValue.replace("-", "") + ")";
        }

        return formattedValue;
    }

    static percentageFormatter = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "percent"
    });

    static formatPercentage(value) {
        return CommonService.percentageFormatter.format(value ? value : 0);
    }

    static formatSignedPercentage(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.percentageFormatter.format(value ? value : 0);
    }

    static formatSignedPercentage_1(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.percentageFormatter_1.format(value ? value : 0);
    }

    static percentageFormatter_1 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "percent",
        minimumFractionDigits: 1
    });

    static formatPercentage_1(value) {
        return CommonService.percentageFormatter_1.format(value ? value : 0);
    }

    static percentageFormatter_2 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "percent",
        minimumFractionDigits: 2
    });

    static formatPercentage_2(value) {
        return CommonService.percentageFormatter_2.format(value ? value : 0);
    }

    static formatDate(value) {
        return moment(value).format("MM/DD/YYYY");
    }

    static formatDate_mmddyy(value) {
        return moment(value).format("MM/DD/YYYY");
    }

    static formatDate_mmddyyyy(value) {
        return moment(this.formatDateToISOString(value)).format("MM/DD/YYYY");
    }

    static formatDateTime(value) {
        return moment(value).format("MM/DD/YYYY hh:mm A");
    }

    static formatDateTime_2(value) {
        return moment(value).format("MM/DD/YYYY HH:mm");
    }

    static formatDateTime_3(value) {
        return moment(value).format("MM/DD/YYYY HH:mm:ss");
    }

    static formatDateTimeZone(utcDateTime) {
        let localDateTime = moment(utcDateTime).local();
        return localDateTime.format("MM/DD/YYYY hh:mm A ") + tz.tz.zone(tz.tz.guess()).abbr(localDateTime);
    }

    static formatMonth(value) {
        return moment(value).format("MMM");
    }

    static formatMonthYear_2(value) {
        return moment(value).format("MMMMyy");
    }

    static formatMonthYear(value) {
        return moment(value).format("MMMM YYYY");
    }

    static formatDate_yyyyMMdd(value) {
        return moment(value).format("yyyyMMDD");
    }

    static formatYear(value) {
        return moment(value).format("YYYY");
    }

    static formatDay(value) {
        return moment(value).format("ddd");
    }

    static formatTime(value) {
        return moment(value).format("hh:mm A");
    }

    static formatPhoneNumber(phoneNumberString, addBraces = null) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        if (cleaned.length > 10) {
            let prefix = cleaned.substr(0, cleaned.length - 10);
            cleaned = cleaned.slice(-10);
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return this.addOrRemoveBracesWithPrefix(match, prefix, addBraces);
            }
        }
        else {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return this.addOrRemoveBraces(match, addBraces);
            }
        }
        return null;
    }

    static addOrRemoveBracesWithPrefix(match, prefix, addBraces) {
        if (addBraces != null) {
            return '+' + prefix + match[1] + '-' + match[2] + '-' + match[3];
        }
        else {
            return '+' + prefix + ' (' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }

    static addOrRemoveBraces(match, addBraces) {
        if (addBraces != null) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        else {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }

    static formatServerDateTime(value) {
        return moment(this.formatDateToISOString(value)).format("MM-DD-YYYY");
    }

    static formatDateToISOString(value) {
        if (value && value._isValid) {
            let date = new Date(value);
            return date.toISOString();
        }

        return value;
    }

    static boolRegex = /^\s*(yes|true|1|on)\s*$/i
    static formatBoolString(value) {
        return CommonService.boolRegex.test(value) ? "Yes" : "";
    }

    static toTitleCase(value) {
        return value.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    static parseDecimal(value) {
        return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    }

    static isDateValid(dateString) {
        return moment(dateString).isValid() ? moment(dateString) : false;
    }

    static clientAzureStorageLog(component, part2, part3, part4, body, applicationInsights) {
        var logBody = {
            component: component,
            part2: part2,
            part3: part3,
            part4: part4,
            body: body
        };

        applicationInsights?.trackTrace({ message: "/CommonService/clientAzureStorageLog", properties: { ...logBody } });
        return $.ajax({
            method: "POST",
            url: "/api/Log",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(logBody)
        }).done((response) => {
            //alert(response.property)
        });
    }

    static download(response, status, xhr) {
        let blob = new Blob([response], { type: xhr.getResponseHeader("Content-Type") });
        let filename = "";
        let disposition = xhr.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var url = window.URL.createObjectURL(blob);
            var link = $("<a style='display: none;' href='" + url + "' download='" + filename + "'/>");
            $("body").append(link);
            link[0].click();
            window.URL.revokeObjectURL(url);
            link.remove();
        }
    }

    static downloadBlob(response) {
        response.blob().then(blob => {
            let contentDisposition = response.headers.get("content-disposition");
            let filename = "";
            if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var url = window.URL.createObjectURL(blob);
                var link = $("<a style='display: none;' href='" + url + "' download='" + filename + "'/>");
                $("body").append(link);
                link[0].click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        });
    }

    static chartMinimizedTextStyleFontSize = 12;
    static chartMinimizedTextStyleFontColor = "#424242";
    static chartMaximizedTextStyleFontSize = 16;

    static ordinalColumnName = "dgdg_column_ordinal";
    static tablePageSize = 50;

    static addOrdinalColumn(rowTableData) {
        if (rowTableData && rowTableData.length) {
            rowTableData.forEach((rowData, index) => {
                rowData[CommonService.ordinalColumnName] = index + 1;
            });
        }

        return rowTableData;
    }

    static sortJsonData(tableData, sortColumn, sortOrder, dataType) {
        if (tableData && tableData.length > 0 && sortColumn && sortOrder) {
            // Sort data here
            tableData.sort((row1, row2) => {
                let row1Data = row1;
                let row2Data = row2;
                sortColumn.split(".").forEach((columnName) => {
                    row1Data = row1Data[columnName];
                    row2Data = row2Data[columnName];
                });

                if (row1Data === row2Data) {
                    if (sortOrder === "Asc") {
                        return row1[CommonService.ordinalColumnName] > row2[CommonService.ordinalColumnName] ? 1 : -1;
                    } else {
                        return row1[CommonService.ordinalColumnName] < row2[CommonService.ordinalColumnName] ? 1 : -1;
                    }
                } else if (row1Data === null || row2Data === null) {
                    if (sortOrder === "Asc") {
                        return row2Data === null ? 1 : -1;
                    } else {
                        return row1Data === null ? 1 : -1;
                    }
                }
                else {
                    if (row1Data !== null && row2Data !== null && dataType === null) {
                        if (sortOrder === "Asc") {
                            return row1Data.toLowerCase() > row2Data.toLowerCase() ? 1 : -1;
                        } else {
                            return row1Data.toLowerCase() < row2Data.toLowerCase() ? 1 : -1;
                        }
                    } else {
                        if (sortOrder === "Asc") {
                            return row1Data > row2Data ? 1 : -1;
                        } else {
                            return row1Data < row2Data ? 1 : -1;
                        }
                    }
                }
            });

            return tableData;
        }

        return tableData;
    }

    static filterJsonData(tableData, filterColumn, filterText, dataType, filterOption) {
        let filterObject, filteredTableData;
        if (dataType === "date") {
            filterObject = new Date(filterText);
        }

        if (!filterText || filterText === "") {
            filteredTableData = tableData;
        }
        else {
            filteredTableData = tableData.filter((obj) => {
                let found = false;
                let cellData = obj;
                filterColumn.split(".").forEach((columnName) => {
                    cellData = cellData[columnName];
                });

                if (!found) {
                    switch (dataType) {
                        case "date":
                            if (cellData) {
                                let filterData = new Date(cellData);
                                switch (filterOption) {
                                    case "exclude":
                                        found = filterObject.getTime() !== filterData.getTime();
                                        break;
                                    default:
                                        found = filterObject.getTime() === filterData.getTime();
                                        break;
                                }
                            }
                            break;
                        case "number":
                            if (cellData) {
                                switch (filterOption) {
                                    case "exclude":
                                        found = cellData.toString() !== filterText.toString();
                                        break;
                                    default:
                                        found = cellData.toString() === filterText.toString();
                                        break;
                                }
                            }
                            break;
                        case "bool":
                            switch (filterOption) {
                                case "exclude":
                                    filterText = filterText.toLowerCase();
                                    cellData = cellData.toLowerCase();
                                    if (filterText === "yes" || filterText === "true" || filterText === "1") {
                                        found = !CommonService.boolRegex.test(cellData);
                                    }
                                    else if (filterText === "no" || filterText === "false" || filterText === "0") {
                                        found = CommonService.boolRegex.test(cellData);
                                    }
                                    else {
                                        found = false;
                                    }

                                    break;
                                default:
                                    filterText = filterText.toLowerCase();
                                    cellData = cellData.toLowerCase();
                                    if (filterText === "yes" || filterText === "true" || filterText === "1") {
                                        found = CommonService.boolRegex.test(cellData);
                                    }
                                    else if (filterText === "no" || filterText === "false" || filterText === "0") {
                                        found = !CommonService.boolRegex.test(cellData);
                                    }
                                    else {
                                        found = false;
                                    }

                                    break;
                            }
                            break;
                        default:
                            if (cellData) {
                                switch (filterOption) {
                                    case "exclude":
                                        found = cellData.toString().search(new RegExp(filterText, "i")) === -1;
                                        break;
                                    default:
                                        found = cellData.toString().search(new RegExp(filterText, "i")) !== -1;
                                        break;
                                }
                            }
                            break;
                    }
                }
                return found;
            });
        }

        return filteredTableData;
    }

    static smoothScrollTo(container, selector) {
        $(container).animate({
            scrollTop: $(container).scrollTop() + ($(selector).offset().top - $(container).offset().top)
        }, 1000);
    }

    static getAmountType(amount) {
        let type = "";
        if (amount > 0) {
            type = "DR";
        }
        else if (amount < 0) {
            type = "CR";
        }
        return type;
    }

    static getMonths(fromMonth = null) {
        let startDate = moment().startOf("month").subtract(26, "month");
        if (fromMonth) {
            startDate = moment(fromMonth, "MM/DD/YYYY");
        }
        let dateCounter = moment().startOf("month");
        let months = [];
        while (dateCounter > startDate) {
            months.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "month");
        }

        return months;
    }

    static getYears(fromYear, showNull = false, toYear = null) {
        let startDate = moment(fromYear, "MM/DD/YYYY");
        let dateCounter = moment().startOf("year");
        if (toYear) {
            dateCounter = moment(toYear, "MM/DD/YYYY").startOf("year");
        }
        let years = [];
        if (showNull) {
            years = [null];
        }
        while (dateCounter > startDate) {
            years.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "year");
        }
        return years;
    }

    static getUSStates() {
        return [
            { key: "AL", value: "Alabama" },
            { key: "AK", value: "Alaska" },
            { key: "AZ", value: "Arizona" },
            { key: "AR", value: "Arkansas" },
            { key: "CA", value: "California" },
            { key: "CO", value: "Colorado" },
            { key: "CT", value: "Connecticut" },
            { key: "DE", value: "Delaware" },
            { key: "DC", value: "District of Columbia" },
            { key: "FL", value: "Florida" },
            { key: "GA", value: "Georgia" },
            { key: "HI", value: "Hawaii" },
            { key: "ID", value: "Idaho" },
            { key: "IL", value: "Illinois" },
            { key: "IN", value: "Indiana" },
            { key: "IA", value: "Iowa" },
            { key: "KS", value: "Kansas" },
            { key: "KY", value: "Kentucky" },
            { key: "LA", value: "Louisiana" },
            { key: "ME", value: "Maine" },
            { key: "MD", value: "Maryland" },
            { key: "MA", value: "Massachusetts" },
            { key: "MI", value: "Michigan" },
            { key: "MN", value: "Minnesota" },
            { key: "MS", value: "Mississippi" },
            { key: "MO", value: "Missouri" },
            { key: "MT", value: "Montana" },
            { key: "NE", value: "Nebraska" },
            { key: "NV", value: "Nevada" },
            { key: "NH", value: "New Hampshire" },
            { key: "NJ", value: "New Jersey" },
            { key: "NM", value: "New Mexico" },
            { key: "NY", value: "New York" },
            { key: "NC", value: "North Carolina" },
            { key: "ND", value: "North Dakota" },
            { key: "OH", value: "Ohio" },
            { key: "OK", value: "Oklahoma" },
            { key: "OR", value: "Oregon" },
            { key: "PA", value: "Pennsylvania" },
            { key: "PR", value: "Puerto Rico" },
            { key: "RI", value: "Rhode Island" },
            { key: "SC", value: "South Carolina" },
            { key: "SD", value: "South Dakota" },
            { key: "TN", value: "Tennessee" },
            { key: "TX", value: "Texas" },
            { key: "UT", value: "Utah" },
            { key: "VT", value: "Vermont" },
            { key: "VA", value: "Virginia" },
            { key: "VI", value: "Virgin Islands" },
            { key: "WA", value: "Washington" },
            { key: "WV", value: "West Virginia" },
            { key: "WI", value: "Wisconsin" },
            { key: "WY", value: "Wyoming" }
        ];
    }

    static getPreviousYearsByDate(noOfyears) {
        let startDate = moment().subtract(noOfyears, 'years');
        let dateCounter = moment().startOf("year").add(1, 'years');
        let years = [];
        while (dateCounter > startDate) {
            years.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "year");
        }

        return years;
    }

    static getComponentConstructorData(componentValues) {
        let componentValuesArray = [];
        if (componentValues !== null) {
            Object.keys(componentValues).map((componentValue) => componentValuesArray.push(componentValue));
        }

        return componentValuesArray.join(", ");
    }

    static getComponentName(traceString) {
        let componentName = "";
        let traceComponentRegex = /^at (.*) (.*)$/g;
        let componentMatch = traceComponentRegex.exec(traceString);
        if (componentMatch) {
            componentName = componentMatch[1].split('.');
            if (componentName.length > 1) {
                componentName = componentName[1].replace("new ", "");
            }
        }

        return componentName;
    }

    static getFunctionName(traceString) {
        let functionName = "";
        let traceComponentRegex = /^at (.*) (.*)$/g;
        let functionMatch = traceComponentRegex.exec(traceString);
        if (functionMatch) {
            if (functionMatch[1].indexOf("new ") === 0) {
                functionName = "constructor"
            } else {
                functionName = functionMatch[1].split('.');
                if (functionName.length > 2) {
                    functionName = functionName[2];
                }
            }
        }

        return functionName;
    }

    static getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static showPrint(printTitle, printContent, isLandScape, showFooter) {
        let windowOrigin = window.location.origin;
        let now = new Date();
        let landscapeScript = '';
        if (isLandScape) {
            landscapeScript = '<style type="text/css" media="print">@media print{@page {size: landscape;}}</style>';
        }

        let windowCss = '<link rel="stylesheet" href="' + windowOrigin + '/static/css/bootstrap.min.css" />';
        windowCss += '<link rel="stylesheet" href="' + windowOrigin + '/static/css/Print.css?' + now.getTime() + '" />';
        let windowContent = '<!DOCTYPE html><html><head>' + windowCss
            + '<title>' + printTitle + '</title>' + landscapeScript
            + '</head><body><div class="dgdg-print-body">' + printContent
            + '</div>';

        if (showFooter) {
            windowContent += '<table class="footer"><tr><td> DGDG Confidential</td><td>' + CommonService.formatDateTime(now.getTime()) + '</td></tr></table>';
        }

        windowContent += '<script type="text/javascript">window.print();</script></body></html>';;
        let printWindow = window.open("", "_blank", "");
        printWindow.document.write(windowContent);
    }

    static createGuid() {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }
}
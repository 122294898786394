import React from "react";

export class DGDGInputFileComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        let inputClassName = "custom-file-input";
        if (this.props.isButtonOnly) {
            inputClassName += " d-none"
        }

        return <div className={"form-group " + colSpan}>
            <div className={"custom-file " + colSpan}>
                <input type="file" id={this.props.id} className={inputClassName} accept={this.props.accept} onChange={(event) => this.onFileChange(event)} disabled={this.props.disabled} />
                {
                    this.props.isButtonOnly
                        ? <label className="fas fa-upload dgdg-input-file-color" role="button" htmlFor={this.props.id} disabled={this.props.disabled} />
                        : <label className="btn btn-primary custom-file-label" htmlFor={this.props.id} disabled={this.props.disabled}>Choose file</label>
                }
            </div>
        </div>;
    }

    onFileChange(event) {
        if (this.props.onFileChange) {
            this.props.onFileChange(event);
        }
    }
}

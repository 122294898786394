import React from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class UserListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: "",
            showSpinner: false,
            users: [],
            paintTable: false
        };

        this.nameColumnCallback = this.nameColumnCallback.bind(this);
        this.actionColumnCallback = this.actionColumnCallback.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Users";

    nameColumnCallback(rowData) {
        if (rowData.can_impersonate)
            return <Link to={"/User/Detail/" + rowData.guid}>{rowData.display_name}</Link>;
        else
            return <label>{rowData.display_name}</label>;
    }

    actionColumnCallback(rowData) {
        if (rowData.can_impersonate) {
            return <a className="dgdg-tooltip" href={"/User/Impersonate?Id=" + rowData.guid + "&RedirectURL=User"}>
                <span className="dgdg-tooltiptext">Impersonate</span>
                <span className="fas fa-user-plus dgdg-icon" />
            </a>;
        }

        return null;
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("UserRouteComponent", "UserListComponent", "onCopyTableCallBack", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            let inputData = {
                filterValue: this.state.filterValue,
            };
            this.setState({ showSpinner: true });
            this.props.applicationInsights.trackTrace({ message: "/UserRouteComponent/GetUsers", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/User/GetUsers", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.roles = responseItem.roles !== null ? responseItem.roles.replace(/&amp;/i, "&") : null;
                    });

                    this.setState({
                        users: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true,
                        showSpinner: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        users: [],
                        paintTable: true,
                        showSpinner: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/GetUsers", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                {
                    this.props.canAddEditUser
                        ? <div className="card dgdg-card-gutter">
                            <div className="card-block">
                                <div className="form-inline">
                                    <DGDGFormInputComponent id="filterValue" label="User Filter" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                                    <Link className="btn btn-primary" to="/User/Detail">
                                        Add<span className="fas fa-plus dgdg-icon"></span>
                                    </Link>
                                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Users" tableData={this.state.users} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" />
                            <DGDGTableColumnComponent headerText="Name" dataColumnCallback={this.nameColumnCallback} dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" />
                            <DGDGTableColumnComponent headerText="Logon" dataColumn="remote_upn" sortColumn="remote_upn" filterColumn="remote_upn" />
                            <DGDGTableColumnComponent headerText="Position Code" dataColumn="position_code" sortColumn="position_code" filterColumn="position_code" />
                            <DGDGTableColumnComponent headerText="Title" dataColumn="job_title" sortColumn="job_title" filterColumn="job_title" />
                            <DGDGTableColumnComponent headerText="Roles" dataColumn="roles" sortColumn="roles" filterColumn="roles" />
                            <DGDGTableColumnComponent headerText="Last Logon" dataColumn="last_logon_date" dataType="dateTime" sortColumn="last_logon_date" filterColumn="last_logon_date" />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.actionColumnCallback} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class TechnicianPayrollDetailComponent extends React.Component {
    pageTitle = "Technician Payroll";

    constructor(props) {
        super(props);
        this.state = {
            payrollDetail: null,
            payrollDetailEditComponentTop: null,
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <div className="dgdg-payroll" style={{ "marginLeft": "60px" }}>
            <table width="100%">
                <tbody>
                    <tr>
                        <td className="dgdg-no-border" style={{ "width": "200px" }}>Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}</td>
                        <td className="dgdg-no-border">
                            {
                                (this.props.isEditEnabled)
                                    ? <button type="button" className="btn btn-link float-right" disabled={this.props.rowData.disable_edit} onClick={(event) => this.props.onEditClick(event, this.props.rowData)}>
                                        <span className="fas fa-edit" />
                                    </button>
                                    : null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="dgdg-payroll-period">
                <tbody>
                    {
                        this.props.payPeriod === 1
                            ? <tr>
                                <td>Pay Period: 1</td>
                                <td>Plan Name: {this.props.rowData.pay_1_plan_name}</td>
                                <td>Base Hourly Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_base_hourly_rate)}</td>
                                <td>Flat Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_flat_rate)}</td>
                                <td>Bonused Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_bonused_rate)}</td>
                                <td>Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_1_commission_bonus)}</td>
                                <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_sick_labor_rate)}</td>
                                <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_pto_labor_rate)}</td>
                                <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_bereavement_labor_rate)}</td>
                                <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_holiday_labor_rate)}</td>
                                <td>Training Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_training_labor_rate)}</td>
                                <td>Non Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_1_non_commission_bonus)}</td>
                                <td>Sold Hours: {CommonService.formatNumber_1(this.props.rowData.pay_1_sold_hours)}</td>
                                <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_1_mealtime_premium_hours)}</td>
                                <td>Comments: {this.props.rowData.pay_1_comments}</td>
                            </tr>
                            : <tr>
                                <td>Pay Period: 2</td>
                                <td>Plan Name: {this.props.rowData.pay_2_plan_name}</td>
                                <td>Base Hourly Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_base_hourly_rate)}</td>
                                <td>Flat Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_flat_rate)}</td>
                                <td>Bonused Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_bonused_rate)}</td>
                                <td>Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_2_commission_bonus)}</td>
                                <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_sick_labor_rate)}</td>
                                <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_pto_labor_rate)}</td>
                                <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_bereavement_labor_rate)}</td>
                                <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_holiday_labor_rate)}</td>
                                <td>Training Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_training_labor_rate)}</td>
                                <td>Non Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_2_non_commission_bonus)}</td>
                                <td>Sold Hours: {CommonService.formatNumber_1(this.props.rowData.pay_2_sold_hours)}</td>
                                <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_2_mealtime_premium_hours)}</td>
                                <td>Comments: {this.props.rowData.pay_2_comments}</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>;
    }
}

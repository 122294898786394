import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { CPOInspectionQueryComponent } from "./CPOInspectionQueryComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class CPOInspectionRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPdfView: false,
            isLoadingData: false,
            isDownLoading: false,
            tableData: []
        };

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CPOInspectionRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CPOInspection", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("CPOInspectionRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "CPO Inspection";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/CPOInspectionRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CPOInspection/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "CPOInspectionRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CPOInspection/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <div className="form-inline justify-content-center">
            <button disabled={this.state.showSpinner} className="btn btn-link" onClick={(event) => this.onViewClick(event, selectedRow)}>
                <i className="far fa-eye" />
            </button>
            <button disabled={this.state.showSpinner} className="btn btn-link" onClick={(event) => this.onDownloadClick(event, selectedRow)}>
                <i className="far fa-download" />
            </button>
            <DGDGSpinnerComponent showSpinner={selectedRow.showSpinner} />
        </div>;
    }

    onGetReportClick(event, vin) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                vin: vin
            };
            this.props.applicationInsights.trackTrace({ message: "/CPOInspectionRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CPOInspection/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.length > 0) {
                        this.setState({
                            tableData: parsedResponse,
                            showPdfView: false,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: true });
                        });
                    }
                    else {
                        this.setState({
                            tableData: [],
                            showPdfView: false,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        this.props.onShowAlert("danger", {
                            message: "CPO Inspection report for VIN: " + vin + " was not found."
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: [],
                        showPdfView: false,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CPOInspection/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                this.props.onShowAlert("danger", {
                                    message: "This CPO Inspection report for VIN: " + vin + " was not found. Please contact the service department to see if the MPI report was generated."
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onViewClick(event, selectedRow) {
        try {
            selectedRow.showSpinner = true;
            this.setState({ isLoadingData: true });
            let inputData = {
                blobName: selectedRow.blobName
            };

            this.props.applicationInsights.trackTrace({ message: "/CPOInspectionRouteComponent/onViewClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CPOInspection/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    selectedRow.showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        showPdfView: true
                    });
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        document.getElementById("viewFrame").src = url;
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CPOInspection/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                this.props.onShowAlert("danger", {
                                    message: "This CPO Inspection report for VIN: " + selectedRow.vin + " was not found. Please contact the service department to see if the MPI report was generated."
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event, selectedRow) {
        try {
            selectedRow.showSpinner = true;
            this.setState({
                isDownLoading: true
            });
            let inputData = {
                blobName: selectedRow.blobName
            };

            this.props.applicationInsights.trackTrace({ message: "/CPOInspectionRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CPOInspection/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    selectedRow.showSpinner = false;
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CPOInspection/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                this.props.onShowAlert("danger", {
                                    message: "This CPO Inspection report for VIN: " + selectedRow.vin + " was not found. Please contact the service department to see if the MPI report was generated."
                                });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <CPOInspectionQueryComponent {...this.props} vin={this.props.match.params.vin}
                    onViewClick={this.onViewClick} onDownloadClick={this.onDownloadClick} onGetReportClick={this.onGetReportClick}
                    isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading} />
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-flex dgdg-row dgdg-site-content">
                        <div>
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Inspection Reports" tableData={this.state.tableData} paintTable={this.state.paintTable}  >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="RO Number" dataColumn="roNumber" />
                                    <DGDGTableColumnComponent headerText="File Date" dataColumn="fileDate" dataType="date" />
                                    <DGDGTableColumnComponent headerText="File Size" dataColumn="fileSize" dataType="formatted_number" />
                                    <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                        {
                            this.state.showPdfView
                                ? <div className="dgdg-flex-item dgdg-icon">
                                    <iframe id="viewFrame" width="100%" height="100%" type="application/x-pdf" title="CPO Inspection" />
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
import React from "react";

import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";
import { DGDGChartColumnComponent } from "../common/dgdgchart/DGDGChartColumnComponent";

export class UsedReconditioningChartColumnComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOptions: {
                height: 370,
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 0 },
                    2: { targetAxisIndex: 0 },
                    3: { targetAxisIndex: 0 },
                    4: { targetAxisIndex: 0 },
                    5: { targetAxisIndex: 0 },
                    6: { targetAxisIndex: 1 }
                },
                hAxis: {
                    title: "Store",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Delay Days (Service,Parts, Body, Detail, Recon & Photo)",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    },
                    1: {
                        title: "Delay Days (Total)",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                colors: ['#0000FF', '#FF0000', '#FFA500', '#008000', '#800080', '#1569C7', '#000000'],
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            console.devLog("shouldComponentUpdate - UsedReconditioningChartComponent");
            return this.props.paintChart;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGWidgetComponent title={this.props.headerText} restoredCss="dgdg-widget-maximized" isVisible>
            {
                this.props.chartData && this.props.chartData.length > 1
                    ? <DGDGChartColumnComponent chartData={this.props.chartData} chartOption={this.state.chartOptions} />
                    : null
            }
        </DGDGWidgetComponent>;
    }
}

import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { ExcelReportsDataComponent } from "./ExcelReportsDataComponent";
import { ExcelReportsTemplateComponent } from "./ExcelReportsTemplateComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class ExcelReportsRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canShowTemplate: false
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ExcelReportsRouteComponent - componentDidMount");
            this.getControllerPolicy();
            this.canShowTemplate();
            CommonService.clientAzureStorageLog("ExcelReportsRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Excel Reports";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ExcelReportsRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ExcelReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "ExcelReportsRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ExcelReports/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    canShowTemplate() {
        this.props.applicationInsights.trackTrace({ message: "/ExcelReportsRouteComponent/canShowTemplate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/ExcelReports/CanShowTemplate")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ canShowTemplate: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ExcelReports/CanShowTemplate", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/ExcelReports/Data" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Data") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Data</Link>
                    {this.state.canShowTemplate
                        ? <Link to="/ExcelReports/Template" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Template") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Template</Link>
                        : null

                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/ExcelReports/Data" render={(routeProps) => <ExcelReportsDataComponent {...this.props} />} />
                        <Route exact path="/ExcelReports/Template" render={(routeProps) => <ExcelReportsTemplateComponent {...this.props} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}

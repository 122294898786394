import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { OrgChartQueryComponent } from "./OrgChartQueryComponent";

export class OrgChartStoreComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            selectedMonth: null,
            isLoadingData: false,
            tableData: [],
            tableTitle: "",
            hiresAndTransfersInTableData: [],
            terminationsAndTransfersOut: [],
            leaveTableData: [],
            generalManager: [],
            generalSalesMgr: "",
            employeeTree: [],
            departmentSummaryData: [],
            employeeSummaryData: []
        };

        this.onGetChartClick = this.onGetChartClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("OrgChartStoreComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/OrgChart/Store", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("OrgChartStoreComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetChartClick(selectedMonth, selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                chartDate: CommonService.formatServerDateTime(selectedMonth),
                storeNumber: selectedStore.store_number
            };

            this.setState({ isLoadingData: true });

            this.props.applicationInsights.trackTrace({ message: "/OrgChartStoreComponent/onGetChartClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OrgChart/GetChart", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: parsedResponse.table.filter((store) => store.store_number === selectedStore.store_number),
                        hiresAndTransfersInTableData: parsedResponse.table1.filter((rowData) => rowData.store_number === selectedStore.store_number),
                        terminationsAndTransfersOut: parsedResponse.table2.filter((rowData) => rowData.store_number === selectedStore.store_number),
                        leaveTableData: parsedResponse.table4.filter((rowData) => rowData.store_number === selectedStore.store_number),
                        tableTitle: "Org Chart - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        selectedStore: selectedStore,
                        selectedMonth: selectedMonth
                    }, this.getEmployeeData);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: ""
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/GetChart", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getEmployeeData() {
        if (this.state.tableData !== null) {
            this.getEmployeeRow();
            let employeeTreeColumn1 = this.getEmployeeTreeColumn1();
            let employeeTreeColumn2 = this.getEmployeeTreeColumn2();
            let employeeTreeColumn3 = this.getEmployeeTreeColumn3();
            let employeeTreeColumn4 = this.getEmployeeTreeColumn4();
            let employeeTreeColumn5 = this.getEmployeeTreeColumn5();
            let departmentSummaryData = this.getDepartmentSummaryData();
            let employeeSummaryTable1 = this.getEmployeeSummaryTable1();
            let employeeSummaryTable2 = this.getEmployeeSummaryTable2();
            let employeeSummaryTable3 = this.getEmployeeSummaryTable3();
            let employeeSummaryTable4 = this.getEmployeeSummaryTable4();
            let employeeSummaryTable5 = this.getEmployeeSummaryTable5();

            this.formEmployeeTree(employeeTreeColumn1, employeeTreeColumn2, employeeTreeColumn3, employeeTreeColumn4, employeeTreeColumn5)
            this.departmentSummaryData(departmentSummaryData);
            this.formEmployeeSummaryData(employeeSummaryTable1, employeeSummaryTable2, employeeSummaryTable3, employeeSummaryTable4, employeeSummaryTable5);
        }
    }

    formEmployeeTree(employeeTreeColumn1, employeeTreeColumn2, employeeTreeColumn3, employeeTreeColumn4, employeeTreeColumn5) {
        let tableRows = []
        let maxLength = Math.max(employeeTreeColumn1.length, employeeTreeColumn2.length, employeeTreeColumn3.length, employeeTreeColumn4.length, employeeTreeColumn5.length)
        for (var i = 0; i < maxLength; i++) {
            tableRows.push(<tr className="text-center" key={"employeeTreeRow" + i}>
                {
                    (employeeTreeColumn1.length > i && employeeTreeColumn1[i] !== null)
                        ? <td colSpan="2" style={{ "backgroundColor": employeeTreeColumn1[i].length === 3 ? employeeTreeColumn1[i][1] : "" }} className={employeeTreeColumn1[i].length === 3 ? employeeTreeColumn1[i][2] : employeeTreeColumn1[i][1]}>
                            {
                                employeeTreeColumn1[i][0]
                            }
                        </td>
                        : <td colSpan="2" className="dgdg-no-border"></td>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (employeeTreeColumn2.length > i && employeeTreeColumn2[i] !== null)
                        ? <td colSpan="2" style={{ "backgroundColor": employeeTreeColumn2[i].length === 3 ? employeeTreeColumn2[i][1] : "" }} className={employeeTreeColumn2[i].length === 3 ? employeeTreeColumn2[i][2] : employeeTreeColumn2[i][1]}>
                            {
                                employeeTreeColumn2[i][0]
                            }
                        </td>
                        : <td colSpan="2" className="dgdg-no-border"></td>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (employeeTreeColumn3.length > i && employeeTreeColumn3[i] !== null)
                        ? <td colSpan="2" style={{ "backgroundColor": employeeTreeColumn3[i].length === 3 ? employeeTreeColumn3[i][1] : "" }} className={employeeTreeColumn3[i].length === 3 ? employeeTreeColumn3[i][2] : employeeTreeColumn3[i][1]}>
                            {
                                employeeTreeColumn3[i][0]
                            }
                        </td>
                        : <td colSpan="2" className="dgdg-no-border"></td>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (employeeTreeColumn4.length > i && employeeTreeColumn4[i] !== null)
                        ? <td colSpan="2" style={{ "backgroundColor": employeeTreeColumn4[i].length === 3 ? employeeTreeColumn4[i][1] : "" }} className={employeeTreeColumn4[i].length === 3 ? employeeTreeColumn4[i][2] : employeeTreeColumn4[i][1]}>
                            {
                                employeeTreeColumn4[i][0]
                            }
                        </td>
                        : <td colSpan="2" className="dgdg-no-border"></td>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (employeeTreeColumn5.length > i && employeeTreeColumn5[i] !== null)
                        ? <td colSpan="2" style={{ "backgroundColor": employeeTreeColumn5[i].length === 3 ? employeeTreeColumn5[i][1] : "" }} className={employeeTreeColumn5[i].length === 3 ? employeeTreeColumn5[i][2] : employeeTreeColumn5[i][1]}>
                            {
                                employeeTreeColumn5[i][0]
                            }
                        </td>
                        : <td colSpan="2" className="dgdg-no-border"></td>
                }
            </tr>);
        }

        this.setState({
            employeeTree: tableRows
        });
    }

    departmentSummaryData(departmentSummaryData) {
        let tableRows = []
        let maxLength = Math.max(departmentSummaryData.length)
        for (var i = 0; i < maxLength; i++) {
            tableRows.push(<tr className="text-center" key={"departmentSummary" + i}>
                {
                    (departmentSummaryData.length > i && departmentSummaryData[i] !== null && departmentSummaryData[i].length === 5)
                        ? <Fragment>
                            <td colSpan="4" className="text-left dgdg-no-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom" style={{ "backgroundColor": departmentSummaryData[i][1] }}>
                                {
                                    departmentSummaryData[i][0]
                                }
                            </td>
                            <td colSpan="2" className=" dgdg-no-border-left dgdg-no-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom">
                                {
                                    departmentSummaryData[i][2] === 0 ? "" : departmentSummaryData[i][2]
                                }
                            </td>
                            <td colSpan="2" className="dgdg-no-border-left dgdg-border-dotted-top dgdg-border-dotted-bottom">
                                {
                                    departmentSummaryData[i][3] === 0 ? "" : CommonService.formatPercentage_1(departmentSummaryData[i][3])
                                }
                            </td>
                            <td colSpan="2" className="dgdg-border-dotted-top dgdg-border-dotted-bottom">
                                {
                                    departmentSummaryData[i][4] === 0 ? "" : departmentSummaryData[i][4]
                                }
                            </td>
                        </Fragment>
                        : (departmentSummaryData.length > i && departmentSummaryData[i] !== null && departmentSummaryData[i].length === 4)
                            ? <Fragment>
                                <td colSpan="4" className="text-left dgdg-no-border-left dgdg-no-border-right dgdg-no-border-bottom">
                                    {
                                        departmentSummaryData[i][0]
                                    }
                                </td>
                                <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right dgdg-no-border-bottom">
                                    {
                                        departmentSummaryData[i][1] === 0 ? "" : departmentSummaryData[i][1]
                                    }
                                </td>
                                <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right dgdg-no-border-bottom">
                                    {
                                        departmentSummaryData[i][2] === 0 ? "" : CommonService.formatPercentage_1(departmentSummaryData[i][2])
                                    }
                                </td>
                                <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right dgdg-no-border-bottom">
                                    {
                                        departmentSummaryData[i][3] === 0 ? "" : departmentSummaryData[i][3]
                                    }
                                </td>
                            </Fragment>

                            : (departmentSummaryData.length > i && departmentSummaryData[i] !== null && departmentSummaryData[i].length === 3)
                                ? <Fragment>
                                    <td colSpan="4" className="text-left dgdg-no-border-left dgdg-no-border-right">
                                        {
                                            departmentSummaryData[i][0]
                                        }
                                    </td>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">
                                        {
                                            departmentSummaryData[i][1] === 0 ? "" : departmentSummaryData[i][1]
                                        }
                                    </td>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">
                                        {
                                            departmentSummaryData[i][2] === 0 ? "" : CommonService.formatPercentage_1(departmentSummaryData[i][2])
                                        }
                                    </td>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right"> </td>
                                </Fragment>
                                : (departmentSummaryData.length > i && departmentSummaryData[i] !== null && departmentSummaryData[i].length === 2)
                                    ? departmentSummaryData[i][0] !== ""
                                        ? <Fragment>
                                            <td colSpan="4" className="text-left dgdg-no-border-right">
                                                {
                                                    departmentSummaryData[i][0]
                                                }
                                            </td>
                                            <td colSpan="2" className="dgdg-no-border-left">
                                                {
                                                    departmentSummaryData[i][1] === 0 ? "" : departmentSummaryData[i][1]
                                                }
                                            </td>
                                        </Fragment>
                                        : <Fragment>
                                            <td colSpan="4" className="dgdg-no-border"></td>
                                            <td colSpan="2" className="dgdg-no-border">
                                                {
                                                    departmentSummaryData[i][1] === 0 ? "" : departmentSummaryData[i][1]
                                                }
                                            </td>
                                        </Fragment>
                                    : < td colSpan="2" className="dgdg-no-border">&nbsp;</td>

                }
            </tr>);
        }

        this.setState({
            departmentSummaryData: tableRows
        });
    }

    formEmployeeSummaryData(employeeSummaryTable1, employeeSummaryTable2, employeeSummaryTable3, employeeSummaryTable4, employeeSummaryTable5) {
        let tableRows = []
        let transferData = employeeSummaryTable2;
        transferData.push(["Transfers Out", "To", "#000000", "0fffff"])
        transferData = transferData.concat(employeeSummaryTable3);
        let departuresLeaveData = employeeSummaryTable4;
        departuresLeaveData.push(["Employees on Leave", "#000000", "0fffff"])
        departuresLeaveData = departuresLeaveData.concat(employeeSummaryTable5);
        tableRows.push(<tr className="text-center" key="departuresRow1">
            <td colSpan="4" className="dgdg-org-chart-table-header"> New Hires </td>
            <td colSpan="2" className="dgdg-no-border"></td>
            <td colSpan="4" className="dgdg-org-chart-table-header">Transfers In </td>
            <td colSpan="4" className="dgdg-org-chart-table-header">From</td>
            <td colSpan="2" className="dgdg-no-border"></td>
            <td colSpan="4" className="dgdg-org-chart-table-header">Departures</td>
            <td colSpan="4" className="dgdg-org-chart-table-header">Reason</td>
        </tr>);
        let maxLength = Math.max(employeeSummaryTable1.length, transferData.length, departuresLeaveData.length)
        for (var i = 0; i < maxLength; i++) {
            tableRows.push(<tr className="text-center" key={"transferRow" + i}>
                {
                    (employeeSummaryTable1.length > i && employeeSummaryTable1[i] !== null)
                        ? <td colSpan="4" className={employeeSummaryTable1[i][1]}>
                            {
                                employeeSummaryTable1[i][0]
                            }
                        </td>
                        : <td colSpan="4" className="dgdg-no-border"></td>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (transferData.length > i && transferData[i] !== null && transferData[i].length > 3)
                        ? <Fragment>
                            <td colSpan="4" className="dgdg-org-chart-table-header">Transfers Out </td>
                            <td colSpan="4" className="dgdg-org-chart-table-header">To</td>
                        </Fragment>
                        : (transferData.length > i && transferData[i] !== null)
                            ? <Fragment>
                                <td colSpan="4" className={"dgdg-border-dotted-top dgdg-no-border-right" + (transferData[i].length === 3 ? transferData[i][2] : " dgdg-border-dotted-bottom")}>
                                    {
                                        transferData[i][0]
                                    }
                                </td>
                                <td colSpan="4" className={"dgdg-border-dotted-top dgdg-no-border-left" + (transferData[i].length === 3 ? transferData[i][2] : " dgdg-border-dotted-bottom")}>
                                    {
                                        transferData[i][1]
                                    }
                                </td>
                            </Fragment>
                            : <Fragment>
                                <td colSpan="4" className="dgdg-no-border"></td>
                                <td colSpan="4" className="dgdg-no-border"></td>
                            </Fragment>
                }
                <td colSpan="2" className="dgdg-no-border"></td>
                {
                    (departuresLeaveData.length > i && departuresLeaveData[i] !== null && departuresLeaveData[i].length === 4)
                        ? <Fragment>
                            <td colSpan="4" className={"dgdg-border-left dgdg-border-dotted-top" + departuresLeaveData[i][2]}>
                                {
                                    departuresLeaveData[i][0]
                                }
                            </td>
                            <td colSpan="4" className={"dgdg-no-border-top" + departuresLeaveData[i][3]}>
                                {
                                    departuresLeaveData[i][1]
                                }
                            </td>
                        </Fragment>
                        : (departuresLeaveData.length > i && departuresLeaveData[i] !== null && departuresLeaveData[i].length === 3)
                            ? <td colSpan="6" className="dgdg-org-chart-table-header">{departuresLeaveData[i][0]}</td>
                            : (departuresLeaveData.length > i && departuresLeaveData[i] !== null && departuresLeaveData[i].length === 2)
                                ? <td colSpan="6" className={departuresLeaveData[i][1]}>
                                    {
                                        departuresLeaveData[i][0]
                                    }
                                </td>
                                : <Fragment>
                                    <td colSpan="4" className="dgdg-no-border"></td>
                                    <td colSpan="4" className="dgdg-no-border"></td>
                                </Fragment>
                }
            </tr>);
        }
        this.setState({
            employeeSummaryData: tableRows
        });
    }

    getEmployeeRow() {
        let generalManager = [];
        let generalSalesMgr = [];

        let positionCodeRows = this.filterData("Admin-General Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                generalManager.push(<tr className="text-center" key={"generalManagerRow" + rowIndex}><td colSpan="18">{CommonService.toTitleCase(rowData.employee_name)}</td></tr>);
            });
        }

        let salesPartsDir = this.filterData("Sales-General Sales Manager");
        let servicePartsDir = this.filterData("Service-Service & Parts Director");
        if (salesPartsDir || servicePartsDir) {
            let maxLength = salesPartsDir ? salesPartsDir.length : 0;
            maxLength = servicePartsDir ? (servicePartsDir.length > maxLength ? servicePartsDir.length : maxLength) : maxLength;
            for (let index = 0; index < maxLength; index++) {
                generalSalesMgr.push(<tr className="text-center" key={"generalSalesMgrRow" + index}>
                    <td colSpan="6" className={salesPartsDir && salesPartsDir[index] ? "" : "dgdg-no-border"}>
                        {
                            salesPartsDir && salesPartsDir[index]
                                ? CommonService.toTitleCase(salesPartsDir[index].employee_name)
                                : null
                        }
                    </td>
                    <td colSpan="2" className="dgdg-no-border" />
                    <td colSpan="10" className={servicePartsDir && servicePartsDir[index] ? "" : "dgdg-no-border"}>
                        {
                            servicePartsDir && servicePartsDir[index]
                                ? CommonService.toTitleCase(servicePartsDir[index].employee_name)
                                : null
                        }
                    </td>
                </tr>);
            }
        }

        this.setState({
            generalManager: generalManager,
            generalSalesMgr: generalSalesMgr
        });
    }

    getEmployeeTreeColumn1() {
        let positionCodeRows = null;
        let headerColor = "#f4b084";

        let dataList = [];
        dataList.push(["Sales Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Sales Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Product Specialists", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Product Specialists");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Receptionist/Admin", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Admin-Support")
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Sales Support", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Support")
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length1 - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        return dataList;
    }

    getEmployeeTreeColumn2() {
        let positionCodeRows = null;
        let headerColor = "#f4b084";

        let dataList = [];
        dataList.push(["Used Car Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Used Car Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Finance Director", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Finance Director");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Finance Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Finance Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Online Product Specialists", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Online Product Specialists");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Sales Porter", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Sales-Porter");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        return dataList;
    }

    getEmployeeTreeColumn3() {
        let positionCodeRows = null;
        let headerColor = "#ffc000";
        let dataList = [];
        dataList.push(["Service Director", headerColor, "dgdg-border-top dgdg-border-bottom"]);

        positionCodeRows = this.filterData("Service-Service Director");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Service Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Service Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Asst Service Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Asst Service Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Warranty Admin", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Warranty Admin");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Dispatcher", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Dispatcher");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Service Advisors", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Service Advisors");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Service Support", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Support");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Shuttle Driver", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Shuttle Driver");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Service Porter", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Porter");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        return dataList;
    }

    getEmployeeTreeColumn4() {
        let positionCodeRows = null;
        let headerColor = "#ffc000";

        let dataList = [];
        dataList.push(["Shop Foreman", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Shop Foreman");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Technicians", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Technicians");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        return dataList;
    }

    getEmployeeTreeColumn5() {
        let positionCodeRows = null;
        let headerColor = "#92d050";
        let dataList = []
        dataList.push(["Parts Manager", headerColor, "dgdg-border-top dgdg-border-bottom"]);

        positionCodeRows = this.filterData("Parts-Parts Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Counter Team", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Parts-Counter Team");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Parts Support", headerColor, "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Parts-Parts Support");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Detail Manager", "#ffc000", "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Detail Manager");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }
        dataList.push(null);

        positionCodeRows = null;
        dataList.push(["Detail Dept", "#ffc000", "dgdg-border-top dgdg-border-bottom"]);
        positionCodeRows = this.filterData("Service-Detail Dept");
        if (positionCodeRows) {
            positionCodeRows.forEach(function (rowData, rowIndex) {
                dataList.push([CommonService.toTitleCase(rowData.employee_name), positionCodeRows.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
            });
        }

        return dataList;
    }

    getDepartmentSummaryData() {
        let dataList = []

        let totalSalesDept = 0;
        let totalServiceDept = 0;
        let totalPartsDept = 0;
        let totalPTCount = 0;
        let numberOfRows = this.state.tableData.length;

        let positionCodeRows = this.filterData("Admin-General Manager");
        let ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        let percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Admin-General Manager", "#bfbfbf", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Sales-General Sales Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Sales-General Sales Manager", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Sales-Sales Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Sales-Sales Manager", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Sales-Used Car Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Used Car Manager", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Sales-Finance Manager");
        let addPositionCodeRows = this.filterData("Sales-Finance Director");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        let addPtCount = addPositionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += (ptCount.length + addPtCount.length);
        percentOfTotal = (positionCodeRows.length + addPositionCodeRows.length) / numberOfRows;
        dataList.push(["Finance Team", "#f4b084", positionCodeRows.length + addPositionCodeRows.length, percentOfTotal, (ptCount.length + addPtCount.length)]);
        totalSalesDept += (positionCodeRows.length + addPositionCodeRows.length);

        positionCodeRows = this.filterData("Sales-Product Specialists");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Product Specialists", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Sales-Online Product Specialists");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Online Product Specialists", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Admin-Support");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;

        let supportPositionCodeRows = this.filterData("Sales-Support");
        let sptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += sptCount.length;
        let totalAdminRows = positionCodeRows.length + supportPositionCodeRows.length;
        percentOfTotal = totalAdminRows / numberOfRows;
        dataList.push(["Receptionist/Admin", "#f4b084", totalAdminRows, percentOfTotal, (ptCount.length + sptCount.length)]);
        totalSalesDept += totalAdminRows;

        positionCodeRows = this.filterData("Sales-Porter");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Porter", "#f4b084", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalSalesDept += positionCodeRows.length;

        percentOfTotal = totalSalesDept / numberOfRows;
        dataList.push(["Total Sales Dept", totalSalesDept, percentOfTotal]);

        positionCodeRows = this.filterData("Service-Service & Parts Director");
        addPositionCodeRows = this.filterData("Service-Service Director");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        addPtCount = addPositionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += (ptCount.length + addPtCount.length);
        percentOfTotal = (positionCodeRows.length + addPositionCodeRows.length) / numberOfRows;
        dataList.push(["Service & Parts Director", "#ffc000", (positionCodeRows.length + addPositionCodeRows.length), percentOfTotal, (ptCount.length + addPtCount.length)]);
        totalServiceDept += (positionCodeRows.length + addPositionCodeRows.length);

        positionCodeRows = this.filterData("Service-Service Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Service Director/Manager", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Asst Service Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Asst Service Manager", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Warranty Admin");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Warranty Admin", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Dispatcher");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Dispatcher", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Service Advisors");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Service Advisors", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Technicians");
        addPositionCodeRows = this.filterData("Service-Shop Foreman");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        addPtCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += (ptCount.length + addPtCount.length);
        percentOfTotal = (positionCodeRows.length + addPositionCodeRows.length) / numberOfRows;
        dataList.push(["Technicians", "#ffc000", (positionCodeRows.length + addPositionCodeRows.length), percentOfTotal, (ptCount.length + addPtCount.length)]);
        totalServiceDept += (positionCodeRows.length + addPositionCodeRows.length);

        positionCodeRows = this.filterData("Service-Support");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Support", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Shuttle Driver");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Shuttle Driver", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Porter");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Porter", "#ffc000", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalServiceDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Service-Detail Dept");
        addPositionCodeRows = this.filterData("Service-Detail Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        addPtCount = addPositionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += (ptCount.length + addPtCount.length);
        percentOfTotal = (positionCodeRows.length + addPositionCodeRows.length) / numberOfRows;
        dataList.push(["Detail Dept", "#ffc000", (positionCodeRows.length + addPositionCodeRows.length), percentOfTotal, (ptCount.length + addPtCount.length)]);
        totalServiceDept += (positionCodeRows.length + addPositionCodeRows.length);

        percentOfTotal = totalServiceDept / numberOfRows;
        dataList.push(["Total Service Dept", totalServiceDept, percentOfTotal]);

        positionCodeRows = this.filterData("Parts-Parts Manager");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Parts Manager", "#92d050", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalPartsDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Parts-Counter Team");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Counter Team", "#92d050", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalPartsDept += positionCodeRows.length;

        positionCodeRows = this.filterData("Parts-Parts Support");
        ptCount = positionCodeRows.filter(row => row.employement_type === "RPT");
        totalPTCount += ptCount.length;
        percentOfTotal = positionCodeRows.length / numberOfRows;
        dataList.push(["Parts Support", "#92d050", positionCodeRows.length, percentOfTotal, ptCount.length]);
        totalPartsDept += positionCodeRows.length;
        percentOfTotal = totalPartsDept / numberOfRows;
        dataList.push(["Total Parts Dept", totalPartsDept, percentOfTotal, totalPTCount]);
        dataList.push(null);

        dataList.push(["Total Dealership", this.state.tableData.length]);

        dataList.push(["", (totalSalesDept + totalServiceDept + totalPartsDept) - this.state.tableData.length]);
        dataList.push(null);
        dataList.push(null);
        return dataList;
    }

    getEmployeeSummaryTable1() {
        let dataList = [];
        let year = moment(this.state.selectedMonth).year();
        let month = moment(this.state.selectedMonth).format('MM');
        let hireMonthKey = this.state.selectedStore.store_number + "-Hire-" + year + "/" + month;
        let newHiresTableData = this.state.terminationsAndTransfersOut.filter((rowData) => rowData.term_month_key === hireMonthKey);
        newHiresTableData.forEach(function (rowData, rowIndex) {
            dataList.push([CommonService.toTitleCase(rowData.employee_name), newHiresTableData.length - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
        });
        return dataList;
    }

    getEmployeeSummaryTable2() {
        let dataList = [];
        let year = moment(this.state.selectedMonth).year();
        let month = moment(this.state.selectedMonth).format('MM');
        let transferMonthKey = this.state.selectedStore.store_number + "-Transfer-" + year + "/" + month;
        let transfersInTableData = this.state.hiresAndTransfersInTableData.filter((rowData) => rowData.hire_month_key === transferMonthKey);
        transfersInTableData.forEach(function (rowData, rowIndex) {
            dataList.push([CommonService.toTitleCase(rowData.employee_name), CommonService.toTitleCase(rowData.transfer_from_store_name)]);
        });
        return dataList;
    }

    getEmployeeSummaryTable3() {
        let dataList = [];
        let year = moment(this.state.selectedMonth).year();
        let month = moment(this.state.selectedMonth).format('MM');
        let transferMonthKey = this.state.selectedStore.store_number + "-Transfer-" + year + "/" + month;
        let transfersOutTableData = this.state.terminationsAndTransfersOut.filter((rowData) => rowData.term_month_key === transferMonthKey);
        transfersOutTableData.forEach(function (rowData, rowIndex) {
            dataList.push([CommonService.toTitleCase(rowData.employee_name), CommonService.toTitleCase(rowData.transfer_to_store_name), transfersOutTableData.length - 1 === rowIndex ? " dgdg-border-bottom" : " dgdg-border-dotted-bottom"]);
        });
        return dataList;
    }

    getEmployeeSummaryTable4() {
        let dataList = [];
        let year = moment(this.state.selectedMonth).year();
        let month = moment(this.state.selectedMonth).format('MM');
        let termMonthKey = this.state.selectedStore.store_number + "-Term-" + year + "/" + month;
        let departuresTableData = this.state.terminationsAndTransfersOut.filter((rowData) => rowData.term_month_key === termMonthKey);
        departuresTableData.forEach(function (rowData, rowIndex) {
            dataList.push([CommonService.toTitleCase(rowData.employee_name), rowData.term_reason ? CommonService.toTitleCase(rowData.term_reason) : "", departuresTableData.length - 1 === rowIndex ? " dgdg-border-bottom" : " dgdg-border-dotted-bottom", departuresTableData.length - 1 === rowIndex ? " dgdg-border-bottom" : " dgdg-border-dotted-bottom"]);
        });
        dataList.push(null);
        return dataList;
    }

    getEmployeeSummaryTable5() {
        let dataList = [];
        let arrayLength = this.state.leaveTableData.length;
        this.state.leaveTableData.forEach(function (rowData, rowIndex) {
            dataList.push([CommonService.toTitleCase(rowData.employee_name), arrayLength - 1 === rowIndex ? "dgdg-border-dotted-top dgdg-border-bottom" : "dgdg-border-dotted-top dgdg-border-dotted-bottom"]);
        });

        return dataList;
    }

    filterData(positionCode) {
        if (this.state.selectedStore) {
            let positionKey = this.state.selectedStore.store_number + "-" + positionCode;
            return this.state.tableData.filter(row => row.position_key === positionKey);
        }
    }

    pageTitle = "Org Chart";

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <OrgChartQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                isDownLoading={this.props.isDownLoading}
                months={this.props.months}
                stores={this.props.stores}
                onGetChartClick={this.onGetChartClick}
                onDownloadClick={this.props.onDownloadClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-header">{this.state.tableTitle}</div>
                    <div className="card-block">
                        <table style={{ "width": "100%" }}>
                            <tbody>
                                <tr>
                                    <td className="align-top">
                                        <table className="dgdg-org-table">
                                            <tbody>
                                                <tr className="text-center font-weight-bold">
                                                    <td colSpan="18" style={{ "fontSize": "12px" }}>{this.state.selectedStore ? CommonService.toTitleCase(this.state.selectedStore.store_name) : null}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td colSpan="18" style={{ "backgroundColor": "#bfbfbf" }}>General Manager</td>
                                                </tr>

                                                {this.state.generalManager}
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td colSpan="6" style={{ "backgroundColor": "#f4b084" }}>General Sales Manager</td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="10" style={{ "backgroundColor": "#ffc000" }}>Service & Parts Director</td>
                                                </tr>
                                                {this.state.generalSalesMgr}
                                                <tr className="text-center">
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                    <td colSpan="2" className="dgdg-no-border"></td>
                                                </tr>

                                                {this.state.employeeTree}

                                            </tbody>
                                        </table>
                                    </td>
                                    <td colSpan="2" className="dgdg-no-border" style={{ "paddingLeft": "20px" }}></td>
                                    <td className="align-top">
                                        <table className="dgdg-org-table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="10" className="text-center">Department Headcount Summary</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="dgdg-org-chart-table-header"> Department</td>
                                                    <td colSpan="2" className="dgdg-org-chart-table-header">Count</td>
                                                    <td colSpan="2" className="dgdg-org-chart-table-header">% of Total</td>
                                                    <td colSpan="2" className="dgdg-org-chart-table-header"># P/T</td>
                                                </tr>

                                                {this.state.departmentSummaryData}
                                                {this.state.employeeSummaryData}

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }
}

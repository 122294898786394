import React from "react";

export class DGDGCarouselComponent extends React.Component {
    render() {
        return <div className={this.props.cssName ? this.props.cssName : ""}>
            {
                this.props.text
                    ? <div className={"text-center dgdg-carousel-text-" + (this.props.status ? this.props.status : "")}>{this.props.text}</div>
                    : null
            }
            {
                this.props.statusIn
                    ? <hr className={"d-inline-block dgdg-carousel-line dgdg-carousel-line-left dgdg-carousel-" + (this.props.statusIn ? this.props.statusIn : "")} />
                    : <hr className="d-inline-block dgdg-carousel-line dgdg-carousel-line-left dgdg-carousel-none" />
            }
            <span className={"dgdg-carousel-outer dgdg-carousel-" + (this.props.status ? this.props.status : "")}>
                <span className="dgdg-carousel-inner" />
            </span>
            {
                this.props.statusOut
                    ? <hr className={"d-inline-block dgdg-carousel-line dgdg-carousel-line-right dgdg-carousel-" + (this.props.statusOut ? this.props.statusOut : "")} />
                    : <hr className="d-inline-block dgdg-carousel-line dgdg-carousel-line-right dgdg-carousel-none" />
            }
        </div>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportWeekendRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <tr className="dgdg-table-v3-row text-center">
            <td className="text-right">
                {this.props.salesData[0].store_name}
            </td>
            <td className={this.props.salesData[0] && this.props.salesData[0].is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatNumber(this.props.salesData[0].volume)
                }
            </td>
            <td className={this.props.salesData[0] && this.props.salesData[0].is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatCurrency(this.props.salesData[0].total_gross)
                }
            </td>
            <td className={this.props.salesData[1] && this.props.salesData[1].is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatNumber(this.props.salesData[1].volume)
                }
            </td>
            <td className={this.props.salesData[1] && this.props.salesData[1].is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatCurrency(this.props.salesData[1].total_gross)
                }
            </td>
            <td className={this.props.salesData[2] && this.props.salesData[2].is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatNumber(this.props.salesData[2].volume)
                }
            </td>
            <td className={this.props.salesData[2] && this.props.salesData[2].is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatCurrency(this.props.salesData[2].total_gross)
                }
            </td>
            <td className={this.props.salesData[3] && this.props.salesData[3].is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatNumber(this.props.salesData[3].volume)
                }
            </td>
            <td className={this.props.salesData[3] && this.props.salesData[3].is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatCurrency(this.props.salesData[3].total_pvr)
                }
            </td>
            <td className={this.props.salesData[3] && this.props.salesData[3].is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                {
                    CommonService.formatCurrency(this.props.salesData[3].total_gross)
                }
            </td>
            <td className="text-center font-weight-bold">
                {this.props.salesData[3].store_code}
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].wow_volume_delta_pct ? this.props.salesData[3].wow_volume_delta_pct_color : "" }}>
                {
                    CommonService.formatPercentage(this.props.salesData[3].wow_volume_delta_pct)
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].yoy_volume_delta_pct ? this.props.salesData[3].yoy_volume_delta_pct_color : "" }}>
                {
                    CommonService.formatPercentage(this.props.salesData[3].yoy_volume_delta_pct)
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].wow_total_gross_delta_pct ? this.props.salesData[3].wow_total_gross_delta_pct_color : "" }}>
                {
                    CommonService.formatPercentage(this.props.salesData[3].wow_total_gross_delta_pct)
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].yoy_total_gross_delta_pct ? this.props.salesData[3].yoy_total_gross_delta_pct_color : "" }}>
                {
                    CommonService.formatPercentage(this.props.salesData[3].yoy_total_gross_delta_pct)
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].wow_total_pvr_delta ? this.props.salesData[3].wow_total_pvr_delta_color : "" }}>
                {
                    CommonService.formatCurrency(this.props.salesData[3].wow_total_pvr_delta)
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.salesData[3].yoy_total_pvr_delta ? this.props.salesData[3].yoy_total_pvr_delta_color : "" }}>
                {
                    CommonService.formatCurrency(this.props.salesData[3].yoy_total_pvr_delta)
                }
            </td>
        </tr>;
    }
}

import React from "react";

import { DGDGFormDropdownComponent } from "../dgdgdropdown/DGDGFormDropdownComponent";

export class DGDGEditDropdownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onClick = this.onClick.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                this.props.onHideClick();
                break;
            default:
                break;
        }
    }

    onClick(event) {
        let targetCss = event.target.getAttribute("class");
        if (event.stopPropagation && targetCss.indexOf("dropdown-toggle") === -1 && targetCss.indexOf("dropdown-item") === -1) {
            event.stopPropagation();
            if (event.nativeEvent.stopImmediatePropagation) {
                event.nativeEvent.stopImmediatePropagation();
            }
        }
        else if (window.event && targetCss.indexOf("dropdown-toggle") === -1 && targetCss.indexOf("dropdown-item") === -1) {
            window.event.cancelBubble = true;
        }
    }

    render() {
        let className = "";
        if (this.props.dataType === "comment") {
            className = "dgdg-comment";
        }

        return (
            this.props.showEditDropdown
                ? <div style={{ position: "absolute", top: this.props.top, left: this.props.left, zIndex: "101" }} className={className} onClick={(event) => this.onClick(event)}>
                    <div className="input-group input-group-sm">
                        <DGDGFormDropdownComponent additionalData={this.props.additionalData} inputCssName={this.props.inputCssName}
                            disabled={this.props.disabled} data={this.props.data} itemKey={this.props.itemKey} value={this.props.value}
                            onItemClick={this.props.onItemClick} onKeyDown={this.props.onKeyDown}
                        />
                        {
                            !this.props.readOnly
                                ? <span className="input-group-append">
                                    <button className="btn btn-secondary" type="button" onClick={(event) => this.props.onSaveClick(event, this.props.value)}>
                                        <i className="fas fa-check dgdg-font-blue" />
                                    </button>
                                </span>
                                : null
                        }
                        <span className="input-group-append">
                            <button className="btn btn-secondary" type="button" onClick={(event) => this.props.onHideClick(event)}>
                                <span className="fas fa-times text-danger" />
                            </button>
                        </span>
                    </div>
                </div>
                : null
        );
    }
}

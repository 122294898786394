import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class DirectoryPhoneNumbersComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            legendText: null,
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("DirectoryPhoneNumbersComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Directory/PhoneNumbers", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getLegendText();
            this.onGetReportClick();
            CommonService.clientAzureStorageLog("DirectoryPhoneNumbersComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Directory";

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-green" />&nbsp;&nbsp;&nbsp;Available</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-cyan" />&nbsp;&nbsp;&nbsp;1</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-yellow" />&nbsp;&nbsp;&nbsp;2</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-peachpuff" />&nbsp;&nbsp;&nbsp;3</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-pink" />&nbsp;&nbsp;&nbsp;4</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-honeydew" />&nbsp;&nbsp;&nbsp;5</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-lavender" />&nbsp;&nbsp;&nbsp;7</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-metalic-green" />&nbsp;&nbsp;&nbsp;10</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-plum" />&nbsp;&nbsp;&nbsp;11</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-silver-pink" />&nbsp;&nbsp;&nbsp;12</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-mistyrose" />&nbsp;&nbsp;&nbsp;13</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-mint" />&nbsp;&nbsp;&nbsp;14</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-mustard-yellow" />&nbsp;&nbsp;&nbsp;16</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-blue" />&nbsp;&nbsp;&nbsp;18</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-gold" />&nbsp;&nbsp;&nbsp;19</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-sage" />&nbsp;&nbsp;&nbsp;20</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-thistle" />&nbsp;&nbsp;&nbsp;21</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-purple" />&nbsp;&nbsp;&nbsp;22</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-yellow-chalk" />&nbsp;&nbsp;&nbsp;23</div>
            <div className="d-inline-block dgdg-directory-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-directory-bg-light-yellow" />&nbsp;&nbsp;&nbsp;99</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    setStoreColor(rowData) {
        switch (rowData.store_number) {
            case 1:
                rowData.store_number_cellCssName += " dgdg-directory-bg-cyan";
                break;
            case 2:
                rowData.store_number_cellCssName += " dgdg-directory-bg-yellow";
                break;
            case 3:
                rowData.store_number_cellCssName += "  dgdg-directory-bg-peachpuff";
                break;
            case 4:
                rowData.store_number_cellCssName += " dgdg-directory-bg-pink";
                break;
            case 5:
                rowData.store_number_cellCssName += " dgdg-directory-bg-honeydew";
                break;
            case 7:
                rowData.store_number_cellCssName += " dgdg-directory-bg-lavender";
                break
            case 10:
                rowData.store_number_cellCssName += " dgdg-directory-bg-metalic-green";
                break;
            case 11:
                rowData.store_number_cellCssName += " dgdg-directory-bg-plum";
                break;
            case 12:
                rowData.store_number_cellCssName += " dgdg-directory-bg-silver-pink";
                break;
            case 13:
                rowData.store_number_cellCssName += " dgdg-directory-bg-mistyrose";
                break;
            case 14:
                rowData.store_number_cellCssName += " dgdg-directory-bg-mint";
                break;
            case 16:
                rowData.store_number_cellCssName += " dgdg-directory-bg-mustard-yellow";
                break;
            case 18:
                rowData.store_number_cellCssName += " dgdg-directory-bg-blue";
                break;
            case 19:
                rowData.store_number_cellCssName += " dgdg-directory-bg-gold";
                break;
            case 20:
                rowData.store_number_cellCssName += " dgdg-directory-bg-sage";
                break;
            case 21:
                rowData.store_number_cellCssName += " dgdg-directory-bg-thistle";
                break;
            case 22:
                rowData.store_number_cellCssName += " dgdg-directory-bg-purple";
                break;
            case 23:
                rowData.store_number_cellCssName += " dgdg-directory-bg-yellow-chalk";
                break;
            case 99:
                rowData.store_number_cellCssName += " dgdg-directory-bg-light-yellow";
                break;
            default:
                rowData.store_number_cellCssName += "";
        }

        switch (rowData.preassign_store_number) {
            case 1:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-cyan";
                break;
            case 2:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-yellow";
                break;
            case 3:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-peachpuff";
                break;
            case 4:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-pink";
                break;
            case 5:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-honeydew";
                break;
            case 7:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-lavender";
                break
            case 10:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-metalic-green";
                break;
            case 11:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-plum";
                break;
            case 12:
                rowData.preassign_store_number_cellCssName += "dgdg-directory-bg-silver-pink";
                break;
            case 13:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-mistyrose";
                break;
            case 14:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-mint";
                break;
            case 16:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-mustard-yellow";
                break;
            case 18:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-blue";
                break;
            case 19:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-gold";
                break;
            case 20:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-sage";
                break;
            case 21:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-thistle";
                break;
            case 22:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-purple";
                break;
            case 23:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-yellow-chalk";
                break;
            case 99:
                rowData.preassign_store_number_cellCssName += " dgdg-directory-bg-light-yellow";
                break;
            default:
                rowData.preassign_store_number_cellCssName += "";
        }
    }

    onGetReportClick() {
        try {
            this.setState({
                isLoadingData: true
            });
            this.props.applicationInsights.trackTrace({ message: "DirectoryPhoneNumbersComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/Directory/GetPhoneNumbers", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    tableData.forEach((responseItem, index) => {
                        responseItem.rowCssName = responseItem.status === "available" ? "dgdg-directory-bg-green" : null;
                        responseItem.is_reserved_cellCssName = responseItem.is_reserved === true ? "dgdg-directory-bg-orange" : null;
                        this.setStoreColor(responseItem);
                    });
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Directory/GetPhoneNumbers", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DirectoryPhoneNumbersComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {

        return <div className="dgdg-report-body">
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader legendText={this.state.legendText}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Phone Number" dataColumn="phone_number" sortColumn="phone_number" filterColumn="phone_number" />
                        <DGDGTableColumnComponent headerText="Formatted Number" dataColumn="number_fmt" sortColumn="number_fmt" filterColumn="number_fmt" />
                        <DGDGTableColumnComponent headerText="NPA" dataColumn="npa" sortColumn="npa" filterColumn="npa" />
                        <DGDGTableColumnComponent headerText="NXX" dataColumn="nxx" sortColumn="nxx" filterColumn="nxx" />
                        <DGDGTableColumnComponent headerText="Last 4" dataColumn="last_4" sortColumn="last_4" filterColumn="last_4" />
                        <DGDGTableColumnComponent headerText="Store Number" dataColumn="store_number" sortColumn="store_number" filterColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Pre-assigned Store Number" dataColumn="preassign_store_number" sortColumn="preassign_store_number" filterColumn="preassign_store_number" />
                        <DGDGTableColumnComponent headerText="Reserved" dataColumn="is_reserved" sortColumn="is_reserved" filterColumn="is_reserved" dataType="bool" />
                        <DGDGTableColumnComponent headerText="Name" dataColumn="name" sortColumn="name" filterColumn="name" />
                        <DGDGTableColumnComponent headerText="Email" dataColumn="email" sortColumn="email" filterColumn="email" />
                        <DGDGTableColumnComponent headerText="Position Code" dataColumn="position_code" sortColumn="position_code" filterColumn="position_code" />
                        <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                        <DGDGTableColumnComponent headerText="First" dataColumn="first" sortColumn="first" filterColumn="first" />
                        <DGDGTableColumnComponent headerText="Last" dataColumn="last" sortColumn="last" filterColumn="last" />
                        <DGDGTableColumnComponent headerText="Logon Email" dataColumn="logon_email" sortColumn="logon_email" filterColumn="logon_email" />
                        <DGDGTableColumnComponent headerText="Employee Status" dataColumn="employee_status" sortColumn="employee_status" filterColumn="employee_status" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}

import React from "react";

export class DGDGFormTextAreaComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        let placeHolder = this.props.placeholder ? this.props.placeholder : this.props.label;
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        return <div className={"form-group " + colSpan} style={{ "margin": "0px" }}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan} htmlFor={this.props.id}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }
            <div className={"input-group " + controlSpan} style={{ "padding": "0px" }}>
                <textarea type="text" id={this.props.id} disabled={this.props.disabled} readOnly={this.props.readOnly} rows={this.props.rows} cols={this.props.cols} className={"form-control " + (this.props.inputCssName ? this.props.inputCssName : "")} placeholder={placeHolder} value={this.props.value ? this.props.value : ""} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} onBlur={(event) => this.onBlur(event)} />
            </div>
        </div>;
    }

    onTextChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.additionalData);
        }
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.id, event.target.value, this.props.additionalData);
        }
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarRangeComponent } from "../common/dgdgcalendar/DGDGFormCalendarRangeComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";

export class SellToDGDGOffersQueryComponent extends React.Component {
    static VinCookie = "SellToDGDGOffersVinCookie";
    static EmailCookie = "SellToDGDGOffersEmailCookie";
    static ShowExpiredCookie = "SellToDGDGOffersShowExpiredCookie";
    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf('month'), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf('month'), endDate: moment().subtract(1, "months").endOf('month') },
        { key: "2 Months Back", value: "2 Months Back", startDate: moment().subtract(2, "months").startOf('month'), endDate: moment().subtract(2, "months").endOf('month') },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Last Year", value: "Last Year", startDate: moment().subtract(1, "years").startOf('year'), endDate: moment().subtract(1, "years").endOf('year') },
        { key: "2 Years Back", value: "2 Years Back", startDate: moment().subtract(2, "years").startOf('year'), endDate: moment().subtract(2, "years").endOf('year') },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("SellToDGDGOffersQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        let startDate = moment().startOf('month');
        let endDate = moment()

        this.state = {
            startOfferDate: startDate,
            endOfferDate: endDate,
            selectedDateRange: this.dateRange[0],
            vin: "",
            email: "",
            showExpired: false,
            showSpinner: false,
            firstLoad: true
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onShowExpiredChange = this.onShowExpiredChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.firstLoad) {
                let vin = CommonService.getCookie(SellToDGDGOffersQueryComponent.VinCookie);
                let email = CommonService.getCookie(SellToDGDGOffersQueryComponent.EmailCookie);
                let showExpired = CommonService.getCookie(SellToDGDGOffersQueryComponent.ShowExpiredCookie) === "true";
                this.setState({
                    vin: vin,
                    email: email,
                    showExpired: showExpired,
                    firstLoad: false
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startOfferDate: startDate,
                endOfferDate: endDate
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            CommonService.setCookie(SellToDGDGOffersQueryComponent.VinCookie, this.state.vin ? this.state.vin : "");
            CommonService.setCookie(SellToDGDGOffersQueryComponent.EmailCookie, this.state.email ? this.state.email : "");
            CommonService.setCookie(SellToDGDGOffersQueryComponent.ShowExpiredCookie, this.state.showExpired);
            this.props.onGetReportClick(this.state.startOfferDate, this.state.endOfferDate, this.state.vin ? this.state.vin : null, this.state.email ? this.state.email : null, this.state.showExpired);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowExpiredChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormCalendarRangeComponent
                        applicationInsights={this.props.applicationInsights}
                        label="Offer Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                        startId="startOfferDate" startValue={this.state.startOfferDate} startMin={this.props.minDate} startMax={this.props.maxDate}
                        endId="endOfferDate" endValue={this.state.endOfferDate} endMin={this.props.minDate} endMax={this.props.maxDate} />
                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="email" label="Email" value={this.state.email} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <DGDGFormCheckboxComponent id="showExpired" label="Show Expired" value={this.state.showExpired} onChange={this.onShowExpiredChange} />
                    <div style={{ "height": "33px", "marginTop": "30px" }}>
                        <button disabled={this.state.showSpinner || !(this.state.endOfferDate._isValid && this.state.startOfferDate._isValid)} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <i className="fas fa-redo-alt dgdg-icon" />
                        </button>
                    </div>
                    <div style={{ "height": "33px", "marginTop": "30px" }}>
                        <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarRangeComponent } from "../common/dgdgcalendar/DGDGFormCalendarRangeComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class DMVRegistrationQueryComponent extends React.Component {
    static DateRangeCookie = "DMVRegistrationDateRangeCookie";
    static StartDateCookie = "DMVRegistrationStartDateCookie";
    static EndDateCookie = "DMVRegistrationEndDateCookie";
    static VinCookie = "DMVRegistrationVinCookie";
    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf('month'), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf('month'), endDate: moment().subtract(1, "months").endOf('month') },
        { key: "2 Months Back", value: "2 Months Back", startDate: moment().subtract(2, "months").startOf('month'), endDate: moment().subtract(2, "months").endOf('month') },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];


    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DMVRegistrationQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isDownloading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        let startDate = moment().startOf('month');
        let endDate = moment();

        this.state = {
            startDate: startDate,
            endDate: endDate,
            selectedDateRange: null,
            showSpinner: false,
            vin: ""
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onTextChange = this.onTextChange.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.selectedDateRange === null && !this.state.vin) {
                let selectedDateRange = this.dateRange[0];
                let cookieDateRange = CommonService.getCookie(DMVRegistrationQueryComponent.DateRangeCookie);
                if (cookieDateRange) {
                    let filterDateRange = this.dateRange.filter(dateRange => String(dateRange.key) === String(cookieDateRange));
                    selectedDateRange = filterDateRange.length > 0 ? filterDateRange[0] : null;
                }

                let selectedStartDate = CommonService.formatDateToISOString(this.state.startDate);
                let cookieStartDate = CommonService.getCookie(DMVRegistrationQueryComponent.StartDateCookie);
                if (cookieStartDate) {
                    selectedStartDate = moment(cookieStartDate);
                }

                let selectedEndDate = CommonService.formatDateToISOString(this.state.endDate);
                let cookieEndDate = CommonService.getCookie(DMVRegistrationQueryComponent.EndDateCookie);
                if (cookieEndDate) {
                    selectedEndDate = moment(cookieEndDate);
                }

                let vin = this.state.vin;
                if (!vin) {
                    vin = CommonService.getCookie(DMVRegistrationQueryComponent.VinCookie);
                }

                this.setState({
                    selectedDateRange: selectedDateRange,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    vin: vin
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startDate: startDate,
                endDate: endDate
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        CommonService.setCookie(DMVRegistrationQueryComponent.DateRangeCookie, this.state.selectedDateRange ? this.state.selectedDateRange.key : "", 30);
        CommonService.setCookie(DMVRegistrationQueryComponent.StartDateCookie, moment(CommonService.formatDateToISOString(this.state.startDate)).toDate(), 30);
        CommonService.setCookie(DMVRegistrationQueryComponent.EndDateCookie, moment(CommonService.formatDateToISOString(this.state.endDate)).toDate(), 30);
        CommonService.setCookie(DMVRegistrationQueryComponent.VinCookie, this.state.vin, 30);
        this.setState({ showSpinner: true });

        this.props.onGetReportClick(this.state.vin, this.state.startDate, this.state.endDate);
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let minDate = moment().subtract(2, "years").startOf('year').format('MM/DD/YYYY'),
            maxDate = moment().format('MM/DD/YYYY')

        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <div className="form-row dgdg-query">
                        <DGDGFormCalendarRangeComponent applicationInsights={this.props.applicationInsights}
                            label="Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                            startId="startDate" startValue={this.state.startDate} startMin={minDate} startMax={maxDate}
                            endId="endDate" endValue={this.state.endDate} endMin={minDate} endMax={maxDate} />
                        <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                        &nbsp;<button disabled={this.state.showSpinner || this.state.selectedDateRange === null || !(this.state.startDate._isValid && this.state.endDate._isValid)} className="btn btn-primary" onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <span className="fas fa-redo-alt dgdg-icon" />
                        </button>
                        &nbsp;<DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
import React from "react";

export class DGDGPageComponent extends React.Component {
    render() {
        let bodyCssName = this.props.bodyCssName ?? "";

        return <div className={"dgdg-site-body " + bodyCssName}>
            {this.props.children}
        </div>;
    }
}

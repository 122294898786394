import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportWeekendSummaryHeaderRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <Fragment>
            <tr className="text-center dgdg-sales-store-bg-title-foot">
                <td colSpan="11" className="dgdg-sales-store-bg">&nbsp; </td>
                <td colSpan="2">VOLUME</td>
                <td colSpan="2">GROSS</td>
                <td colSpan="2">PVR</td>
            </tr>
            <tr className="dgdg-table-v3-header">
                <td colSpan="11" className="dgdg-sales-store-bg dgdg-no-border-right" />
                <td>WOW %&Delta;</td>
                <td>YOY %&Delta;</td>
                <td>WOW %&Delta;</td>
                <td>YOY %&Delta;</td>
                <td>WOW $&Delta;</td>
                <td>YOY $&Delta;</td>
            </tr>
        </Fragment>;
    }
}
